import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { suggestionUserToIconListItem } from '@app/web/src/components/SuggestionPanel/utils';
import { InvitationNewUserItem } from '@app/web/src/hooks/utils/useAhaInvitation';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItem } from '@lib/ia/src/layouts/IconListLayout/types';

import { SuggestionUserIconListLayoutItemObj } from '../../../../SuggestionPanel/types';
import CreateQuizContext from '../../../context';
import { ChallengerIconListLayoutItemObj } from '../../../type';

export default function useAddFriendsTabLayoutConfig({
  displayDataset,
  totalCount = 0,
  listState,
  newUser,
  selectedIds,
  searchLoading,
  isSelected,
}: {
  displayDataset: ChallengerIconListLayoutItemObj[];
  totalCount: number;
  listState: SearchState | null;
  newUser: InvitationNewUserItem | null;
  selectedIds: string[];
  searchLoading: boolean;
  isSelected: (id: string) => boolean;
}) {
  const { t } = useTranslation('quiz');
  const [{ challengerMap }] = useContext(CreateQuizContext);

  return useMemo<IaLayoutConfig[]>(() => {
    const selectedChallengers: SuggestionUserIconListLayoutItemObj[] = [];
    selectedIds.forEach((challengerId) => {
      if (challengerMap[challengerId]) {
        selectedChallengers.push(
          suggestionUserToIconListItem(challengerMap[challengerId])
        );
      }
    });
    const selectedItems: IconListLayoutItem[] =
      selectedChallengers.length > 0
        ? [
            t('createQuiz.challengers.search.count_selected', {
              count: selectedChallengers.length,
            }),
            ...selectedChallengers,
          ]
        : [];

    const filteredDataset = displayDataset.filter((d) => !isSelected(d.id));
    const filteredTotalCount = totalCount - selectedChallengers.length;

    if (listState === SearchState.Initial) {
      if (displayDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items: selectedItems,
          },
        ];
      }

      const resultItems =
        filteredDataset.length > 0
          ? [
              t('createQuiz.challengers.search.count', {
                count: filteredDataset.length,
              }),
              ...filteredDataset,
            ]
          : [];

      return [
        {
          layout: 'icon-list-layout',
          items: [...selectedItems, ...resultItems],
        },
      ];
    }

    if (listState === SearchState.Searching) {
      if (!searchLoading && displayDataset.length === 0 && !newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [t('createQuiz.challengers.noResult')],
          },
        ];
      }

      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t('createQuiz.challengers.cta_invite'),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }
    }

    const resultItems =
      filteredDataset.length > 0
        ? [
            t('createQuiz.challengers.search.count_0', {
              count: filteredTotalCount,
            }),
            ...filteredDataset,
          ]
        : [];

    return [
      {
        layout: 'icon-list-layout',
        items: [...selectedItems, ...resultItems],
      },
    ];
  }, [
    selectedIds,
    t,
    displayDataset,
    totalCount,
    listState,
    challengerMap,
    isSelected,
    searchLoading,
    newUser,
  ]);
}
