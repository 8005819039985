import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  SmallIconListLayoutConfig,
  SmallIconListLayoutDetailedItem,
} from '@lib/ia/src/layouts/SmallIconListLayout/types';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { useIaWorkflows, WorkflowViewSlug } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

export default function SelectWorkflowPanel() {
  const { t } = useTranslation('agent');
  const { club } = useCurrentIaClub();
  const clubId = club?.clubId;

  const { getRightParams, rightPanelHistory } =
    useBaseRightPanel<GlobalPanelParams>();
  const { onSelect } = getRightParams(GlobalPanelKeys.GlobalSelectWorkflow);

  const { dataset, isLoading, totalCount } = useIaWorkflows(
    {
      viewSlug: WorkflowViewSlug.ListCenterIaAiWorkflowDefault,
      ...combineSearch([`club:${clubId}`, 'club:eq']),
    },
    {
      enable: !!clubId,
    }
  );

  const layouts = useMemo(
    () => [
      {
        layout: 'small-icon-list-layout',
        areas: [
          {
            key: 'detailedIconList',
            areaType: 'detailedIconList',
            title: t('workflow.count', { count: totalCount }),
            items: dataset.map((workflow) => ({
              id: workflow.id,
              icon: {
                type: 'icon',
                value: 'AgentWorkflowContainRecipes',
                text: workflow.recipeNum.toString(),
              },
              content: {
                type: 'content',
                value: workflow.name,
              },
              properties: [],
              buttons: [
                {
                  type: 'button',
                  value: 'selectWorkflow',
                  text: t('workflow.select.button'),
                  variant: 'filled',
                  color: 'default',
                  prefixIcon: 'TestAdd',
                },
              ],
              metadata: {
                workflow,
              },
            })),
            isLoading,
          },
        ],
      } as SmallIconListLayoutConfig,
    ],
    [dataset, isLoading, totalCount, t]
  );

  const availableActions = {
    selectWorkflow: {
      action: (
        item: SmallIconListLayoutDetailedItem<{
          workflow: GetIaWorkflowDefaultRes;
        }>
      ) => {
        if (!item.metadata) return;

        onSelect?.(item.metadata.workflow);
        rightPanelHistory.back();
      },
    },
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        title={t('workflow.select.title')}
        titleIcon={<Icon name="AgentWorkflow" width={16} height={16} />}
      >
        <BaseLayoutRightPanel.ScrollArea>
          <IaLayouts layouts={layouts} />
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
