import { AxiosError } from 'axios';
import { AnswerFormatType, apis } from '@lib/web/apis';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

const types = {
  FETCH_QUESTIONS: 'SEO/FETCH_QUESTIONS',
  SET_SHORT_ID: 'SEO/SET_SHORT_ID',
  UPDATE_QUESTION_INDEX: 'SEO/UPDATE_QUESTION_INDEX',
  UPDATE_QUESTION_NOTE: 'SEO/UPDATE_QUESTION_NOTE',
  START_ANSWER: 'SEO/START_ANSWER',
  UPDATE_ANSWER: 'SEO/UPDATE_ANSWER',
  SUBMIT_ANSWER: 'SEO/SUBMIT_ANSWER',
  PAUSE_QUIZ: 'SEO/PAUSE_QUIZ',
  RESUME_QUIZ: 'SEO/RESUME_QUIZ',
  LAZY_SUBMIT: 'SEO/LAZY_SUBMIT',
  CANCEL_SUBMIT: 'SEO/CANCEL_SUBMIT',
  ELIMINATE_ANSWER: 'SEO/ELIMINATE_ANSWER',
};

export const setShortId = createAction(types.SET_SHORT_ID, (id: string) => ({
  payload: id,
}));

export const updateQuestionIndex = createAction(
  types.UPDATE_QUESTION_INDEX,
  (index: number) => ({
    payload: index,
  })
);

export const updateQuestionNote = createAction(
  types.UPDATE_QUESTION_NOTE,
  (text: string) => ({
    payload: text,
  })
);

export const startAnswer = createAction(
  types.START_ANSWER,
  (shortId: string) => ({
    payload: shortId,
  })
);

export const updateAnswer = createAction(
  types.UPDATE_ANSWER,
  (value: string[], shortId: string) => ({
    payload: {
      value,
      shortId,
    },
  })
);

export const eliminateAnswer = createAction(
  types.ELIMINATE_ANSWER,
  (value: string[], shortId: string) => ({
    payload: {
      value,
      shortId,
    },
  })
);

export const submitAnswer = createAction(
  types.SUBMIT_ANSWER,
  (questionAnswer: {
    creatorQuestionInfo: { answerFormatType: AnswerFormatType };
    correctAnswerIds: string[];
    correctAnswerValues: string[];
    tpq: number;
  }) => ({
    payload: {
      questionAnswer,
    },
  })
);

export const pauseQuiz = createAction(types.PAUSE_QUIZ);
export const resumeQuiz = createAction(types.RESUME_QUIZ);
export const lazySubmit = createAction(types.LAZY_SUBMIT);
export const cancelSubmit = createAction(types.CANCEL_SUBMIT);

export const fetchQuestions = createAsyncThunk<
  { questionMetadata: GetQuestionMetadataRes },
  { clubSlug?: string },
  { rejectValue: ResponseError }
>(types.FETCH_QUESTIONS, async ({ clubSlug }, { rejectWithValue }) => {
  try {
    const res = await apis.func.getQuestionMetadata(clubSlug);
    return {
      questionMetadata: res.data.data,
    };
  } catch (err: any) {
    const error: AxiosError<ResponseError> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
