import React from 'react';
import { alpha, Box, SxProps, Theme } from '@mui/material';

type SizeType = 'md' | 'lg';
type ColorType = 'success' | 'error';

export type CircleIndicatorProps = {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  size?: SizeType;
  color?: ColorType;
  circleSx?: SxProps<Theme>;
};

const styles = {
  root: (size: SizeType) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: { md: 160, lg: 220 }[size],
    height: { md: 160, lg: 220 }[size],
    position: 'relative',
  }),
  circle: (size: SizeType, color: ColorType) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: { md: 156, lg: 213 }[size],
    height: { md: 156, lg: 213 }[size],
    borderRadius: '50%',
    border: (theme: Theme) =>
      `${{ md: 7, lg: 9 }[size]}px solid ${
        {
          success: theme.palette.success.light,
          error: theme.palette.error.dark,
        }[color]
      }`,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: { md: 156, lg: 213 }[size],
      height: { md: 156, lg: 213 }[size],
      borderRadius: '50%',
      border: (theme: Theme) =>
        `${{ md: 18, lg: 27 }[size]}px solid ${alpha(
          {
            success: theme.palette.success.light,
            error: theme.palette.error.dark,
          }[color],
          0.5
        )}`,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: { md: 98, lg: 134 }[size],
      height: { md: 98, lg: 134 }[size],
      borderRadius: '50%',
      border: (theme: Theme) =>
        `1px solid ${
          {
            success: theme.palette.success.light,
            error: theme.palette.error.dark,
          }[color]
        }`,
    },
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default function CircleIndicator({
  children,
  tooltip,
  size = 'md',
  color = 'success',
  circleSx,
}: CircleIndicatorProps) {
  const circleSxProps = Array.isArray(circleSx) ? circleSx : [circleSx];

  return (
    <Box sx={styles.root(size)}>
      <Box sx={[styles.circle(size, color), ...circleSxProps]}>
        <Box sx={styles.content}>{children}</Box>
      </Box>
      {tooltip}
    </Box>
  );
}
