export default function OtherPDF({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4 20.5V3.5C4 2.67157 4.67157 2 5.5 2H15L21 8V20.5C21 21.3284 20.3284 22 19.5 22H5.5C4.67157 22 4 21.3284 4 20.5Z"
        fill="#E5252A"
      />
      <path d="M21 8L15 2V6.5C15 7.32843 15.6716 8 16.5 8H21Z" fill="#ED676A" />
      <path
        d="M15.2334 16.1799V12.0479H18V12.8289H16.1636V13.6935H17.7973V14.4746H16.1636V16.1799H15.2334Z"
        fill="white"
      />
      <path
        d="M11.7676 15.4225C11.8113 15.4265 11.861 15.4304 11.9166 15.4344C11.9762 15.4344 12.0458 15.4344 12.1253 15.4344C12.5904 15.4344 12.9342 15.3171 13.1568 15.0826C13.3834 14.8481 13.4967 14.5241 13.4967 14.1107C13.4967 13.6775 13.3894 13.3495 13.1747 13.1269C12.9601 12.9043 12.6202 12.793 12.1551 12.793C12.0915 12.793 12.0259 12.795 11.9584 12.799C11.8908 12.799 11.8272 12.803 11.7676 12.8109V15.4225ZM14.4567 14.1107C14.4567 14.4685 14.401 14.7805 14.2897 15.0468C14.1784 15.3132 14.0194 15.5338 13.8127 15.7087C13.61 15.8836 13.3615 16.0148 13.0674 16.1022C12.7732 16.1897 12.4433 16.2334 12.0776 16.2334C11.9107 16.2334 11.7159 16.2254 11.4933 16.2095C11.2707 16.1976 11.0521 16.1698 10.8374 16.1261V12.1014C11.0521 12.0616 11.2747 12.0358 11.5052 12.0239C11.7397 12.008 11.9405 12 12.1074 12C12.4612 12 12.7812 12.0398 13.0674 12.1193C13.3576 12.1988 13.606 12.324 13.8127 12.4949C14.0194 12.6658 14.1784 12.8844 14.2897 13.1508C14.401 13.4171 14.4567 13.7371 14.4567 14.1107Z"
        fill="white"
      />
      <path
        d="M8.2402 12C8.85633 12 9.32936 12.1093 9.65928 12.3279C9.98921 12.5426 10.1542 12.8964 10.1542 13.3893C10.1542 13.8861 9.98722 14.2459 9.65332 14.4685C9.31942 14.6871 8.84242 14.7964 8.22232 14.7964H7.93015V16.1797H7V12.1073C7.20273 12.0676 7.41738 12.0398 7.64395 12.0239C7.87053 12.008 8.06928 12 8.2402 12ZM8.29983 12.793C8.23225 12.793 8.16468 12.795 8.0971 12.799C8.0335 12.803 7.97785 12.8069 7.93015 12.8109V14.0034H8.22232C8.54429 14.0034 8.78677 13.9597 8.94974 13.8722C9.11272 13.7848 9.19421 13.6218 9.19421 13.3833C9.19421 13.268 9.17234 13.1726 9.12862 13.0971C9.08887 13.0216 9.02924 12.962 8.94974 12.9182C8.87422 12.8705 8.78081 12.8387 8.6695 12.8228C8.5582 12.803 8.43498 12.793 8.29983 12.793Z"
        fill="white"
      />
    </svg>
  );
}
