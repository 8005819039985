import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCloudfront from '@app/web/src/hooks/utils/useCloudfront';
import { toast } from '@front/ui';
import { apis, useAuth, useMyCurrency } from '@lib/web/apis';
import { getCurrencyCode } from '@lib/web/utils';

import { ProductCheckoutContext } from '../contexts/checkoutContext';

export default function useProductCheckout() {
  const { t } = useTranslation('marketplace');
  const [value, setValue] = useContext(ProductCheckoutContext);
  const { member } = useAuth();

  const { data: currencyData } = useMyCurrency();
  const { country } = useCloudfront();
  const currency =
    currencyData?.data.currency?.toUpperCase() || getCurrencyCode(country);

  const setActiveProduct = (activeProduct: GetSanityProductRes) => {
    setValue((st) => ({
      ...st,
      activeProduct,
    }));
  };

  const setDiscountCode = (discountCode?: GetMyPromoCodeRes) => {
    setValue((st) => ({
      ...st,
      discountCode,
    }));
  };

  const setUseAhaPoint = (isUseAhaPoint: boolean) => {
    setValue((st) => ({
      ...st,
      isUseAhaPoint,
    }));
  };

  const createCheckoutOrder = async (shared = false) => {
    if (!member) return;

    const { activeProduct, isUseAhaPoint, discountCode } = value;
    if (!currency || !activeProduct) return;

    const { data: stock } = await apis.marketplace.getProductStock(
      activeProduct.id
    );
    if (!stock || stock.data <= 0) {
      toast.error(t('Oops! ## just sold out', { name: activeProduct.name }));
      return '';
    }

    try {
      const successUrl = !shared
        ? `${process.env.APP_URL}/marketplace/my-avatars?productId=${activeProduct.id}&purchased=true`
        : `${process.env.APP_URL}/public/payment-link/product?memberId=${member.memberId}&sourceId={CHECKOUT_SESSION_ID}`;
      const cancelUrl = !shared
        ? `${process.env.APP_URL}/marketplace/available?productId=${activeProduct.id}`
        : `${process.env.APP_URL}/public/payment-link/product?cancelled=true&memberId=${member.memberId}&sourceId={CHECKOUT_SESSION_ID}`;
      const createCheckoutData: ProductCheckoutReq = {
        products: [
          {
            productId: activeProduct.id,
            quantity: 1,
            currency: currency,
          },
        ],
        isUseAhaPoint: isUseAhaPoint,
        promoCode: isUseAhaPoint ? undefined : discountCode?.code || undefined,
        successUrl,
        cancelUrl,
      };
      const response = await apis.product.checkout(createCheckoutData);

      if (response) {
        return response.data.data;
      }

      toast.error(t('Oops! Something went wrong'));
    } catch (err) {
      toast.error(t('Oops! Something went wrong'));
    }
    return null;
  };

  const loadOrderInfo = async (orderId: string) => {
    try {
      const orderInfoResponse = await apis.marketplace.getProductOrderInfo(
        orderId
      );
      const shareInfoResponse = await apis.marketplace.shareProductOrderInfo({
        orderId,
      });

      setValue((st) => ({
        ...st,
        orderInfo: orderInfoResponse.data.data,
        shareInfo: shareInfoResponse.data.data,
      }));
      return true;
    } catch (err) {
      toast.error(t('Oops! Something went wrong'));
    }
    return false;
  };

  const checkout = async () => {
    const order = await createCheckoutOrder();
    if (order && !order.url) {
      // no need to pay
      return true;
    }
    if (order && order.url) {
      window.location.replace(order.url);
    }
  };
  return {
    ...value,
    currency,
    setActiveProduct,
    setDiscountCode,
    setUseAhaPoint,
    createCheckoutOrder,
    loadOrderInfo,
    checkout,
  };
}
