import { ReactNode } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { sanitize } from 'dompurify';

const styles = {
  root: {
    display: 'grid',
    gap: 1,
  },
  text: {
    opacity: 0.64,
  },
};
export default function EmptySection({
  sx,
  text,
  actionComponent,
}: {
  sx?: BoxProps['sx'];
  actionComponent?: ReactNode;
  text: string;
}) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, ...sxProps]}>
      <Typography
        sx={styles.text}
        variant="body2"
        component="div"
        dangerouslySetInnerHTML={{
          __html: sanitize(text),
        }}
      />

      {!!actionComponent && <Box>{actionComponent}</Box>}
    </Box>
  );
}
