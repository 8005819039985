import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Skeleton, Theme, useMediaQuery } from '@mui/material';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import useMenuClub from '@app/web/src/hooks/utils/useMenuClub';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { useLatestValueRef } from '@front/helper';
import {
  Icon,
  MaskIcon,
  SquareAvatar,
  useBaseLayout,
  useBaseLeftPanel,
  useBaseRightPanel,
} from '@front/ui';
import { useIaClub } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { getClubIconPath } from '@lib/web/utils';

import getAnalyticsPageConfig from '../../pageConfig/analytics';
import getClubPageConfig from '../../pageConfig/club';
import { ClubMenuVariant, TriggerTemporaryMenuType } from '../context';
import useCurrentMenu from '../hooks/useCurrentMenu';
import useMainLayout from '../hooks/useMainLayout';

import NavButton from './NavButtons/NavButton';
import SubNavButton from './NavButtons/SubNavButton';

const styles = {
  root: {
    mb: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  button: {
    opacity: 1,
  },
};

function NewClubButton({
  slug,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  slug: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) {
  const { club, isLoading } = useIaClub(slug);
  const { temporaryMenuTarget } = useMainLayout();

  if (isLoading) {
    return (
      <Box sx={styles.root}>
        <NavButton disabled>
          <MaskIcon>
            <Skeleton width={32} height={32} variant="rectangular" />
          </MaskIcon>
        </NavButton>
      </Box>
    );
  }

  if (!club) return null;

  const validIconPath = club.clubLogo || getClubIconPath(club.clubName || '');

  return (
    <Box sx={styles.root}>
      <NavButton
        data-testid="club-button"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        active={temporaryMenuTarget === 'clubs'}
      >
        <SquareAvatar src={validIconPath} size={32}>
          {club.clubName}
        </SquareAvatar>
      </NavButton>
    </Box>
  );
}
function ClubButton({
  club,
  isGroup,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  club: GetIaClubProfileCenterDefaultViewRes;
  isGroup?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) {
  const { t } = useTranslation();
  const { unreadChannels } = useThread();
  const validIconPath = club.clubLogo || getClubIconPath(club.clubName || '');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { seeClubMenu, temporaryMenuTarget, triggerTemporaryMenuType } =
    useMainLayout();
  const { openRightPanel } = useBaseLayout();
  const hasUnreadChannelsForThisClub = useMemo(
    () =>
      unreadChannels.some((channel) => channel.data?.clubId === club.clubId),
    [unreadChannels, club.clubId]
  );
  const analyticsPageConfig = getAnalyticsPageConfig(t);
  const clubPageConfig = getClubPageConfig(t);
  const { currentClubMenu } = useCurrentMenu();

  const { views } = useClubThreadViews({
    clubId: club.clubId,
  });
  const { hasEditAuth } = useMemberClubRole();

  const handleSeeMenu = (target: ClubMenuVariant, href: string) => {
    if (
      mdUp ||
      target === 'practice' ||
      target === 'rankings' ||
      target === 'summary'
    ) {
      Router.push(href);
    }
    seeClubMenu(target);
  };
  const isActive = (menu: ClubMenuVariant | null) => {
    return (
      menu === currentClubMenu &&
      !(temporaryMenuTarget && temporaryMenuTarget !== 'club-suggestion')
    );
  };
  const handleAddFriendsClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAddFriends);
  };

  const allThreadView =
    views && views.length > 0 ? views[views.length - 1] : false;
  const viewHref = allThreadView
    ? `/club/${club.clubSlug}/thread/${allThreadView.id}`
    : '';

  return (
    <Box sx={styles.root}>
      <NavButton
        data-testid="club-button"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        hasNotification={hasUnreadChannelsForThisClub}
        sx={styles.button}
        mask={isGroup ? false : true}
        active={
          temporaryMenuTarget === 'clubs' &&
          triggerTemporaryMenuType === 'clicked'
        }
      >
        <SquareAvatar src={validIconPath} size={32} showStacked={isGroup}>
          {club.clubName}
        </SquareAvatar>
      </NavButton>

      <SubNavButton
        onClick={() =>
          handleSeeMenu('summary', `/club/${club.clubSlug}/summary`)
        }
        active={isActive('summary')}
        tooltip={analyticsPageConfig.summary.title}
        title={analyticsPageConfig.summary.title}
      >
        <Icon name={analyticsPageConfig.summary.icon} />
      </SubNavButton>
      <SubNavButton
        onClick={() =>
          handleSeeMenu('practice', `/club/${club.clubSlug}/start`)
        }
        active={isActive('practice')}
        tooltip={clubPageConfig.startPractice.title}
        title={clubPageConfig.startPractice.shortTitle}
      >
        <Icon name={clubPageConfig.startPractice.icon} />
      </SubNavButton>
      <SubNavButton
        onClick={() => handleSeeMenu('threads', viewHref)}
        active={isActive('threads')}
        tooltip={clubPageConfig.allThreads.title}
        title={clubPageConfig.allThreads.shortTitle}
        hasNotification={hasUnreadChannelsForThisClub}
      >
        <Icon name={clubPageConfig.allThreads.icon} />
      </SubNavButton>
      <SubNavButton
        onClick={() =>
          handleSeeMenu('rankings', `/club/${club.clubSlug}/rankings`)
        }
        active={isActive('rankings')}
        tooltip={clubPageConfig.rankings.title}
        title={clubPageConfig.rankings.title}
      >
        <Icon name={clubPageConfig.rankings.icon} />
      </SubNavButton>
      <SubNavButton
        onClick={() =>
          handleSeeMenu('analytics', `/club/${club.clubSlug}/analytics/my-path`)
        }
        active={isActive('analytics')}
        tooltip={t('analytics.title')}
        title={t('analytics.title')}
      >
        <Icon name="MainAnalytics" />
      </SubNavButton>
      <SubNavButton
        onClick={() =>
          handleSeeMenu('playlists', `/club/${club.clubSlug}/playlists/history`)
        }
        active={isActive('playlists')}
        tooltip={t('playlists.title')}
        title={t('playlists.title')}
      >
        <Icon name="ProfilePlaylist" />
      </SubNavButton>
      <SubNavButton
        onClick={handleAddFriendsClick}
        tooltip={clubPageConfig.addFriends.title}
        title={clubPageConfig.addFriends.shortTitle}
      >
        <Icon name="OtherAddFriend" />
      </SubNavButton>
      <SubNavButton
        onClick={() =>
          Router.push(
            `/club/form/${club.clubSlug}/${
              hasEditAuth ? 'overview' : 'members'
            }`
          )
        }
        tooltip={clubPageConfig.settings.title}
        title={clubPageConfig.settings.shortTitle}
      >
        <Icon name={clubPageConfig.settings.icon} />
      </SubNavButton>
    </Box>
  );
}

export default function ClubNav() {
  const { club, groups, clubSlug } = useMenuClub();
  const { rightPanelOpened, closeRightPanel } = useBaseRightPanel();
  const { enableLeftPanel, openLeftPanel } = useBaseLeftPanel();
  const { seeClubs, triggerTemporaryMenuType } = useMainLayout();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const triggerTemporaryMenuTypeRef = useLatestValueRef(
    triggerTemporaryMenuType
  );

  const openClubsMenu = (triggerType: TriggerTemporaryMenuType) => {
    if (rightPanelOpened) closeRightPanel();

    seeClubs(triggerType);
    enableLeftPanel();
    openLeftPanel();
  };
  const handleClickClub = () => {
    openClubsMenu('clicked');
  };

  const handleHoverStart = () => {
    timeoutRef.current = setTimeout(() => {
      // nothing to do when the user already has clicked on the club logo
      if (!triggerTemporaryMenuTypeRef.current) openClubsMenu('hovered');
    }, 300);
  };

  const handleHoverEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  if (!club && clubSlug)
    return (
      <NewClubButton
        onClick={handleClickClub}
        onMouseEnter={handleHoverStart}
        onMouseLeave={handleHoverEnd}
        slug={clubSlug}
      />
    );

  if (!club) return null;
  return (
    <ClubButton
      onClick={handleClickClub}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      isGroup={groups.length > 0}
      club={club}
    />
  );
}
