import React, { useEffect } from 'react';
import { Router } from 'next/router';
import { useBaseRightPanel } from '@front/ui';
import { AccountBlockerType } from '@lib/web/apis';
import { PaidFeatureBlocker, PaidFeatureBlockerType } from '@lib/web/ui';

import AccountBlocker from './components/AccountBlocker';
import useBlocker from './hooks/useBlocker';

export default function BlockerControl() {
  const { rightPanelOpened, rightPanelTarget } = useBaseRightPanel();
  const { currentBlockerType, currentPlacement, closeBlocker } = useBlocker();

  useEffect(() => {
    const handleRouteChange = () => {
      if (currentBlockerType) {
        closeBlocker();
      }
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeError', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeError', handleRouteChange);
    };
  }, [currentBlockerType, closeBlocker]);

  useEffect(() => {
    if (!rightPanelOpened) {
      closeBlocker();
    }
  }, [rightPanelOpened, closeBlocker]);

  useEffect(() => {
    closeBlocker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelTarget]);

  if (!currentPlacement || !currentBlockerType) return;

  if (
    currentBlockerType === PaidFeatureBlockerType.SelectIncognitoMode ||
    currentBlockerType === PaidFeatureBlockerType.SelectSuperLevelDifficulty ||
    currentBlockerType === PaidFeatureBlockerType.ViewSolution
  )
    return (
      <PaidFeatureBlocker
        type={currentBlockerType}
        placement={currentPlacement}
        onClose={closeBlocker}
      />
    );

  if (
    currentBlockerType === AccountBlockerType.Unclaimed ||
    currentBlockerType === AccountBlockerType.Unverified
  )
    return (
      <AccountBlocker
        type={currentBlockerType}
        placement={currentPlacement}
        onClose={closeBlocker}
      />
    );

  return null;
}
