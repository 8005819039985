import { AccountBlockerType } from '@lib/web/apis';
import { PaidFeatureBlockerType } from '@lib/web/ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BlockerType = PaidFeatureBlockerType | AccountBlockerType;
export type BlockerPlacement = 'center' | 'rhs' | 'menu';

const initialState: {
  blockerType: BlockerType | null;
  placement: BlockerPlacement | null;
} = {
  blockerType: null,
  placement: null,
};

const blockerSlice = createSlice({
  name: 'blocker',
  initialState,
  reducers: {
    setBlocker: (
      state,
      {
        payload,
      }: PayloadAction<{
        blockerType: BlockerType;
        placement: BlockerPlacement;
      }>
    ) => {
      state.blockerType = payload.blockerType;
      state.placement = payload.placement;
    },
    clearBlocker: (state) => {
      state.blockerType = null;
      state.placement = null;
    },
  },
});

export const { setBlocker, clearBlocker } = blockerSlice.actions;
export default blockerSlice.reducer;
