import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import AgentProfilePanel from '@app/web/src/widgets/CommonPanels/AgentProfilePanel';
import ClubMembersPanel from '@app/web/src/widgets/CommonPanels/ClubMembersPanel';
import FilterPanel from '@app/web/src/widgets/CommonPanels/FilterPanel';
import FindUserPanel from '@app/web/src/widgets/CommonPanels/FindUserPanel';
import GoalRingPanel from '@app/web/src/widgets/CommonPanels/GoalRingPanel';
import HashtagDetailPanel from '@app/web/src/widgets/CommonPanels/HashtagDetailPanel';
import LayoutSettingPanel from '@app/web/src/widgets/CommonPanels/LayoutSettingPanel';
import ManageAgentsPanel from '@app/web/src/widgets/CommonPanels/ManageAgentsPanel';
import ManageAgentsPanelSearch from '@app/web/src/widgets/CommonPanels/ManageAgentsPanelSearch';
import MutualClubsPanel from '@app/web/src/widgets/CommonPanels/MutualClubsPanel';
import ProfilePanels from '@app/web/src/widgets/CommonPanels/ProfilePanels';
import RankingsPanel from '@app/web/src/widgets/CommonPanels/RankingsPanel';
import SortPanel from '@app/web/src/widgets/CommonPanels/SortPanel/SortPanel';
import ThreadEmojiRightPanel from '@app/web/src/widgets/CommonPanels/ThreadEmojiPanel';
import ThreadActiveMembersPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadActiveMembersPanel';
import ThreadAddMemberPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadAddMemberPanel';
import ThreadChatPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadChatPanel';
import ThreadCreatorFromViewPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadCreatorFromViewPanel';
import ThreadMembersPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadMembersPanel';
import ThreadPropertiesPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadPropertiesPanel';
import ThreadViewPanel from '@app/web/src/widgets/CommonPanels/ThreadViewPanel';
import { BaseLayoutRightPanelPortal, useBaseLayout } from '@front/ui';
import {
  TextComposerPanelKeys,
  TextComposerPanelParams,
} from '@lib/web/editor/TextComposerPanels/panel';

import AddClubGroupPanel from '../../widgets/CommonPanels/AddClubGroupPanel';
import AddDiscountPanel from '../../widgets/CommonPanels/AddDiscountPanel';
import AddFriendsPanel from '../../widgets/CommonPanels/AddFriendsPanel';
import AskForHelpPanel from '../../widgets/CommonPanels/AskForHelpPanel';
import AvatarDetailPanel from '../../widgets/CommonPanels/AvatarDetailPanel';
import AwardDetailsPanel from '../../widgets/CommonPanels/AwardDetailsPanel';
import ChallengeFriendsPanel from '../../widgets/CommonPanels/ChallengeFriendsPanel';
import ChallengersPanels from '../../widgets/CommonPanels/ChallengersPanels/ChallengersPanels';
import ClubInvitationPanel from '../../widgets/CommonPanels/ClubInvitationPanel';
import ClubsPanel from '../../widgets/CommonPanels/ClubsPanel';
import FollowersPanel from '../../widgets/CommonPanels/FollowersPanel';
import FollowingPanel from '../../widgets/CommonPanels/FollowingPanel';
import FollowMorePanel from '../../widgets/CommonPanels/FollowMorePanel';
import InviteByEmailPanel from '../../widgets/CommonPanels/InviteByEmailPanel';
import PlaylistDetailPanel from '../../widgets/CommonPanels/PlaylistDetailPanel';
import ProfileAwardsPanel from '../../widgets/CommonPanels/ProfileAwardsPanel';
import SelectWorkflowPanel from '../../widgets/CommonPanels/SelectWorkflowPanel';
import SetGoalPanel from '../../widgets/CommonPanels/SetGoalPanel';
import ShareDiscountPanel from '../../widgets/CommonPanels/ShareDiscountPanel';
import SharePanel from '../../widgets/CommonPanels/SharePanel';
import StartChallengePanel from '../../widgets/CommonPanels/StartChallengePanel';
import StreaksPanel from '../../widgets/CommonPanels/StreaksPanel';
import ThreadCreatorFromAskForHelpPanel from '../../widgets/CommonPanels/ThreadPanels/ThreadCreatorFromAskForHelpPanel';
import ThreadCreatorFromMessagePanel from '../../widgets/CommonPanels/ThreadPanels/ThreadCreatorFromMessagePanel';

export default function GlobalRightPanels({ missingClubQuery = false }) {
  const { rightPanelTarget } = useBaseLayout<
    GlobalPanelParams & TextComposerPanelParams
  >();

  return (
    <BaseLayoutRightPanelPortal>
      {rightPanelTarget.includes(GlobalPanelKeys.GlobalProfile) && (
        <ProfilePanels />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalProfileAwards && (
        <ProfileAwardsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFollowers && (
        <FollowersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFollowing && (
        <FollowingPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalClubs && <ClubsPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalMutualClubs && (
        <MutualClubsPanel />
      )}

      {/* "GlobalClubInvitation" does not apply to pages unrelated to clubs, such as
      on profiles where the functionality of "GlobalClubInvitation" cannot be
      used. */}
      {(rightPanelTarget === GlobalPanelKeys.GlobalClubInvitation ||
        rightPanelTarget === GlobalPanelKeys.GlobalProfile) &&
        !missingClubQuery && <ClubInvitationPanel />}
      {rightPanelTarget.includes(GlobalPanelKeys.GlobalHashtagsDetail) && (
        <HashtagDetailPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFindUser && <FindUserPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalFollowMore && (
        <FollowMorePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalGoalRing && <GoalRingPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalLayout && (
        <LayoutSettingPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFilter && <FilterPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalSort && <SortPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalRankings && <RankingsPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalManageAgents && (
        <ManageAgentsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalManageAgentsSearch && (
        <ManageAgentsPanelSearch />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAgentProfile && (
        <AgentProfilePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalClubMembers && (
        <ClubMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAddFriends && (
        <AddFriendsPanel />
      )}
      {[
        GlobalPanelKeys.GlobalThreadChat,
        TextComposerPanelKeys.TextComposerLineMarker,
        TextComposerPanelKeys.TextComposerHighlight,
        TextComposerPanelKeys.TextComposerVariable,
      ].includes(rightPanelTarget) && <ThreadChatPanel />}
      {rightPanelTarget ===
        GlobalPanelKeys.GlobalThreadCreatorFromViewPanel && (
        <ThreadCreatorFromViewPanel />
      )}
      {rightPanelTarget ===
        GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel && (
        <ThreadCreatorFromMessagePanel />
      )}
      {rightPanelTarget ===
        GlobalPanelKeys.GlobalThreadCreatorFromAskForHelpPanel && (
        <ThreadCreatorFromAskForHelpPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadView && (
        <ThreadViewPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadProperties && (
        <ThreadPropertiesPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadMembers && (
        <ThreadMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadActiveMembers && (
        <ThreadActiveMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadAddMember && (
        <ThreadAddMemberPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalStreaks && <StreaksPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalAwardDetail && (
        <AwardDetailsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalDirectToShare && (
        <SharePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAskForHelp && (
        <AskForHelpPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalSetGoal && <SetGoalPanel />}
      {rightPanelTarget.includes(GlobalPanelKeys.GlobalStartChallenge) && (
        <StartChallengePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalChallengeRank && (
        <ChallengersPanels />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAddChallenger && (
        <ChallengeFriendsPanel />
      )}
      {rightPanelTarget.includes(GlobalPanelKeys.GlobalPlaylistDetail) && (
        <PlaylistDetailPanel />
      )}
      {rightPanelTarget.includes(GlobalPanelKeys.GlobalAddClubGroup) && (
        <AddClubGroupPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadEmoji && (
        <ThreadEmojiRightPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalSelectWorkflow && (
        <SelectWorkflowPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalMarketplaceDetail && (
        <AvatarDetailPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAddDiscount && (
        <AddDiscountPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalShareDiscount && (
        <ShareDiscountPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalInviteByEmail && (
        <InviteByEmailPanel />
      )}
    </BaseLayoutRightPanelPortal>
  );
}
