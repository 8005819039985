import React, { PropsWithChildren, useState } from 'react';
import { InternalLinkType } from '@lib/ia/src/hooks/useLinkInternalDetail/types';

import useLinkInternalDetail from '../../hooks/useLinkInternalDetail';

import AgentLinkTooltip from './AgentLinkTooltip';
import ClubGroupTooltip from './ClubGroupTooltip';
import ClubLinkTooltip from './ClubLinkTooltip';

type LinkTooltipProps<T> = {
  type: T;
  sourceId: string;
};

function LinkTooltipContent<T extends InternalLinkType>({
  type,
  sourceId,
  children,
}: PropsWithChildren<LinkTooltipProps<T>>) {
  const { data } = useLinkInternalDetail<T>(type, sourceId);

  if (!data?.data) return children;

  if ('club' in data.data && data.data.club) {
    return <ClubLinkTooltip club={data.data.club}>{children}</ClubLinkTooltip>;
  }
  if ('agent' in data.data && data.data.agent) {
    return (
      <AgentLinkTooltip agent={data.data.agent}>{children}</AgentLinkTooltip>
    );
  }
  if ('clubGroup' in data.data && data.data.clubGroup) {
    return (
      <ClubGroupTooltip clubGroup={data.data.clubGroup}>
        {children}
      </ClubGroupTooltip>
    );
  }

  return children;
}
export default function LinkTooltip<T extends InternalLinkType>({
  children,
  ...rest
}: PropsWithChildren<LinkTooltipProps<T>>) {
  const [hovered, setHovered] = useState(false);

  // only call the API after the link has been hovered
  if (hovered)
    return <LinkTooltipContent {...rest}>{children}</LinkTooltipContent>;

  return <span onMouseEnter={() => setHovered(true)}>{children}</span>;
}
