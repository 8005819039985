import { useCallback, useContext } from 'react';
import { useAuth } from '@lib/web/apis';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import ThreadComposer, {
  ThreadComposerProps,
} from '@lib/web/thread/ThreadComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import { useThreadComposer } from '../../hooks/core/useThreadComposer';
import useThreadSendMessage from '../hooks/useThreadSendMessage';

export type ThreadMessageInputProps = {
  channel: Channel;
  onBeforeSubmit?: (message: StreamMessage<StreamChatGenerics>) => void;
};

export default function ThreadMessageInput({
  channel,
  onBeforeSubmit,
}: ThreadMessageInputProps) {
  const sendMessage = useThreadSendMessage();
  const { threadMemberIds, setText } = useThreadComposer();
  const { getThreadComposerCustomProps } = useContext(
    ThreadComposerCustomContext
  );

  const { isBlocking, blockerTip } = useAuth();

  const handleComposerSubmit = useCallback<
    Exclude<ThreadComposerProps['onSubmit'], undefined>
  >(
    async (params) => {
      setText('');

      sendMessage({
        channel,
        threadMemberIds,
        text: params.text,
        blocks: params.blocks,
        onBeforeSubmit,
      });
    },
    [channel, onBeforeSubmit, sendMessage, setText, threadMemberIds]
  );

  const handleChange = (value: string) => {
    setText(value);
  };
  return (
    <ThreadComposer
      autoFocus
      onChange={handleChange}
      onSubmit={handleComposerSubmit}
      disabled={isBlocking}
      disabledTip={blockerTip}
      disableSubmitWhileSubmitting={false}
      {...getThreadComposerCustomProps?.({ channel })}
      cid={channel.cid}
    />
  );
}
