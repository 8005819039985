import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { IaClubCtaAction } from '@app/web/src/ia/club/iaClubCta';
import useIaClubCtaAction from '@app/web/src/ia/club/useIaClubCTAAction';
import { TestAdd as TestAddIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

function getJoinContext(tag: GetNotificationRes['tag']) {
  switch (tag) {
    case 'club.member.removed.global':
      return 'rejoin';
    case 'club.join.request.rejected.member.global':
      return 'reapply';
  }
}

export type JoinToClubButtonProps = {
  tag: GetNotificationRes['tag'];
  clubSlug: string;
};

export default function JoinToClubButton({
  tag,
  clubSlug,
}: JoinToClubButtonProps) {
  const { t } = useTranslation('notification');
  const [loading, setLoading] = useState<boolean>(false);
  const { club, clubCta, clubCtaAction } = useIaClubCtaAction(clubSlug);

  if (!clubCta || !club) return null;

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    if (clubCtaAction) {
      await clubCtaAction.action({
        id: club.clubSlug,
        title: club.clubName,
      });
    }
    setLoading(false);
  };

  if (
    clubCta.value === IaClubCtaAction.RequestToJoinClub ||
    clubCta.value === IaClubCtaAction.JoinClub
  ) {
    const context = getJoinContext(tag);
    return (
      <Box sx={styles.root}>
        <Button
          onClick={handleClick}
          loading={loading}
          prefixIcon={<TestAddIcon />}
        >
          {t('join.club.status.button.label', { context })}
        </Button>
      </Box>
    );
  }

  if (clubCta.value === IaClubCtaAction.CancelRequired) {
    return (
      <Box sx={styles.root}>
        <Button onClick={handleClick} loading={loading} variant="outlined">
          {clubCta.text}
        </Button>
      </Box>
    );
  }

  return null;
}
