import { useEffect } from 'react';
import { useIaClubGroups } from '@lib/web/apis';
import { useClubSlug, useIaClub } from '@lib/web/hooks';

import { updateViewedSlug } from '../../actions/layoutActions';
import { useAppDispatch, useAppSelector } from '../redux';

import useAllJoinedClubs from './useAllJoinedClubs';

export default function useMenuClub() {
  const lastViewedSlug = useAppSelector((st) => st.layout.lastViewedSlug);
  const { joinedSlugs } = useAllJoinedClubs();
  const dispatch = useAppDispatch();
  const routerSlug = useClubSlug();
  const clubSlug = routerSlug || lastViewedSlug || joinedSlugs[0] || undefined;

  const { club, isLoading } = useIaClub(clubSlug);

  useEffect(() => {
    if (!routerSlug && lastViewedSlug && !club && !isLoading) {
      dispatch(updateViewedSlug('')); // reset clubSlug if club is not found
    }
  }, [clubSlug, club, isLoading, lastViewedSlug, dispatch, routerSlug]);

  const { dataset: clubGroups } = useIaClubGroups(
    {
      filter: 'id;name;joinedStatus',
      expandSearch: `clubs:clubSlug:${clubSlug};`,
      expandSearchFields: 'clubs:clubSlug:eq;',
    },
    { enable: !!clubSlug }
  );

  return {
    clubSlug,
    groups: clubGroups,
    club,
  };
}
