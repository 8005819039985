import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Box from '@mui/material/Box';
import {
  ActionSearch as ActionSearchIcon,
  ProfileManageAIBot as ProfileManageAIBotIcon,
} from '@front/icon';
import { TipButton, useBaseRightPanel } from '@front/ui';
import AgentIcon from '@lib/web/thread/ThreadComposer/components/AiAction/AgentIcon';

import { useThreadComposer } from '../../../hooks/core/useThreadComposer';

const enum GlobalPanelKeys {
  GlobalManageAgents = 'GlobalManageAgents',
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    px: 1.5,

    pb: 0.5,
    '.simplebar-scrollable-x &': {
      pb: 1.5,
    },
  },
};

export default function SelectAgent() {
  const { t } = useTranslation('agent');
  const { aiActionState: state } = useThreadComposer();

  const selectedAgent = state.selectedAgent;
  const otherAgents = useMemo(
    () =>
      state.agents.filter(
        (item) => item.agent.agentId !== selectedAgent?.agent.agentId
      ),
    [selectedAgent?.agent.agentId, state.agents]
  );

  const { openRightPanel, isTargetPanelOpened } = useBaseRightPanel(); // XXX: here is libs/thread , we cannot include GlobalPanelKeys in apps/web

  return (
    <Box sx={styles.root}>
      <TipButton
        customSize={24}
        onClick={() => openRightPanel(GlobalPanelKeys.GlobalManageAgents)}
        selected={isTargetPanelOpened(GlobalPanelKeys.GlobalManageAgents)}
        title={t('Manage Agents')}
      >
        <ProfileManageAIBotIcon />
      </TipButton>

      <TipButton
        customSize={24}
        component={Link}
        href="Agents"
        title="Discover AI"
      >
        <ActionSearchIcon />
      </TipButton>

      {selectedAgent && <AgentIcon agent={selectedAgent?.agent} selected />}

      {otherAgents.map((item, index) => (
        <Box key={index}>
          <AgentIcon
            agent={item?.agent}
            onClick={() => state.setSelectedAgent(item)}
          />
        </Box>
      ))}
    </Box>
  );
}
