import React from 'react';
import { Box, ButtonBase, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import useTextComposerRender from '@lib/web/composer/TextComposer/hooks/useTextComposerRender';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';
import { ChartRenderBox } from '@lib/web/quiz';
import { floatMul, numberFormat } from '@lib/web/utils';

const styles = {
  itemsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  itemWrapper: {
    position: 'relative',
  },
  root: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';

    return {
      background: isDark ? alpha(theme.palette.text.primary, 0.05) : '',
      borderRadius: '12px',
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      justifyContent: 'flex-start',
      boxShadow: isDark
        ? `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`
        : `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.3)}`,
      '&.Mui-disabled': { pointerEvents: 'unset' },
    };
  },
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '54px 1fr',
    position: 'relative',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    userSelect: 'none',
    gap: 1,
  },
  hasNoLeftComponent: {
    gridTemplateColumns: '1fr',
    pl: '20px',
    pr: '12px',
  },
  rightComponent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'stretch',
  },
  rightComponentValueTitle: {
    gap: '8px',
    py: '12px',
    '& .option-text': {
      minHeight: 'auto',
    },
    '& .chart-render-inner': {
      py: 0,
      minHeight: 'auto',
    },
  },
  valueTitle: (theme: Theme) => ({
    textAlign: 'left',
    typography: theme.palette.mode === 'light' ? 'examBody' : 'body2',
    fontWeight: 700,
  }),
  value: (theme: Theme) => ({
    textAlign: 'left',
    typography: theme.palette.mode === 'light' ? 'examBody' : 'body2',
    fontWeight: 400,
    height: '100%',
  }),
  leftComponent: {
    minHeight: '100%',
    display: 'flex',
  },
  ratioContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    '& .MuiTypography-h6': {
      fontSize: 16,
      fontWeight: '700 !important',
    },
  },
  symbolContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  symbol: (theme: Theme) => ({
    mt: '12px',
    ml: 1.5,
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.background.darker}`,
    color: theme.palette.background.darker,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  }),
  submitted: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';

    return {
      boxShadow: isDark
        ? `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`
        : `inset 0 0 0 3px ${theme.palette.grey[200]}`,
      '& .left-component': {
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
    };
  },
  answer: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';
    const mainColor = isDark
      ? theme.palette.success.light
      : theme.palette.success.dark;
    return {
      boxShadow: `inset 0 0 0 3px ${mainColor}`,
      backgroundColor: alpha(mainColor, 0.3),
      '& .left-component': {
        backgroundColor: alpha(mainColor, 0.3),
      },
      '& .option-text, & .katex': {
        fontWeight: 'bold',
      },
      '& .chart-render-inner': {
        fontWeight: 700,
      },
      '& .option-title': {
        color: mainColor,
      },
    };
  },
  correct: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';
    const mainColor = isDark
      ? theme.palette.success.light
      : theme.palette.success.dark;
    return {
      boxShadow: `inset 0 0 0 3px ${mainColor}`,
      backgroundColor: alpha(mainColor, 0.3),
      '& .left-component': {
        backgroundColor: mainColor,
        color: theme.palette.background.darker,
      },
      '& .option-text, & .katex': {
        fontWeight: 'bold',
      },
      '& .chart-render-inner': {
        fontWeight: 700,
      },
      '& .option-title': {
        color: mainColor,
      },
    };
  },
  incorrect: (theme: Theme) => ({
    boxShadow: `inset 0 0 0 3px ${theme.palette.error.dark}`,
    backgroundColor: alpha(theme.palette.error.dark, 0.2),
    '& .left-component': {
      backgroundColor: 'error.dark',
      color: 'white',
    },
    '& .option-title': {
      color: theme.palette.error.dark,
    },
  }),
};

type OptionRatioProps = {
  ratio: number;
};

function OptionRatio({ ratio }: OptionRatioProps) {
  return (
    <Box
      className="left-component"
      sx={[styles.leftComponent, styles.ratioContainer]}
    >
      <Typography variant="h6">
        {numberFormat(floatMul(ratio, 100), 1)}
        <Typography variant="caption" fontWeight="bold">
          %
        </Typography>
      </Typography>
    </Box>
  );
}

type OptionCircleProps = {
  symbol: string;
};

function OptionCircle({ symbol }: OptionCircleProps) {
  return (
    <Box
      className="left-component"
      sx={[styles.leftComponent, styles.symbolContainer]}
    >
      <Box sx={styles.symbol}>{symbol}</Box>
    </Box>
  );
}

type AnswerChoiceItemProps = {
  /**
   * correct : user selected and is the answer
   * incorrect : user selected wrong
   * answer : user not selected and is the answer
   */
  type: 'correct' | 'incorrect' | 'answer';
  value: string;
  valueTitle?: string;
  valueScrollable?: boolean;
  leftComponent?: React.ReactNode;
  textComponent?: React.ReactNode;
  onClick?: () => void;
};

function AnswerChoiceItem({
  type,
  value,
  valueTitle,
  valueScrollable = false,
  leftComponent,
  textComponent,
  onClick,
}: AnswerChoiceItemProps) {
  const { renderOptions } = useTextComposerRender();
  return (
    <Box sx={styles.itemWrapper}>
      <ButtonBase
        component="div"
        disableRipple
        sx={[
          styles.root,
          styles.submitted,
          type === 'answer' && styles.answer,
          type === 'incorrect' && styles.incorrect,
          type === 'correct' && styles.correct,
        ]}
        disabled={!onClick}
        onClick={onClick}
      >
        <Box
          sx={[
            styles.innerContent,
            !leftComponent && styles.hasNoLeftComponent,
          ]}
        >
          {leftComponent}
          <Box
            sx={[
              styles.rightComponent,
              !!valueTitle && styles.rightComponentValueTitle,
            ]}
          >
            <Box sx={styles.valueTitle} className="option-title">
              {valueTitle}
            </Box>
            {textComponent || (
              <EditorComposerRenderer styleProvider>
                <ChartRenderBox
                  sx={styles.value}
                  className="option-text"
                  tex={value}
                  scrollable={valueScrollable}
                  renderOptions={renderOptions}
                />
              </EditorComposerRenderer>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

type AnswerChoiceItemsProps = {
  children: React.ReactNode;
};

export default function AnswerChoiceItems({
  children,
}: AnswerChoiceItemsProps) {
  return <Box sx={styles.itemsRoot}>{children}</Box>;
}

AnswerChoiceItems.Item = AnswerChoiceItem;
AnswerChoiceItems.OptionRatio = OptionRatio;
AnswerChoiceItems.OptionCircle = OptionCircle;
