import { Difficulty, ExamMode, ExamQuestionDetailType } from '@lib/web/apis';

export enum QuizProfileQuestionType {
  Username = 'Username',
  FullName = 'FullName',
  Location = 'Location',
  Birthday = 'Birthday',
  Gender = 'Gender',
  Bio = 'Bio',
  HighSchool = 'HighSchool',
  HighSchoolGraduation = 'HighSchoolGraduation',
  DreamCollege = 'DreamCollege',
  GoalTargetDate = 'GoalTargetDate',
  GoalTargetScore = 'GoalTargetScore',
}

export enum QuizState {
  Onboarding = 'Onboarding',
  Init = 'Init',
  Redirecting = 'Redirecting',
  Pending = 'Pending',
  Playing = 'Playing',
  Paused = 'Paused',
  Review = 'Review',
  Finish = 'Finish',
  Solution = 'Solution',
  Error = 'Error',
  Question = 'Question',
  Viewing = 'Viewing',
}

export interface QuestionDetail {
  id: string;
  examQuestionDetailId: string;
  type: ExamQuestionDetailType;
  order: number;
  linkedComponentId: string;
}

export interface Material {
  id: string;
  examQuestionDetailId: string;
  type: ExamQuestionDetailType;
  content: string;
  solution: string | null;
}

export interface Question {
  id: string;
  examQuestionSetId: string;
  examQuestionId: string;
  question: string;
  questionDetails: QuestionDetail[];
  creatorQuestionInfo: CreatorQuestionInfo;
  revision: number;
  creatorId: string;
  correctAnswerValues: string[];
  correctAnswerIds: string[];
  setId: number;
  isDynamicDifficulty: boolean;
  staticDifficulty: Difficulty;
  dynamicDifficulty: Difficulty;

  // basic status
  questionNo: number;
  isAnswered: boolean;
  isOvertime: boolean;
  isCorrect: boolean;
  isIncorrect: boolean;
  isSubmitted: boolean;
  isLoading: boolean;
  isSkipped: boolean;
  isStreak: boolean;
  isLeaveBlank: boolean;
  startAt: number | null;
  timeSpent: number;
  tpq: number;

  isPendingSubmit: boolean;
}

export interface State {
  // basic status
  state: QuizState;
  error: any | null | undefined;
  questionError: any | null | undefined;
  loading: boolean;
  loaded: boolean;
  isCompleted: boolean;
  isSubmitting: boolean;
  isTaken: boolean;
  isOnboarding: boolean;
  expiredTime: string | null;
  practicedQuestionCount: number;
  // values need while doing practice
  startAt: number | null;
  totalTime: number;
  totalTimeSpent: number;
  questionNo: number;
  roundNo: number;
  mode: ExamMode;
  totalCount: number;

  // all questions
  mappedQuestions: {
    [order: number]: Question;
  };

  mappedAnswers: {
    [order: number]: string[];
  };

  mappedNotes: {
    [order: number]: string;
  };

  mappedMaterials: {
    [code: string]: GetQuizMaterialRes;
  };

  mappedQuestionIds: {
    [id: string]: number;
  };

  highlight: {
    [quizId: string]: string;
  };

  eliminatedAnswers: {
    [order: number]: string[];
  };

  // quiz data
  quiz: GetQuizRes;

  challenge: GetQuizChallengeRes | null;

  userId: string;

  profileQuestionType: QuizProfileQuestionType | null;
  profileQuestionNo: number;
  profileQuestionDirty: boolean;
  profileQuestionLoading: boolean;
  profileFormTargetId: string;

  // creator question data
  creatorQuestionGroups: CreatorQuestionGroup[];

  openAnswerResultAfterSubmit: boolean;

  layout: {
    showKeyboard: boolean;
    isOpenTimer: boolean;
    redirectPage?: string | null;
  };
}
