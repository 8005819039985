import { useMemo, useState } from 'react';
import { useIaAgentActions } from '@app/web/src/ia/agent';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';

export const useAgentProfileLayoutActions = ({
  agentId,
  directMessageOnRhs,
}: {
  agentId: string;
  directMessageOnRhs?: boolean;
}) => {
  const [buttonInProgress, setButtonInProgress] = useState(false);
  const { openRightPanel } = useBaseRightPanel();
  const iaAgentActions = useIaAgentActions();
  const { views } = useDirectMessageViews();

  return useMemo(
    () => ({
      addMyAgent: {
        action: async () => {
          setButtonInProgress(true);
          await iaAgentActions.addMyAgent({ id: agentId });
          setButtonInProgress(false);
        },
        inProgress: buttonInProgress,
      },
      removeMyAgent: {
        action: async () => {
          setButtonInProgress(true);
          await iaAgentActions.removeMyAgent({ id: agentId });
          setButtonInProgress(false);
        },
        inProgress: buttonInProgress,
      },
      openUserProfile: {
        action: ({ userId }: { userId: string }) => {
          openRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId,
          });
        },
      },
      directMessage: {
        action: async () => {
          if (directMessageOnRhs) {
            openRightPanel(GlobalPanelKeys.GlobalThreadView, {
              openAgentMemberId: `agent_${agentId}`,
            });
            return;
          }
          setButtonInProgress(true);
          await iaAgentActions.directMessage({ views, agentId });
          setButtonInProgress(false);
        },
        inProgress: buttonInProgress,
      },
    }),
    [
      agentId,
      buttonInProgress,
      iaAgentActions,
      openRightPanel,
      views,
      directMessageOnRhs,
    ]
  );
};
