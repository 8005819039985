import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import {
  NFTFavorite as NFTFavoriteIcon,
  NFTFavoriteSolid as NFTFavoriteSolidIcon,
} from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import { apis, useAuth, useMyFavoriteProductInfo } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import useMarketplace from '../hooks/useMarketplace';

const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      p: 1,
    },
  },
  favorite: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    p: 0.5,
    borderRadius: 1.5,
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
};

type FavoriteButtonProps = {
  productId: string;
  tooltipPlacement?: 'bottom-start' | 'top-end';
  autoFavorite?: boolean;
  sx?: SxProps;
};

export default function FavoriteButton({
  productId,
  tooltipPlacement = 'bottom-start',
  autoFavorite = false,
  sx,
}: FavoriteButtonProps) {
  const { t } = useTranslation('marketplace');
  const { isLogged } = useAuth();
  const { refreshFavorite } = useMarketplace();
  const { data, mutate } = useMyFavoriteProductInfo(productId);
  const autoProcessed = useRef<boolean>(false);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const returnTo =
    window.location.href.split('?')[0] +
    `?productId=${productId}&favorited=true`;

  const { loginUrl } = useAuthLinks({
    loginReturnTo: returnTo,
  });

  const refresh = useCallback(() => {
    mutate();
    refreshFavorite();
  }, [mutate, refreshFavorite]);

  const addFavorite = useCallback(async () => {
    await call(apis.marketplace.addMyProductFavorite(productId));
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, refresh]);

  const removeFavorite = useCallback(async () => {
    await call(apis.marketplace.removeMyProductFavorite(productId));
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, refresh]);

  const goToLoginPage = useCallback(() => {
    Router.push(loginUrl);
  }, [loginUrl]);

  const handleClicked = useCallback(async () => {
    if (!isLogged) {
      goToLoginPage();
      return;
    }

    if (!data) return;
    if (data.data.isFavorite) {
      removeFavorite();
      return;
    }
    addFavorite();
  }, [isLogged, data, addFavorite, goToLoginPage, removeFavorite]);

  useEffect(() => {
    if (autoFavorite && !autoProcessed.current) {
      autoProcessed.current = true;
      addFavorite();
      return;
    }
  }, [autoFavorite, addFavorite]);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const ButtonContent = () => {
    return (
      <ButtonBase
        sx={styles.button}
        onMouseDown={(event) => event.stopPropagation()}
        data-testid="favorite-button"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleClicked();
        }}
        disableRipple
      >
        {data?.data.isFavorite ? <NFTFavoriteSolidIcon /> : <NFTFavoriteIcon />}
      </ButtonBase>
    );
  };

  if (mdDown) {
    return (
      <Box sx={[styles.favorite, ...sxProps]}>
        <ButtonContent />
      </Box>
    );
  }

  return (
    <Box sx={[styles.favorite, ...sxProps]}>
      <SimpleTooltip
        title={
          data?.data.isFavorite
            ? t('Remove from favorite')
            : t('Add to favorite')
        }
        placement={tooltipPlacement}
        slotProps={{
          popper: {
            sx: styles.tooltip,
          },
        }}
      >
        <Box>
          <ButtonContent />
        </Box>
      </SimpleTooltip>
    </Box>
  );
}
