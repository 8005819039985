import { Fragment, ReactNode, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BaseLayoutCloseLeftPanel from './components/BaseLayoutCloseLeftPanel';
import BaseLayoutComponent from './components/BaseLayoutComponent';
import BaseLayoutRightPanelContainer from './components/BaseLayoutRightPanelContainer';
import useBaseLayout from './hooks/useBaseLayout';
import config from './config';
import { BaseLayoutProvider } from './contexts';

const contentStyles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
};

const getTransformStyles = (transitionProperty: string) => ({
  transitionDuration: `${config.rightPanelAnimationDurationInMs}ms`,
  transitionTimingFunction: 'ease',
  transitionProperty,
});

const styles = {
  root: {
    ...contentStyles,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  main: {
    ...contentStyles,
    height: '100%',
    flex: 1,
    minWidth: 0,
  },
  content: {
    ...contentStyles,
    height: '100%',
    flex: 1,
    minWidth: 0,
  },
  mainAnimated: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      ...getTransformStyles('transform'),
    },
    [theme.breakpoints.up('md')]: {
      ...getTransformStyles('padding'),
    },
  }),
  leftPanelMain: (theme: Theme, width?: number) => ({
    [theme.breakpoints.up('md')]: {
      pl: `${width || config.leftPanelWidth}px`,
    },
  }),
  leftMenu: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      ...getTransformStyles('transform, opacity, visibility'),
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 1,
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateX(-100%)',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      display: 'flex',
    },
  }),
  menuOpened: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateX(0)',
    },
  }),
  nav: {
    position: 'relative',
    minWidth: config.navWidth,
    height: '100%',
    zIndex: 3,
  },
  navHidden: {
    display: 'none',
  },
  subNav: {
    position: 'relative',
    minWidth: config.subNavWidth,
    height: '100%',
    zIndex: 3,
  },
  subNavHidden: {
    display: 'none',
  },
  leftPanel: (theme: Theme, width?: number) => ({
    minWidth: width === -1 ? '100vw' : width || config.leftPanelWidth,
    width: width === -1 ? '100vw' : width || config.leftPanelWidth,
    boxShadow: theme.palette.mode === 'dark' ? 9 : 'unset',
    ...getTransformStyles('transform, opacity, visibility, width, min-width'),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0.7,
      zIndex: 2,
      visibility: 'hidden',
      transform: `translate(0%)`,
    },
  }),
  leftPanelHidden: {
    display: 'none',
  },
  leftPanelOpened: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translate(100%)',
    },
  }),
};

function getMainTransform(
  leftPanelWidth?: number,
  mainNavOpened?: boolean,
  leftPanelEmpty?: boolean,
  subNavEmpty?: boolean,
  mainNavEmpty?: boolean
) {
  return (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      transform:
        leftPanelWidth === -1
          ? 'translateX(100vw)'
          : `translateX(${
              (leftPanelEmpty ? 0 : config.leftPanelWidth) +
              (!mainNavOpened || mainNavEmpty ? 0 : config.navWidth) +
              (subNavEmpty ? 0 : config.subNavWidth)
            }px)`,
    },
  });
}

export type BaseLayoutProps = {
  children: ReactNode;
  announcementComponent?: ReactNode;
  navComponent?: ReactNode;
  subNavComponent?: ReactNode;
  leftPanelComponent?: ReactNode;
  rightPanelComponent?: ReactNode;
  leftPanelContainerSx?: BoxProps['sx'];
  /**
   * use this to provide providers for the whole page, be careful with this because if there are different providers for different pages, it will cause re-render issue
   */
  ExtensionGlobalProvider?: React.FC<{ children: ReactNode }>;
  /**
   * use this to provide providers for centre and right panel area, most of time different pages will have different providers for this area
   */
  ExtensionCentreAndRightPanelProvider?: React.FC<{ children: ReactNode }>;
};

function BaseLayout({
  children,
  announcementComponent,
  navComponent,
  subNavComponent,
  leftPanelComponent,
  rightPanelComponent,
  leftPanelContainerSx,
  ExtensionCentreAndRightPanelProvider = Fragment,
}: BaseLayoutProps) {
  const {
    leftPanelOpened,
    hideLeftPanel,
    isResizing,
    mainNavOpened,
    leftPanelWidth,
  } = useBaseLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [mainAnimationEnabled, setMainAnimationEnabled] = useState(true);

  const leftPanelContainerSxProps = Array.isArray(leftPanelContainerSx)
    ? leftPanelContainerSx
    : [leftPanelContainerSx];

  const leftPanelEmpty = !leftPanelComponent;
  const subNavEmpty = !subNavComponent;
  const mainNavEmpty = !navComponent;

  /**
   * this logic is to avoid the main container looks flashy/lag
   * (issue: https://www.notion.so/rootdomain/Eng-Only-Discover-Back-button-should-not-be-active-on-first-time-landing-on-desktop-a-club-to-d-bfb7f37222cf44b98bf3125ebef4b2db)
   * the reason: when going from a page without the LHS menu to a page with the LHS menu,
   * the main container will reduce the padding-left from 240px to 0px in 300ms duration.
   * => this logic is to remove animation when navigating between pages with and without the LSH menu.
   */
  useEffect(() => {
    setMainAnimationEnabled(false);
    setTimeout(() => {
      if (!leftPanelEmpty) {
        setMainAnimationEnabled(true);
      }
    }, 350);
  }, [leftPanelEmpty]);

  return (
    <Box sx={styles.root}>
      <BaseLayoutComponent>{announcementComponent}</BaseLayoutComponent>
      <Box sx={styles.wrapper}>
        <Box
          sx={[
            styles.leftMenu,
            (mdUp || leftPanelOpened) && !hideLeftPanel && styles.menuOpened,
          ]}
        >
          <BaseLayoutComponent
            sx={[
              styles.nav,
              (!mainNavOpened || mainNavEmpty) && styles.navHidden,
            ]}
          >
            {navComponent}
          </BaseLayoutComponent>
          <BaseLayoutComponent
            sx={[styles.subNav, subNavEmpty && styles.subNavHidden]}
          >
            {subNavComponent}
          </BaseLayoutComponent>
          <BaseLayoutComponent
            sx={[
              (theme) => styles.leftPanel(theme, leftPanelWidth),
              leftPanelEmpty && styles.leftPanelHidden,
              (lgUp || (mdUp && leftPanelOpened)) &&
                !hideLeftPanel &&
                !leftPanelEmpty &&
                styles.leftPanelOpened,
              ...leftPanelContainerSxProps,
            ]}
          >
            {leftPanelComponent}
          </BaseLayoutComponent>
        </Box>
        <ExtensionCentreAndRightPanelProvider>
          <Box
            sx={[
              styles.main,
              mainAnimationEnabled && styles.mainAnimated,

              (lgUp || leftPanelOpened) &&
                !hideLeftPanel &&
                !leftPanelEmpty &&
                ((theme) => styles.leftPanelMain(theme, leftPanelWidth)),

              (lgUp || leftPanelOpened) &&
                !hideLeftPanel &&
                getMainTransform(
                  leftPanelWidth,
                  mainNavOpened,
                  leftPanelEmpty,
                  subNavEmpty,
                  mainNavEmpty
                ),

              isResizing && { transition: 'none !important' },
            ]}
            className="base-layout-main"
            data-testid="base-layout-main"
          >
            <Box
              sx={styles.content}
              className="base-layout-content"
              data-testid="base-layout-content"
            >
              {children}
            </Box>
            <BaseLayoutCloseLeftPanel />
          </Box>
          <BaseLayoutRightPanelContainer>
            {rightPanelComponent}
          </BaseLayoutRightPanelContainer>
        </ExtensionCentreAndRightPanelProvider>
      </Box>
    </Box>
  );
}

export default function BaseLayoutRoot({
  ExtensionGlobalProvider = Fragment,
  ...rest
}: BaseLayoutProps) {
  return (
    <BaseLayoutProvider>
      <ExtensionGlobalProvider>
        <BaseLayout {...rest} />
      </ExtensionGlobalProvider>
    </BaseLayoutProvider>
  );
}
