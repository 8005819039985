import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export type MenuVariant =
  | 'discover'
  | 'club'
  | 'profile'
  | 'directMessages'
  | 'marketplace'
  | 'internal'
  | 'agents';

export type ClubMenuVariant =
  | 'summary'
  | 'threads'
  | 'rankings'
  | 'practice'
  | 'analytics'
  | 'playlists';

export type ProfileMenuVariant =
  | 'profile'
  | 'awards'
  | 'subscribe'
  | 'invite'
  | 'wallet'
  | 'discount'
  | 'avatars'
  | 'settings'
  | 'phone'
  | 'logout';

export type TemporaryMenuVariant =
  | 'clubs'
  | 'notifications'
  | 'addFriends'
  | 'club-suggestion'
  | 'newDirectMessage';

export type TriggerTemporaryMenuType = 'hovered' | 'clicked';

export interface MainLayoutValue {
  menuTarget: MenuVariant | null;
  temporaryMenuTarget: TemporaryMenuVariant | null;
  clubMenuTarget: ClubMenuVariant | null;
  profileMenuTarget: ProfileMenuVariant | null;
  currentClub?: string | null;
  currentClubGroup?: string | null;
  triggerTemporaryMenuType?: TriggerTemporaryMenuType;
}

const initialValue = {
  menuTarget: null,
  temporaryMenuTarget: null,
  clubMenuTarget: null,
  profileMenuTarget: null,
  currentClub: null,
  currentClubGroup: null,
};

type MainLayoutContextValue = [
  MainLayoutValue,
  Dispatch<SetStateAction<MainLayoutValue>>
];

type MainLayoutProviderProps = {
  children: ReactNode;
};

export const MainLayoutContext = createContext<MainLayoutContextValue>([
  initialValue,
  () => {},
]);

export function MainLayoutProvider({ children }: MainLayoutProviderProps) {
  const providerValue = useState<MainLayoutValue>(initialValue);

  return (
    <MainLayoutContext.Provider value={providerValue}>
      {children}
    </MainLayoutContext.Provider>
  );
}

export const MainLayoutConsumer = MainLayoutContext.Consumer;
