import type { ReactNode } from 'react';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider as TP } from '@mui/material/styles';

type ThemeProviderProps = {
  theme: Theme;
  children: ReactNode;
};

export default function ThemeProvider({ theme, children }: ThemeProviderProps) {
  return (
    <TP theme={theme}>
      <CssBaseline />
      <svg
        style={{
          opacity: 0,
          visibility: 'hidden',
          width: 0,
          height: 0,
          position: 'absolute',
          zIndex: -1,
        }}
      >
        <defs>
          <linearGradient
            id="primary-gradient-linear-vertical"
            x1="100%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#00FFFF" />
            <stop offset="100%" stopColor="#00A4FF" />
          </linearGradient>
          <linearGradient
            id="primary-gradient-linear-horizontal"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#00FFFF" />
            <stop offset="100%" stopColor="#00A4FF" />
          </linearGradient>
          <linearGradient
            id="primary-gradient-linear-horizontal-dark"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#00DEEB" />
            <stop offset="100%" stopColor="#0072DD" />
          </linearGradient>
        </defs>
      </svg>
      {children}
    </TP>
  );
}
