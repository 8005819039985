import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { ChatExplore as ChatExploreIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
  },
};

function getVariant(tag: GetNotificationRes['tag']) {
  if (tag === 'club.join.request.rejected.member.global') {
    return 'outlined';
  }
  return 'filled';
}

function isAlignRight(tag: GetNotificationRes['tag']) {
  return (
    tag === 'club.join.request.rejected.member.global' ||
    tag === 'club.deleted.global' ||
    tag === 'club.member.left.global' ||
    tag === 'club.member.removed.global'
  );
}

type DiscoverClubsButtonProps = {
  tag: GetNotificationRes['tag'];
};

export default function DiscoverClubsButton({ tag }: DiscoverClubsButtonProps) {
  const { t } = useTranslation('notification');

  return (
    <Box
      sx={[styles.root, isAlignRight(tag) && { justifyContent: 'flex-end' }]}
    >
      <Button
        variant={getVariant(tag)}
        prefixIcon={<ChatExploreIcon />}
        component={Link}
        href="/"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {t('email.verify.cta.discoverClubs')}
      </Button>
    </Box>
  );
}
