import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import SuggestionList from '@app/web/src/components/SuggestionList';
import useDebounce from '@app/web/src/hooks/utils/useDebounce';
import { SearchBar, useBaseRightPanel } from '@front/ui';
import { useInfiniteScroll } from '@lib/web/hooks';
import { sanitize } from 'dompurify';

import useFriendRankingList from '../hooks/useFriendRankingList';

import FriendRankingList from './FriendRankingList';
import RankingListHeader from './RankingListHeader';
import { RankingsContext } from './RankingsContextProvider';
import TabArea from './RankingTabArea';

const styles = {
  searchBar: {
    px: { xs: '20px', md: '12px' },
    '& .search-input-wrap': {
      py: '12px',
    },
  },
  searchResult: {
    opacity: 0.75,
    px: { xs: '20px', md: '12px' },
  },
  empty: {
    px: { xs: '20px', md: '12px' },
    display: 'flex',
    flexDirection: 'column',
  },
  emptyContent: {
    opacity: 0.64,
  },
  suggestion: {
    py: 2,
  },
};

type EmptyStateProps = {
  variant: 'following' | 'follower';
  hasKeyword: boolean;
};

function EmptyState({ variant, hasKeyword }: EmptyStateProps) {
  const { t } = useTranslation('club');

  return (
    <Box sx={styles.empty}>
      <Typography
        sx={styles.emptyContent}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: sanitize(
            t('ranking.search.empty', {
              context: hasKeyword ? 'keyword' : variant,
            })
          ),
        }}
      />
    </Box>
  );
}

type FriendRankingTabContentProps = {
  variant: 'following' | 'follower';
};

export default function FriendRankingTabContent({
  variant,
}: FriendRankingTabContentProps) {
  const { t } = useTranslation('club');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim());
  const { rightPanelOpened } = useBaseRightPanel();
  const { scrollRef: outerScrollRef } = useContext(RankingsContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const data = useFriendRankingList({
    type: variant,
    keyword: debouncedSearch,
  });

  const {
    dataset,
    isEmpty,
    totalCount,
    isLoadingInitialData,
    error,
    isLoadingMore,
  } = data;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
    scrollEl: outerScrollRef?.current,
  });

  useEffect(() => {
    if (!rightPanelOpened) {
      setSearch('');
    }
  }, [rightPanelOpened]);

  const isSearching = !!search && isLoadingMore;

  const ctaCol = mdUp ? '16px 101px' : '8px 32px';
  const headerGridTemplateColumns = `1fr 8px 32px 16px 34px 16px 34px ${ctaCol}`;

  return (
    <TabArea scrollable={!outerScrollRef} scrollRef={scrollRef}>
      <TabArea.Header>
        <Box sx={styles.searchBar}>
          <SearchBar
            placeholder={
              variant === 'follower'
                ? t('search.placeholder_followers', 'Search followers...')
                : t('search.placeholder_followings', 'Search followings...')
            }
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            loading={isSearching}
          />
        </Box>
        <Box sx={styles.searchResult}>
          {isLoadingInitialData && <Skeleton width={100} />}
          {!isLoadingInitialData && totalCount > 0 && (
            <Typography variant="body2">
              {variant === 'follower'
                ? t('## Followers', { count: totalCount })
                : t('Following ##', { count: totalCount })}
            </Typography>
          )}
        </Box>
      </TabArea.Header>

      <TabArea.EmptyState
        visible={!isLoadingInitialData && (!!error || isEmpty)}
      >
        <EmptyState variant={variant} hasKeyword={!!debouncedSearch} />
      </TabArea.EmptyState>

      <TabArea.List visible={!isLoadingInitialData && !error && !isEmpty}>
        <RankingListHeader gridTemplateColumns={headerGridTemplateColumns} />
        <FriendRankingList
          items={dataset || []}
          loading={isLoadingInitialData || isLoadingMore}
        />
      </TabArea.List>

      {!isLoadingInitialData && (!!error || isEmpty) && (
        <SuggestionList sx={styles.suggestion} messageDisabled />
      )}
    </TabArea>
  );
}
