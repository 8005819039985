import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { alpha, PopperProps, Theme } from '@mui/material';
import { EXCLUDE_ELEMENTS, useLatestValueRef } from '@front/helper';
import { Popper } from '@front/ui';

import AhaProfile from './components/AhaProfile';
import UserIdProfile from './components/UserIdProfile';
import UserProfile from './components/UserProfile';
import { FloatingProfileExtension, FloatingProfileType } from './types';

const styles = {
  popper: {
    zIndex: 20,
    width: 344,
    '& .popper-content': {
      p: '12px',
    },
  },
  spacing: (spacing: number) => ({
    py: `${spacing}px`,
  }),
  profileContainer: {
    display: 'grid',
    overflow: 'hidden',
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  userData: {
    display: 'flex',
    gap: '4px',
    mt: 2,
  },
  userDataItem: {
    px: '4px',
    py: '2px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      fontWeight: 500,
      mr: '5px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2,
    mt: 2,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
    },
  },
  bioContainer: {
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  creatorContainer: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
};

function useClosePopperWhenMoveOutside(callback: () => void) {
  const callbackRef = useLatestValueRef(callback);
  useEffect(() => {
    function handleClick(event: Event) {
      const clickedElement = event.target as HTMLElement;
      if (
        !clickedElement.closest('.card-corner-avatar') &&
        !clickedElement.closest('.floating-avatar-anchor') &&
        !clickedElement.closest(EXCLUDE_ELEMENTS.join(','))
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousemove', handleClick);
    return () => {
      document.removeEventListener('mousemove', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const generateGetBoundingClientRect =
  (x = 0, y = 0) =>
  () =>
    ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    } as DOMRect);

const useFollowCursorVirtualElement = ({ enable }: { enable: boolean }) => {
  const [elem, setElem] = useState<{
    getBoundingClientRect: () => DOMRect;
  }>();

  useEffect(() => {
    if (!enable) return;
    const handleMousemove = (e: MouseEvent) => {
      setElem({
        getBoundingClientRect: generateGetBoundingClientRect(
          e.clientX,
          e.clientY
        ),
      });
    };
    document.addEventListener('mousemove', handleMousemove);
    return () => {
      document.removeEventListener('mousemove', handleMousemove);
    };
  }, [enable]);

  return { virtualElement: elem };
};

type FloatingProfileContentProps = FloatingProfileType & {
  extension?: FloatingProfileExtension;
  disableCta?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
  onMessageClick?: (userProfile: UserProfileObj) => void;
  onChallengeClick?: (userProfile: UserProfileObj) => void;
  beforeClickCheck?: (ev?: ReactMouseEvent) => boolean;
};

function FloatingProfileContent({
  extension,
  disableCta = false,
  onToggleFollow,
  onMessageClick,
  onChallengeClick,
  beforeClickCheck,
  ...rest
}: FloatingProfileContentProps) {
  if (rest.type === 'aha') {
    return <AhaProfile extension={extension} />;
  }
  if (rest.type === 'userId') {
    return (
      <UserIdProfile
        userId={rest.userId}
        extension={extension}
        disableCta={disableCta}
        onToggleFollow={onToggleFollow}
        onChallengeClick={onChallengeClick}
        onMessageClick={onMessageClick}
        beforeClickCheck={beforeClickCheck}
      />
    );
  }

  if (rest.type === 'userProfile') {
    return (
      <UserProfile
        userProfile={rest.userProfile}
        extension={extension}
        disableCta={disableCta}
        onToggleFollow={onToggleFollow}
        onChallengeClick={onChallengeClick}
        onMessageClick={onMessageClick}
        beforeClickCheck={beforeClickCheck}
      />
    );
  }
}

type FloatingProfileProps = FloatingProfileContentProps & {
  anchorEl?: Element | null;
  onClose?: () => void;
  followCursor?: boolean;
  show?: boolean; // used when using followCursor
  popperOptions?: PopperProps['popperOptions'];
  spacing?: number;
};

function FloatingProfile({
  extension,
  anchorEl,
  onClose = () => {},
  followCursor = false,
  show = false,
  popperOptions,
  spacing = 0,
  ...rest
}: FloatingProfileProps) {
  void useClosePopperWhenMoveOutside(onClose);
  const { virtualElement } = useFollowCursorVirtualElement({
    enable: followCursor,
  });

  return (
    <Popper
      open={followCursor ? show && !!virtualElement : !!anchorEl}
      anchorEl={followCursor ? virtualElement : anchorEl}
      placement="bottom"
      transition
      sx={[styles.popper, !!spacing && styles.spacing(spacing)]}
      popperOptions={popperOptions}
      className="floating-profile-popper"
    >
      <FloatingProfileContent {...rest} />
    </Popper>
  );
}

export default FloatingProfile;
