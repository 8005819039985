import { useRef } from 'react';
import Box from '@mui/material/Box';
import { currencyFormat, Token } from '@front/ui';

import useTableSettings from '../../hooks/useTableSettings';
import { TableLayoutRow, TableLayoutTokenCell } from '../../types';

export type IaTokenCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutTokenCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    minHeight: '32px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  nowrap: {
    height: '32px',
    py: 'unset',
    alignItems: 'center',
  },
};
export default function IaTokenCell({ cell }: IaTokenCellProps) {
  const { sx, value, className } = cell;

  const ref = useRef<HTMLDivElement>();
  const { wrap } = useTableSettings();

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      sx={[styles.root, !wrap && styles.nowrap, ...sxProps]}
      ref={ref}
      className={className}
    >
      <Token>{currencyFormat(value)}</Token>
    </Box>
  );
}
