import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Theme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import { Mention, Popper, UserAvatar } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { IaHoverEvent } from '@lib/ia/src/core/types';
import { ThreadViewType, useMemberInfo } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { getIndicators } from '@lib/web/utils';

const styles = {
  root: {
    mt: 1,
    display: 'grid',
    gap: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  others: {
    ml: 0.5,
    color: 'primary.main',
    backgroundColor: 'alpha.primaryLightA10',
    borderRadius: '2px',
    px: '2px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
  popper: {
    '& .popper-content': {
      px: 0,
      py: '6px',
      minWidth: '171px',
      width: '171px',
    },
  },
  otherMember: {
    px: '12px',
    py: '3.5px',
  },
};

type DmGroupMemberProps = {
  memberId?: string;
};

function DmGroupMember({ memberId }: DmGroupMemberProps) {
  const { data } = useMemberInfo(null, memberId, true);
  const { showUserIdProfile } = useFloatingProfile();
  const { openProfile } = useOpenGlobalProfilePanel();

  if (!data) {
    return <Skeleton width={100} />;
  }

  const memberInfo = data.data;

  return (
    <ButtonBase
      onClick={(ev: MouseEvent) => {
        ev.stopPropagation();
        openProfile(memberInfo.userId);
      }}
      onMouseEnter={(ev: MouseEvent) => {
        ev.stopPropagation();
        showUserIdProfile({
          userId: memberInfo.userId,
          anchorEl: ev.currentTarget,
        });
      }}
      className="floating-avatar-anchor"
    >
      <Mention
        src={memberInfo.nftAvatar || memberInfo.avatar}
        alt={memberInfo.distinctName}
        display={memberInfo.distinctName}
        indicators={getIndicators(memberInfo.indicator)}
      />
    </ButtonBase>
  );
}

type OtherMemberProps = {
  memberId: string;
};

function OtherMember({ memberId }: OtherMemberProps) {
  const { data } = useMemberInfo(null, memberId, true);

  if (!data) return null;
  const memberInfo = data.data;

  return (
    <Box sx={styles.otherMember}>
      <UserAvatar
        src={memberInfo.nftAvatar || memberInfo.avatar}
        title={memberInfo.displayName || memberInfo.distinctName}
        indicators={getIndicators(memberInfo.indicator)}
        size="xxs"
      />
    </Box>
  );
}

type OtherMembersProps = {
  memberIds: string[];
};

function OtherMembers({ memberIds }: OtherMembersProps) {
  const { t } = useTranslation('thread');
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonBase
        sx={styles.others}
        ref={ref}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Typography variant="body2">
          {t('groupDm.subtitle.others', { count: memberIds.length })}
        </Typography>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={ref.current}
        placement="bottom-start"
        sx={styles.popper}
        popperOptions={{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        }}
      >
        {memberIds.map((id) => (
          <OtherMember key={id} memberId={id} />
        ))}
      </Popper>
    </>
  );
}

type GroupDmSubtitleProps = {
  view: GetThreadViewRes;
};
export default function GroupDmSubtitle({ view }: GroupDmSubtitleProps) {
  const { t } = useTranslation('thread');
  const { viewType, viewMemberIds, myMemberId } = useThreadViewDetail(view);
  const groupMemberIds = viewMemberIds.filter(
    (memberId) => memberId !== myMemberId && !memberId.startsWith('agent_')
  );
  const { openProfile } = useOpenGlobalProfilePanel();
  const { showUserIdProfile } = useFloatingProfile();

  if (viewType !== ThreadViewType.DmGroup) return null;

  const availableActions = {
    openProfile: {
      action: ({ userId }: { userId: string }) => {
        openProfile(userId);
      },
    },
    showFloatingProfile: {
      action: (event: IaHoverEvent<{ userId: string }>) => {
        showUserIdProfile({
          userId: event.target.userId,
          anchorEl: event.anchorEl,
        });
      },
    },
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <Box sx={styles.root}>
        <Box sx={styles.title}>
          <Typography variant="body2">{t('groupDm.subtitle.part1')}</Typography>
          {groupMemberIds.slice(0, 2).map((memberId, index) => (
            <>
              {index > 0 && ','}
              <DmGroupMember key={memberId} memberId={memberId} />
            </>
          ))}
          {groupMemberIds.length > 2 && (
            <>
              <Typography variant="body2">
                {t('groupDm.subtitle.part2')}
              </Typography>
              <OtherMembers memberIds={groupMemberIds.slice(2)} />
            </>
          )}
        </Box>
      </Box>
    </IaActionContextProvider>
  );
}
