import { useEffect } from 'react';
import { useRouter } from 'next/router';

type RouteGroup = {
  [key: string]: string[];
};

/**
 * Feature-specific route groups
 */
const ROUTE_GROUPS: RouteGroup = {
  common: [
    '/',
    '/404',
    '/login',
    '/signup',
    '/invite',
    '/link',
    '/extend-trial',
  ],
  agent: [
    '/agent/form/[agentId]/data-source/files',
    '/agent/form/[agentId]/data-source/links',
    '/agent/form/[agentId]/data-source/playlists',
    '/agent/form/[agentId]/data-source/playlists/[sourceId]',
    '/agent/form/[agentId]/data-source/questions',
    '/agent/form/[agentId]/data-source/questions/[sourceId]',
    '/agent/form/[agentId]/prompts',
    '/agent/form/[agentId]/settings',
    '/agent/form/data-source/files',
    '/agent/form/data-source/links',
    '/agent/form/data-source/playlists',
    '/agent/form/data-source/playlists/[sourceId]',
    '/agent/form/data-source/questions',
    '/agent/form/data-source/questions/[sourceId]',
    '/agent/form/prompts',
    '/agent/form/settings',
  ],
  agents: [
    '/agents',
    '/agents/[agentId]',
    '/agents/my-agents',
    '/agents/workflow',
  ],
  clubGroup: [
    '/club-group',
    '/club-group/[groupId]',
    '/club-group/form/[groupId]/clubs',
    '/club-group/form/[groupId]/settings',
    '/club-group/form/clubs',
    '/club-group/form/settings',
  ],
  club: [
    '/club',
    '/club/joined',
    '/club/my-created',
    '/club/[slug]',
    '/club/[slug]/analytics/goals',
    '/club/[slug]/analytics/mistakes/[category]',
    '/club/[slug]/analytics/my-path',
    '/club/[slug]/analytics/weakness/[category]',
    '/club/[slug]/challenge/[quizId]',
    '/club/[slug]/challenge/[quizId]/challengers',
    '/club/[slug]/challenges',
    '/club/[slug]/create-questions',
    '/club/[slug]/editor/[questionId]',
    '/club/[slug]/exam/[quizId]/[roundNo]',
    '/club/[slug]/exam/[quizId]/[roundNo]/result',
    '/club/[slug]/exam/[quizId]/[roundNo]/result/awards',
    '/club/[slug]/exam/[quizId]/[roundNo]/result/awards/[categoryPath]',
    '/club/[slug]/pdf/quiz/[quizId]',
    '/club/[slug]/playlist/[quizId]',
    '/club/[slug]/playlists/[group]',
    '/club/[slug]/playlists/[group]/[markId]',
    '/club/[slug]/playlists/creators',
    '/club/[slug]/playlists/creators/[quizId]',
    '/club/[slug]/playlists/emoji',
    '/club/[slug]/playlists/friends-playlists',
    '/club/[slug]/playlists/hashtags',
    '/club/[slug]/playlists/history',
    '/club/[slug]/playlists/saved',
    '/club/[slug]/practice/[quizId]/[roundNo]',
    '/club/[slug]/practice/[quizId]/[roundNo]/result',
    '/club/[slug]/practice/[quizId]/[roundNo]/result/awards',
    '/club/[slug]/practice/[quizId]/[roundNo]/result/awards/[categoryPath]',
    '/club/[slug]/qbank-reporter',
    '/club/[slug]/rankings',
    '/club/[slug]/review-questions',
    '/club/[slug]/review-questions/[userId]',
    '/club/[slug]/review-questions/all',
    '/club/[slug]/review/[userId]/[questionId]',
    '/club/[slug]/review/all/[questionId]',
    '/club/[slug]/shared/[sid]/invitation',
    '/club/[slug]/start',
    '/club/[slug]/summary/awards',
    '/club/[slug]/summary/challenges',
    '/club/[slug]/summary/playlists',
    '/club/[slug]/summary/points-activity',
    '/club/[slug]/thread/[viewId]',
    '/club/[slug]/thread/[viewId]/[cid]',
    '/club/form/[clubSlug]/exam-settings',
    '/club/form/[clubSlug]/manage-ai',
    '/club/form/[clubSlug]/members',
    '/club/form/[clubSlug]/overview',
    '/club/form/[clubSlug]/requests',
    '/club/form/exam-settings',
    '/club/form/manage-ai',
    '/club/form/members',
    '/club/form/overview',
  ],
  directMessages: [
    '/direct-messages',
    '/direct-messages/channel/[cid]',
    '/direct-messages/new-thread',
    '/direct-messages/view/[viewId]',
  ],
  marketplace: ['/marketplace/[status]'],
  profile: [
    '/profile/account',
    '/profile/account/edit',
    '/profile/awards',
    '/profile/awards/[categoryPath]',
    '/profile/awards/almost-there',
    '/profile/discount-code',
    '/profile/discount-code/management',
    '/profile/invite-friends',
    '/profile/my-avatars',
    '/profile/my-plan',
    '/profile/parent-phone-number',
    '/profile/settings',
    '/profile/wallet',
    '/profile/wallet/aha-points',
  ],
  workflow: ['/workflow/[workflowId]'],
};

/**
 *  Define route groups with their associated modules
 *
 *  XXX: While it would be ideal to split pages into separate modules that load dynamically based on user navigation,
 *  we currently lack clear module boundaries. Our experiments show that loading all pages upfront results in ~80
 *  additional network requests totaling around 200KB, which we consider acceptable for now given the tradeoffs.
 */
const ALL_PAGES = Object.values(ROUTE_GROUPS).flat();

export function useModulePrefetch() {
  const { prefetch } = useRouter();

  useEffect(() => {
    ALL_PAGES.forEach((p) => prefetch(p));
  }, [prefetch]);
}
