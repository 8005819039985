import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Router from 'next/router';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';
import { CheckoutPanelKeys, MarketplaceKeys } from '@app/web/src/types/panel';
import { OtherLink as OtherLinkIcon } from '@front/icon';
import { Button, SimpleTooltip, useBaseLayout } from '@front/ui';
import { apis, useAuth, useMemberInfo } from '@lib/web/apis';
import { useCopyToClipboard } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import useMarketplace from '../hooks/useMarketplace';
import useProductAction from '../hooks/useProductAction';
import useProductCheckout from '../hooks/useProductCheckout';

const styles = {
  button: {
    width: '100%',
  },
  buttonText: {
    ':not(:hover)': {
      background: 'transparent',
      borderColor: 'transparent',
      color: 'text.primary',
    },
  },
  buttonTooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: 200,
    },
    '&[data-popper-placement*="bottom-end"] .MuiTooltip-tooltip': {
      mt: '0 !important',
    },
    '&[data-popper-placement*="top-end"] .MuiTooltip-tooltip': {
      mb: '0 !important',
    },
  },
  buttonCopy: {
    minWidth: 0,
  },
  copyActive: {
    opacity: 1,
    '& .em-button-base': {
      transform: 'translate(4px, 4px)',
      color: 'background.darker',
      bgcolor: 'success.light',
      boxShadow: 'none',
      border: 'none',
    },
    '& .em-button-shadow': {
      border: 'none',
      bgcolor: 'alpha.successLightA30',
    },
  },
};

type ProductActionButtonProps = {
  product: GetSanityProductRes;
  detailPanel?: boolean; // action button on detail panel (not on product item in list)
  onClick?: (action?: string) => void;
  dataTestId?: string;
};

export default function ProductActionButton({
  product,
  detailPanel = false,
  onClick,
  dataTestId,
}: ProductActionButtonProps) {
  const { t } = useTranslation('marketplace');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { member, isLogged, isGuest, blockerTip } = useAuth();
  const { data: userData, mutate: mutateUser } = useMemberInfo(
    member?.userId,
    null
  );
  const guestSignUrlUrl = useClaimSignUpUrl();

  const nftAvatar = userData?.data.nftAvatar || undefined;
  const action = useProductAction(product, detailPanel, nftAvatar);
  const { setActiveProduct } = useProductCheckout();
  const [actionLoading, setActionLoading] = useState(false);
  const [, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  const copyTimeRef = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const { setSelected } = useMarketplace();

  const { openRightPanel, rightPanelParams } = useBaseLayout();

  const returnTo =
    window.location.href.split('?')[0] + `?productId=${product.id}`;

  const { loginUrl } = useAuthLinks({
    loginReturnTo: returnTo,
  });

  const goToLoginPage = useCallback(() => {
    Router.push(loginUrl);
  }, [loginUrl]);

  const handleCheckout = useCallback(async () => {
    if (!isLogged) {
      goToLoginPage();
      return;
    }
    setActionLoading(true);
    setActiveProduct(product);
    openRightPanel(CheckoutPanelKeys.Checkout, { ...rightPanelParams });
    setActionLoading(false);
  }, [
    goToLoginPage,
    isLogged,
    openRightPanel,
    product,
    setActiveProduct,
    rightPanelParams,
  ]);

  const handleSetProfilePic = async () => {
    setActionLoading(true);
    const [success] = await call(
      apis.marketplace.selectMyActiveNft({
        productId: product.id,
      })
    );
    if (success) {
      mutateUser();
    }
    setActionLoading(false);
  };

  const handleResetProfilePic = async () => {
    setActionLoading(true);
    const [success] = await call(
      apis.marketplace.selectMyActiveNft({
        productId: null,
      })
    );
    if (success) {
      mutateUser();
    }
    setActionLoading(false);
  };

  const handleCopyPaymentLink = async () => {
    const orderInfo = action.orderInfo;
    if (!orderInfo) return;

    setLoading(true);
    const shareInfoResponse = await apis.marketplace.shareProductOrderInfo({
      orderId: orderInfo.orderId,
    });
    const shareInfo = shareInfoResponse.data.data;
    await copy(
      `${process.env.APP_URL}/public/${shareInfo.sid}/payment-link/product`
    );
    setLoading(false);

    setCopied(true);
    if (copyTimeRef.current) {
      clearTimeout(copyTimeRef.current);
    }
    copyTimeRef.current = setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleOpenDetail = async () => {
    setSelected(product);
    openRightPanel(MarketplaceKeys.MarketplaceDetail, { product });
  };

  const handleActionClicked = () => {
    if (action.disabled) {
      return;
    }
    onClick?.(action.action);
    if (action.action === 'buy-now') {
      handleCheckout();
      return;
    }
    if (action.action === 'set-profile-pic') {
      handleSetProfilePic();
      return;
    }
    if (action.action === 'reset-profile-pic') {
      handleResetProfilePic();
      return;
    }
    if (action.action === 'copy-payment-link') {
      handleCopyPaymentLink();
      return;
    }
    if (action.action === 'open-detail') {
      handleOpenDetail();
      return;
    }
  };

  useEffect(
    () => () => {
      if (copyTimeRef.current) clearTimeout(copyTimeRef.current);
    },
    []
  );

  const ActionButton = () => {
    if (isGuest) {
      return (
        <Button
          sx={styles.button}
          disabled={!guestSignUrlUrl}
          component={Link}
          href={guestSignUrlUrl}
        >
          {t('button.Buy Now')}
        </Button>
      );
    }
    if (blockerTip) {
      return (
        <SimpleTooltip title={blockerTip}>
          <span>
            <Button sx={styles.button} disabled>
              {t('button.Buy Now')}
            </Button>
          </span>
        </SimpleTooltip>
      );
    }
    if (action.action === 'copy-payment-link') {
      return (
        <Button
          sx={[styles.buttonCopy, copied && styles.copyActive]}
          variant="outlined"
          disabled={action.disabled}
          onMouseDown={(event) => event.stopPropagation()}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleActionClicked();
          }}
          prefixIcon={<OtherLinkIcon />}
          loading={loading}
        >
          {action.label}
        </Button>
      );
    }
    return (
      <Button
        sx={[styles.button, action.type === 'text' && styles.buttonText]}
        disabled={action.disabled}
        onMouseDown={(event) => event.stopPropagation()}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleActionClicked();
        }}
        loading={actionLoading}
        data-testid={dataTestId}
      >
        {action.label}
      </Button>
    );
  };

  if (action.tooltip && mdUp) {
    return (
      <SimpleTooltip
        title={action.tooltip}
        slotProps={{
          popper: {
            sx: styles.tooltip,
          },
        }}
        placement="bottom-end"
      >
        <Box sx={styles.buttonTooltip}>
          <ActionButton />
        </Box>
      </SimpleTooltip>
    );
  }

  return <ActionButton />;
}
