import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  alignRight: {
    ml: 'auto',
  },
};

type NewChallengeButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.new.challenge' }>;
};
export default function NewChallengeButton({ cta }: NewChallengeButtonProps) {
  const { t } = useTranslation('notification');

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    Router.push(`/club/${cta.clubSlug}/start`);
  };

  return (
    <Button
      prefixIcon={<MainChallengeIcon />}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      sx={styles.alignRight}
    >
      {t('cta.user.challenge.status.button.New Challenge', 'New Challenge')}
    </Button>
  );
}
