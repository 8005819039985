import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import SuggestionList from '@app/web/src/components/SuggestionList';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState } from '@app/web/src/utils/search';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  SearchBar,
  SquareAvatar,
  TextButton,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  useSearchClubMembers,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call } from '@lib/web/utils';
import { sanitize } from 'dompurify';

import useClubMembersTabDataset from '../hooks/useClubMembersTabDataset';
import useClubMembersTabLayoutConfig from '../hooks/useClubMembersTabLayoutConfig';

const styles = {
  content: {
    flex: 1,
    position: 'relative',
  },
  searchBar: {
    px: { xs: 2.5, md: '12px' },
  },
  emptyContent: {
    px: { xs: '16px', md: '12px' },
    pt: 1,
  },
  addFriendsButton: {
    mt: 1,
    px: 0,
  },
};

export default function ClubMembersTabContent() {
  const { t } = useTranslation('club');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {
    focused,
    search,
    debouncedSearch,
    isSearching,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus();
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const rightParams = getRightParams(GlobalPanelKeys.GlobalAddFriends);

  const clubSlug = useClubSlug();
  const data = useSearchClubMembers({
    clubSlug,
    keyword: debouncedSearch || '',
    isGetRole: true,
    isJoined: true,
  });
  const { dataset, isLoading: searchLoading, totalCount, mutate } = data;

  const displayDataset = useClubMembersTabDataset({ dataset });

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: false,
  });

  const configs = useClubMembersTabLayoutConfig({
    listState,
    displayDataset,
    totalCount,
    searchLoading,
  });
  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );
  const { showUserIdProfile } = useFloatingProfile();

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const handleChallengeClick = (
    item: IconListLayoutItemObj<GetSearchClubMembersRes>
  ) => {
    if (!item.metadata) return;
    const challenger = {
      id: item.id,
      display: item.title,
      avatar: item.avatarUrl,
      memberInfo: item.metadata,
    };
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource: [challenger],
    });
  };

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const availableActions = {
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
    challenge: {
      action: async (item: IconListLayoutItemObj<GetSearchClubMembersRes>) => {
        handleChallengeClick(item);
      },
    },
    titleHover: {
      action: (
        event: IconListLayoutItemHoverEvent<GetSearchClubMembersRes>
      ) => {
        if (!event.target.metadata) return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.metadata.userId,
        });
      },
    },
    avatarHover: {
      action: (
        event: IconListLayoutItemHoverEvent<GetSearchClubMembersRes>
      ) => {
        if (!event.target.metadata) return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.metadata.userId,
          options: {
            spacing: 8,
          },
        });
      },
    },
  };

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
  });

  const handleAddFriendsClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAddFriends, {
      ...rightParams,
      activeTab: 0,
      defaultSearch: search,
    });
  };

  const emptyContent = (
    <>
      {displayDataset.length === 0 && isSearching && !searchLoading && (
        <Box sx={styles.emptyContent}>
          <Typography
            variant="body2"
            color="alpha.lightA64"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                t('club.RHS.addFriends.clubMembersTab.empty_keyword', {
                  context: 'keyword',
                })
              ),
            }}
          />
          <TextButton
            prefixIcon={<OtherAddFriendIcon width="16" height="16" />}
            sx={styles.addFriendsButton}
            onClick={handleAddFriendsClick}
          >
            {t('club.RHS.addFriends.clubMembersTab.empty.cta', 'Add Friends')}
          </TextButton>
        </Box>
      )}
    </>
  );
  const hasData = configs.length > 0;

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={styles.content}>
          <BaseLayoutRightPanel.ScrollArea
            scrollableNodeProps={{ ref: scrollRef }}
          >
            <Box sx={styles.searchBar}>
              <SearchBar
                placeholder={t(
                  'search.placeholder_clubMembers',
                  'Search club members...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </Box>
            {hasData && <IaLayouts layouts={configs} />}
            {emptyContent}
            <Box sx={{ height: hasData ? 16 : 8 }} />
            <SuggestionList />
          </BaseLayoutRightPanel.ScrollArea>
        </Box>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
