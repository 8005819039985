import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import Box from '@mui/material/Box';
import DisplayTableJoinClubButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/components/DisplayTableJoinClubButton';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import {
  DisplayTableLayoutConfig,
  DisplayTableLayoutRow,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  ClubJoinedStatus,
  ClubMemberRequestStatus,
  ClubOfficialType,
  ClubPrivacy,
  ClubStatus,
  ClubVisibility,
} from '@lib/web/apis';
import { getClubIconPath } from '@lib/web/utils';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
  },
  button: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
  loadMore: {
    '& .MuiTypography-root': {
      fontWeight: 'normal',
    },
  },
};

type Metadata = {
  clubMemberRequestId: string;
  clubMemberRequestStatus: ClubMemberRequestStatus;
  clubId: string;
  clubSlug: string;
  clubName: string;
  clubIcon: string;
  clubPrivacy: ClubPrivacy;
  clubOfficialType: ClubOfficialType;
  clubVisibility: ClubVisibility;
  clubStatus: ClubStatus;
  joinedStatus: ClubJoinedStatus;
};

export type RelatedClubListProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.related.club.list' }>;
};

export default function RelatedClubList({ cta }: RelatedClubListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 188px';
    const columnOrder = ['name', 'action'];
    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'MainApps',
                label: t('related.club.table.clubs', 'Clubs'),
              },
              { type: 'empty' },
            ],
          },
          rows: cta.clubs
            .filter((_, index) => (collapse ? index === 0 : true)) // 1 if collapsed => show 1st row
            .map((item) => {
              return {
                id: item.clubMemberRequestId,
                cells: {
                  name: {
                    type: 'avatarText',
                    avatar: item.clubIcon || getClubIconPath(item.clubName),
                    text: item.clubName,
                    iconSuffix:
                      item.clubOfficialType === ClubOfficialType.Official
                        ? 'ProfileOfficial'
                        : undefined,
                  },
                  action: {
                    type: 'custom',
                    renderKey: 'joinClubButton',
                    metadata: item,
                  },
                },
                clickAction: {
                  type: 'event',
                  value: 'goToClubDetails',
                },
                metadata: item,
              };
            }),
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'textButton',
                action: 'toggleCollapse',
                text: collapse
                  ? t('related.club.table.seeMore', 'See more')
                  : t('related.club.table.hide', 'Hide'),
                suffixIcon: collapse
                  ? 'ActionChevronRightSmall'
                  : 'ActionChevronUp',
                sx: { ml: 0 },
                containerSx: { pt: 1 },
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [collapse, cta.clubs, t]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
      goToClubDetails: {
        action: (ev: DisplayTableLayoutRow<Metadata>) => {
          if (!ev.metadata) return;
          void Router.push(`/club/${ev.metadata.clubSlug}`);
        },
      },
    }),
    []
  );

  const renders = useMemo(() => {
    return {
      joinClubButton: (ev: Metadata) => {
        return <DisplayTableJoinClubButton clubSlug={ev.clubSlug} />;
      },
    };
  }, []);

  if (!cta.clubs || cta.clubs.length === 0) return null;

  return (
    <Box sx={styles.root}>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={config} />
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </Box>
  );
}
