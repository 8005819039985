/**
 * Creates a function that ensures only one promise runs at a time and
 * specifically executes the last triggered call, dropping any intermediate calls.
 *
 * Example:
 * If calls are made in sequence: A -> B -> C while A is still running:
 * - A will complete its execution
 * - B will be dropped but resolved/rejected when C finishes
 * - C (the latest call) will execute after A finishes
 *
 * @param fn The async function to serialize
 * @returns A wrapped version of the function that executes only the latest call
 */
export function createLatestCallExecutor<
  TArgs extends Array<string | number | boolean | object>,
  TReturn
>(
  fn: (...args: TArgs) => Promise<TReturn>
): (...args: TArgs) => Promise<TReturn> {
  let currentPromise: Promise<TReturn> | null = null;
  let pendingCall: {
    args: TArgs;
    resolve: (value: TReturn) => void;
    reject: (error: unknown) => void;
  } | null = null;
  let droppedHandlers: {
    resolve: (value: TReturn) => void;
    reject: (error: unknown) => void;
  }[] = [];

  const executeNext = async () => {
    if (!pendingCall) return currentPromise as Promise<TReturn>;

    const { args, resolve, reject } = pendingCall;
    pendingCall = null;

    try {
      currentPromise = fn(...args);
      const result = await currentPromise;
      resolve(result);
      // Only resolve dropped calls if there's no pending call
      if (!pendingCall) {
        droppedHandlers.forEach((handlers) => handlers.resolve(result));
        droppedHandlers = [];
      }
      return result;
    } catch (error) {
      reject(error);
      // Reject dropped calls immediately on error
      droppedHandlers.forEach((handlers) => handlers.reject(error));
      droppedHandlers = [];
    } finally {
      currentPromise = null;
      // If there's another pending call, execute it
      if (pendingCall) {
        void executeNext();
      }
    }
  };

  return async (...args: TArgs): Promise<TReturn> => {
    // If there's an ongoing call, store this as the pending call and return a new promise
    if (currentPromise) {
      return new Promise<TReturn>((resolve, reject) => {
        // If there was a previous pending call, add its handlers to dropped list
        if (pendingCall) {
          droppedHandlers.push({
            resolve: pendingCall.resolve,
            reject: pendingCall.reject,
          });
        }
        pendingCall = { args, resolve, reject };
      });
    }

    // Otherwise execute immediately
    return new Promise<TReturn>((resolve, reject) => {
      pendingCall = { args, resolve, reject };
      void executeNext();
    });
  };
}
