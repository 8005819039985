import { useCallback, useContext } from 'react';

import { AsyncTaskContext, WatchingConfig } from '../contexts';

export default function useWatchAsyncTask() {
  const { watchingConfigsRef } = useContext(AsyncTaskContext);

  const watchAsyncTask = useCallback(
    (config: WatchingConfig) => {
      if (!watchingConfigsRef.current) return;

      /**
       * prevent duplicate watching for the same taskId and callbackKey
       * callbackKey is used to identify the callback function
       */
      if (
        watchingConfigsRef.current.find(
          (c) =>
            c.taskId === config.taskId && c.callbackKey === config.callbackKey
        )
      ) {
        return;
      }

      watchingConfigsRef.current.push(config);
    },
    [watchingConfigsRef]
  );

  return {
    watchAsyncTask,
  };
}
