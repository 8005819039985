import { NextRouter } from 'next/router';
import { SharedNotificationPathname } from '@lib/web/apis';
import { TFunction } from 'i18next';

import getAnalyticsPageConfig from '../layouts/pageConfig/analytics';
import getClubPageConfig from '../layouts/pageConfig/club';
import getMainPageConfig from '../layouts/pageConfig/main';
import getPlaylistsPageConfig from '../layouts/pageConfig/playlists';
import getPracticePageConfig from '../layouts/pageConfig/practice';
import getProfilePageConfig from '../layouts/pageConfig/profile';

export default function getPathnameSettings(
  t: TFunction,
  router: NextRouter | VirtualRouter
) {
  const clearPathname = router?.pathname.replace('/shared/[sid]', '');
  const clubPageConfig = getClubPageConfig(t);
  const analyticsPageConfig = getAnalyticsPageConfig(t);
  const playlistConfig = getPlaylistsPageConfig(t);
  const practiceConfig = getPracticePageConfig(t);
  const mainPageConfig = getMainPageConfig(t);
  const profilePageConfig = getProfilePageConfig(t);
  const { query } = router;
  switch (clearPathname) {
    case SharedNotificationPathname.ClubSummary: {
      return analyticsPageConfig.summary;
    }
    case SharedNotificationPathname.ClubAnalyticsMyPath: {
      return analyticsPageConfig.comparePath;
    }
    case SharedNotificationPathname.ClubAnalyticsGoals: {
      return analyticsPageConfig.dailyGoals;
    }
    case SharedNotificationPathname.ClubAnalyticsWeakness: {
      if (query.category === 'overtime') {
        return analyticsPageConfig.weaknessOvertime;
      }
      if (query.category === 'total-wrong') {
        return analyticsPageConfig.weaknessMistakes;
      }
      return analyticsPageConfig.weaknessNotPracticed;
    }
    case SharedNotificationPathname.ClubAnalyticsMistakes: {
      if (query.category === 'overtime') {
        return analyticsPageConfig.overallOvertime;
      }
      return analyticsPageConfig.overallMistakes;
    }
    case SharedNotificationPathname.ClubChallengeDetails: {
      return playlistConfig.challengesDetail;
    }
    case SharedNotificationPathname.ClubPlaylistDetails: {
      return playlistConfig.historyDetail;
    }
    case SharedNotificationPathname.ClubPlaylistsGroupMark: {
      if (query.group === 'emoji') {
        return playlistConfig.reaction;
      }
      return playlistConfig.topics;
    }
    case SharedNotificationPathname.ClubPlaylistsGroup: {
      if (query.group === 'overtime') {
        return playlistConfig.historyOvertime;
      }
      return playlistConfig.historyMistakes;
    }
    case SharedNotificationPathname.ClubExamResult:
    case SharedNotificationPathname.ClubPracticeResult: {
      return practiceConfig.practiceResults;
    }
    case SharedNotificationPathname.ClubReviewQuestion: {
      return { title: t('Review Questions'), icon: 'ChatQuestionList' };
    }
    case SharedNotificationPathname.ClubEditorQuestion:
    case SharedNotificationPathname.ClubReviewUserQuestion:
    case SharedNotificationPathname.ClubPracticeQuestion:
    case SharedNotificationPathname.ClubExamQuestion: {
      return { title: t('Question'), icon: 'ProfilePlaylist' };
    }
    case SharedNotificationPathname.ClubRankings: {
      return clubPageConfig.rankings;
    }
    case SharedNotificationPathname.ClubChallengePlaylists: {
      return { title: t('Challenges'), icon: 'MainChallenge' };
    }
    case SharedNotificationPathname.ClubHistoryPlaylists:
    case SharedNotificationPathname.ClubSavedPlaylists:
    case SharedNotificationPathname.ClubEmojiPlaylists:
    case SharedNotificationPathname.ClubFriendsPlaylists:
    case SharedNotificationPathname.ClubHashtagsPlaylists:
    case SharedNotificationPathname.ClubCreatorsPlaylists: {
      return { title: t('Sessions'), icon: 'ProfilePlaylist' };
    }
    case SharedNotificationPathname.ClubReviewUserQuestions:
    case SharedNotificationPathname.ClubReviewAllQuestions:
    case SharedNotificationPathname.ClubCreateQuestions:
    case SharedNotificationPathname.ClubReviewQuestions: {
      return { title: t('Questions'), icon: 'ProfilePlaylist' };
    }
    case SharedNotificationPathname.ProfileAccount: {
      return profilePageConfig.account;
    }
    case SharedNotificationPathname.ClubGroups: {
      return mainPageConfig.clubGroups;
    }
    case SharedNotificationPathname.Clubs: {
      return mainPageConfig.allClubs;
    }
    case SharedNotificationPathname.MyAgents: {
      return mainPageConfig.myAgents;
    }
    case SharedNotificationPathname.Agents: {
      return mainPageConfig.agents;
    }
    case SharedNotificationPathname.ClubGroupDetails: {
      return { title: t('Club') };
    }
    case SharedNotificationPathname.ClubDetails: {
      return { title: t('Club') };
    }
    case SharedNotificationPathname.AgentDetails: {
      return mainPageConfig.agents;
    }
  }

  return null;
}
