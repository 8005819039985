import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/web/src/hooks/redux';
import {
  BlockerPlacement,
  BlockerType,
  clearBlocker,
  setBlocker,
} from '@app/web/src/reducers/blockerReducer';

export default function useBlocker() {
  const currentBlockerType = useAppSelector(
    (state) => state.blocker.blockerType
  );
  const currentPlacement = useAppSelector((state) => state.blocker.placement);

  const dispatch = useAppDispatch();
  const openBlocker = useCallback(
    (type: BlockerType, placement: BlockerPlacement) => {
      dispatch(setBlocker({ blockerType: type, placement }));
    },
    [dispatch]
  );

  const closeBlocker = useCallback(() => {
    dispatch(clearBlocker());
  }, [dispatch]);

  return {
    currentBlockerType,
    currentPlacement,
    openBlocker,
    closeBlocker,
  };
}
