import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { ElementPresenceProvider } from '@front/helper';
import { Scrollbar, SimpleTooltip } from '@front/ui';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ComposerBlock } from '@lib/web/composer';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { ThreadChannelProperties } from '@lib/web/thread/ThreadProperties';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isNil } from 'lodash';
import { MessageResponse } from 'stream-chat';
import { Channel as StreamChannel } from 'stream-chat/dist/types/channel';
import { Channel, Chat, StreamMessage } from 'stream-chat-react';

import { ThreadComposerProvider } from '../contexts';
import { useChannelInformation } from '../hooks/channel/useChannelInformation';
import { useMaybeMarkThreadChatRead } from '../hooks/channel/useMaybeMarkThreadChatRead';
import { useThread } from '../hooks/core/useThread';

import ThreadChatSkeleton from './components/ThreadChatSkeleton';
import ThreadMessageInput from './components/ThreadMessageInput';
import { useThreadChatMessages } from './hooks/useThreadChatMessages';
import { useThreadMessageChannelLayoutConfig } from './hooks/useThreadMessageChannelLayoutConfig';
import useThreadSendMessage from './hooks/useThreadSendMessage';

const styles = {
  root: {
    width: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  containerWithThreadProperties: {
    pt: '111px',
  },
  messageList: {
    position: 'relative',
    '& > [data-simplebar]': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .simplebar-wrapper': {
      position: 'relative',
      height: '100%',
    },
    '& .simplebar-content-wrapper': {
      height: '100%',
    },
    '& .simplebar-content': {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
};

export type ThreadChatContentHandler = {
  sendMessage(params: { text?: string; blocks?: ComposerBlock[] }): void;
};

const ThreadChatContent = forwardRef<
  ThreadChatContentHandler | undefined,
  {
    channel: StreamChannel;
    showThreadProperties: boolean;
    enableChat?: boolean;
    threadMemberIds?: string[];
    tooltip?: string;
    searchedMessages?: MessageResponse<StreamChatGenerics>[];
    availableEventNames?: string[];
  }
>(
  (
    {
      channel,
      showThreadProperties,
      threadMemberIds,
      tooltip,
      searchedMessages,
      availableEventNames,
    },
    contentRef
  ) => {
    const [text, setText] = useState('');
    const sendMessage = useThreadSendMessage();

    const { messageIdToChildChannel } = useChannelsHierarchyDisplay({
      channels: useMemo(() => [channel], [channel]),
    });

    const { scrollRef, messageStatus, channelMessages, appendNewMessages } =
      useThreadChatMessages({
        channel,
      });

    const config = useThreadMessageChannelLayoutConfig({
      channel: channel,
      messages: !isNil(searchedMessages) ? searchedMessages : channelMessages,
      isLoading: messageStatus === 'init' || messageStatus === 'loading',
      messageIdToChildChannel,
      availableEventNames,
    });

    const handleBeforeSubmit = useCallback(
      (message: StreamMessage<StreamChatGenerics>) => {
        appendNewMessages([message]);
      },
      [appendNewMessages]
    );

    const { ref } = useMaybeMarkThreadChatRead({ channel });

    useImperativeHandle(
      contentRef,
      () => ({
        sendMessage(params: { text?: string; blocks?: ComposerBlock[] }) {
          sendMessage({
            channel,
            threadMemberIds,
            text: params.text,
            blocks: params.blocks,
            onBeforeSubmit: handleBeforeSubmit,
          });
        },
      }),
      [channel, handleBeforeSubmit, sendMessage, threadMemberIds]
    );

    return (
      <ElementPresenceProvider elementRef={ref}>
        <Channel channel={channel} markReadOnMount={false}>
          <Box sx={styles.root} ref={ref}>
            {showThreadProperties && (
              <ThreadChannelProperties
                channel={channel}
                mode="expandableBrief"
              />
            )}
            <Box
              sx={[
                styles.container,
                showThreadProperties && styles.containerWithThreadProperties,
              ]}
            >
              <Box sx={styles.messageList} className="thread-chat-message-list">
                <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
                  <IaLayouts layouts={config} />
                </Scrollbar>
              </Box>
              <Box>
                <ThreadComposerProvider
                  sendPubliclyCheckboxEnable={false}
                  threadMemberIds={threadMemberIds}
                  text={text}
                  setText={setText}
                >
                  {tooltip ? (
                    <SimpleTooltip title={tooltip} followCursor>
                      <ThreadMessageInput
                        channel={channel}
                        onBeforeSubmit={handleBeforeSubmit}
                      />
                    </SimpleTooltip>
                  ) : (
                    <ThreadMessageInput
                      channel={channel}
                      onBeforeSubmit={handleBeforeSubmit}
                    />
                  )}
                </ThreadComposerProvider>
              </Box>
            </Box>
          </Box>
        </Channel>
      </ElementPresenceProvider>
    );
  }
);

type ThreadChatProps = {
  channelCid: string;
  channel?: StreamChannel;
  showThreadProperties?: boolean;
  tooltip?: string;
  searchedMessages?: MessageResponse<StreamChatGenerics>[];
  availableEventNames?: string[];
  contentRef?: MutableRefObject<ThreadChatContentHandler | undefined>;
};

export default function ThreadChat({
  channelCid,
  channel,
  showThreadProperties = true,
  tooltip,
  searchedMessages,
  availableEventNames,
  contentRef,
}: ThreadChatProps) {
  const { t } = useTranslation('thread');
  const { chatClient } = useThread();
  const [isSelfRemovedFromChannel, setIsSelfRemovedFromChannel] =
    useState(false);
  const [isChannelDeleted, setIsChannelDeleted] = useState(false);
  const { getChannelInformation } = useChannelInformation();

  const { channelMemberIds = undefined } = channel
    ? getChannelInformation(channel)
    : {};

  useAddWatchingEvent({
    scope: 'chat-room-self-removed-from-channel',
    key: channelCid,
    callback: (event) => {
      if (event.channel_id !== channel?.id) return;
      if (event.type !== 'notification.removed_from_channel') return;
      setIsSelfRemovedFromChannel(true);
    },
  });

  useAddWatchingEvent({
    scope: 'channel-deleted',
    key: channelCid,
    callback: (event) => {
      if (event.channel_id !== channel?.id) return;
      if (event.type !== 'channel.deleted') return;
      setIsChannelDeleted(true);
    },
  });

  if (!chatClient || !channel) return <ThreadChatSkeleton />;

  if (channelCid !== channel.cid) return;

  if (isSelfRemovedFromChannel) {
    return (
      <Box
        sx={{
          px: 3,
          color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        }}
      >
        {t('chat.self.removed', "You've been removed from this thread")}
      </Box>
    );
  }

  if (isChannelDeleted) {
    return (
      <Box
        sx={{
          px: 3,
          color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        }}
      >
        {t('chat.channel.deleted', 'The channel has been deleted')}
      </Box>
    );
  }

  return (
    <Chat client={chatClient}>
      <ThreadChatContent
        ref={contentRef}
        key={channelCid}
        threadMemberIds={channelMemberIds}
        channel={channel}
        showThreadProperties={showThreadProperties}
        tooltip={tooltip}
        searchedMessages={searchedMessages}
        availableEventNames={availableEventNames}
      />
    </Chat>
  );
}
