import { MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { Button, Icon, useBaseRightPanel } from '@front/ui';

export default function ShareDiscountCodeActions() {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel();
  const handleShareCode = (ev: MouseEvent) => {
    ev.stopPropagation();
    openRightPanel(GlobalPanelKeys.GlobalShareDiscount);
  };
  return (
    <Box
      width="100%"
      display="flex"
      gap={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button
        color="earn"
        prefixIcon={<Icon name="OtherShareToDirect" />}
        onClick={handleShareCode}
      >
        {t('button.Share Code')}
      </Button>
    </Box>
  );
}
