import React from 'react';
import { useTranslation } from 'react-i18next';
import RichText from '@lib/ia/src/components/RichText';
import { IaRichText } from '@lib/ia/src/core/types';
import { getTagIconPath } from '@lib/web/utils';

type PlaylistDetailLinkProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.playlist.detail' }>;
  tag: GetNotificationRes['tag'];
  clubSlug: string;
};

export default function PlaylistDetailLink({
  cta,
  tag,
  clubSlug,
}: PlaylistDetailLinkProps) {
  const { t } = useTranslation('notification');
  const content: IaRichText = [
    {
      type: 'link',
      text: cta.quizName || 'Untitled',
      src: getTagIconPath('ProfilePlaylist'),
      badgeSrc: cta.ownerAvatar,
      action: {
        type: 'link',
        value: `/club/${clubSlug}/playlist/${cta.quizShortId}`,
      },
      inline: true,
    },
  ];

  if (tag === 'challenge.ending.scheduler.challenger.club.v2') {
    content.push({
      type: 'html',
      value: t(
        'challenge.ending.scheduler.challenger.club.3',
        'Feeling competitive? Challenge other competitors.'
      ),
    });
  }

  return <RichText text={content} variant="body2" />;
}
