import { MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import {
  ActionArrowLeft as ActionArrowLeftIcon,
  ActionArrowRight as ActionArrowRightIcon,
  TestSolution as TestSolutionIcon,
} from '@front/icon';
import { Button, useBaseLayout } from '@front/ui';

import usePracticeFlowEvent from '../../../hooks/usePracticeFlowEvent';
import usePracticeQuestionAnswers from '../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionResult from '../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../hooks/usePracticeStaticData';
import usePracticeStatus from '../../../hooks/usePracticeStatus';

import { useFormatSpecificSubmittable } from './FooterActions';
import SubmitButton from './SubmitButton';

const styles = {
  buttons: {
    position: 'relative',
    px: 2.5,
    py: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    '& button:not(.icon-button)': {
      flex: { xs: 1, md: 'unset' },
      width: { md: 120 },
      minWidth: 'unset',
    },
    '& button.icon-button': {
      width: { xs: 42, md: 36 },
    },
    '& button': {
      height: { xs: 42, md: 36 },
    },
  },
  nextButton: (theme: Theme) => ({
    width: 'auto !important',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      maxWidth: '207px !important',
    },
  }),
};

type FooterSimpleActionsProps = {
  sx?: BoxProps['sx'];
};

export default function FooterSimpleActions({ sx }: FooterSimpleActionsProps) {
  const { t } = useTranslation('quiz');

  const { questionCount, questionIndex, isPaused } = usePracticeStatus();

  const {
    onNextQuestion,
    onPreviousQuestion,
    onSubmitQuestion,
    onLazySubmitQuestion,
    onCancelSubmitQuestion,
  } = usePracticeFlowEvent();

  const { isPendingSubmit, isLoading, isSubmitted } =
    usePracticeQuestionStatus();

  const { isCorrect } = usePracticeQuestionResult();
  const { answers } = usePracticeQuestionAnswers();
  const { panelKeys } = usePracticeStaticData();
  const hasAnswer = answers.length > 0;
  const formatSpecificSubmittable = useFormatSpecificSubmittable();

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { rightPanelOpened, rightPanelTarget, toggleRightPanel } =
    useBaseLayout();
  const solutionOpened =
    rightPanelOpened && rightPanelTarget === panelKeys.solution;

  const handleClick = (
    ev: MouseEvent<HTMLButtonElement>,
    fc?: (ev: MouseEvent<HTMLButtonElement>) => void
  ) => {
    ev.currentTarget.blur();

    if (fc) fc(ev);
  };

  const color = isCorrect ? 'success' : 'error';

  if (solutionOpened) {
    return (
      <Box sx={[styles.buttons, ...sxProps]}>
        <Button
          variant="outlined"
          onClick={(ev) => handleClick(ev, onPreviousQuestion)}
          disabled={questionIndex === 0}
          color={color}
          icon
        >
          <ActionArrowLeftIcon />
        </Button>
        <Button
          data-toast-anchor
          color={color}
          sx={styles.nextButton}
          variant="outlined"
          onClick={(ev) => handleClick(ev, onNextQuestion)}
          suffixIcon={<ActionArrowRightIcon />}
        >
          {t('button.Next')}
        </Button>
      </Box>
    );
  }
  if (isSubmitted) {
    return (
      <Box sx={[styles.buttons, ...sxProps]}>
        <Button
          variant="outlined"
          disabled={questionIndex === 0}
          color={color}
          icon
          onClick={(ev) => handleClick(ev, onPreviousQuestion)}
        >
          <ActionArrowLeftIcon />
        </Button>
        <Button
          variant="outlined"
          disabled={isLoading || questionIndex === questionCount - 1}
          color={color}
          onClick={(ev) => handleClick(ev, onNextQuestion)}
          suffixIcon={<ActionArrowRightIcon />}
          sx={styles.nextButton}
        >
          {t('button.Next')}
        </Button>
        <Button
          data-toast-anchor
          data-testid="quiz-solution-button"
          color={color}
          prefixIcon={<TestSolutionIcon />}
          onClick={(ev) =>
            handleClick(ev, () => toggleRightPanel(panelKeys.solution))
          }
        >
          {t('button.Solution')}
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={[styles.buttons, ...sxProps]}>
      {!isPendingSubmit && (
        <>
          <Button
            variant="outlined"
            onClick={onPreviousQuestion}
            disabled={isLoading || questionIndex === 0}
            icon
          >
            <ActionArrowLeftIcon />
          </Button>
          <Button
            variant="outlined"
            disabled={isLoading || questionIndex === questionCount - 1}
            onClick={(ev) => handleClick(ev, onNextQuestion)}
            suffixIcon={<ActionArrowRightIcon />}
            sx={styles.nextButton}
          >
            {t('button.Skip')}
          </Button>
        </>
      )}

      <SubmitButton
        data-toast-anchor
        data-testid="quiz-submit-button"
        disabled={!hasAnswer || isPaused || !formatSpecificSubmittable}
        loading={isLoading}
        isPendingSubmit={isPendingSubmit}
        lazySubmit={onLazySubmitQuestion}
        cancelSubmit={onCancelSubmitQuestion}
        submitAnswer={onSubmitQuestion}
      >
        {t('button.Submit')}
      </SubmitButton>
    </Box>
  );
}
