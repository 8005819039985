import { useMemo } from 'react';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import useMemberTrialQuota from '@app/web/src/hooks/utils/useMemberTrialQuota';

import useBlocker from './useBlocker';

export const usePaidFeatureBlocker = () => {
  const { isFreeUser, isLoading } = useMemberSubscription();
  const { isRunOutOfQuestionQuota } = useMemberTrialQuota();

  const { currentPlacement, currentBlockerType, openBlocker, closeBlocker } =
    useBlocker();

  const isFeatureBlocked = useMemo(
    () => ({
      selectIncognitoMode: isFreeUser,
      selectSuperLevelDifficulty: isRunOutOfQuestionQuota,
      viewSolution: isRunOutOfQuestionQuota,
    }),
    [isFreeUser, isRunOutOfQuestionQuota]
  );

  return {
    isLoading,
    isFeatureBlocked,
    openBlocker,
    closeBlocker,
    currentBlockerType,
    currentPlacement,
  };
};
