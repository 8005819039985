import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '@app/web/src/hooks/utils/useUsers';
import { useDateFormat } from '@front/helper';
import { IaRichText } from '@lib/ia/src/core/types';
import {
  FeedLayoutItem,
  FeedLayoutMessageAvatar,
} from '@lib/ia/src/layouts/FeedLayout/types';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { InfiniteHookResponse } from '@lib/web/apis';
import { format, isSameDay } from 'date-fns';

import { getClickAction } from '../../utils/clickActions';
import {
  getMessageActionAreas,
  getMessageActions,
  getMessageActionSx,
  getSenderInfo,
  transNewStyleNotificationToRichText,
  transToRichText,
} from '../../utils/dataFormat';

const skeleton = ({ count }: { count: number }): FeedLayoutItem[] => {
  return Array.from({ length: count }, () => {
    return { type: 'skeleton' };
  });
};

export default function useNotificationLayoutConfig({
  notificationData,
  joinedClubSlugs,
  skeletonCount,
}: {
  notificationData: InfiniteHookResponse<GetNotificationRes>;
  joinedClubSlugs: string[];
  skeletonCount: number;
}) {
  const { t } = useTranslation('notification');
  const { getUser } = useUsers();
  const { displayDateDivider } = useDateFormat();

  const getMessageAvatar = useCallback(
    (item: GetNotificationRes): FeedLayoutMessageAvatar => {
      const senderInfo = getSenderInfo(item);
      if ('avatarIcon' in senderInfo) {
        return {
          type: 'icon',
          icon: senderInfo.avatarIcon,
          bgColor: senderInfo.avatarBgcolor,
        };
      }
      const { type } = senderInfo;
      if (type === 'user') {
        return {
          type: 'user',
          name: senderInfo.name,
          avatarUrl: senderInfo.avatar,
          userId: senderInfo.userId || '',
          className: 'floating-avatar-anchor',
          hoverAction: {
            value: 'showFloatingProfile',
          },
        };
      }

      if (type === 'club') {
        return {
          type: 'club',
          name: senderInfo.name,
          clubIcon: senderInfo.avatar,
        };
      }

      return {
        type: 'default',
        name: 'Aha',
        avatarUrl: '/logo.png',
      };
    },
    []
  );

  const getMessageContent = useCallback(
    (item: GetNotificationRes): IaRichText => {
      const senderInfo = getSenderInfo(item);
      const content =
        transNewStyleNotificationToRichText(item, t) ||
        transToRichText(item.body || item.title, { joinedClubSlugs }, getUser);
      if (typeof content === 'string') return content;

      const contents = Array.isArray(content) ? content : [content];
      if ('showAvatarOnly' in senderInfo && senderInfo.showAvatarOnly) {
        return contents;
      }
      if ('type' in senderInfo && senderInfo.type === 'user') {
        contents.unshift({
          type: 'userText',
          name: senderInfo.name,
          indicators: senderInfo.indicators,
          id: senderInfo.userId || '',
          className: 'floating-avatar-anchor',
          hoverAction: {
            value: 'showFloatingProfile',
          },
        });
      } else {
        contents.unshift({
          type: 'text',
          value: senderInfo.name,
          sx: { fontWeight: 700 },
        });
      }
      return contents;
    },
    [getUser, joinedClubSlugs, t]
  );

  const feedItemsMap = useCallback(
    (items: GetNotificationRes[]): FeedLayoutItem[] => {
      const results: FeedLayoutItem[] = [];
      let currentDate: Date | undefined = undefined;
      items.forEach((item, index) => {
        const itemDate = new Date(item.scheduledAt);
        if (!currentDate || !isSameDay(itemDate, currentDate)) {
          results.push({
            type: 'divider',
            text: displayDateDivider(itemDate),
            isNew: !item.isRead && index !== 0,
          });
        }
        results.push({
          type: 'message',
          isNew: !item.isRead,
          showNewIndicator: !item.isRead && index === 0,
          avatar: getMessageAvatar(item),
          sentTime: format(new Date(item.scheduledAt), 'HH:mm'),
          content: getMessageContent(item),
          clickAction: getClickAction(item),
          cta: {
            items: getMessageActions(item, t),
            gridTemplateAreas: getMessageActionAreas(item),
            sx: getMessageActionSx(item),
          },
          customContent: {
            value: item.tag,
            metadata: {
              body: item.data?.payload?.body,
            },
          },
        });
        currentDate = itemDate;
      });
      return results;
    },
    [displayDateDivider, getMessageAvatar, getMessageContent, t]
  );

  const config: IaLayoutConfig[] = useMemo(() => {
    if (notificationData.isLoadingInitialData) {
      return [
        {
          layout: 'feed-layout',
          items: skeleton({ count: skeletonCount }),
        },
      ];
    }
    if (notificationData.isEmpty) return [];
    const items = feedItemsMap(notificationData.dataset);

    const loadingItems = notificationData.isLoadingMore
      ? skeleton({ count: 2 })
      : [];
    return [
      {
        layout: 'feed-layout',
        items: [...items, ...loadingItems],
      },
    ];
  }, [feedItemsMap, notificationData, skeletonCount]);

  return config;
}
