import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Skeleton, Theme, useMediaQuery } from '@mui/material';
import { SquareAvatar, useBaseLayout } from '@front/ui';
import { useAuth } from '@lib/web/apis';

import useCurrentMenu from '../hooks/useCurrentMenu';
import useMainLayout from '../hooks/useMainLayout';

import NavButton from './NavButtons/NavButton';

const styles = {
  root: {
    height: 'auto',
    py: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
};

export default function NavActions() {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { member } = useAuth();
  const { mainNavOpened } = useBaseLayout();
  const { seeMenu, seeProfileMenu, clearMenu } = useMainLayout();
  const { currentMenu } = useCurrentMenu();

  const handleProfileClick = () => {
    if (mdUp) {
      void Router.push('/profile/account');
    }
    clearMenu();
    seeMenu('profile');
    // reset the profile menu to default
    seeProfileMenu(null);
  };

  return (
    <Box sx={styles.root}>
      {member ? (
        <NavButton
          onClick={handleProfileClick}
          tooltip={t('menu.Profile')}
          active={mainNavOpened && currentMenu === 'profile'}
          hasNotification={member.emailVerified === false}
        >
          <SquareAvatar size={40} src={member.avatarUrl || member.photoUrl}>
            {member.displayName || member.distinctName}
          </SquareAvatar>
        </NavButton>
      ) : (
        <SquareAvatar size={32}>
          <Skeleton width={32} height={32} variant="rectangular" />
        </SquareAvatar>
      )}
    </Box>
  );
}
