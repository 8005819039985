import React, { KeyboardEvent, MouseEvent, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { TextButton } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';

import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import { TableLayoutRow, TableLayoutTextButtonCell } from '../../types';

export type IaButtonTextCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutTextButtonCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    mx: 'auto',
    minHeight: 24,
    px: '4px',
    py: 0,
    gap: 0.5,
    fontSize: 12,
  },
};
export default function IaTextButtonCell({
  row,
  cell,
  columnKey,
}: IaButtonTextCellProps) {
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<TableLayoutRow>();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const iaAction = getIaAction<TableLayoutRow>(cell.value);

  const loading =
    typeof iaAction?.inProgress === 'function'
      ? iaAction?.inProgress(row)
      : iaAction?.inProgress;

  const { disabled = false } = getItemStatus ? getItemStatus(row) : {};

  const handleClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    iaAction?.action(row);
  };

  const { getCellSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    if (selectedState === 'focused') {
      buttonRef.current?.focus({
        preventScroll: true,
      });
    } else {
      buttonRef.current?.blur();
    }
  }, [selectedState]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    buttonRef.current?.click();
  };

  return (
    <Box sx={styles.root}>
      <TextButton
        sx={styles.button}
        prefixIcon={cell.icon ? <Icon name={cell.icon} /> : undefined}
        className={cell.className}
        onClick={handleClick}
        disabled={disabled || cell.disabled}
        loading={loading}
        size="sm"
        ref={buttonRef}
        onKeyDown={handleKeyDown}
      >
        {disabled ? cell.disabledText || cell.text : cell.text}
      </TextButton>
    </Box>
  );
}
