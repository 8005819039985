import { useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import useClubMenuVariant from './useClubMenuVariant';
import useMainLayout from './useMainLayout';
import useMenuVariant from './useMenuVariant';

export default function useNavMenuVisibility() {
  const menuVariant = useMenuVariant();
  const clubMenuVariant = useClubMenuVariant();
  const { menuTarget, temporaryMenuTarget, clubMenuTarget } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const menuVisible = useMemo(() => {
    const currentMenu = mdUp ? menuVariant : menuTarget || menuVariant;
    const currentClubMenu = mdUp
      ? clubMenuVariant
      : clubMenuTarget || clubMenuVariant;

    if (temporaryMenuTarget) {
      return true;
    }

    if (currentMenu === 'profile') {
      return true;
    }
    if (currentMenu === 'club') {
      // practice and rankings nav there is no LHS menu
      return (
        currentClubMenu &&
        currentClubMenu !== 'practice' &&
        currentClubMenu !== 'rankings' &&
        currentClubMenu !== 'summary'
      );
    }
    if (
      currentMenu === 'directMessages' ||
      currentMenu === 'discover' ||
      currentMenu === 'agents'
    ) {
      return true;
    }
    return false;
  }, [
    mdUp,
    menuVariant,
    menuTarget,
    clubMenuVariant,
    clubMenuTarget,
    temporaryMenuTarget,
  ]);

  return {
    menuVisible,
  };
}
