import { useController, useFormContext } from 'react-hook-form';
import { Box, ListSubheader, MenuItem, Typography } from '@mui/material';
import { Select, SquareAvatar } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useInputEvent from '../hooks/useInputEvent';
import useInputGuard from '../hooks/useInputGuard';
import useInputStateIndicator from '../hooks/useInputStateIndicator';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutItemStatus, FormLayoutSelectItem } from '../types';
import { getCustomValidate, getLabelIcon } from '../utils';

type SelectInputProps = {
  item: FormLayoutSelectItem;
};

type Option = FormLayoutSelectItem['options'][number];

const styles = {
  hint: {
    opacity: 0.5,
  },
  menuItem: {
    py: { xs: '8.5px', md: '3px' },
    display: 'flex',
    gap: 1,
  },
  iconContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    height: { xs: 24, md: 21 },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDisabled: {
    opacity: 0.5,
  },
};

function OptionIcon({
  icon: { type, value },
  disabled = false,
}: {
  icon: {
    type: 'icon' | 'image';
    value: string;
  };
  disabled?: boolean;
}) {
  return (
    <Box sx={[styles.icon, disabled && styles.iconDisabled]}>
      {type === 'icon' && <Icon name={value} width={16} height={16} />}
      {type === 'image' && <SquareAvatar src={value} size={16} />}
    </Box>
  );
}

export default function SelectInput({ item }: SelectInputProps) {
  const { control, getValues } = useFormContext();
  const stateIndicator = useInputStateIndicator({
    name: item.name,
    stateIndicator: item.stateIndicator,
  });
  const inputGuardEvents = useInputGuard(item.guard);

  const labelIcon = getLabelIcon(item.type, item.icon);

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues })
    : undefined;

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: string) => {
    if (!newValue) {
      return;
    }
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const renderDisplay = (v: string | number) => {
    return (
      item.options.find((option) => 'value' in option && option.value === v)
        ?.label || ''
    );
  };

  const currentOption = item.options.find(
    (option) => 'value' in option && option.value === value
  );
  const disabled = item.status === FormLayoutItemStatus.Disabled;

  const { getIaAction } = useIaAction();
  const handleOptionClick = (option: Option): void => {
    if ('type' in option || !option.optionClickAction) {
      return;
    }
    getIaAction<Option>(option.optionClickAction)?.action(option);
  };

  return (
    <Select
      className={`ia-form-layout-select ${item.className || ''}`}
      label={item.label}
      placeholder={item.placeholder}
      labelIcon={labelIcon}
      value={value ?? item.defaultValue}
      onChange={(ev) => handleChange(ev.target.value)}
      onBlur={handleBlur}
      size={item.textFieldSize}
      disabled={disabled || item.disabled}
      renderDisplay={renderDisplay}
      prefix={
        !!currentOption && 'icon' in currentOption && currentOption.icon ? (
          <OptionIcon icon={currentOption.icon} disabled={disabled} />
        ) : undefined
      }
      success={successState.success}
      error={!!error}
      helperText={error?.message || item.helperText}
      stateIndicator={stateIndicator}
    >
      {item.options.map((option) => {
        if ('type' in option) {
          return (
            <ListSubheader key={option.label}>{option.label}</ListSubheader>
          );
        }
        return (
          <MenuItem
            sx={styles.menuItem}
            key={option.value}
            value={option.value}
            onClick={() => handleOptionClick(option)}
            {...inputGuardEvents}
          >
            {!!option.icon && (
              <Box sx={styles.iconContainer}>
                <OptionIcon icon={option.icon} disabled={disabled} />
              </Box>
            )}

            <Box>
              <Box>{option.label}</Box>
              {!!option.hint && (
                <Typography sx={styles.hint} variant="caption">
                  {option.hint}
                </Typography>
              )}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
}
