import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { FollowButtonProps } from '@app/web/src/components/FriendButtons/FollowButton';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import DisplayTableFollowButton, {
  DisplayTableFollowButtonProps,
} from '@app/web/src/widgets/NotificationPage/CtaActionButtons/components/DisplayTableFollowButton';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { getLeagueBadgeIconName } from '@lib/web/utils';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
  },
};

export type ClubWelcomeRecommendListProps = {
  cta: Extract<
    NotificationCtaType,
    { type: 'cta.club.welcome.recommend.list' }
  >;
  clubSlug?: string;
};

export default function ClubWelcomeRecommendList({
  cta,
  clubSlug,
}: ClubWelcomeRecommendListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 125px 114px 32px';
    const columnOrder = ['name', 'dreamSchool', 'league', 'action'];
    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'MainProfileSolid',
                label: t('club.welcome.recommend.table.name', 'Name'),
              },
              {
                type: 'default',
                icon: 'ProfileDreamCollege',
                label: t(
                  'club.welcome.recommend.table.dreamSchool',
                  'Dream School'
                ),
              },
              {
                type: 'default',
                icon: 'TestTrophy',
                label: t('club.welcome.recommend.table.league', 'League'),
              },
              {
                type: 'default',
              },
            ],
          },
          rows: cta.users
            .filter((_, index) => (collapse ? index === 0 : true)) // 1 if collapsed => show 1st row
            .map((user) => {
              return {
                id: user.userId,
                cells: {
                  name: {
                    type: 'avatarText',
                    userId: user.userId,
                  },
                  dreamSchool: {
                    type: 'avatarText',
                    avatar: '',
                    text: user.dreamSchoolName,
                  },
                  league: {
                    type: 'iconText',
                    icon: {
                      type: 'icon',
                      value: getLeagueBadgeIconName(user.leagueName),
                    },
                    text: t('club::profile.league.rank', {
                      count: user.leagueRank,
                      ordinal: true,
                      name: user.leagueName,
                    }),
                  },
                  action: {
                    type: 'custom',
                    renderKey: 'challengeButton',
                    metadata: {
                      userId: user.userId,
                      clubSlug,
                    } as DisplayTableFollowButtonProps,
                  },
                },
                clickAction: {
                  type: 'event',
                  value: 'showProfile',
                },
                metadata: {
                  userId: user.userId,
                },
              };
            }),
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'textButton',
                action: 'toggleCollapse',
                text: collapse
                  ? t('club.welcome.recommend.table.seeMore', 'See more')
                  : t('club.welcome.recommend.table.hide', 'Hide'),
                suffixIcon: collapse
                  ? 'ActionChevronRightSmall'
                  : 'ActionChevronUp',
                sx: { ml: 0 },
                containerSx: { pt: 1 },
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [collapse, cta.users, t, clubSlug]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
      showProfile: {
        action: ({ metadata }: { metadata: { userId: string } }) => {
          openRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId: metadata.userId,
          });
        },
      },
    }),
    [openRightPanel]
  );

  const renders = useMemo(() => {
    return {
      challengeButton: (ev: FollowButtonProps) => (
        <DisplayTableFollowButton {...ev} />
      ),
    };
  }, []);

  return (
    <Box sx={styles.root}>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={config} />
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </Box>
  );
}
