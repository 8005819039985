import { useContext } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';

import { MenuVariant } from '../context';

const getMenuVariant = (
  pathname: string,
  isLogged?: boolean
): MenuVariant | null => {
  if (pathname === '/extend-trial') return null;
  if (pathname.includes('/profile')) return 'profile';
  if (isLogged && pathname.includes('/marketplace')) return 'profile';
  if (!isLogged && pathname.includes('/marketplace')) return 'marketplace';
  if (pathname.includes('/direct-messages')) return 'directMessages';
  if (pathname.includes('/agents')) return 'agents';
  if (pathname.includes('/workflow')) return null;
  if (
    /\/club\/.*\//.test(pathname) ||
    pathname.includes('[slug]') ||
    pathname.includes('[clubSlug]')
  )
    return 'club';

  return 'discover';
};

export default function useMenuVariant() {
  const { pathname } = useRouter();
  const { isLogged } = useAuth();
  const { sharePath } = useContext(SharedUserContext);
  const clearPathname = pathname?.replace(sharePath, '');
  if (!clearPathname) return null;

  return getMenuVariant(clearPathname, isLogged);
}
