import { useContext, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import {
  alpha,
  Box,
  Fade,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import { StateDataType } from '@app/web/src/types/state';
import { decodeData } from '@app/web/src/utils/backend';
import { GuestContext } from '@front/helper';
import { ActionArrowRight as ActionArrowRightIcon } from '@front/icon';
import { Button, TextButton, toast } from '@front/ui';
import {
  apis,
  PinChatViewSlug,
  setClientToken,
  useAuth,
  useIaPinChats,
} from '@lib/web/apis';
import { useClearRoute } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

const images = [
  '/static/onboarding/1.svg',
  '/static/onboarding/2.svg',
  '/static/onboarding/3.svg',
  '/static/onboarding/1-mobile.svg',
  '/static/onboarding/2-mobile.svg',
  '/static/onboarding/3-mobile.svg',
];
const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
    backdropFilter: 'blur(25px)',
    zIndex: 5000,
    overflow: 'hidden',
  },
  item: (theme: Theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pt: '40px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      pt: 0,
      justifyContent: 'center',
    },
  }),
  image: {
    position: 'relative',
    height: {
      xs: 325,
      md: 400,
    },
  },
  title: (theme: Theme) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    maxWidth: 496,
    display: 'block',
    b: {
      background: theme.palette.gradient.primary,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
      span: {
        display: 'block',
      },
    },
  }),
  content: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    height: 145,
    pt: '30px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      pt: 0,
    },
  }),
  bottom: (theme: Theme) => ({
    mt: 'auto',
    [theme.breakpoints.up('md')]: {
      mt: 'unset',
    },
  }),
  dots: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
    pt: '24px',
    pb: '12px',
    [theme.breakpoints.up('md')]: {
      mt: 'unset',
      py: '24px',
    },
  }),
  dot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: 'text.primary',
    cursor: 'pointer',
    opacity: 0.5,
  },
  dotActive: {
    width: 8,
    height: 8,
    opacity: 1,
  },
  actions: (theme: Theme) => ({
    height: 124,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 343 + 32, // including padding
    mx: 'auto',
    px: 2,
    gap: 2,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
      height: 'auto',
      alignItems: 'center',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      width: 'fit-content',
      'button:first-of-type': {
        order: 2,
      },
    },
  }),
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 12,
  },
};

const Dot = ({
  active,
  onClick,
}: {
  active: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box sx={[styles.dot, active && styles.dotActive]} onClick={onClick} />
  );
};

const contentKeys = [
  {
    title: {
      key: 'onboarding.blocker.items.1.title',
      defaults:
        '<1>Join</1> a club to practice <br/>unlimitedAI-customized questions',
    },
    cta: {
      key: 'onboarding.blocker.items.1.cta',
      defaults: 'Let’s Go',
    },
  },
  {
    title: {
      key: 'onboarding.blocker.items.2.title',
      defaults: '<1>Challenge</1> friends <br/>to compare rankings',
    },
    cta: {
      key: 'onboarding.blocker.items.2.cta',
      defaults: 'Almost there',
    },
  },
  {
    title: {
      key: 'onboarding.blocker.items.3.title',
      defaults: '<1>Practice</1> from our <br/>extensive question bank',
    },
    cta: {
      key: 'onboarding.blocker.items.3.cta',
      defaults: 'Try a Practice',
    },
  },
];
function OnboardingItem({
  index,
  currentIndex,
  loadingType,
  goNext,
  goBack,
  onClose,
}: {
  index: number;
  currentIndex: number;
  loadingType?: 'done' | 'close' | null;
  goNext: () => void;
  goBack: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const titleKey = contentKeys[index].title.key;
  const image = mdUp ? (
    <Image src={images[index]} alt={t(titleKey)} fill />
  ) : (
    <Image src={images[index + 3]} alt={t(titleKey)} fill />
  );

  const handleDotClick = (dotIndex: number) => {
    if (currentIndex === dotIndex) return;

    if (dotIndex < currentIndex) {
      goBack();
    } else {
      goNext();
    }
  };

  return (
    <Fade in={currentIndex === index}>
      <Box sx={styles.item}>
        {!mdUp && (
          <TextButton sx={styles.closeButton} onClick={onClose}>
            {t('onboarding.blocker.buttons.close')}
          </TextButton>
        )}
        <Box sx={styles.image}>{image}</Box>
        <Box sx={styles.content}>
          <Typography sx={styles.title} variant="appH1" component="h1">
            <Trans
              i18nKey={contentKeys[index].title.key}
              components={{
                1: <b />,
                2: <span />,
                br: <br />,
              }}
              defaults={contentKeys[index].title.defaults}
            />
          </Typography>
        </Box>

        <Box sx={styles.bottom}>
          <Box sx={styles.dots}>
            <Dot
              active={currentIndex === 0}
              onClick={() => handleDotClick(0)}
            />
            <Dot
              active={currentIndex === 1}
              onClick={() => handleDotClick(1)}
            />
            <Dot
              active={currentIndex === 2}
              onClick={() => handleDotClick(2)}
            />
          </Box>
          <Box sx={styles.actions}>
            <Button
              suffixIcon={<ActionArrowRightIcon width={16} height={16} />}
              onClick={goNext}
              disabled={!!loadingType}
              loading={loadingType === 'done'}
            >
              {t(contentKeys[index].cta.key, contentKeys[index].cta.defaults)}
            </Button>
            {(index !== 0 || mdUp) && (
              <TextButton
                onClick={index === 0 ? onClose : goBack}
                disabled={!!loadingType}
                loading={loadingType === 'close'}
              >
                {index === 0
                  ? t('onboarding.blocker.buttons.close')
                  : t('onboarding.blocker.buttons.back')}
              </TextButton>
            )}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}

export default function OnboardingBlocker() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingType, setLoadingType] = useState<'done' | 'close' | null>(null);
  const router = useRouter();
  const state = router.query.state as string;
  const stateData = decodeData<StateDataType>(state);
  const { mutate } = useIaPinChats({
    viewSlug: PinChatViewSlug.PinChatOverlayDefault,
    limit: 0,
  });

  const { isLoggedOut } = useAuth();
  const isUnclaimFlow = stateData && isLoggedOut;
  const { login } = useContext(GuestContext);
  const { i18n } = useTranslation();
  const { loginUrl } = useAuthLinks({
    loginQueries: isUnclaimFlow
      ? [`email=${encodeURIComponent(stateData.receiverEmail)}`]
      : undefined,
  });

  const { clearRouteParams } = useClearRoute();

  const handleDone = async (actionType: 'done' | 'close') => {
    // Unclaim account => create a guest account => redirect to the playlist
    // Login user => redirect to the playlist
    setLoadingType(actionType);

    const params = {
      state,
      target: router.query.target as string,
      language: i18n.language,
    };

    const [res, err] = await call(apis.auth.onboarding(params));

    if (res) {
      if (res.data.memberLogin) {
        login(res.data.memberLogin);
        setClientToken(res.data.memberLogin.accessToken);
      }
      mutate();
      router.push(res.data.redirectUrl);
    } else if (err?.code === 409 && isUnclaimFlow) {
      // User already has an account
      router.push(loginUrl);
    } else {
      if (err?.message) toast.error(err.message);
      setLoadingType(null);
      clearRouteParams(['blocker']);
    }
  };

  return (
    <Box sx={styles.root}>
      <OnboardingItem
        index={0}
        currentIndex={currentIndex}
        onClose={() => handleDone('close')}
        goBack={() => handleDone('close')}
        goNext={() => setCurrentIndex(currentIndex + 1)}
        loadingType={loadingType}
      />
      <OnboardingItem
        index={1}
        currentIndex={currentIndex}
        onClose={() => handleDone('close')}
        goBack={() => setCurrentIndex(currentIndex - 1)}
        goNext={() => setCurrentIndex(currentIndex + 1)}
        loadingType={loadingType}
      />
      <OnboardingItem
        index={2}
        currentIndex={currentIndex}
        onClose={() => handleDone('close')}
        goBack={() => setCurrentIndex(currentIndex - 1)}
        goNext={() => handleDone('done')}
        loadingType={loadingType}
      />
    </Box>
  );
}
