import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box } from '@mui/material';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import { Button } from '@front/ui';
import { ChallengeStatus, ExamMode, useQuizResultSummary } from '@lib/web/apis';

import RedoButton from '../../PlaylistDetailPage/RedoButton';

const styles = {
  alignRight: {
    ml: 'auto',
  },
};

type StartChallengeButtonProps = {
  clubSlug: string;
  cta: Extract<NotificationCtaType, { type: 'cta.start.challenge' }>;
};

export default function StartChallengeButton({
  clubSlug,
  cta,
}: StartChallengeButtonProps) {
  const { t } = useTranslation('notification');
  const { data } = useQuizResultSummary({ quizId: cta.quizId });

  if (!data) {
    return (
      <Button prefixIcon={<OtherPlayIcon />} sx={styles.alignRight} loading />
    );
  }

  const { challenge } = data.data;
  if (!challenge || challenge.status === ChallengeStatus.Expired) return null;

  const startQuiz = (e: MouseEvent) => {
    e.stopPropagation();
    if (!data) return;
    const { mode } = data.data.quiz;
    const targetUrl = mode === ExamMode.MockExam ? 'exam' : 'practice';
    const id = cta.quizShortId || cta.quizId;
    const path = `/club/${clubSlug}/${targetUrl}/${id}/1#1`;
    return Router.push(path);
  };

  if (challenge.status === ChallengeStatus.Ongoing && !challenge.isTaken) {
    return (
      <Button
        prefixIcon={<OtherPlayIcon />}
        onClick={startQuiz}
        onMouseDown={(e) => e.stopPropagation()}
        sx={styles.alignRight}
      >
        {t(
          'cta.user.challenge.status.button.Start Challenge',
          'Start Challenge'
        )}
      </Button>
    );
  }

  return (
    <Box sx={styles.alignRight}>
      <RedoButton
        quizId={cta.quizId}
        roundNo={data.data.quiz.latestRoundNo}
        clubSlug={clubSlug}
        panelKeyPrefix={cta.quizShortId || cta.quizId}
      />
    </Box>
  );
}
