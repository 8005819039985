import React, { MouseEvent, useState } from 'react';
import Router from 'next/router';
import useIaClubCtaAction from '@app/web/src/ia/club/useIaClubCTAAction';
import { Button, Icon } from '@front/ui';
import { ClubJoinedStatus } from '@lib/web/apis';

const styles = {
  button: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    width: '100%',
  },
};

function getIcon(joinStatus: ClubJoinedStatus) {
  if (joinStatus === ClubJoinedStatus.Joined) return 'ProfileLogin';
  if (joinStatus === ClubJoinedStatus.RequestedPending) return undefined;
  return 'TestAdd';
}

function getVariant(joinStatus: ClubJoinedStatus) {
  if (
    joinStatus === ClubJoinedStatus.Joined ||
    joinStatus === ClubJoinedStatus.RequestedPending
  ) {
    return 'outlined';
  }
  return 'filled';
}

export type DisplayTableJoinClubButtonProps = {
  clubSlug: string;
};

export default function DisplayTableJoinClubButton({
  clubSlug,
}: DisplayTableJoinClubButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { club, clubCta, clubCtaAction } = useIaClubCtaAction(clubSlug);

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    if (!clubCta || !club) {
      setLoading(false);
      return;
    }

    if (clubCta.type === 'link' || !clubCtaAction) {
      void Router.push(clubCta.value);
    } else {
      await clubCtaAction.action({
        id: club.clubSlug,
        title: club.clubName,
      });
    }
    setLoading(false);
  };

  if (!clubCta || !club) {
    return <Button loading={true} sx={styles.button} />;
  }

  const customIcon = getIcon(club.joinStatus);
  const variant = getVariant(club.joinStatus);
  return (
    <Button
      onClick={handleClick}
      loading={loading}
      prefixIcon={customIcon ? <Icon name={customIcon} /> : undefined}
      sx={styles.button}
      variant={variant}
    >
      {clubCta.text}
    </Button>
  );
}
