import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { LeagueTier } from '@lib/web/apis';
import { Emoji } from '@lib/web/ui';
import { getLeagueName } from '@lib/web/utils';

import useLeagueRankingList from '../hooks/useLeagueRankingList';
import useNextLeagueHint from '../hooks/useNextLeagueHint';

import LeagueBadges from './LeagueBadges';
import LeagueRankingList from './LeagueRankingList';
import RankingListHeader from './RankingListHeader';
import { RankingsContext } from './RankingsContextProvider';
import TabArea from './RankingTabArea';

const styles = {
  leagueBadges: {
    px: {
      xs: '20px',
      md: '6px',
    },
    mt: 1,
  },
  currentLeague: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    px: {
      xs: '20px',
      md: '12px',
    },
    mt: 1,
    mb: 1,
  },
  description: {
    display: 'flex',
    gap: 1,
    rowGap: 0.5,
    flexWrap: 'wrap',
  },
  currentLeagueSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    px: {
      xs: '20px',
      md: '12px',
    },
  },

  empty: {
    px: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      width: { xs: 88, md: 110 },
      height: { xs: 160, md: 200 },
    },
  },
  emptyContent: {
    opacity: 0.5,
  },
  currentLeagueContent: {
    typography: 'body1',
    color: 'alpha.lightA75',

    span: {
      typography: 'subtitle2',
      opacity: 'unset',
      color: 'text.primary',
      fontWeight: 700,
    },
  },
};

function CurrentLeagueSkeleton() {
  return (
    <Box sx={styles.currentLeagueSkeleton}>
      <Skeleton width={170} height={31} />
      <Skeleton width="100%" height={24} />
      <Skeleton width={50} height={24} />
    </Box>
  );
}

type CurrentLeagueProps = {
  isEmpty: boolean;
  isLoading: boolean;
  currentTier?: LeagueTier;
};

function CurrentLeague({
  isEmpty,
  isLoading,
  currentTier,
}: CurrentLeagueProps) {
  const { t } = useTranslation('club');
  const nextLeagueHint = useNextLeagueHint();

  if (isLoading) return <CurrentLeagueSkeleton />;

  return (
    <Box sx={styles.currentLeague}>
      <Typography variant="appH2" fontWeight={700} fontSize={24}>
        {t('## League', {
          name: getLeagueName(currentTier || LeagueTier.Alpha),
        })}
      </Typography>
      {!isEmpty && (
        <Box sx={styles.description}>
          <Typography variant="body1" sx={styles.currentLeagueContent}>
            {nextLeagueHint}
          </Typography>
        </Box>
      )}
      {isEmpty && (
        <Typography variant="body1" sx={styles.currentLeagueContent}>
          {t('Compete against students in a weekly leaderboard')}
        </Typography>
      )}
    </Box>
  );
}

function EmptyState() {
  const { t } = useTranslation('club');
  return (
    <Box sx={styles.empty}>
      <Emoji variant="alien" />
      <Typography sx={styles.emptyContent} variant="body1">
        {t('Complete a practice to join this week league')}
      </Typography>
    </Box>
  );
}

export default function LeagueRankingTabContent() {
  const userId = useCurrentUserId();
  const { data, error } = useLeagueRankingList();
  const { scrollRef: outerScrollRef } = useContext(RankingsContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const isEmpty = (data?.data && data.data.length === 0) || !!error;
  const currentTier = data?.data.find(
    (item) => item.userId === userId
  )?.leagueTier;
  const loaded = !!data || !!error;

  const ctaCol = mdUp ? '16px 101px' : '8px 32px';
  const headerGridTemplateColumns = `1fr 8px 32px 16px 52px 16px 34px ${ctaCol}`;

  return (
    <TabArea scrollable={!outerScrollRef}>
      <TabArea.Header>
        <Box sx={styles.leagueBadges}>
          <LeagueBadges
            currentTier={loaded ? currentTier || LeagueTier.Alpha : undefined}
          />
        </Box>
        <CurrentLeague
          isEmpty={isEmpty}
          isLoading={!loaded}
          currentTier={loaded ? currentTier || LeagueTier.Alpha : undefined}
        />
      </TabArea.Header>

      <TabArea.EmptyState visible={isEmpty}>
        <EmptyState />
      </TabArea.EmptyState>

      <TabArea.List visible={!isEmpty && !!data}>
        <RankingListHeader gridTemplateColumns={headerGridTemplateColumns} />
        <LeagueRankingList items={data?.data || []} />
      </TabArea.List>
    </TabArea>
  );
}
