export const appConfig = {
  facebookId: process.env.FACEBOOK_ID || '900774237263184',
  landingPageUrl: process.env.LANDING_PAGE_URL || 'https://earnaha.com',
  getCodeInterval: 60,
  resendVerifyEmailInterval: 45,
  trialDays: 5,
  maxQuestionPickCount: 280,
  ahaUserId: '00000000-0000-0000-0000-000000000000',
  ahaAiId: '10f81574-c6bf-4eed-9311-0a682b5ef188',
  ratingScale: 5,
  resendInvitationIntervalDays: 3,
  maxThreadMentionItemsDisplay: 15,
  maxRecentMarksDisplay: 15,
  maxChallengersCount: 30,
  minPracticeCountToShowAnalytics: 10,
  maxComparePathUser: 4,
  maxResultEarnedAwards: 8,
  aiApproximatedTokensPerResponse: 50,
  ahaDefaultAiUserNames: ['Aha AI', 'Aha Grading', 'Aha Rubric'],
  referRewardToken: 0.5,
  tokenToUSD: 1,
};
