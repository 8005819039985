import {
  ChangeEvent,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Link from 'next/link';
import Router from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  Button,
  Checkbox,
  EmphasizeButton,
  IconButton,
  IndicatorGroup,
  InfoTooltip,
  LoadingIcon,
  MaskIcon,
  ResponsiveMenu,
  ResponsiveTooltip,
  SimpleTooltip,
  SquareAvatar,
  StatusTag,
  Tag,
  TextButton,
  TipButton,
  useTruncated,
} from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import IaDataContext from '@lib/ia/src/core/IaData/IaDataContext';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';
import { IaAction } from '@lib/ia/src/core/types';
import { isNil } from 'lodash';

import {
  IconListLayoutItemActionMap,
  IconListLayoutItemHoverEvent,
  IconListLayoutItemLabel,
  IconListLayoutItemObj,
} from '../types';

type IconListItemProps = {
  item: IconListLayoutItemObj;
};

const styles = {
  root: {
    width: '100%',
    py: 1,
    px: { xs: 2.5, md: '12px' },
    minHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 1,
    textAlign: 'left',
    position: 'relative',
  },
  borderLeft: {
    width: 4,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
  hoverable: {
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  hoverShowButtons: {
    '& .ia-icon-list-layout_item-multiple-actions': {
      opacity: 0,
      visibility: 'hidden',
    },
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
        '& .ia-icon-list-layout_item-multiple-actions': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
  },
  selected: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  left: {
    position: 'relative',
  },
  oneLineTitle: {
    alignSelf: 'center',
  },
  defaultTitle: {
    pt: '5px',
  },
  titleClickable: {
    span: {
      cursor: 'pointer',
      textUnderlinePosition: 'under',
      textDecorationThickness: 2,
    },
    '@media (hover:hover)': {
      '&:hover span': {
        textDecoration: 'underline',
      },
    },
  },
  badge: {
    mt: '-12px',
    ml: '12px',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'text.primary',
    color: 'background.darker',
    typography: 'caption',
    fontSize: 13,
  },

  main: {
    flex: 1,
    alignSelf: 'center',
    minWidth: 0,
  },
  display: {
    display: 'flex',
  },
  noWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  titleSuffix: {
    typography: 'body1',
    ml: 1,
    px: 1,
    py: 0,
    lineHeight: '24px',
    borderRadius: 1,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    fontWeight: 400,
  },
  titleSuffixIcon: {
    px: 1,
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    typography: 'body1',
    px: 1,
    ml: 1,
    py: 0,
    minHeight: 24,
    lineHeight: '24px',
    minWidth: 'fit-content',
    display: 'flex',
    border: 'none',
  },
  description: {
    opacity: 0.64,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '-25px !important',
      whiteSpace: 'normal',
    },
    maxWidth: '200px',
  },
  action: {
    alignSelf: 'center',
  },
  properties: {
    display: 'flex',
    gap: 1,
  },
  checkbox: {
    p: '6px',
    mr: '-6px',
  },
  actions: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    alignSelf: 'center',
  },
  subMenu: {
    '& .MuiPaper-root': {
      maxWidth: 265,
    },
  },
  subMenuItem: {
    '& .MuiListItemText-root': {
      py: '3.5px',
      display: 'grid',
      gap: 0.5,
    },
    '& .MuiListItemText-secondary': {
      typography: 'caption',
      whiteSpace: 'initial',
    },
  },
  subMenuActiveItem: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  buttonTextLoading: {
    background: 'transparent',
  },
  suffix: {
    alignSelf: 'center',
  },
  suffixText: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  buttonSm: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
  avatarIcon: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function AvatarArea({
  item,
  avatarBaseSize = 32,
}: Omit<IconListItemProps, 'index'> & { avatarBaseSize?: number }) {
  const { getIaAction } = useIaAction();
  const isOneLineTitle =
    item.description === undefined && !item.properties?.length;
  const showIndicator = !isNil(item.status);

  const handleMouseEnter = item.titleHoverAction
    ? (event: MouseEvent) => {
        if (!item.avatarHoverAction) return;
        getIaAction<IconListLayoutItemHoverEvent>(
          item.avatarHoverAction.value
        )?.action({
          target: item,
          anchorEl: event.currentTarget,
        });
      }
    : undefined;

  if (item.avatarIcon) {
    return (
      <Box
        sx={[
          styles.left,
          isOneLineTitle ? styles.oneLineTitle : styles.defaultTitle,
        ]}
      >
        <MaskIcon sx={styles.avatarIcon} color={item.avatarIcon.color}>
          <Icon name={item.avatarIcon.name} />
        </MaskIcon>
      </Box>
    );
  }

  if (item.subAvatars && !item.avatarUrl) {
    return (
      <Box
        sx={[
          styles.left,
          isOneLineTitle ? styles.oneLineTitle : styles.defaultTitle,
        ]}
      >
        <AvatarGridFolder
          avatars={item.subAvatars}
          avatarsCount={item.subAvatarsCount}
          size={item.avatarSize}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={[
        styles.left,
        isOneLineTitle ? styles.oneLineTitle : styles.defaultTitle,
      ]}
    >
      <SquareAvatar
        src={item.avatarUrl}
        size={item.avatarSize || avatarBaseSize}
        blackAndWhite={item.avatarBlackAndWhite}
        showIndicator={showIndicator}
        status={item.status}
        statusInfo={item.statusInfo}
        onMouseEnter={handleMouseEnter}
        className={item.avatarClassName}
      >
        {item.avatarTitle || item.title}
      </SquareAvatar>
      {typeof item.avatarBadgeCount === 'number' && (
        <MaskIcon sx={styles.badge}>
          {item.avatarBadgeCount > 9 ? '9+' : item.avatarBadgeCount}
        </MaskIcon>
      )}
    </Box>
  );
}

function TitleLabel({ label }: { label: IconListLayoutItemLabel }) {
  if (typeof label === 'string')
    return (
      <Tag
        sx={styles.label}
        label={label}
        color="primary"
        variant="filled"
        component="span"
      />
    );

  const sxProps = Array.isArray(label.sx) ? label.sx : [label.sx];

  return (
    <Tag
      sx={[styles.label, ...sxProps]}
      label={label.text}
      color={label.color || 'primary'}
      variant={label.variant || 'filled'}
      component="span"
    />
  );
}

function ListTitle({
  children,
  sx,
  className = '',
  ...rest
}: PropsWithChildren<TypographyProps>) {
  const { containerRef, isTruncated } = useTruncated();

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <SimpleTooltip
      title={isTruncated ? children : ''}
      slotProps={{
        popper: {
          sx: styles.tooltip,
          disablePortal: true,
        },
      }}
    >
      <Typography
        sx={[styles.noWrap, ...sxProps]}
        fontWeight="bold"
        ref={containerRef}
        className={`ia-icon-list-layout_item-title ${className}`}
        component="div"
        {...rest}
      >
        <span>{children}</span>
      </Typography>
    </SimpleTooltip>
  );
}

const getLineStyles = (lines: number) => {
  if (lines === 1) {
    return {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis' as const,
    };
  }
  return {
    display: '-webkit-box' as const,
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical' as const,
    overflow: 'hidden' as const,
  };
};

function TitleArea({ item }: Omit<IconListItemProps, 'index'>) {
  const { getIaAction } = useIaAction();

  const handleTitleClick = item.titleAction
    ? (ev: MouseEvent) => {
        ev.stopPropagation();
        if (!item.titleAction) return;

        if (item.titleAction.type === 'link') {
          Router.push(item.titleAction.value);
        } else {
          getIaAction<IconListLayoutItemObj>(item.titleAction.value)?.action(
            item
          );
        }
      }
    : undefined;

  // cancel the button ripple effects when clicking the title.
  const handleMouseDone = item.titleAction
    ? (ev: MouseEvent) => {
        ev.stopPropagation();
      }
    : undefined;

  const handleMouseEnter = item.titleHoverAction
    ? (event: MouseEvent) => {
        if (!item.titleHoverAction) return;
        getIaAction<IconListLayoutItemHoverEvent>(
          item.titleHoverAction.value
        )?.action({
          target: item,
          anchorEl: event.currentTarget,
        });
      }
    : undefined;

  const titleSuffixSxProps = Array.isArray(item.titleSuffixSx)
    ? item.titleSuffixSx
    : [item.titleSuffixSx];

  const descriptionLineStyles =
    item.descriptionLines && item.description
      ? getLineStyles(item.descriptionLines)
      : false;

  return (
    <Box sx={styles.main}>
      <Box sx={styles.display}>
        <ListTitle
          sx={[!!handleTitleClick && styles.titleClickable]}
          onClick={handleTitleClick}
          onMouseDown={handleMouseDone}
          onMouseEnter={handleMouseEnter}
          className={item.titleClassName}
        >
          {item.title}
        </ListTitle>
        {!!item.titleSuffix && (
          <Box
            sx={[styles.titleSuffix, ...titleSuffixSxProps]}
            component="strong"
          >
            {' '}
            {item.titleSuffix}
          </Box>
        )}
        {!!item.label && <TitleLabel label={item.label} />}
        {!!item.titleSuffixIcon && (
          <SimpleTooltip
            title={item.titleSuffixTooltipTitle}
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}
          >
            <Box sx={styles.titleSuffixIcon} component="span">
              <Icon name={item.titleSuffixIcon} width={16} height={16} />
            </Box>
          </SimpleTooltip>
        )}
        {!!item.indicators && (
          <IndicatorGroup
            sx={{ alignSelf: 'center' }}
            indicators={item.indicators}
            size={16}
          />
        )}
      </Box>
      {item.description !== undefined && (
        <Typography
          sx={[styles.description, descriptionLineStyles]}
          variant="body2"
        >
          {item.description}
        </Typography>
      )}

      {!!item.properties && (
        <Box sx={styles.properties}>
          {item.properties.map((property, i) => (
            <Typography key={i} sx={styles.description} variant="body2">
              {property.text}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
}

function SuffixArea({ item }: IconListItemProps) {
  if (!item.suffix) return null;

  return (
    <>
      {item.suffix.type === 'loading' && (
        <Box sx={styles.suffix}>
          <LoadingIcon />
        </Box>
      )}
      {item.suffix.type === 'icon' && (
        <Box sx={styles.suffix}>
          <Icon name={item.suffix.name} />
        </Box>
      )}
      {item.suffix.type === 'text' && (
        <Box sx={styles.suffix}>
          <Typography variant="body1" sx={styles.suffixText}>
            {item.suffix.content}
          </Typography>
        </Box>
      )}
    </>
  );
}

function ClickAction({
  item,
  action,
}: {
  item: IconListLayoutItemObj;
  action: IconListLayoutItemActionMap['click'];
}) {
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<IconListLayoutItemObj>();
  const { authGuardCheck } = useContext(IaDataContext);

  if (!action) return null;

  const { disabled = false, loading = false } = getItemStatus
    ? getItemStatus(item)
    : {};

  const iaAction = getIaAction<IconListLayoutItemObj>(action.value);

  const PrefixIcon = action.icon ? <Icon name={action.icon} /> : undefined;
  const handleClick = (event: MouseEvent) => {
    if (action?.guard === 'auth' && authGuardCheck) {
      if (!authGuardCheck({ event })) {
        return;
      }
    }

    if (action.type === 'link') {
      Router.push(action.value);
    } else {
      iaAction?.action(item);
    }
  };

  const sxProps = Array.isArray(action.sxProps)
    ? action.sxProps
    : [action.sxProps];
  const buttonProp = {
    sx: sxProps,
    prefixIcon: PrefixIcon,
    onClick: handleClick,
    disabled: disabled || action?.disabled || false,
    loading:
      loading ||
      (iaAction?.inProgress
        ? typeof iaAction?.inProgress === 'boolean'
          ? iaAction?.inProgress
          : iaAction?.inProgress?.(item)
        : false),
  };

  if (action.actionType === 'textButton')
    return (
      <TextButton
        {...buttonProp}
        sx={[loading && styles.buttonTextLoading, ...buttonProp.sx]}
      >
        {action.text}
      </TextButton>
    );

  if (action.actionType === 'button')
    return (
      <Button
        variant={action.buttonVariant}
        color={action.buttonColor}
        {...buttonProp}
        sx={[action.size === 'sm' && styles.buttonSm, ...buttonProp.sx]}
      >
        {action.text}
      </Button>
    );

  if (action.actionType === 'emphasizeButton')
    return (
      <EmphasizeButton
        variant={action.buttonVariant}
        color={action.buttonColor}
        {...buttonProp}
      >
        {action.text}
      </EmphasizeButton>
    );

  if (action.actionType === 'iconButton')
    return (
      <IconButton customSize={action.customSize || 32} {...buttonProp}>
        <Icon name={action.icon} />
      </IconButton>
    );

  if (action.actionType === 'tipButton')
    return (
      <TipButton
        customSize={action.customSize}
        title={action.title || ''}
        {...buttonProp}
      >
        <Icon name={action.icon} />
      </TipButton>
    );
}

function SelectAction({
  item,
  action,
}: {
  item: IconListLayoutItemObj;
  action: IconListLayoutItemActionMap['select'];
}) {
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<IconListLayoutItemObj>();

  const { disabled = undefined, selected = undefined } = getItemStatus
    ? getItemStatus(item)
    : {};

  if (!action) return null;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    getIaAction<IconListLayoutItemObj>(action.value)?.action(
      item,
      event.target.checked
    );
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <Checkbox
      sx={styles.checkbox}
      onChange={handleChange}
      onClick={handleClick}
      disabled={disabled || action.disabled}
      checked={selected}
    />
  );
}

type TagTooltipProps = {
  tooltip?: {
    title: string;
    icon: string;
    content: string;
  };
  children: ReactElement;
};

function TagTooltip({ tooltip, children }: TagTooltipProps) {
  if (!tooltip) return children;
  return (
    <InfoTooltip
      title={tooltip.title}
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      content={tooltip.content}
    >
      <Box sx={{ display: 'inline-flex' }}>{children}</Box>
    </InfoTooltip>
  );
}

function StatusTagArea({ item }: IconListItemProps) {
  if (!item.statusTags?.length) return null;

  return (
    <Box sx={styles.status}>
      {item.statusTags.map((tag, i) => (
        <TagTooltip key={i} tooltip={tag.infoTooltip}>
          <StatusTag key={i} {...tag} />
        </TagTooltip>
      ))}
    </Box>
  );
}

function ActionArea({ item }: IconListItemProps) {
  if (!item.actionMap) return null;

  return (
    <>
      {item.actionMap.click !== undefined && (
        <Box sx={styles.action}>
          <ClickAction item={item} action={item.actionMap.click} />
        </Box>
      )}
      {item.actionMap.select !== undefined &&
        !item.actionMap.select.hideCheckbox && (
          <Box sx={styles.action}>
            <SelectAction item={item} action={item.actionMap.select} />
          </Box>
        )}
    </>
  );
}

function MoreActions({ item }: Omit<IconListItemProps, 'index'>) {
  const [subMenu, setSubMenu] = useState<{
    targetValue: string;
    targetEl: Element;
    targetItems: IaAction[];
  } | null>(null);
  const { getIaAction, iaRoutingAction } = useIaAction();

  if (!item.moreActions) return null;

  const handleActionClick = (
    action: IaAction & { subActions?: IaAction[] },
    domEvent: MouseEvent
  ) => {
    domEvent.stopPropagation();

    if (action.subActions) {
      setSubMenu({
        targetValue: action.value,
        targetEl: domEvent.currentTarget,
        targetItems: action.subActions,
      });
      return;
    }

    if (action.type === 'link') {
      void iaRoutingAction(action.value);
    } else {
      const onClickAction = getIaAction<IconListLayoutItemObj>(action.value);
      onClickAction?.action?.(item);
    }

    setSubMenu(null);
  };

  return (
    <>
      <Box sx={styles.actions}>
        {item.moreActions
          .filter((action) => !!(action.icon && action.hint))
          .map((action) => (
            <TipButton
              key={action.value}
              title={action.hint as string}
              onClick={(ev) => handleActionClick(action, ev)}
            >
              <Icon name={action.icon as string} />
            </TipButton>
          ))}
      </Box>
      {!!subMenu?.targetItems.length && (
        <ResponsiveMenu
          open
          onClose={() => setSubMenu(null)}
          menuProps={{
            sx: styles.subMenu,
            anchorEl: subMenu.targetEl,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
          sheetProps={{
            fixedHeight: true,
          }}
        >
          {subMenu.targetItems.map((event) => (
            <MenuItem
              key={`${event.type}-${event.value}`}
              sx={[
                styles.subMenuItem,
                item.moreActionSelectedValue === event.value &&
                  styles.subMenuActiveItem,
              ]}
              value={event.value}
              onClick={(ev) => handleActionClick(event, ev)}
              disabled={event.disabled}
            >
              <ListItemText primary={event.text} secondary={event.hint} />
            </MenuItem>
          ))}
        </ResponsiveMenu>
      )}
    </>
  );
}

function MultipleActions({ item }: IconListItemProps) {
  if (!item.multipleActions) return null;

  return (
    <Box
      sx={styles.actions}
      className="ia-icon-list-layout_item-multiple-actions"
    >
      {item.multipleActions.buttons.map((action, index) => (
        <ClickAction key={index} item={item} action={action} />
      ))}
    </Box>
  );
}

export default function IconListItem({
  item,
  avatarBaseSize,
  disableSelectTooltip,
}: IconListItemProps & {
  avatarBaseSize?: number;
  disableSelectTooltip?: { title: string; content: string; icon: string };
}) {
  const hasCheckboxAction = !!item.actionMap?.select;
  const hasClickAction = !!item.action;
  const selectable =
    !!item.actionMap?.select?.hideCheckbox || !hasCheckboxAction;

  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<IconListLayoutItemObj>();
  const [showMoreActions, setShowMoreActions] = useState(false);
  const {
    disabled = undefined,
    selected = undefined,
    focused = undefined,
  } = getItemStatus ? getItemStatus(item) : {};

  const handelCheckboxClick = useCallback(() => {
    if (!item.actionMap?.select) return;
    getIaAction<IconListLayoutItemObj>(item.actionMap.select.value)?.action(
      item,
      !selected
    );
  }, [getIaAction, item, selected]);

  const handelClick = useCallback(() => {
    if (!item.action) return;
    getIaAction<IconListLayoutItemObj>(item.action.value)?.action(
      item,
      !selected
    );
  }, [getIaAction, item, selected]);

  const handleMouseEnter = () => {
    if (item.moreActions?.length) {
      setShowMoreActions(true);
    }
    if (!item.actionMap?.hover || !item.actionMap.hover?.value) return;
    getIaAction<IconListLayoutItemObj>(item.actionMap.hover.value)?.action(
      item
    );
  };

  const handleMouseLeave = () => {
    if (showMoreActions) setShowMoreActions(false);
  };

  const getButtonProps = useCallback(() => {
    const actionDisabled = disabled || item.action?.disabled || false;
    if (hasCheckboxAction) {
      if (actionDisabled) return {};
      return {
        component: ButtonBase,
        onClick: handelCheckboxClick,
      };
    }

    if (item.action) {
      if (item.action.type === 'link') {
        return {
          component: Link,
          href: item.action.value,
          disabled: actionDisabled,
        };
      }

      return {
        component: ButtonBase,
        onClick: handelClick,
        disabled: actionDisabled,
      };
    }

    return null;
  }, [
    disabled,
    handelCheckboxClick,
    handelClick,
    hasCheckboxAction,
    item.action,
  ]);

  const buttonProps = getButtonProps();

  const isSelected = (selectable && !!selected) || !!focused;

  const hoverable =
    !!item.hoverable ||
    !!item.moreActions?.length ||
    !!hasClickAction ||
    (!!hasCheckboxAction && !item.actionMap?.hover);

  const tooltipProps = useMemo(() => {
    if (disableSelectTooltip && disabled) {
      return {
        title: disableSelectTooltip.title,
        content: disableSelectTooltip.content,
        titleIcon: (
          <Icon name={disableSelectTooltip.icon} width={16} height={16} />
        ),
      };
    }
    return {};
  }, [disableSelectTooltip, disabled]);
  return (
    <ResponsiveTooltip {...tooltipProps} tooltipProps={{ followCursor: true }}>
      <Box
        sx={[
          styles.root,
          hoverable && styles.hoverable,
          isSelected && styles.selected,
          item.multipleActions?.showOnHovered === true &&
            styles.hoverShowButtons,
        ]}
        className="ia-icon-list-layout_item"
        data-item-id={item.id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...buttonProps}
      >
        {item.borderLeftColor && (
          <Box sx={styles.borderLeft} bgcolor={item.borderLeftColor} />
        )}
        <AvatarArea item={item} avatarBaseSize={avatarBaseSize} />
        <TitleArea item={item} />
        <SuffixArea item={item} />
        <StatusTagArea item={item} />
        <ActionArea item={item} />
        {showMoreActions && <MoreActions item={item} />}
        {item.multipleActions && <MultipleActions item={item} />}
      </Box>
    </ResponsiveTooltip>
  );
}
