import { PropsWithChildren } from 'react';
import { currencyFormat, getAgentIconPath, ResponsiveTooltip } from '@front/ui';

import { LinkInternalAgentDetail } from '../../hooks/useLinkInternalDetail/types';
import {
  ListTooltip,
  ListTooltipLayoutProps,
} from '../../layouts/ListTooltipLayout/ListTooltipLayout';

function getCreatorAvatar(name: string, src?: string) {
  if (name === 'Aha') {
    return '/logo.png';
  }
  return src;
}

export default function AgentLinkTooltip({
  agent,
  children,
}: PropsWithChildren<{
  agent: LinkInternalAgentDetail;
}>) {
  const creatorAvatar =
    getCreatorAvatar(agent.creator.name, agent.creator.avatar) || '';

  const config: ListTooltipLayoutProps = {
    title: {
      content: agent.name,
      prefix: {
        type: 'avatar',
        avatarUrl: getAgentIconPath({ agentName: agent.name }),
        avatarText: agent.name,
      },
    },
    properties: [
      {
        name: {
          icon: 'PropertyTypePerson',
          text: 'Bot Creator',
        },
        value: {
          type: 'avatarText',
          avatarText: agent.creator.name,
          avatarUrl: creatorAvatar,
        },
      },
      {
        name: {
          icon: 'PrivacyFriends',
          text: 'Users',
        },
        value: {
          type: 'text',
          text: currencyFormat(agent.userCount),
        },
      },
    ],
  };

  return (
    <ResponsiveTooltip content={<ListTooltip {...config} />}>
      <span>{children}</span>
    </ResponsiveTooltip>
  );
}
