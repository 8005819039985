import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { PlanAction, PlanItem } from '../types';

export interface MyPlanCheckoutValue {
  activePlan?: PlanItem;
  shareInfo?: ShareSubscriptionOrderInfoRes;
  isUseAhaPoint: boolean;
  discountCode?: GetMyPromoCodeRes;
  action?: PlanAction;
}

const initialValue = {
  isUseAhaPoint: true,
};

type MyPlanCheckoutContextValue = [
  MyPlanCheckoutValue,
  Dispatch<SetStateAction<MyPlanCheckoutValue>>
];

type MyPlanProviderProps = {
  children: ReactNode;
};

export const MyPlanCheckoutContext = createContext<MyPlanCheckoutContextValue>([
  initialValue,
  () => {},
]);

export function MyPlanCheckoutProvider({ children }: MyPlanProviderProps) {
  const providerValue = useState<MyPlanCheckoutValue>(initialValue);

  return (
    <MyPlanCheckoutContext.Provider value={providerValue}>
      {children}
    </MyPlanCheckoutContext.Provider>
  );
}

export const MyPlanCheckoutConsumer = MyPlanCheckoutContext.Consumer;
