import { MouseEvent } from 'react';
import { useUserProfileSocial } from '@lib/web/apis';

import useClubSlug from '../../../hooks/useClubSlug';
import { FloatingProfileExtension } from '../types';

import ProfileSkeleton from './ProfileSkeleton';
import UserProfile from './UserProfile';

type UserIdProfileProps = {
  userId: string;
  extension?: FloatingProfileExtension;
  disableCta?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
  onMessageClick?: (userProfile: UserProfileObj) => void;
  onChallengeClick?: (userProfile: UserProfileObj) => void;
  beforeClickCheck?: (ev?: MouseEvent) => boolean;
};

export default function UserIdProfile({ userId, ...rest }: UserIdProfileProps) {
  const clubSlug = useClubSlug();
  const { data } = useUserProfileSocial(userId, {
    clubSlug,
    isGetLeagueInfo: true,
  });
  const socialData = data?.data;

  if (!socialData) {
    return <ProfileSkeleton />;
  }

  const userProfile: UserProfileObj = {
    userId: socialData.userId,
    memberId: socialData.memberId,
    nftAvatar: socialData.avatarUrl,
    avatar: socialData.photoUrl || '',
    displayName: socialData.fullName,
    distinctName: socialData.userName,
    email: socialData.email,
    isFollower: socialData.isFollower,
    isFollowing: socialData.isFollowing,
    indicator: socialData.indicator,
    dreamCollege:
      socialData.dreamSchoolId && socialData.dreamSchoolName
        ? {
            id: socialData.dreamSchoolId,
            name: socialData.dreamSchoolName,
          }
        : undefined,
    locationCountry: socialData.country,
    locationCity: socialData.city,
    highSchoolGraduation: socialData.highSchoolGraduationYear,
    highSchool: socialData.highSchool,
    clubCount: socialData.numJoinedClubs,
    followerCount: socialData.numFollowers,
    followingCount: socialData.numFollowings,
    leagueInfo: socialData.leagueInfo,
  };

  return <UserProfile {...rest} userProfile={userProfile} />;
}
