import { PropsWithChildren } from 'react';
import {
  currencyFormat,
  getClubGroupIconPath,
  getClubIconPath,
  ResponsiveTooltip,
} from '@front/ui';

import {
  ClubOfficialType,
  LinkInternalClubGroupDetail,
} from '../../hooks/useLinkInternalDetail/types';
import {
  ListTooltip,
  ListTooltipLayoutProps,
} from '../../layouts/ListTooltipLayout/ListTooltipLayout';

export default function ClubGroupTooltip({
  clubGroup,
  children,
}: PropsWithChildren<{
  clubGroup: LinkInternalClubGroupDetail;
}>) {
  const config: ListTooltipLayoutProps = {
    title: {
      content: clubGroup.name,
      prefix: {
        type: 'avatar',
        avatarUrl: clubGroup.logo || getClubGroupIconPath(clubGroup.name),
        avatarText: clubGroup.name,
      },
    },
    properties: [
      {
        name: {
          icon: 'PropertyTypePerson',
          text: 'Club Group Creator',
        },
        value: {
          type: 'text',
          text: clubGroup.creator.name,
        },
      },
      {
        name: {
          icon: 'PrivacyFriends',
          text: 'Members',
        },
        value: {
          type: 'text',
          text: currencyFormat(clubGroup.memberCount),
        },
      },
      {
        name: {
          icon: 'UserOnline',
          text: 'Online',
          sxProps: {
            '& svg path': {
              fill: '#00C398',
            },
          },
        },
        value: {
          type: 'text',
          text: currencyFormat(clubGroup.onlineMemberCount),
        },
      },
      {
        name: {
          icon: 'ChatQuestionList',
          text: 'Questions',
        },
        value: {
          type: 'text',
          text: currencyFormat(clubGroup.questionCount),
        },
      },
      {
        name: {
          icon: 'MainApps',
          text: 'Clubs',
        },
        value: {
          type: 'text',
          text: currencyFormat(clubGroup.clubs.length),
        },
      },
      ...clubGroup.clubs.map((club) => {
        const isOfficial = club.officialType === ClubOfficialType.Official;
        return {
          value: {
            type: 'avatarText' as const,
            text: club.name,
            avatarUrl: club.logo || getClubIconPath(club.name),
            avatarText: club.name,
            suffixIcon: isOfficial ? 'ProfileOfficial' : undefined,
            sxProps: { pl: 2.5 },
          },
        };
      }),
    ],
  };

  return (
    <ResponsiveTooltip content={<ListTooltip {...config} />}>
      <span>{children}</span>
    </ResponsiveTooltip>
  );
}
