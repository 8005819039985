import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Box, Skeleton } from '@mui/material';
import usePlaylistRename from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistRename';
import { OtherEdit as OtherEditIcon } from '@front/icon';
import { NameInput } from '@front/ui';
import { useAuth, useQuizResultSummary } from '@lib/web/apis';

function PlaylistHeader({ quizId }: { quizId: string }) {
  const { t } = useTranslation();
  const { value, disabled, onNameChange } = usePlaylistRename(quizId);

  return (
    <NameInput
      value={value || ''}
      onChange={onNameChange}
      disabled={disabled}
      customFloatingButtons={[
        {
          icon: <OtherEditIcon />,
          tooltip: {
            content: t('toolbar.Rename Playlist Title'),
          },
        },
      ]}
    />
  );
}

export default function PlaylistHeaderRoot({ quizId }: { quizId: string }) {
  const { data } = useQuizResultSummary({ quizId });
  const sid = useRouter().query.sid as string;
  const { member } = useAuth();
  const disabled = !member || data?.data.quiz.quizOwnerId !== member?.userId;

  if (!data) {
    return (
      <>
        {/* empty icon */}
        <Box />
        <Skeleton width={100} />
      </>
    );
  }

  if (sid || disabled) {
    return (
      <>
        {/* empty icon */}
        <Box />
        <Box>{data.data.quiz.quizCustomName}</Box>
      </>
    );
  }
  return (
    <>
      {/* empty icon */}
      <Box />
      <PlaylistHeader quizId={quizId} />
    </>
  );
}
