import React, {
  ClipboardEvent,
  FormEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import { Scrollbar } from '@front/ui';
import { parseChildNodesForValueAndLines } from '@lib/web/composer';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { ErrorMessage } from '@lib/web/ui';

const SCROLL_HEIGHT = 135;
const ERRORS_HEIGHT = 40;

const styles = {
  divider: {
    backgroundColor: 'alpha.lightA10',
  },
  root: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    pb: 2,
  },
  scroll: {
    height: `${SCROLL_HEIGHT}px`,
  },
  scrollWhenError: {
    height: `${SCROLL_HEIGHT - ERRORS_HEIGHT}px`,
  },
  content: {
    typography: 'numberBody3',
    '&:empty::before': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },

    pt: 1,
    px: 2.5,
  },
  errors: {
    minHeight: `${ERRORS_HEIGHT}px`,
    pt: 1,
    px: 2.5,
  },
};

export function LatexActionComposer() {
  const { commands: textComposerCommands } = useContext(TextComposerContext);
  const { selectedAction, latexActionState } = useThreadComposer();
  const contentRef = useRef<HTMLDivElement>();
  const latexActionActive = selectedAction === 'latex';

  useImperativeHandle(
    latexActionState.latexComposerRef,
    () => ({
      getElement: () => contentRef.current,
      setValue: (value: string) => {
        if (!contentRef.current) return;
        contentRef.current.innerText = value;
      },
    }),
    []
  );

  useEffect(() => {
    const { latexBlocksMap, composingBlockId } = latexActionState;
    if (!latexActionActive || !composingBlockId) return;
    if (!(composingBlockId in latexBlocksMap)) {
      latexActionState.closeLatexComposer();
    }
  }, [latexActionActive, latexActionState]);

  if (!latexActionActive) return;

  const composingBlock = latexActionState.composingBlockId
    ? latexActionState.latexBlocksMap[latexActionState.composingBlockId]
    : null;

  const handleInput = (ev: FormEvent<Element>) => {
    const newValue =
      parseChildNodesForValueAndLines(
        (ev.target as HTMLDivElement).childNodes
      ) || '\n';

    composingBlock?.setValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      latexActionState.closeLatexComposer();
      textComposerCommands.focus();
    }
  };

  const handlePaste = (ev: ClipboardEvent) => {
    ev.preventDefault();

    if (!contentRef.current) {
      console.warn('no content ref or composing block id');
      return;
    }

    const copyValue = ev.clipboardData?.getData('text/plain') || '';

    // TODO: use execCommand to keep cursor at the end, but execCommand will be deprecated in the future
    document.execCommand('insertHTML', false, copyValue);

    composingBlock?.setValue(copyValue);
  };

  const errors = composingBlock?.errors;
  const hasError = !!errors && errors.length > 0;

  return (
    <>
      <Divider sx={styles.divider} />

      <Box sx={styles.root}>
        <Scrollbar sx={[styles.scroll, hasError && styles.scrollWhenError]}>
          <Box
            sx={styles.content}
            ref={contentRef}
            contentEditable
            data-placeholder="E = MC^2 "
            suppressContentEditableWarning
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
          ></Box>
        </Scrollbar>

        {hasError && <ErrorMessage sx={styles.errors} messages={errors} />}
      </Box>
    </>
  );
}
