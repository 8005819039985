import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import DiscountCodeCard from '@app/web/src/components/DiscountCodeCard';
import { OtherDiscountNoOuter as OtherDiscountNoOuterIcon } from '@front/icon';
import {
  Accordion,
  BaseLayoutRightPanel,
  Button,
  PropertyType as LabelType,
  Scrollbar,
  SimpleTooltip,
  TextField,
} from '@front/ui';
import {
  apis,
  DiscountCodePurchaseType,
  PromoCodeQueryType,
  useAuth,
  useMyPromoCode,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';
import { isNil } from 'lodash';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    px: { xs: 2.5, md: '12px' },
    pt: 1,
    pb: 2.5,
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  addButton: {
    width: '100%',
    maxWidth: 'unset !important',
  },
  discountAccordion: {
    mt: 2,
  },
  discountCards: {
    display: 'grid',
    gap: 2.5,
    pt: 0.5,
  },
  codeItem: {
    cursor: 'pointer',
  },
  tooltip: {
    '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
      mt: '0 !important',
    },
  },
};

type DiscountCodePanelProps = {
  selected?: GetMyPromoCodeRes;
  onSelect?: (val?: GetMyPromoCodeRes) => void;
  purchaseType?: DiscountCodePurchaseType;
};
export default function DiscountCodePanel({
  selected,
  onSelect,
  purchaseType,
}: DiscountCodePanelProps) {
  const { t } = useTranslation('profile');

  const { member } = useAuth();
  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { data: availableCodes, mutate } = useMyPromoCode(
    PromoCodeQueryType.Available
  );
  const { data: usedCodes } = useMyPromoCode(PromoCodeQueryType.UsedOrInvalid);

  const handleApply = async () => {
    setLoading(true);

    const [res, err] = await call(
      apis.marketplace.addPromoCode(promoCode, purchaseType)
    );
    if (err) {
      if (err.statusCode === 423) {
        if (usedCodes?.data.some((code) => code.discountCode === promoCode)) {
          setError(t('discount.You have previously used the promo code'));
        } else {
          setError(t('discount.The promo code was already added'));
        }
      } else if (err.statusCode === 409 && err.statusKey === 'CHECK_FAILED') {
        setError(
          // transform the API error message to a correct message
          // search i18n keys with prefix `discount.error.` for more info
          t(`discount.error.${err.message}`, {
            context: purchaseType,
          })
        );
      } else if (
        member &&
        promoCode.endsWith(member.distinctName.toUpperCase())
      ) {
        setError('Nice try! Use someone else’s, not yours.');
      } else {
        setError(t('discount.The promo code was not found'));
      }
    }

    if (res) {
      setPromoCode('');
      await mutate();
      onSelect?.(res.data);
    }
    setLoading(false);
  };

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setPromoCode(ev.target.value);
  };

  const isDisabled = (itemPurchaseType: DiscountCodePurchaseType) => {
    if (isNil(purchaseType) || isNil(itemPurchaseType)) return false;
    if ([DiscountCodePurchaseType.All].includes(itemPurchaseType)) return false;
    return itemPurchaseType !== purchaseType;
  };

  const activeCodes: GetMyPromoCodeRes[] = [];
  const disabledCodes: GetMyPromoCodeRes[] = [];
  availableCodes?.data.forEach((item) => {
    if (isDisabled(item.purchaseType)) {
      disabledCodes.push(item);
    } else {
      activeCodes.push(item);
    }
  });

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherDiscountNoOuterIcon width="16" height="16" />}
      title={t('profile.Use Discount Code')}
    >
      <Scrollbar sx={styles.scroll}>
        <Box sx={styles.content}>
          <TextField
            placeholder={t('discount.Enter discount code here')}
            value={promoCode}
            onChange={handleChange}
            helperText={error}
            error={!!error}
            disabled={loading}
            label={t('discount.Add Discount Code')}
            size="rwd"
            labelIcon={LabelType.ShortText}
          />
          <Button
            sx={styles.addButton}
            disabled={!promoCode}
            loading={loading}
            onClick={handleApply}
          >
            {t('discount.Use Discount Code')}
          </Button>
          {selected && (
            <Accordion
              title={t('discount.Applied')}
              sx={styles.discountAccordion}
            >
              <Box sx={styles.discountCards}>
                <SimpleTooltip
                  title="Click to unuse discount code"
                  placement="bottom-end"
                  slotProps={{
                    popper: {
                      sx: styles.tooltip,
                    },
                  }}
                >
                  <Box sx={styles.codeItem} onClick={() => onSelect?.()}>
                    <DiscountCodeCard code={selected} />
                  </Box>
                </SimpleTooltip>
              </Box>
            </Accordion>
          )}

          <Accordion
            title={t('discount.My Discounts')}
            sx={styles.discountAccordion}
          >
            <Box sx={styles.discountCards}>
              {activeCodes.map((item) => (
                <SimpleTooltip
                  key={item.id}
                  title="Select to apply discount code"
                  placement="bottom-end"
                  slotProps={{
                    popper: {
                      sx: styles.tooltip,
                    },
                  }}
                >
                  <Box sx={styles.codeItem} onClick={() => onSelect?.(item)}>
                    <DiscountCodeCard
                      code={item}
                      disabled={isDisabled(item.purchaseType)}
                    />
                  </Box>
                </SimpleTooltip>
              ))}
              {disabledCodes.map((item) => (
                <DiscountCodeCard key={item.id} code={item} disabled={true} />
              ))}
            </Box>
          </Accordion>

          <Accordion
            title={t('discount.Used or Expired Discounts')}
            sx={styles.discountAccordion}
          >
            <Box sx={styles.discountCards}>
              {usedCodes?.data.map((item) => (
                <DiscountCodeCard
                  key={item.id}
                  code={item}
                  disabled={isDisabled(item.purchaseType)}
                />
              ))}
            </Box>
          </Accordion>
        </Box>
      </Scrollbar>
    </BaseLayoutRightPanel>
  );
}
