import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Modal, { ModalProps } from '@mui/material/Modal';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { keyframes } from '@emotion/react';
import { ActionClose as ActionCloseIcon } from '@front/icon';

import AnimateBox from '../../../components/AnimateBox';

const wave = keyframes`
	0%, 60%, 100% {
		transform: initial;
	}
	30% {
		transform: translateY(-2px);
	}
`;

const styles = {
  root: (theme: Theme) => ({
    width: '80%',
    maxWidth: 835,
    pointerEvents: 'none',
    userSelect: 'none',
    [theme.breakpoints.down('md')]: {
      pb: 'env(safe-area-inset-bottom)',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  content: (theme: Theme) => ({
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flex: 1,
      gap: 3,
    },
  }),
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  emoji: {
    width: { xs: 140, md: 180 },
    height: { xs: 140, md: 180 },
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'block',
      width: { xs: 88, md: 110 },
      height: { xs: 160, md: 200 },
    },
  },
  icon: {
    width: { xs: 100, md: 120 },
    height: { xs: 100, md: 120 },
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'block',
    },
  },
  titleLoading: {
    ml: 0.5,
    '& span': {
      mr: 0.1,
      display: 'inline-block',
      animation: `${wave} 1.3s linear infinite`,
    },
    '& span:nth-of-type(2)': {
      animationDelay: '-1.1s',
    },
    '& span:nth-of-type(3)': {
      animationDelay: '-0.9s',
    },
  },
  text: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  action: (theme: Theme) => ({
    px: 2.5,
    [theme.breakpoints.down('md')]: {
      p: 2,
      pt: '12px',
    },
  }),
  closeIcon: {
    position: 'absolute',
    right: '50px',
    top: '40px',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
};

export type PopupOverlayProps = Omit<BoxProps, 'title'> & {
  onClose?: () => void;
  title?: ReactNode;
  body?: ReactNode;
  actionComponent?: ReactNode;
  emojiComponent?: ReactNode;
  iconComponent?: ReactNode;
  open: boolean;
  titleLoading?: boolean;
  modalProps?: Omit<ModalProps, 'open' | 'onClose' | 'children'>;
  showCloseButton?: boolean;
};
export default function PopupOverlay({
  sx,
  title,
  body,
  children,
  emojiComponent,
  iconComponent,
  actionComponent,
  open,
  onClose,
  titleLoading = false,
  modalProps = {},
  showCloseButton = false,
  ...rest
}: PopupOverlayProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Modal open={open} onClose={onClose} {...modalProps}>
      {/* do not remove this empty fragment */}
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        <AnimateBox
          sx={[styles.root, ...sxProps]}
          variable={title || body}
          {...rest}
        >
          <Box sx={styles.content} className="popup-overlay-content">
            {!!emojiComponent && (
              <Box sx={styles.emoji} className="popup-overlay-emoji">
                {emojiComponent}
              </Box>
            )}
            {!!iconComponent && <Box sx={styles.icon}>{iconComponent}</Box>}
            <Box sx={styles.body}>
              {!!title && (
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  className="popup-overlay-title"
                >
                  {title}
                  {titleLoading && (
                    <Box sx={styles.titleLoading} component="span">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </Box>
                  )}
                </Typography>
              )}
              {!!body && (
                <Typography sx={styles.text} className="popup-overlay-body">
                  {body}
                </Typography>
              )}
              {children}
            </Box>
          </Box>
          {!!actionComponent && (
            <Box sx={styles.action} className="popup-overlay-action">
              {actionComponent}
            </Box>
          )}
          {showCloseButton && (
            <Box
              sx={styles.closeIcon}
              className="popup-overlay-close"
              onClick={onClose}
            >
              <ActionCloseIcon width={24} height={24} />
            </Box>
          )}
        </AnimateBox>
      </>
    </Modal>
  );
}
