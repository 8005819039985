import { MarketplaceKeys } from '@app/web/src/types/panel';
import { BaseLayoutRightPanelPortal, useBaseLayout } from '@front/ui';

import DetailPanel from './components/DetailPanel';
import MarketplaceSharePanel from './components/MarketplaceSharePanel';

export default function MarketplaceRightPanels() {
  const { rightPanelTarget } = useBaseLayout();

  return (
    <BaseLayoutRightPanelPortal>
      {rightPanelTarget === MarketplaceKeys.MarketplaceDetail && (
        <DetailPanel />
      )}
      {rightPanelTarget === MarketplaceKeys.MarketplaceShare && (
        <MarketplaceSharePanel />
      )}
    </BaseLayoutRightPanelPortal>
  );
}
