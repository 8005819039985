import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  Box,
  ButtonBase,
  styled,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import {
  OtherCircleOutline as OtherCircleOutlineIcon,
  OtherCircleSolid as OtherCircleSolidIcon,
} from '@front/icon';
import { SimpleTooltip } from '@front/ui';

import useInputEvent from '../hooks/useInputEvent';
import useInputGuard from '../hooks/useInputGuard';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutRadioGroup } from '../types';

const styles = {
  group: {
    display: 'grid',
    gap: 1,
  },
  option: {
    py: '3.5px',
    px: '12px',
    width: '100%',
    typography: 'body2',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  radio: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
type RadioGroupProps = {
  item: FormLayoutRadioGroup;
};

const FixedWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <SimpleTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

export default function RadioGroup({ item }: RadioGroupProps) {
  const { control } = useFormContext();
  const inputGuardEvents = useInputGuard(item.guard);

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: item.name,
    rules: item.rules,
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: any) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const handleClick = (newValue: any) => {
    handleChange(newValue);
    handleBlur();
  };
  return (
    <Box
      className={`ia-form-layout_radio-group ${item.className || ''}`}
      sx={styles.group}
    >
      <Typography variant="caption">{item.label}</Typography>
      {/*
       */}
      <Box>
        {item.options.map((option) => {
          const buttonRender = (
            <ButtonBase
              key={option.value}
              sx={styles.option}
              disableRipple
              onClick={() => handleClick(option.value)}
              disabled={item.disabled}
              {...inputGuardEvents}
            >
              {option.label}

              <Box sx={styles.radio}>
                {value === option.value ? (
                  <OtherCircleSolidIcon width={16} height={16} />
                ) : (
                  <OtherCircleOutlineIcon width={16} height={16} />
                )}
              </Box>
            </ButtonBase>
          );

          if (option.hint) {
            return (
              <FixedWidthTooltip
                key={option.value}
                title={option.hint}
                placement="bottom-start"
              >
                <div>{buttonRender}</div>
              </FixedWidthTooltip>
            );
          }
          return buttonRender;
        })}
      </Box>
    </Box>
  );
}
