import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Switch as MuiSwitch, Typography } from '@mui/material';
import { Icon, InfoTooltip, SimpleTooltip } from '@front/ui';

import useInputEvent from '../hooks/useInputEvent';
import useInputGuard from '../hooks/useInputGuard';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutSwitchItem } from '../types';
import { getCustomValidate } from '../utils';

import LabelIcon from './LabelIcon';

type SwitchProps = {
  item: FormLayoutSwitchItem;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    position: 'relative',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  switch: {
    width: 28,
    height: 16,
    '& .MuiSwitch-switchBase': {
      width: 16,
      height: 16,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 14,
      height: 14,
    },
  },
  labelContent: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  hint: {
    opacity: 0.75,
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
};
export default function Switch({ item }: SwitchProps) {
  const { control, getValues } = useFormContext();
  const inputGuardEvents = useInputGuard(item.guard);

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues })
    : undefined;

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleChange = (newValue: boolean) => {
    void handleInputChange(newValue);
    void successState.handleChange();

    handleInputBlur();
    void successState.handleBlur();
  };

  const iconPlaceholder = <Box sx={{ width: 12, height: 12 }} />;

  return (
    <Box
      sx={styles.root}
      className={`ia-form-layout-switch ${item.className || ''}`}
    >
      <Box sx={[styles.label, { order: item.labelPosition === 'end' ? 1 : 0 }]}>
        <Typography sx={styles.labelContent} variant="caption">
          {item.icon && <LabelIcon icon={item.icon} />}
          {item.label}
        </Typography>
        {item.labelTooltip ? (
          <SimpleTooltip title={item.labelTooltip}>
            <MuiSwitch
              sx={styles.switch}
              checked={Boolean(value)}
              disabled={item.disabled}
              onChange={(ev) => handleChange(ev.target.checked)}
              {...inputGuardEvents}
            />
          </SimpleTooltip>
        ) : (
          <MuiSwitch
            sx={styles.switch}
            checked={Boolean(value)}
            disabled={item.disabled}
            onChange={(ev) => handleChange(ev.target.checked)}
            {...inputGuardEvents}
          />
        )}
      </Box>
      {!!item.helperText && (
        <Typography sx={styles.hint} variant="caption">
          {item.icon && iconPlaceholder}
          {item.helperText}
        </Typography>
      )}
      {item.tooltip && (
        <>
          {item.tooltip.type === 'info' && (
            <InfoTooltip
              followCursor
              title={item.tooltip.title}
              titleIcon={
                <Icon name={item.tooltip.titleIcon} width={16} height={16} />
              }
              content={item.tooltip.content}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 1,
                }}
              />
            </InfoTooltip>
          )}
          {item.tooltip.type === 'simple' && (
            <SimpleTooltip title={item.tooltip.content}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 1,
                }}
              />
            </SimpleTooltip>
          )}
        </>
      )}
    </Box>
  );
}
