import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';
import { usePaidFeatureBlocker } from '@app/web/src/components/BlockerControl';
import {
  EditorDifficultyEasy as EditorDifficultyEasyIcon,
  EditorDifficultyHard as EditorDifficultyHardIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
  EditorDifficultySuper as EditorDifficultySuperIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import { Difficulty } from '@lib/web/apis';
import { PaidFeatureBlockerType } from '@lib/web/ui';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue, Placement } from '../../type';

import Section from './Section';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    width: '100%',
    flexWrap: 'wrap',
  },
  chip: (color: string) => ({
    '&:hover .main-color': {
      color,
    },
  }),
  selectedChip: (color: string) => ({
    '& .main-color': {
      color,
    },
  }),
};

export const difficultyOptions = [
  {
    value: Difficulty.Easy,
    icon: <EditorDifficultyEasyIcon width={16} height={16} />,
    activeColor: 'highlight.green',
    key: 'easy',
  },
  {
    value: Difficulty.Medium,
    icon: <EditorDifficultyMediumIcon width={16} height={16} />,
    activeColor: 'highlight.yellow',
    key: 'medium',
  },
  {
    value: Difficulty.Hard,
    icon: <EditorDifficultyHardIcon width={16} height={16} />,
    activeColor: 'highlight.orange',
    key: 'hard',
  },
  {
    value: Difficulty.Super,
    icon: <EditorDifficultySuperIcon width={16} height={16} />,
    activeColor: 'highlight.red',
    key: 'super',
  },
];

export const difficultyTypes = ['easy', 'medium', 'hard', 'super'];

export default function DifficultySection({
  placement,
}: {
  placement: Placement;
}) {
  const [createQuizSettings] = useContext(CreateQuizContext);
  const disabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('difficulty');
  const { t } = useTranslation('quiz');

  const { openBlocker, isLoading, isFeatureBlocked } = usePaidFeatureBlocker();

  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const difficulties = watch('difficulties');

  const handleSelect = (option: Difficulty) => {
    if (
      isFeatureBlocked.selectSuperLevelDifficulty &&
      option === Difficulty.Super
    ) {
      openBlocker(PaidFeatureBlockerType.SelectSuperLevelDifficulty, placement);
      return;
    }
    if (difficulties.includes(option)) {
      setValue(
        'difficulties',
        difficulties.filter((item) => item !== option),
        { shouldDirty: true }
      );
      return;
    }
    setValue('difficulties', [...difficulties, option], { shouldDirty: true });
  };

  return (
    <Section title={t('createQuiz.difficulty.title', 'Difficulty')}>
      <Box sx={styles.root}>
        {difficultyOptions.map((item, i) => {
          const selected = !disabled && difficulties.includes(item.value);

          return (
            <ResponsiveTooltip
              key={item.key}
              titleIcon={item.icon}
              title={t(
                `createQuiz.difficulty.${difficultyTypes[i]}.hint.title`
              )}
              content={t(
                `createQuiz.difficulty.${difficultyTypes[i]}.hint.content`
              )}
            >
              <Chip
                label={t(`createQuiz.difficulty.${difficultyTypes[i]}.title`)}
                sx={[
                  styles.chip(item.activeColor),
                  selected && styles.selectedChip(item.activeColor),
                ]}
                icon={item.icon}
                variant={selected ? 'filled' : 'outlined'}
                clickable
                onClick={() => handleSelect(item.value)}
                disabled={isLoading || disabled}
              />
            </ResponsiveTooltip>
          );
        })}
      </Box>
    </Section>
  );
}
