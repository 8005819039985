import { useMemo } from 'react';
import AvatarCheckout from '@app/web/src/widgets/NotificationPage/CtaActionButtons/AvatarCheckout';
import AvatarDetail from '@app/web/src/widgets/NotificationPage/CtaActionButtons/AvatarDetail';
import ChallengeDetailLink from '@app/web/src/widgets/NotificationPage/CtaActionButtons/ChallengeDetailLink';
import ClubPendingRequestList from '@app/web/src/widgets/NotificationPage/CtaActionButtons/ClubPendingRequestList';
import ClubWelcomeRecommendList from '@app/web/src/widgets/NotificationPage/CtaActionButtons/ClubWelcomeRecommendList';
import CreateChallengeButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/CreateChallengeButton';
import EarnedToken from '@app/web/src/widgets/NotificationPage/CtaActionButtons/EarnedToken';
import FollowerProfileTable from '@app/web/src/widgets/NotificationPage/CtaActionButtons/FollowerProfileTable';
import InviteFriendActions from '@app/web/src/widgets/NotificationPage/CtaActionButtons/InviteFriendActions';
import JoinToClubButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/JoinToClubButton';
import KeepStreakButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/KeepStreakButton';
import MembershipCheckout from '@app/web/src/widgets/NotificationPage/CtaActionButtons/MembershipCheckout';
import MyPlanButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/MyPlanButton';
import NewChallengeButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/NewChallengeButton';
import PlaylistDetailLink from '@app/web/src/widgets/NotificationPage/CtaActionButtons/PlaylistDetailLink';
import PracticeMoreButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/PracticeMoreButton';
import RelatedClubList from '@app/web/src/widgets/NotificationPage/CtaActionButtons/RelatedClubList';
import SeeJoinRequestsButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/SeeJoinRequestsButton';
import SharedDiscountCodeCard from '@app/web/src/widgets/NotificationPage/CtaActionButtons/SharedDiscountCodeCard';
import ShareDiscountCodeActions from '@app/web/src/widgets/NotificationPage/CtaActionButtons/ShareDiscountCodeActions';
import StartChallengeButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/StartChallengeButton';
import StartPracticeButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/StartPracticeButton';
import SuggestedUsersToFollow from '@app/web/src/widgets/NotificationPage/CtaActionButtons/SuggestedUsersToFollow';
import UpdatePaymentButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/UpdatePaymentButton';
import UserInfoList from '@app/web/src/widgets/NotificationPage/CtaActionButtons/UserInfoList';
import ViewProfileButton from '@app/web/src/widgets/NotificationPage/CtaActionButtons/ViewProfileButton';
import { IaRenderContextValue } from '@lib/ia/src/core/IaRender/IaRenderContext';

import AcceptClubInvite from '../../CtaActionButtons/AcceptClubInvite';
import ChallengeButton from '../../CtaActionButtons/ChallengeButton';
import ChallengeInvitationCard from '../../CtaActionButtons/ChallengeInvitationCard';
import ChallengeRecommendList from '../../CtaActionButtons/ChallengeRecommendList';
import ClubHome from '../../CtaActionButtons/ClubHome';
import DiscoverClubsButton from '../../CtaActionButtons/DiscoverClubsButton';
import FriendFollowButton from '../../CtaActionButtons/FriendFollowButton';
import GoalRingCard from '../../CtaActionButtons/GoalRingCard';
import LeagueCard from '../../CtaActionButtons/LeagueCard';
import PlanCard from '../../CtaActionButtons/PlanCard';
import ResendVerifyEmailButton from '../../CtaActionButtons/ResendVerifyEmailButton';
import RetryToSubscribePlanButton from '../../CtaActionButtons/RetryToSubscribePlanButton';
import SharedChallengeCard from '../../CtaActionButtons/SharedChallengeCard';
import SharedPlaylistCard from '../../CtaActionButtons/SharedPlaylistCard';
import SharedSummaryCard from '../../CtaActionButtons/SharedSummaryCard';
import StreakCard from '../../CtaActionButtons/StreakCard';

export default function useNotificationCustomizeCtas() {
  const renders: IaRenderContextValue = useMemo(() => {
    return {
      'cta.league.current': LeagueCard,
      'cta.goal.ring.*': GoalRingCard,
      'cta.streak.*': StreakCard,
      'cta.shared.summary': SharedSummaryCard,
      'cta.shared.challenge': SharedChallengeCard,
      'cta.email.verification.remind': ResendVerifyEmailButton,
      'cta.discover.club': DiscoverClubsButton,
      'cta.shared.*.playlist': SharedPlaylistCard,
      'cta.membership.plan.info.*': PlanCard,
      'cta.challenge.invitation': ChallengeInvitationCard,
      'cta.challenge.recommend.list': ChallengeRecommendList,
      'cta.recommend.challenger.list': ChallengeRecommendList,
      'cta.club.invitation': AcceptClubInvite,
      'cta.follow.followback': FriendFollowButton,
      'cta.share.discountCode': SharedDiscountCodeCard,
      'cta.aha.point.earned': EarnedToken,
      'cta.club.home': ClubHome,
      'cta.user.challenge.status': ChallengeButton,
      'cta.discount.code.share': ShareDiscountCodeActions,
      'cta.refer.invite': InviteFriendActions,
      'cta.start.practice': StartPracticeButton,
      'cta.practice.more': PracticeMoreButton,
      'cta.keep.streak': KeepStreakButton,
      'cta.club.welcome.recommend.list': ClubWelcomeRecommendList,
      'cta.see.all.request': SeeJoinRequestsButton,
      'cta.pending.request.list': ClubPendingRequestList,
      'cta.related.club.list': RelatedClubList,
      'cta.join.club.status': JoinToClubButton,
      'cta.follower.profile': FollowerProfileTable,
      'cta.user.info': UserInfoList,
      'cta.follow.suggest.list': SuggestedUsersToFollow,
      'cta.view.user.profile': ViewProfileButton,
      'cta.retry.subscribe': RetryToSubscribePlanButton,
      'cta.my.plan': MyPlanButton,
      'cta.membership.update.payment': UpdatePaymentButton,
      'cta.challenge.detail': ChallengeDetailLink,
      'cta.playlist.detail': PlaylistDetailLink,
      'cta.new.challenge': NewChallengeButton,
      'cta.create.challenge': CreateChallengeButton,
      'cta.start.challenge': StartChallengeButton,
      'cta.avatar.detail': AvatarDetail,
      'cta.marketplace.order.retry': AvatarCheckout,
      'cta.membership.plan.payment.retry': MembershipCheckout,
    };
  }, []);

  return renders;
}
