import { validateAnswer } from '@app/web/src/utils/quiz';
import { createSlice } from '@reduxjs/toolkit';
import { differenceInMilliseconds } from 'date-fns';

import {
  cancelSubmit,
  eliminateAnswer,
  fetchQuestions,
  lazySubmit,
  pauseQuiz,
  resumeQuiz,
  setShortId,
  startAnswer,
  submitAnswer,
  updateAnswer,
  updateQuestionIndex,
  updateQuestionNote,
} from '../../actions/seoQuizActions';

import { QuizState } from './types';

const initialState: QuizState = {
  shortId: '',
  questionNo: 0,
  questionCount: 0,
  isPausing: false,
  mappedAnswers: {},
  mappedResult: {},
  mappedStatus: {},
  mappedQuestions: {},
  mappedNotes: {},
  eliminatedAnswers: {},
  layout: {
    showKeyboard: false,
    isOpenTimer: true,
  },
};

const defaultStatus = {
  isPendingSubmit: false,
  isLoading: false,
  isSubmitted: false,
  isSkipped: false,
  startAt: null,
  timeSpent: null,
};
const getTimeSpent = (startAtMilliseconds: number | null) => {
  if (!startAtMilliseconds) return 0;

  return differenceInMilliseconds(Date.now(), startAtMilliseconds);
};

const quizSlice = createSlice({
  name: 'seo-quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        const { questionMetadata } = action.payload;
        const questions = questionMetadata.metadata?.questions;
        if (!questions) return;

        const map: { [order: number]: string } = {};
        questions.forEach((item, index) => {
          map[index] = item.shortId;
        });
        state.mappedQuestions = map;
        state.questionCount = questions.length;
        state.questionNo = questions.findIndex(
          (item) => item.shortId === state.shortId
        );
      })
      .addCase(setShortId, (state, action) => {
        state.shortId = action.payload;
      })
      .addCase(updateQuestionNote, (state, action) => {
        state.mappedNotes = {
          ...state.mappedNotes,
          [state.shortId]: action.payload,
        };
      })
      .addCase(updateQuestionIndex, (state, action) => {
        state.questionNo = action.payload;
        state.shortId = state.mappedQuestions[action.payload];
        const prevShortId = state.mappedQuestions[action.payload - 1];

        const prevStatus = state.mappedStatus[prevShortId];
        if (prevStatus) {
          state.mappedStatus[prevShortId] = {
            ...prevStatus,
            startAt: null,
            isSkipped: !prevStatus.isSubmitted,
            timeSpent:
              (prevStatus.timeSpent || 0) +
              getTimeSpent(prevStatus.startAt || 0),
          };
        }
      })
      .addCase(startAnswer, (state, action) => {
        const currentStatus =
          state.mappedStatus[action.payload] || defaultStatus;

        if (!state.mappedAnswers[action.payload]) {
          state.mappedAnswers[action.payload] = [];
        }
        if (!currentStatus.isSubmitted) {
          state.mappedStatus[action.payload] = {
            ...currentStatus,
            startAt: Date.now(),
          };
        }

        state.isPausing = false;
      })
      .addCase(updateAnswer, (state, action) => {
        state.mappedAnswers[action.payload.shortId] = action.payload.value;
      })
      .addCase(pauseQuiz, (state) => {
        state.isPausing = true;

        const currentStatus =
          state.mappedStatus[state.shortId] || defaultStatus;

        if (!currentStatus.isSubmitted) {
          state.mappedStatus[state.shortId] = {
            ...currentStatus,
            timeSpent:
              (currentStatus.timeSpent || 0) +
              getTimeSpent(currentStatus.startAt || 0),
            startAt: null,
          };
        }
      })
      .addCase(resumeQuiz, (state) => {
        state.isPausing = false;
        const currentStatus =
          state.mappedStatus[state.shortId] || defaultStatus;
        if (!currentStatus.isSubmitted) {
          state.mappedStatus[state.shortId] = {
            ...currentStatus,
            startAt: Date.now(),
          };
        }
      })
      .addCase(lazySubmit, (state) => {
        const currentStatus =
          state.mappedStatus[state.shortId] || defaultStatus;

        if (!currentStatus.isSubmitted) {
          state.mappedStatus[state.shortId] = {
            ...currentStatus,
            isPendingSubmit: true,
            timeSpent:
              (currentStatus.timeSpent || 0) +
              getTimeSpent(currentStatus.startAt || 0),
            startAt: null,
          };
        }
      })
      .addCase(cancelSubmit, (state) => {
        const currentStatus =
          state.mappedStatus[state.shortId] || defaultStatus;

        if (!currentStatus.isSubmitted) {
          state.mappedStatus[state.shortId] = {
            ...currentStatus,
            isPendingSubmit: false,
            startAt: Date.now(),
          };
        }
      })
      .addCase(submitAnswer, (state, action) => {
        const questionAnswer = action.payload.questionAnswer;
        const currentStatus = state.mappedStatus[state.shortId] || {
          ...defaultStatus,
        };
        const currentAnswer = state.mappedAnswers[state.shortId];
        const isCorrect = validateAnswer(currentAnswer, questionAnswer);

        const timeSpent =
          (currentStatus.timeSpent || 0) +
          getTimeSpent(currentStatus.startAt || 0);
        const isOvertime = timeSpent / 1000 > questionAnswer.tpq;

        state.mappedStatus[state.shortId] = {
          ...currentStatus,
          timeSpent,
          isLoading: false,
          isSubmitted: true,
        };

        state.mappedResult[state.shortId] = {
          isCorrect: isCorrect,
          isOvertime: isOvertime,
          isStreak: false,
          correctAnswerValues: questionAnswer.correctAnswerValues || [],
          correctAnswerIds: questionAnswer.correctAnswerIds,
        };
      })
      .addCase(eliminateAnswer, (state, action) => {
        state.eliminatedAnswers[action.payload.shortId] = action.payload.value;
      });
  },
});

export default quizSlice.reducer;
