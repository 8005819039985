import { isNumber } from './number';

export function currencyFormat(value: string | number) {
  if (!isNumber(value)) {
    return value;
  }

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberFormat(
  value: number | string,
  offset = 10
): number | string {
  if (!isNumber(value)) {
    return value;
  }

  // eslint-disable-next-line no-bitwise
  return ~~(+value * offset) / offset;
}

export function compactNumberFormat(value: number | string): string {
  if (!isNumber(value)) {
    return `${value}`;
  }
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(+value);
}

export function currencyShortenFormat(number: number) {
  if (number < 1000) return number.toString();

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  });

  return formatter.format(number);
}
