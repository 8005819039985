import React, { ReactNode } from 'react';
import {
  alpha,
  Box,
  BoxProps,
  createTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { OtherLightbulb as OtherLightbulbIcon } from '@front/icon';

import base from '../../themes/base';
import exam from '../../themes/exam';

export type LightbulbCardProps = {
  sx?: BoxProps['sx'];
  title?: string;
  children?: ReactNode;
  icon?: ReactNode;
};

const styles = {
  root: {
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    bgcolor: 'background.darker',
    color: 'text.primary',
    borderRadius: 2,
  },
  title: {
    display: 'flex',
    gap: '6px',
  },
  content: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};
export default function LightbulbCard({
  sx,
  title,
  icon,
  children,
}: LightbulbCardProps) {
  const theme = useTheme();
  const updateTheme = theme.palette.mode === 'dark' ? exam : base;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <ThemeProvider theme={createTheme(updateTheme)}>
      <Box sx={[styles.root, ...sxProps]}>
        <Typography
          sx={styles.title}
          variant="body1"
          component="h6"
          fontWeight={700}
        >
          {icon ?? <OtherLightbulbIcon width="24" height="24" />}
          {title}
        </Typography>
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
}
