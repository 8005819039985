import { MouseEvent, TouchEvent, useCallback } from 'react';
import { useAuth } from '@lib/web/apis';

import useBlocker from '../BlockerControl/hooks/useBlocker';

export default function useAuthGuard() {
  const { blockerType } = useAuth();
  const { openBlocker } = useBlocker();

  return useCallback(
    ({
      event,
      target,
      placement,
      showBlocker = true,
    }: {
      event?: MouseEvent | TouchEvent;
      target?: Element;
      showBlocker?: boolean;
      placement?: 'rhs' | 'center' | 'menu';
    } = {}) => {
      if (!blockerType) {
        return true;
      }

      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      if (placement) {
        if (showBlocker) openBlocker(blockerType, placement);
        return false;
      }

      if (showBlocker) {
        const element =
          target ||
          (event?.target instanceof Element ? event.target : undefined);
        if (element?.closest('.base-layout-right-panel')) {
          openBlocker(blockerType, 'rhs');
        } else if (element?.closest('.menu-content-wrap')) {
          openBlocker(blockerType, 'menu');
        } else {
          openBlocker(blockerType, 'center');
        }
      }

      return false;
    },
    [blockerType, openBlocker]
  );
}
