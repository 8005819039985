import { ChangeEvent, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'next-i18next';
import { Box } from '@mui/material';
import {
  EmojiFace as EmojiFaceIcon,
  OtherEdit as OtherEditIcon,
} from '@front/icon';
import {
  IndicatorGroup,
  NameInput,
  ReactionPicker,
  ReactionPickerTrigger,
  ResponsiveTooltip,
} from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useRecentMarks } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { ThreadUser } from '@lib/web/thread/types';
import ThreadEmojiPicker from '@lib/web/thread/ui/ThreadEmojiPicker';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { ResponsivePageTitle } from '@lib/web/ui';

import useThreadViewChangeEmoji from '../../hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '../../hooks/useThreadViewRename';

const styles = {
  indicators: {
    verticalAlign: 'middle',
  },
  reactionIconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pl: 0.25,
  },
};

const MAX_VISIBLE_MEMBER_COUNT = 3;

const EDITABLE_THREAD_VIEW_TYPES = [
  ThreadViewType.DmGroup,
  ThreadViewType.Club,
];

const SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

type GetTooltipContentProps = {
  t: TFunction;
  viewType: ThreadViewType;
};

const getRenameTooltipProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.rename.description',
        'click to rename, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.rename.description',
        'click to rename, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.rename.description', 'click to rename.');

    default:
      return null;
  }
};

const getChangeIconTooltipProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.changeIcon.description',
        'click to change icon, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.changeIcon.description',
        'click to change icon, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.changeIcon.description', 'click to change icon.');

    default:
      return null;
  }
};

type TitleTextProps = {
  viewNameMembers: NonNullable<ThreadUser | null>[] | null;
  viewType: ThreadViewType;
  viewName: string;
  handleChangeViewName: (ev: ChangeEvent<HTMLInputElement>) => void;
  handleEmojiClick: () => void;
  prefix?: ReactNode;
  handleChangeEmoji: (emoji?: GetMarkEmojiRes | null) => void;
  emoji?: GetMarkEmojiRes | null;
};

function TitleEmojiPicker({
  open,
  anchorEl,
  onClose,
  onChange,
  onBlurClose,
  onMoreClick,
  emoji,
}: {
  open: boolean;
  anchorEl?: Element;
  onClose: () => void;
  onChange?: (emoji?: GetMarkEmojiRes | null) => void;
  onBlurClose?: () => void;
  onMoreClick?: (e: MouseEvent) => void;
  clubId?: string;
  emoji?: GetMarkEmojiRes | null;
}) {
  const { t } = useTranslation('thread');
  const { recentMarks, mutateRecentMarksWithNewMark } = useRecentMarks();

  const handleChange = async (ev: GetMarkEmojiRes | null, e: MouseEvent) => {
    e.stopPropagation();
    if (ev) {
      void mutateRecentMarksWithNewMark(ev);
    }
    onChange?.(ev);
    onClose();
  };

  return (
    <ReactionPicker
      anchorEl={anchorEl}
      open={open}
      selected={emoji ? [emoji] : undefined}
      onClose={onClose}
      onChange={handleChange}
      onBlurClose={onBlurClose}
      onMoreClick={onMoreClick}
      recentEmojis={recentMarks}
      customMoreLabel={t('thread.view.changeIcon.label', 'Change Icon')}
    />
  );
}

const TitleText = ({
  viewNameMembers,
  viewType,
  viewName,
  handleChangeViewName,
  handleEmojiClick,
  prefix,
  handleChangeEmoji,
  emoji,
}: TitleTextProps) => {
  const { t } = useTranslation('thread');
  const [reactionMenuOpened, setReactionMenuOpened] = useState(false);
  const [reactionMenuAnchorEl, setReactionMenuAnchorEl] = useState<Element>();
  const [firstItem] = viewNameMembers || [];
  // Format the viewName to display member names or "+x others" when member count exceeds 3
  const formattedViewName = useMemo(() => {
    if (
      viewNameMembers?.length &&
      viewNameMembers.length > MAX_VISIBLE_MEMBER_COUNT
    ) {
      const displayedMembers = viewNameMembers
        .slice(0, MAX_VISIBLE_MEMBER_COUNT)
        .map((m) => m.name);
      const remainingCount = viewNameMembers.length - MAX_VISIBLE_MEMBER_COUNT;
      return `${displayedMembers.join(', ')} +${remainingCount} others`;
    }
    return viewName || 'Untitled';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(viewNameMembers), viewName]);

  const handleHoverReaction = (el?: Element) => {
    setReactionMenuOpened(true);
    setReactionMenuAnchorEl(el);
  };

  const floatingButtons = useMemo(
    () => [
      {
        tooltip: {
          title: t('thread.view.changeIcon.tooltip.title', 'Change Icon'),
          content: getChangeIconTooltipProps({ t, viewType }),
          tooltipProps: {
            slotProps: {
              popper: {
                sx: { zIndex: 9999 },
              },
            },
          },
        },
        component: (
          <ResponsiveTooltip
            title={t('thread.view.changeIcon.tooltip.title', 'Change Icon')}
            content={getChangeIconTooltipProps({ t, viewType })}
            tooltipProps={{
              followCursor: true,
              disableInteractive: true,
              slotProps: {
                popper: {
                  sx: { zIndex: 9999 }, // above the reaction popover
                },
              },
            }}
          >
            <Box sx={styles.reactionIconButton}>
              <ReactionPickerTrigger
                value={undefined}
                onChangeReaction={() => {}}
                onHover={handleHoverReaction}
                onPress={handleHoverReaction}
              >
                <EmojiFaceIcon />
              </ReactionPickerTrigger>
            </Box>
          </ResponsiveTooltip>
        ),
      },
      {
        icon: <OtherEditIcon />,
        tooltip: {
          content: t('thread.view.rename.tooltip.title', 'Rename thread title'),
        },
      },
    ],
    [t, viewType]
  );

  if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
    return (
      <>
        <NameInput
          value={formattedViewName}
          onChange={(value) =>
            handleChangeViewName({
              target: { value },
            } as ChangeEvent<HTMLInputElement>)
          }
          prefix={prefix}
          customFloatingButtons={floatingButtons}
          tooltipTitle={viewName}
          tooltipContent={getRenameTooltipProps({ t, viewType })}
          keepHovered={reactionMenuOpened}
        />
        <TitleEmojiPicker
          open={reactionMenuOpened}
          anchorEl={reactionMenuAnchorEl}
          onClose={() => setReactionMenuOpened(false)}
          onBlurClose={() => setReactionMenuOpened(false)}
          onMoreClick={(e) => {
            e.stopPropagation();
            handleEmojiClick();
            setReactionMenuOpened(false);
          }}
          onChange={(ev) => {
            handleChangeEmoji(ev);
          }}
          emoji={emoji}
        />
      </>
    );
  }

  return (
    <>
      {prefix}
      {viewName}{' '}
      {SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES.includes(viewType) &&
        firstItem?.indicators && (
          <IndicatorGroup
            sx={styles.indicators}
            indicators={firstItem.indicators}
          />
        )}
    </>
  );
};

type ThreadTitleProps = {
  view: GetThreadViewRes;
  clubId?: string;
  subtitleText?: ReactNode;
};

const ThreadViewTitle = ({ view, clubId, subtitleText }: ThreadTitleProps) => {
  const { viewIcon, viewNameMembers, viewType } = useThreadViewDetail(view);

  const { emoji, handleOpenEmojiPanel, handleChangeEmoji } =
    useThreadViewChangeEmoji({
      view,
      clubId,
    });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
    clubId,
  });

  const onEmojiChanged = (e?: GetMarkEmojiRes | null) => {
    handleChangeEmoji({ changedEmoji: e });
  };

  const handleIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
      handleOpenEmojiPanel();
    }
  };

  const icon = (
    <>
      {viewIcon?.type === 'emoji' ? (
        <ThreadEmojiPicker emoji={emoji} onEmojiClick={handleIconClick} />
      ) : (
        <ThreadViewIcon view={view} size="md" onClick={handleIconClick} />
      )}
    </>
  );

  return (
    <ResponsivePageTitle
      titleText={
        <TitleText
          viewNameMembers={viewNameMembers}
          viewType={viewType as ThreadViewType}
          viewName={viewName}
          handleChangeViewName={handleChangeViewName}
          handleEmojiClick={handleOpenEmojiPanel}
          handleChangeEmoji={onEmojiChanged}
          prefix={icon}
          emoji={emoji}
        />
      }
      subtitleText={subtitleText}
    />
  );
};

export default ThreadViewTitle;
