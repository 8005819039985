import React, { ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps } from '@mui/material';
import CopyLinkButton from '@app/web/src/components/CopyLinkButton';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { useAuth, useSearchMemberList } from '@lib/web/apis';
import { useSearchStatus } from '@lib/web/hooks';
import { emailRegex, getIndicators } from '@lib/web/utils';

import useOpenGlobalProfilePanel from '../../CommonPanels/hooks/useOpenGlobalProfilePanel';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    pb: 2.5,
  },
  main: {
    position: 'relative',
    flex: 1,
  },
  subContent: {
    px: { xs: 2.5, sm: '12px' },
    pb: 1,
  },
  ahaUser: {
    opacity: 0.5,
  },
  action: {
    display: {
      xs: 'grid',
      md: 'block',
    },
    textAlign: { md: 'right' },
    svg: {
      path: {
        fill: 'url(#primary-gradient-linear-vertical)',
        stroke: 'url(#primary-gradient-linear-vertical)',
      },
    },
  },
};

type InviteNewUsersProps = {
  onSentSuccess?: () => void;
  successMsg?: string;
  sendingMsg?: string;
  lightbulbText?: string;
  lightbulbIcon?: ReactNode;
  lightbulbSx?: BoxProps['sx'];
  panelTitle: string;
  panelTitleIcon: ReactNode;
  searchPlaceholder?: string;
  searchIcon?: ReactNode;
};
export default function InviteNewUsers({
  onSentSuccess,
  successMsg,
  sendingMsg,
  lightbulbSx,
  lightbulbIcon,
  lightbulbText,
  panelTitle,
  panelTitleIcon,
  searchPlaceholder,
  searchIcon,
}: InviteNewUsersProps) {
  const { t } = useTranslation('profile');
  const searchInputRef = useRef<HTMLInputElement>();
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const { invitedHistory, inviteToAha, newUserFormatter } = useAhaInvitation();
  const { member } = useAuth();

  const link = member
    ? `${window.location.host || ''}/signup?refer=${member.distinctName}`
    : '';

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const userSearchData = useSearchMemberList(
    isSearching ? debouncedSearch : ''
  );

  const { dataset: searchDataset, isLoading: searchLoading } = userSearchData;

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.find((d) => d.email === debouncedSearch) &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';

    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset,
    searchLoading,
  ]);

  const displayDataset: IconListLayoutItemObj[] = useMemo(() => {
    if (newUser) {
      return [
        {
          id: newUser.email,
          title: newUser.email,
          avatarBlackAndWhite: true,
          actionMap: {
            click: {
              actionType: 'textButton',
              type: 'event',
              value: 'invite',
              text: t('button.Invite', 'Invite'),
              icon: 'ActionInviteToClub',
              disabled:
                newUser.isAccepted ||
                (newUser.isPending && !newUser.resendAble),
              sxProps: {
                svg: {
                  path: { fill: 'url(#primary-gradient-linear-horizontal)' },
                },
              },
            },
          },
        },
      ];
    }

    return searchDataset.map((item) => ({
      id: item.userId,
      title: item.displayName,
      titleAction: { type: 'event', value: 'titleClick' },
      indicators: getIndicators(item.indicator),
      description: 'Aha Member',
      avatarUrl: item.nftAvatar || item.avatar,
      actionMap: {
        select: {
          value: 'selectChanged',
        },
        hover: {
          value: 'userHovered',
        },
      },
      metadata: {
        userId: item.userId,
      },
    }));
  }, [newUser, searchDataset, t]);

  const config: IaLayoutConfig[] = useMemo(() => {
    if (!isSearching) return [];
    if (isSearching && !searchLoading && displayDataset.length === 0) {
      return [
        {
          layout: 'empty-layout',
          settings: {
            sx: {
              px: { xs: 2.5, md: '12px' },
            },
          },
          text: t(
            'invite.friends.RHS.empty',
            '<b>No results.</b><br/>Please try a different search.'
          ),
        },
      ];
    }
    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('Results', { count: displayDataset.length }),
          ...displayDataset,
        ],
      },
    ];
  }, [isSearching, searchLoading, displayDataset, t]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset.filter((d) => !d.metadata?.userId)
  );

  const inviteNewUser = async (email: string) => {
    await inviteToAha([email], {
      toastOptions: {
        anchorEl: document.querySelector(
          '[data-testid="invite-users"]'
        ) as Element,
        successMsg,
        loadingMsg:
          sendingMsg ?? t('Sending invitation', 'Sending invitation...'),
      },
    });
    onSentSuccess?.();
  };

  const availableActions = {
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
    invite: {
      action: (value: IconListLayoutItemObj) => {
        inviteNewUser(value.id); // The new user's id to use for email
      },
    },
  };
  const getItemStatus = ({
    id,
    metadata: { userId } = {},
  }: IconListLayoutItemObj) => {
    return {
      disabled:
        !!userId || invitedHistory.some((item) => item.receiver.email === id),
      focused: suggestItem?.id === id,
    };
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      !!suggestItem &&
      getItemStatus(suggestItem).disabled === false
    ) {
      inviteNewUser(suggestItem.id);
    }
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          title={panelTitle}
          titleIcon={panelTitleIcon}
          actionComponent={
            <Box sx={styles.action} data-testid="invite-users">
              <CopyLinkButton
                data-testid="copy-link"
                loading={!member}
                link={t(
                  'invite.friends.copy.content',
                  'Hey, check this out. I use Aha for exam prep, and it’s pretty cool. Try it out: {{link}}',
                  {
                    link,
                  }
                )}
                variant="outlined"
                successText={t('invite.friends.copied', 'Link Copied')}
              >
                {t('invite.friends.cta', 'Share Link')}
              </CopyLinkButton>
            </Box>
          }
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={searchPlaceholder || t('Type to Search')}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
                searchIcon={searchIcon}
              />
              {!isSearching && (
                <LightbulbCard sx={lightbulbSx} icon={lightbulbIcon}>
                  {lightbulbText ??
                    t('You can invite others to join Aha by email')}
                </LightbulbCard>
              )}
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea>
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
