import { Typography } from '@mui/material';

import { currencyShortenFormat } from '../../utils';
import NumberCard, { NumberCardTooltipProps } from '../NumberCard';
import Token from '../Token';

import CircleIndicator from './CircleIndicator';

export type CurrencyCircleProps = {
  value: number;
  label: string;
  tooltip?: NumberCardTooltipProps;
};

const styles = {
  value: {
    lineHeight: '45px',
    letterSpacing: -2.5,
    display: 'flex',
    alignItems: 'center',
    svg: {
      transform: 'unset',
    },
    '&.MuiTypography-tokenH3': {
      lineHeight: '60px',
    },
  },
};

function getVariant(valueLength: number) {
  if (valueLength < 4) return 'tokenH3';
  if (valueLength < 6) return 'tokenH5';
  return 'tokenH6';
}

export default function CurrencyCircle({
  value,
  label,
  tooltip,
}: CurrencyCircleProps) {
  const displayValue = currencyShortenFormat(value);
  const variant = getVariant(displayValue.length);

  return (
    <CircleIndicator tooltip={!!tooltip && <NumberCard.Tooltip {...tooltip} />}>
      <Token sx={styles.value} variant={variant}>
        {displayValue}
      </Token>
      <Typography variant="caption">{label}</Typography>
    </CircleIndicator>
  );
}
