import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box } from '@mui/material';
import { Icon, NumberCard } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';
import { getLeagueBadgeIconName } from '@lib/web/utils';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  leagueBadge: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function LeagueCard({
  leagueName,
  clubSlug: clubSlugProp,
}: {
  leagueName:
    | 'Alpha'
    | 'Centurion'
    | 'Commandus'
    | 'Cosmus'
    | 'Incendium'
    | 'Legatus'
    | 'Maximus'
    | 'Nocturnus'
    | 'Prima'
    | 'Spectus'
    | 'Locked';
  clubSlug?: string;
}) {
  const { t } = useTranslation('notification');

  const routerClubSlug = useClubSlug();
  const clubSlug = clubSlugProp || routerClubSlug;

  return (
    <NumberCard
      width="100%"
      maxWidth={256}
      minHeight={160}
      title={t('Current League')}
      titleIcon="TestTrophy"
      sx={styles.card}
      onClick={() => Router.push(`/club/${clubSlug}/rankings`)}
      actionIcon="ActionArrowRightUp"
    >
      <NumberCard.Tooltip
        title={t('cta.league.current.tooltip.Current League')}
        titleIcon="TestTrophy"
        content={t(
          'cta.league.current.tooltip.Your current league in the club'
        )}
      />
      <NumberCard.Value>
        <Box sx={styles.leagueBadge}>
          <Icon name={getLeagueBadgeIconName(leagueName)} size={64} />
        </Box>{' '}
      </NumberCard.Value>
      <NumberCard.Description>
        {t('## League', { name: leagueName })}
      </NumberCard.Description>
    </NumberCard>
  );
}
