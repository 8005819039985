import React from 'react';
import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { OtherAhaPoints as OtherAhaPointsIcon } from '@front/icon';

const variantMapping = {
  tokenH1: 'h1',
  tokenH2: 'h2',
  tokenH3: 'h3',
  tokenH4: 'h4',
  tokenH5: 'h5',
  tokenH6: 'h6',
  tokenSubtitle: 'h6',
  tokenBody1: 'p',
  tokenBody2: 'p',
};

const iconSizeMap = {
  tokenH1: 44,
  tokenH2: 24,
  tokenH3: 20,
  tokenH4: 26,
  tokenH5: 24,
  tokenH6: 20,
  tokenSubtitle: 16,
  tokenBody1: 16,
  tokenBody2: 12,
  tokenCaption: 10,
};

const styles = {
  tokenH1: {
    svg: {
      transform: 'translateY(5px)',
    },
  },
  tokenH2: {
    svg: {
      transform: 'translateY(3px)',
    },
  },
  tokenH3: {
    svg: {
      transform: 'translateY(2.8px)',
    },
  },
  tokenH4: {
    svg: {
      transform: 'translateY(2.7px)',
    },
  },
  tokenH5: {
    svg: {
      transform: 'translateY(2.6px)',
    },
  },
  tokenH6: {
    svg: {
      transform: 'translateY(2.5px)',
    },
  },
  tokenSubtitle: {
    svg: {
      transform: 'translateY(2.3px)',
    },
  },
  tokenBody1: {
    svg: {
      transform: 'translateY(2.2px)',
    },
  },
  tokenBody2: {
    svg: {
      transform: 'translateY(2px)',
    },
  },
  tokenCaption: {
    svg: {
      transform: 'translateY(1.5px)',
    },
  },
};
export type TokenProps<
  D extends React.ElementType = TypographyTypeMap['defaultComponent'],
  P = {
    variant?:
      | 'tokenH1'
      | 'tokenH2'
      | 'tokenH3'
      | 'tokenH4'
      | 'tokenH5'
      | 'tokenH6'
      | 'tokenSubtitle'
      | 'tokenBody1'
      | 'tokenBody2'
      | 'tokenCaption';
  }
> = TypographyProps<D, P>;

function TokenInner<D extends React.ElementType>(
  { sx, variant = 'tokenBody1', children, ...rest }: TokenProps<D>,
  ref: React.ForwardedRef<HTMLSpanElement>
) {
  const iconSize = iconSizeMap[variant as keyof typeof iconSizeMap] || 16;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Typography
      ref={ref}
      sx={[styles[variant], ...sxProps]}
      variant={variant}
      variantMapping={variantMapping}
      {...rest}
    >
      <OtherAhaPointsIcon width={iconSize} height={iconSize} />
      {children}
    </Typography>
  );
}

const Token = React.forwardRef(TokenInner) as <
  D extends React.ElementType = 'span'
>(
  props: TypographyProps<D>
) => ReturnType<typeof TokenInner<D>>;

export default Token;
