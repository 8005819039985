import useCloudfront from './useCloudfront';

export default function useLandingPageLink() {
  const { country } = useCloudfront();

  if (country === 'IN') {
    return 'https://in.earnaha.com/';
  }

  return 'https://www.earnaha.com/';
}
