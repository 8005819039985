import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import getPathnameSettings from '../../utils/getPathnameSettings';

export default function usePathnameSettings(virtualRouter?: VirtualRouter) {
  const nextRouter = useRouter();
  const router = virtualRouter || nextRouter;
  const { t } = useTranslation();

  return getPathnameSettings(t, router);
}
