import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutPanelKeys,
  CheckoutPanelParams,
} from '@app/web/src/types/panel';
import { MyPlanCheckoutProvider } from '@app/web/src/widgets/MyPlanPage/contexts/checkoutContext';
import usePlanConfigs from '@app/web/src/widgets/MyPlanPage/hooks/usePlanConfigs';
import useSubscriptionCheckout from '@app/web/src/widgets/MyPlanPage/hooks/useSubscriptionCheckout';
import SubscriptionCheckoutRightPanel from '@app/web/src/widgets/MyPlanPage/SubscriptionCheckoutRightPanel';
import { OtherResend as OtherResendIcon } from '@front/icon';
import { Button, useBaseLayout } from '@front/ui';

const styles = {
  alignRight: {
    ml: 'auto',
  },
};

type MembershipCheckoutProps = {
  cta: Extract<
    NotificationCtaType,
    { type: 'cta.membership.plan.payment.retry' }
  >;
};

function MembershipCheckoutRoot({ cta }: MembershipCheckoutProps) {
  const { t } = useTranslation('notification');
  const { setActivePlan } = useSubscriptionCheckout();
  const { getPlan } = usePlanConfigs();
  const { openRightPanel } = useBaseLayout<CheckoutPanelParams>();

  const plan = getPlan(cta.subscriptionPlanId);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!plan) return;
    setActivePlan(plan, 'subscribe');
    setTimeout(() => {
      openRightPanel(CheckoutPanelKeys.Checkout);
    });
  };

  return (
    <Button
      prefixIcon={<OtherResendIcon />}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      sx={styles.alignRight}
      loading={!plan}
    >
      {t('cta.marketplace.order.retry.button.Retry', 'Retry')}
    </Button>
  );
}

export default function MembershipCheckout({ cta }: MembershipCheckoutProps) {
  return (
    <MyPlanCheckoutProvider>
      <MembershipCheckoutRoot cta={cta} />
      <SubscriptionCheckoutRightPanel />
    </MyPlanCheckoutProvider>
  );
}
