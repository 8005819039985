import { useMemo } from 'react';
import { IaPlaylistQuestionData } from '@app/web/src/ia/playlistQuestion';
import { IaPlaylistQuestionDataItem } from '@app/web/src/ia/playlistQuestion/types';
import { ExamMode, useAuth } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { SharedDropdown } from '@lib/web/ui';

type ShareQuestionDropdownProps = {
  anchorEl?: HTMLElement;
  playlistQuestionData?: IaPlaylistQuestionData;
  question?: IaPlaylistQuestionDataItem;
  onClose: () => void;
};

export default function ShareQuestionDropdown({
  anchorEl,
  playlistQuestionData,
  question,
  onClose,
}: ShareQuestionDropdownProps) {
  const { member } = useAuth();
  const clubSlug = useClubSlug();

  const { playlistPath } = playlistQuestionData || {};
  const { latestRoundNo, mode, id, shortId } =
    playlistQuestionData?.data?.quiz || {};
  const modePath = mode === ExamMode.MockExam ? 'exam' : 'practice';
  const quizId = shortId || id;
  const { questionNumber } = question || {};
  const refer = member ? `?refer=${member.distinctName}` : '';

  const virtualRouter = useMemo(() => {
    if (!quizId) return undefined;
    return {
      pathname: `/club/[slug]/${modePath}/[quizId]/[roundNo]`,
      query: {
        slug: clubSlug as string,
        quizId: quizId as string,
        roundNo: `${latestRoundNo}`,
      },
      asPath: `${playlistPath}${refer}#${questionNumber}`,
    };
  }, [
    clubSlug,
    modePath,
    quizId,
    latestRoundNo,
    playlistPath,
    refer,
    questionNumber,
  ]);

  if (
    !anchorEl ||
    !playlistQuestionData ||
    !playlistQuestionData.data ||
    !question
  ) {
    return null;
  }

  return (
    <SharedDropdown
      open
      menuAnchorEl={anchorEl}
      onClose={onClose}
      customTitle="Question"
      virtualRouter={virtualRouter}
    />
  );
}
