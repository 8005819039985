import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
export interface MarketplaceValue {
  selected: GetSanityProductRes | null;
  favoriteIds: string[] | null;
  myProductIds: string[] | null;
  filterByType: boolean;
  autoFavorite: boolean;
}

const initialValue = {
  selected: null,
  favoriteIds: null,
  myProductIds: null,
  filterByType: false,
  autoFavorite: false,
};

type MarketplaceContextValue = [
  MarketplaceValue,
  Dispatch<SetStateAction<MarketplaceValue>>
];

type MarketplaceProviderProps = {
  children: ReactNode;
};

export const MarketplaceContext = createContext<MarketplaceContextValue>([
  initialValue,
  () => {},
]);

export function MarketplaceProvider({ children }: MarketplaceProviderProps) {
  const providerValue = useState<MarketplaceValue>(initialValue);

  return (
    <MarketplaceContext.Provider value={providerValue}>
      {children}
    </MarketplaceContext.Provider>
  );
}

export const MarketplaceConsumer = MarketplaceContext.Consumer;
