import Practice, {
  PracticeQuestionContent,
  PracticeQuestionFooter,
  PracticeSkeleton,
} from './Practice';
import PracticeContextProvider from './PracticeContextProvider';

export {
  PracticeContextProvider,
  PracticeQuestionContent,
  PracticeQuestionFooter,
  PracticeSkeleton,
};

export { default as PracticeMultipleChoices } from './components/QuestionContent/components/QuizAnswerOptions/PracticeMultipleChoices';
export { default as usePracticeFlowEvent } from './hooks/usePracticeFlowEvent';
export { default as usePracticeQuestion } from './hooks/usePracticeQuestion';
export { default as usePracticeQuestionResult } from './hooks/usePracticeQuestionResult';
export { default as usePracticeQuestionStatus } from './hooks/usePracticeQuestionStatus';
export { BLANK_OPTION_VALUE } from './types';

export default Practice;
