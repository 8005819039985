import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Typography } from '@mui/material';
import useMenuClub from '@app/web/src/hooks/utils/useMenuClub';
import {
  buildInfiniteHookMutate,
  FollowingFilterType,
  useSearchClubMembers,
} from '@lib/web/apis';

import SuggestionItem, { SuggestionItemProps } from './SuggestionItem';

const styles = {
  title: {
    opacity: 0.5,
    px: { xs: 2.5, md: '12px' },
  },
};

export default function SuggestionList({
  sx,
  messageDisabled,
}: {
  sx?: BoxProps['sx'];
  messageDisabled?: boolean;
}) {
  const { t } = useTranslation('club');
  const { clubSlug } = useMenuClub();
  const data = useSearchClubMembers(
    {
      clubSlug,
      followingFilterType: FollowingFilterType.UnfollowingOnly,
      isExcludeMe: true,
      canBeSearchedByEmptyKeyword: true,
    },
    20
  );
  const reloadSuggestionData = useMemo(
    () => buildInfiniteHookMutate(data.mutate),
    [data.mutate]
  );

  const ctaCol = '16px min-content';
  const gridTemplateColumns = `1fr ${ctaCol}`;

  const dataItems: Omit<
    SuggestionItemProps,
    'messageDisabled' | 'onToggleFollow'
  >[] = data.dataset.map((item) => {
    return {
      userInfo: {
        userId: item.userId,
        memberId: item.memberId,
        avatarUrl: item.nftAvatar || item.avatar,
        displayName: item.displayName,
        distinctName: item.userName,
        indicator: item.indicator,
        isFollower: item.isFollower,
        isFollowing: item.isFollowing,
      },
      settings: {
        gridTemplateColumns,
      },
    };
  });

  const handleToggleFollow = (targetId: string, promiseData: Promise<any>) => {
    reloadSuggestionData(promiseData, {
      optimisticData: data.dataset.map((d) =>
        d.userId === targetId ? { ...d, isFollowing: true } : d
      ) as any,
      revalidate: false,
    });
  };

  if (data.dataset.length === 0) return null;

  return (
    <Box sx={sx}>
      <Typography sx={styles.title} variant="caption">
        {t('suggestion.subtitle', 'Suggested for you')}
      </Typography>
      {dataItems.map((item, index) => (
        <SuggestionItem
          key={index}
          {...item}
          onToggleFollow={handleToggleFollow}
          messageDisabled={messageDisabled}
        />
      ))}
    </Box>
  );
}
