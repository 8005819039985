import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useAuth } from '@lib/web/apis';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
  },
};

export type CurrentUserInfoListProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.follower.profile' }>;
  tag: GetNotificationRes['tag'];
};

export default function FollowerProfileTable({
  cta,
  tag,
}: CurrentUserInfoListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);
  const { openProfile } = useOpenGlobalProfilePanel();
  const { userId } = useAuth();

  const hideName = tag === 'follow.relation.requester.addressee.global.v2';

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 1fr';
    const columnOrder = ['key', 'value'];
    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          rows: [
            ...(!hideName
              ? [
                  {
                    id: 'name',
                    cells: {
                      key: {
                        type: 'iconText',
                        icon: {
                          type: 'icon',
                          value: 'MainProfileSolid',
                        },
                        text: t('user.info.table.Name'),
                      },
                      value: {
                        type: 'avatarText',
                        text: cta.name,
                        avatar: cta.avatar,
                      },
                    },
                    clickAction: {
                      type: 'event',
                      value: 'openProfile',
                    },
                  },
                ]
              : []),
            {
              id: 'dreamSchool',
              cells: {
                key: {
                  type: 'iconText',
                  icon: {
                    type: 'icon',
                    value: 'ProfileDreamCollege',
                  },
                  text: t('user.info.table.Dream School'),
                },
                value: {
                  type: 'avatarText',
                  text: cta.dreamSchoolName,
                },
              },
              clickAction: {
                type: 'event',
                value: 'openProfile',
              },
            },
            ...(!collapse
              ? [
                  {
                    id: 'location',
                    cells: {
                      key: {
                        type: 'iconText',
                        icon: {
                          type: 'icon',
                          value: 'OtherLocation',
                        },
                        text: t('user.info.table.Location'),
                      },
                      value: {
                        type: 'text',
                        text:
                          cta.city && cta.country
                            ? `${cta.city}, ${cta.country}`
                            : '',
                      },
                    },
                    clickAction: {
                      type: 'event',
                      value: 'openProfile',
                    },
                  },
                  {
                    id: 'highSchool',
                    cells: {
                      key: {
                        type: 'iconText',
                        icon: {
                          type: 'icon',
                          value: 'ProfileTutor',
                        },
                        text: t('user.info.table.High School'),
                      },
                      value: {
                        type: 'text',
                        text: cta.highSchool,
                      },
                    },
                    clickAction: {
                      type: 'event',
                      value: 'openProfile',
                    },
                  },
                ]
              : []),
          ],
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'textButton',
                action: 'toggleCollapse',
                text: collapse
                  ? t('user.info.table.seeMore', 'See more')
                  : t('user.info.table.hide', 'Hide'),
                suffixIcon: collapse
                  ? 'ActionChevronRightSmall'
                  : 'ActionChevronUp',
                sx: { ml: 0 },
                containerSx: { pt: 1 },
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [
    collapse,
    cta.avatar,
    cta.city,
    cta.country,
    cta.dreamSchoolName,
    cta.highSchool,
    cta.name,
    hideName,
    t,
  ]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
      showProfile: {
        action: () => {
          if (!userId) return;
          openProfile(userId);
        },
      },
    }),
    [openProfile, userId]
  );

  return (
    <Box sx={styles.root}>
      <IaActionContextProvider availableActions={availableActions}>
        <IaLayouts layouts={config} />
      </IaActionContextProvider>
    </Box>
  );
}
