import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAllJoinedClubs from '@app/web/src/hooks/utils/useAllJoinedClubs';
import useLandingPageLink from '@app/web/src/hooks/utils/useLandingPageLink';
import {
  MainBrowse as MainBrowseIcon,
  MainNotificationOn as MainNotificationOnIcon,
  OtherShoppingCart as OtherShoppingCartIcon,
  ProfileClubAgent as ProfileClubAgentIcon,
  ThreadsDirectMessages as ThreadsDirectMessagesIcon,
} from '@front/icon';
import {
  Logo,
  Scrollbar,
  useBaseLayout,
  useBaseLeftPanel,
  useBaseRightPanel,
} from '@front/ui';
import { useAuth, useUnreadNotificationClubs } from '@lib/web/apis';
import { useNotifications } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

import { MenuVariant } from '../context';
import useCurrentMenu from '../hooks/useCurrentMenu';
import useMainLayout from '../hooks/useMainLayout';
import useMenuVariant from '../hooks/useMenuVariant';

import NavButton from './NavButtons/NavButton';
import ClubNav from './ClubNav';
import NavActions from './NavActions';

const styles = {
  nav: {
    height: '100%',
    position: 'relative',
    bgcolor: '#151515',
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-content-wrapper, & .simplebar-content': {
      height: '100% !important',
    },
  },
  contentWrapper: {
    width: '100%',
    position: 'relative',
  },
  content: {
    pt: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  logo: {
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
  discoverButton: {
    '& .menu-icon:not(.active)': {
      background: 'transparent',
    },
  },
};

function NotLoggedNav() {
  const { t } = useTranslation();

  const { menuTarget, seeMenu } = useMainLayout();
  const menuVariant = useMenuVariant();
  const currentMenu = menuTarget || menuVariant;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const landingPageLink = useLandingPageLink();
  const handleSeeMenu = (target: MenuVariant, href: string) => {
    if (mdUp && href && menuVariant !== target) Router.push(href);
    seeMenu(target);
  };

  return (
    <Box sx={styles.nav}>
      <Scrollbar sx={styles.scroll}>
        <Box sx={styles.content}>
          <NavButton onClick={() => Router.push(landingPageLink)} tooltip="Aha">
            <Logo />
          </NavButton>
          <NavButton
            onClick={() =>
              handleSeeMenu('marketplace', '/marketplace/available')
            }
            active={currentMenu === 'marketplace'}
            tooltip={t('menu.Avatar Marketplace')}
          >
            <OtherShoppingCartIcon />
          </NavButton>
        </Box>
      </Scrollbar>
    </Box>
  );
}

function NotificationButton() {
  const { t } = useTranslation();
  const { rightPanelOpened, closeRightPanel } = useBaseRightPanel();
  const { enableLeftPanel, openLeftPanel, closeLeftPanel } = useBaseLeftPanel();
  const { seeNotifications, clearTemporaryMenu, temporaryMenuTarget } =
    useMainLayout();

  const { globalNotificationCount } = useNotifications();
  const { data } = useUnreadNotificationClubs();
  const hasNewClubNotification = data?.data.some(
    (club) => club.hasNewNotification
  );

  const handleClick = () => {
    if (temporaryMenuTarget === 'notifications') {
      clearTemporaryMenu();
      closeLeftPanel();
      return;
    }
    if (rightPanelOpened) closeRightPanel();
    seeNotifications();
    enableLeftPanel();
    openLeftPanel();
  };

  return (
    <NavButton
      onClick={handleClick}
      active={temporaryMenuTarget === 'notifications'}
      tooltip={t('menu.Notifications')}
      hasNotification={globalNotificationCount > 0 || hasNewClubNotification}
    >
      <MainNotificationOnIcon />
    </NavButton>
  );
}

function DiscoverButton() {
  const { t } = useTranslation();
  const { seeMenu, clearMenu, temporaryMenuTarget } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { currentMenu } = useCurrentMenu();

  const handleClick = () => {
    if (mdUp) {
      void Router.push('/');
    }
    clearMenu();
    seeMenu('discover');
  };

  return (
    <NavButton
      onClick={handleClick}
      active={currentMenu === 'discover' && !temporaryMenuTarget}
      tooltip={t('menu.Discover')}
      sx={styles.discoverButton}
    >
      <MainBrowseIcon />
    </NavButton>
  );
}

function DirectMessageButton() {
  const { t } = useTranslation();
  const { seeMenu, clearMenu, temporaryMenuTarget } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { currentMenu } = useCurrentMenu();
  const { unreadChannels } = useThread();

  const handleClick = () => {
    if (mdUp) {
      void Router.push('/direct-messages/view/everyone');
    }
    clearMenu();
    seeMenu('directMessages');
  };

  return (
    <NavButton
      onClick={handleClick}
      active={currentMenu === 'directMessages' && !temporaryMenuTarget}
      tooltip={{
        title: t('menu.chat.tooltip.title', 'Chat'),
        content: t(
          'menu.chat.tooltip.content',
          'Start exploring discussions, hot questions, share your ideas, and send direct messages to friends, all in one place with Chat.'
        ),
        icon: 'ThreadsDirectMessages',
      }}
      hasNotification={unreadChannels.length > 0}
    >
      <ThreadsDirectMessagesIcon />
    </NavButton>
  );
}

function AgentsButton() {
  const { t } = useTranslation();
  const { seeMenu, clearMenu, temporaryMenuTarget } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { currentMenu } = useCurrentMenu();

  const handleClick = () => {
    if (mdUp) {
      void Router.push('/agents');
    }
    clearMenu();
    seeMenu('agents');
  };

  return (
    <NavButton
      onClick={handleClick}
      active={currentMenu === 'agents' && !temporaryMenuTarget}
      tooltip={t('menu.AI')}
    >
      <ProfileClubAgentIcon />
    </NavButton>
  );
}

export default function Nav() {
  const { member } = useAuth();
  const { mainNavOpened } = useBaseLayout();
  const { joinedSlugs } = useAllJoinedClubs();
  const { openLeftPanel } = useBaseLeftPanel();
  const { seeNotifications } = useMainLayout();
  const shouldOpenNotification = Router.query.notification === 'true';

  useEffect(() => {
    if (shouldOpenNotification) {
      openLeftPanel();
      setTimeout(() => {
        seeNotifications();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenNotification]);

  // XXX: it seems redundant, but without this,
  // SimpleTooltip inside NavButton will raise error: "MUI: The `anchorEl` prop provided to the component is invalid."
  // cause tooltip flash
  if (!mainNavOpened) return null;

  if (!member) return <NotLoggedNav />;

  return (
    <Box sx={styles.nav}>
      <Box sx={styles.scrollWrapper}>
        <Scrollbar sx={styles.scroll}>
          <Box sx={styles.content}>
            {!!joinedSlugs.length && <ClubNav />}
            <DiscoverButton />
            <AgentsButton />
            <DirectMessageButton />
            <NotificationButton />
          </Box>
        </Scrollbar>
      </Box>
      <NavActions />
    </Box>
  );
}
