import GenericOnboarding from '@app/web/src/components/GenericOnboarding';

const stepConfig = {
  1: {
    selector: () => {
      const elements = document.querySelectorAll<HTMLElement>(
        '[data-testid="discount-code-receiver"]'
      );
      return elements[elements.length - 1];
    },
    xOffset: ({ rect }: { rect: DOMRect }) => rect.width / 2,
    yOffset: ({ rect }: { rect: DOMRect }) => rect.height / 2,
    arrowOffsetY: -104,
    arrowDirection: 'left-bottom' as const,
    dialogAlign: 'bottom' as const,
  },
};

export default function DiscountCodePinChats() {
  return (
    <GenericOnboarding
      target="direct-messages/discount-code"
      stepConfig={stepConfig}
    />
  );
}
