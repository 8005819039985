import { ReactNode } from 'react';
import AuthGuard from '@app/web/src/components/AuthGuard';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import {
  Button,
  ButtonProps,
  DropdownButton,
  DropdownButtonOption,
} from '@front/ui';

const styles = {
  button: {
    minWidth: 120,
    flex: 1,
    px: 'unset',
    borderColor: 'transparent',
  },
};

type BaseRedoButtonProps = {
  dropdownOptions?: DropdownButtonOption[];
  loading?: boolean;
  label?: string;
  color?: 'success' | 'default';
  onClick?: () => void;
  sx?: ButtonProps['sx'];
  tooltip?: {
    icon: string;
    title: string;
    content: ReactNode;
  };
};

export default function BaseRedoButton({
  dropdownOptions = [],
  loading,
  label,
  color = 'default',
  onClick,
  sx,
  tooltip,
}: BaseRedoButtonProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const handleClick = () => {
    if (dropdownOptions.length === 0) {
      onClick?.();
    }
  };

  return (
    <AuthGuard>
      <DropdownButton
        options={dropdownOptions}
        tooltip={tooltip}
        dropdownProps={{ sx: { width: 185 } }}
      >
        <Button
          variant="filled"
          prefixIcon={<OtherPlayIcon width={16} height={16} />}
          onClick={handleClick}
          color={color}
          sx={[styles.button, ...sxProps]}
          loading={loading}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {label}
        </Button>
      </DropdownButton>
    </AuthGuard>
  );
}
