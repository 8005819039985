import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ThreadSearchUsers, {
  ThreadSearchUserIconListLayoutItemObj,
} from '@app/web/src/components/ThreadSearchUsers';
import { useAuth } from '@lib/web/apis';

const styles = {
  root: {
    minHeight: 'calc(100vh - 48px)',
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  searchBar: {
    px: { xs: '16px', md: '12px' },
  },
  emptyTip: {
    px: { xs: '16px', md: '12px' },
  },
  emptyContent: {
    px: { xs: '16px', md: '12px' },
    pt: 1,
  },
};

const SELECTED_LIMIT = 9;

type DmNewThreadLeftPanelProps = {
  oncMembersChanged: (ids: string[]) => void;
};

export default function DmNewThreadLeftPanel({
  oncMembersChanged,
}: DmNewThreadLeftPanelProps) {
  const { t } = useTranslation('thread');
  const [selected, setSelected] = useState<
    ThreadSearchUserIconListLayoutItemObj[]
  >([]);
  const { member } = useAuth();

  useEffect(() => {
    if (!member) return;

    const result: string[] = [member.memberId];

    selected.forEach((item) => {
      item.metadata?.threadMemberIds.forEach((id) => {
        result.push(id);
      });
    });
    oncMembersChanged(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, selected]);

  return (
    <Box sx={styles.root}>
      <ThreadSearchUsers
        selected={selected}
        setSelected={setSelected}
        searchPlaceholder={t('dm.newThread.Searchbar.placeholder')}
        selectedLimit={SELECTED_LIMIT}
        enableSearchThreadGroups={false}
      />
    </Box>
  );
}
