import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import PaidFeatureBlockerControl from '@app/web/src/components/BlockerControl';
import {
  BaseLayout,
  BaseLayoutProps,
  useBaseLayout,
  useBaseLeftPanel,
} from '@front/ui';
import { PanelControl } from '@lib/web/ui';

import GlobalRightPanels from '../../components/GlobalRightPanels';
import { FloatingProfileContextProvider } from '../../contexts/floatingProfileContext';

import Header from './components/Header';
import MemberTierWrap from './components/Menu/MemberTierWrap';
import Menu from './components/Menu/Menu';
import Nav from './components/Nav';
import useMainLayout from './hooks/useMainLayout';
import useNavMenuVisibility from './hooks/useNavMenuVisibility';
import useReportOnlineMember from './hooks/useReportOnlineMember';
import { MainLayoutProvider } from './context';

const LHS_NOTIFICATIONS_WIDTH = 446;
const LHS_NEW_DM_WIDTH = 343;

type MainLayoutProps = {
  children: BaseLayoutProps['children'];
  pageTitle?: ReactNode;
  pageTitleIcon?: ReactNode;
  pageTitleKey?: string;
  titleSuffix?: ReactNode;
  toolComponent?: ReactNode;
  headerBottomComponent?: ReactNode;
  rightPanelComponent?: BaseLayoutProps['rightPanelComponent'];
  backClickType?: 'back' | 'menu';
  ExtensionCentreAndRightPanelProvider?: BaseLayoutProps['ExtensionCentreAndRightPanelProvider'];
};

function MainLayoutChildren({
  children,
  pageTitle,
  pageTitleIcon,
  pageTitleKey,
  titleSuffix,
  toolComponent,
  headerBottomComponent,
  backClickType,
}: MainLayoutProps) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { menuVisible } = useNavMenuVisibility();
  const { temporaryMenuTarget } = useMainLayout();
  const { resetLeftPanelWidth, setLeftPanelWidth } = useBaseLeftPanel();
  const { closeMainNav, openMainNav } = useBaseLayout();
  useEffect(() => {
    if (temporaryMenuTarget === 'notifications') {
      setLeftPanelWidth(mdUp ? LHS_NOTIFICATIONS_WIDTH : -1);
      if (!mdUp) closeMainNav();
      else openMainNav();
      return;
    }
    if (temporaryMenuTarget === 'newDirectMessage') {
      setLeftPanelWidth(mdUp ? LHS_NEW_DM_WIDTH : -1);
      if (!mdUp) closeMainNav();
      else openMainNav();
      return;
    }
    openMainNav();
    resetLeftPanelWidth();
  }, [
    mdUp,
    temporaryMenuTarget,
    resetLeftPanelWidth,
    setLeftPanelWidth,
    openMainNav,
    closeMainNav,
  ]);

  return (
    <>
      <Header
        title={pageTitleKey ? t(pageTitleKey) : pageTitle}
        icon={pageTitleIcon}
        titleSuffix={titleSuffix}
        toolComponent={toolComponent}
        bottomComponent={headerBottomComponent}
        backClickType={backClickType}
        hideCollapseLeftPanelButton={!menuVisible}
      />
      {children}
      <GlobalRightPanels />
      <PanelControl />
      <PaidFeatureBlockerControl />
    </>
  );
}

function MainLayout({
  children,
  rightPanelComponent,
  ExtensionCentreAndRightPanelProvider,
  ...rest
}: MainLayoutProps) {
  useReportOnlineMember();
  const { menuVisible } = useNavMenuVisibility();

  return (
    <BaseLayout
      navComponent={<Nav />}
      leftPanelComponent={
        menuVisible && (
          <MemberTierWrap>
            <Menu />
          </MemberTierWrap>
        )
      }
      rightPanelComponent={rightPanelComponent}
      ExtensionGlobalProvider={FloatingProfileContextProvider}
      ExtensionCentreAndRightPanelProvider={
        ExtensionCentreAndRightPanelProvider
      }
    >
      <MainLayoutChildren {...rest}>{children}</MainLayoutChildren>
    </BaseLayout>
  );
}

export default function MainLayoutRoot({ children, ...rest }: MainLayoutProps) {
  return (
    <MainLayoutProvider>
      <MainLayout {...rest}>{children}</MainLayout>
    </MainLayoutProvider>
  );
}
