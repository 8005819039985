import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { isDev } from '@app/web/src/config/constants';
import { GlobalPanelKeys, MarketplaceKeys } from '@app/web/src/types/panel';
import { getProductPath } from '@app/web/src/utils/marketplace';
import {
  OtherLink as OtherLinkIcon,
  OtherShareToDirect as OtherShareToDirectIcon,
  OtherShareVia as OtherShareViaIcon,
  ProfileQRCode as ProfileQRCodeIcon,
  SocialMediaFacebook as SocialMediaFacebookIcon,
} from '@front/icon';
import { ResponsiveDropdown, TipButton, useBaseLayout } from '@front/ui';
import { apis, useAuth } from '@lib/web/apis';
import { useCopyToClipboard } from '@lib/web/hooks';

import useMarketplace from '../../hooks/useMarketplace';

const styles = {
  container: {
    marginLeft: 'auto',
  },
  option: { display: 'flex', alignItems: 'center', gap: 1 },
  popper: {
    zIndex: 1600,
    '& .popper-content': {
      mt: 1,
    },
  },
};

const shareToDirect = {
  display: 'button.Share to Direct',
  value: 'share-direct',
  icon: <OtherShareToDirectIcon width={16} height={16} />,
};

const copyLink = {
  display: 'button.Copy Link',
  value: 'copy-link',
  icon: <OtherLinkIcon width={16} height={16} />,
};

const shareQrCode = {
  display: 'button.Share as QR Code',
  value: 'share-qr-code',
  icon: <ProfileQRCodeIcon width={16} height={16} />,
};

const shareFacebook = {
  display: 'button.Share to Facebook',
  value: 'share-facebook',
  icon: <SocialMediaFacebookIcon width={16} height={16} />,
};

type RenderOptionProps = {
  display: string;
  icon: ReactNode;
};

function RenderOption({ display, icon }: RenderOptionProps) {
  const { t } = useTranslation('marketplace');
  return (
    <Box sx={styles.option}>
      {icon}
      {t(display)}
    </Box>
  );
}

const ShareButton = () => {
  const { t } = useTranslation('marketplace');
  const [shareOpened, setShareOpened] = useState(false);
  const shareButtonRef = useRef<HTMLButtonElement>(null);
  const handleShareClose = () => setShareOpened(false);
  const [, copy] = useCopyToClipboard();
  const { selected } = useMarketplace();
  const { isLogged } = useAuth();
  const { openRightPanel, rightPanelParams } = useBaseLayout();

  const handleCopyLink = async () => {
    if (!selected) return;
    // fetch latest data before create the link
    const { data: product } = await apis.marketplace.getProduct(selected?.id);
    const path = getProductPath(product?.data);
    const link = `${process.env.APP_URL}/marketplace/${path}?panel=${GlobalPanelKeys.GlobalMarketplaceDetail}&productId=${selected.id}`;
    await copy(link);
  };

  const handleShareChange = async (ev: { value: string }) => {
    if (!selected) return;

    if (ev.value === 'share-direct') {
      openRightPanel(MarketplaceKeys.MarketplaceShare, {
        ...rightPanelParams,
      });
    }
    if (ev.value === 'copy-link') {
      handleCopyLink();
    }
    if (ev.value === 'share-qr-code') {
      // TODO:
    }
    if (ev.value === 'share-facebook') {
      // TODO:
    }

    handleShareClose();
  };

  if (!selected) return null;

  // TODO: remove isDev when ready
  const options = [
    ...(isLogged ? [shareToDirect] : []),
    copyLink,
    ...(isDev ? [shareQrCode] : []),
    ...(isDev ? [shareFacebook] : []),
  ];

  return (
    <>
      <Box
        sx={styles.container}
        ref={shareButtonRef}
        onClick={() => setShareOpened(true)}
      >
        <TipButton
          ref={shareButtonRef}
          title={t('Share')}
          selected={shareOpened}
          onClick={() => setShareOpened(true)}
          hideTooltip={shareOpened}
        >
          <OtherShareViaIcon />
        </TipButton>
      </Box>
      <ResponsiveDropdown
        open={shareOpened}
        options={options}
        onClick={handleShareChange}
        onClose={handleShareClose}
        menuDropdownProps={{
          anchorEl: shareButtonRef.current,
          popperProps: {
            sx: styles.popper,
          },
        }}
        renderOption={(ev) => (
          <RenderOption display={ev.display} icon={ev.icon} />
        )}
      />
    </>
  );
};

export default ShareButton;
