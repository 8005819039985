import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Icon } from '@front/ui';
import { SharedUserContext } from '@lib/web/contexts';
import { MenuComps } from '@lib/web/ui';

import getMainPageConfig from '../../../pageConfig/main';

export default function DiscoverMenu() {
  const { t } = useTranslation();
  const { sharePath } = useContext(SharedUserContext);
  const mainConfig = getMainPageConfig(t);

  return (
    <MenuComps title={t('menu.Discover')}>
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('menu.Clubs')} />
        <MenuComps.Button
          icon={<Icon name={mainConfig.allClubs.icon} />}
          href={`${sharePath}/`}
          active={Router.asPath === '/'}
        >
          {mainConfig.allClubs.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={mainConfig.clubGroups.icon} />}
          href={`${sharePath}/club-group`}
          active={Router.asPath === '/club-group'}
        >
          {mainConfig.clubGroups.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={mainConfig.myCreatedClubs.icon} />}
          href={`${sharePath}/club/my-created`}
          active={Router.asPath === '/club/my-created'}
        >
          {mainConfig.myCreatedClubs.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={mainConfig.joinedClubs.icon} />}
          href={`${sharePath}/club/joined`}
          active={Router.asPath === '/club/joined'}
        >
          {mainConfig.joinedClubs.title}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
