import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import Box from '@mui/material/Box';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import { Button, useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { apis, ClubMemberRequestStatus } from '@lib/web/apis';
import { call } from '@lib/web/utils';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
  },
  button: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
  loadMore: {
    '& .MuiTypography-root': {
      fontWeight: 'normal',
    },
  },
};

const DEFAULT_LIMIT = 3;

type Metadata = {
  requestStatus: ClubMemberRequestStatus;
  requestId: string;
};

export type ClubPendingRequestListProps = {
  body: GetNewJoinRequestNotificationRes['data']['payload']['body'];
  cta: Extract<NotificationCtaType, { type: 'cta.pending.request.list' }>;
  clubSlug?: string;
};

export default function ClubPendingRequestList({
  body,
  cta,
  clubSlug,
}: ClubPendingRequestListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const [statusMap, setStatusMap] = useState<
    Record<string, ClubMemberRequestStatus | 'rejecting' | 'accepting'>
  >({});

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 122px 119px';
    const columnOrder = ['name', 'accept', 'reject'];
    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'MainProfileSolid',
                label: t('pending.request.table.name', 'Name'),
              },
              { type: 'empty' },
              { type: 'empty' },
            ],
          },
          rows: cta.requests
            .filter((_, index) => index < DEFAULT_LIMIT)
            .filter((_, index) => (collapse ? index === 0 : true)) // 1 if collapsed => show 1st row
            .map((item) => {
              return {
                id: item.clubMemberRequestId,
                cells: {
                  name: {
                    type: 'avatarText',
                    userId: item.userId,
                  },
                  accept: {
                    type: 'custom',
                    renderKey: 'acceptButton',
                    metadata: {
                      requestStatus: item.clubMemberRequestStatusDyna,
                      requestId: item.clubMemberRequestId,
                    },
                  },
                  reject: {
                    type: 'custom',
                    renderKey: 'rejectButton',
                    metadata: {
                      requestStatus: item.clubMemberRequestStatusDyna,
                      requestId: item.clubMemberRequestId,
                    },
                  },
                },
                clickAction: {
                  type: 'event',
                  value: 'showProfile',
                },
                metadata: {
                  requestStatus: item.clubMemberRequestStatusDyna,
                  requestId: item.clubMemberRequestId,
                },
              };
            }),
          footerRows: [
            !collapse &&
              !!body.joinRequestCount &&
              body.joinRequestCount > DEFAULT_LIMIT && {
                gridTemplateColumns: '1fr',
                cells: [
                  {
                    type: 'button',
                    action: 'loadMore',
                    text: t('pending.request.table.loadMore', {
                      count: body.joinRequestCount - DEFAULT_LIMIT,
                    }),
                    icon: 'ActionMore',
                    fullWidth: true,
                    sx: styles.loadMore,
                  },
                ],
                rowHeight: 32,
              },
            {
              gridTemplateColumns: '1fr',
              cells: [
                {
                  type: 'textButton',
                  action: 'toggleCollapse',
                  text: collapse
                    ? t('pending.request.table.seeMore', 'See more')
                    : t('pending.request.table.hide', 'Hide'),
                  suffixIcon: collapse
                    ? 'ActionChevronRightSmall'
                    : 'ActionChevronUp',
                  sx: { ml: 0 },
                  containerSx: { pt: 1 },
                },
              ],
              rowHeight: 21,
            },
          ],
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [body.joinRequestCount, collapse, cta.requests, t]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
      showProfile: {
        action: ({ metadata }: { metadata: { userId: string } }) => {
          openRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId: metadata.userId,
          });
        },
      },
      loadMore: {
        action: () => {
          void Router.push(`/club/form/${clubSlug}/requests`);
        },
      },
    }),
    [clubSlug, openRightPanel]
  );

  const updateStatus = useCallback(
    (
      id: string,
      status: ClubMemberRequestStatus | 'rejecting' | 'accepting'
    ) => {
      setStatusMap((prev) => ({
        ...prev,
        [id]: status,
      }));
    },
    []
  );

  const handleAcceptRequest = useCallback(
    async (ev: Metadata) => {
      if (!clubSlug) return;
      const latestStatus = statusMap[ev.requestId] || ev.requestStatus;
      if (latestStatus !== ClubMemberRequestStatus.UserSent) return;
      updateStatus(ev.requestId, 'accepting');
      await call(apis.club.acceptJoinRequest(clubSlug, ev.requestId));
      updateStatus(ev.requestId, ClubMemberRequestStatus.ManagerAccepted);
    },
    [clubSlug, statusMap, updateStatus]
  );

  const handleRejectRequest = useCallback(
    async (ev: Metadata) => {
      if (!clubSlug) return;
      const latestStatus = statusMap[ev.requestId] || ev.requestStatus;
      if (latestStatus !== ClubMemberRequestStatus.UserSent) return;
      updateStatus(ev.requestId, 'rejecting');
      await call(apis.club.rejectJoinRequest(clubSlug, ev.requestId));
      updateStatus(ev.requestId, ClubMemberRequestStatus.ManagerRejected);
    },
    [clubSlug, statusMap, updateStatus]
  );

  const renders = useMemo(() => {
    return {
      acceptButton: (ev: Metadata) => {
        const latestStatus = statusMap[ev.requestId] || ev.requestStatus;
        return (
          <Button
            sx={styles.button}
            prefixIcon={<TextEditorCheckIcon />}
            disabled={latestStatus !== ClubMemberRequestStatus.UserSent}
            onClick={(e) => {
              e.stopPropagation();
              void handleAcceptRequest(ev);
            }}
            loading={latestStatus === 'accepting'}
          >
            {latestStatus === ClubMemberRequestStatus.ManagerAccepted
              ? t('pending.request.table.accepted', 'Accepted')
              : t('pending.request.table.accept', 'Accept')}
          </Button>
        );
      },
      rejectButton: (ev: Metadata) => {
        const latestStatus = statusMap[ev.requestId] || ev.requestStatus;
        return (
          <Button
            sx={styles.button}
            variant="outlined"
            prefixIcon={<ActionCloseSmallIcon />}
            disabled={latestStatus !== ClubMemberRequestStatus.UserSent}
            onClick={(e) => {
              e.stopPropagation();
              void handleRejectRequest(ev);
            }}
            loading={latestStatus === 'rejecting'}
          >
            {latestStatus === ClubMemberRequestStatus.ManagerRejected
              ? t('pending.request.table.rejected', 'Rejected')
              : t('pending.request.table.reject', 'Reject')}
          </Button>
        );
      },
    };
  }, [handleAcceptRequest, handleRejectRequest, statusMap, t]);

  return (
    <Box sx={styles.root}>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={config} />
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </Box>
  );
}
