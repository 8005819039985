import { SanityProductType } from '@lib/web/apis';

export function getProductPath(product?: GetSanityProductRes | null) {
  if (!product) return null;
  const { status, quantityStock } = product;
  if (status === SanityProductType.Unavailable || quantityStock === 0) {
    return 'unavailable';
  }
  if (status === SanityProductType.Available && quantityStock > 0) {
    return 'available';
  }
  if (status === SanityProductType.ComingSoon) {
    return 'coming-soon';
  }
  return null;
}
