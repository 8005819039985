import { useContext } from 'react';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { OtherShareToDirect as OtherShareToDirectIcon } from '@front/icon';
import { Button } from '@front/ui';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { apis, SharedNotificationTag } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { call } from '@lib/web/utils';

import SearchUserPanel from '../../../CommonPanels/SearchUserPanel';
import useMarketplace from '../../hooks/useMarketplace';

export default function MarketplaceSharePanel() {
  const { selected } = useMarketplace();
  const { userShareSid } = useContext(SharedUserContext);
  const userId = useCurrentUserId();

  const handleSend = async (users: IconListLayoutItemObj[]) => {
    if (!selected) return;

    const userIds: string[] = [];
    const emails: string[] = [];

    users.forEach((user) => {
      if (user.metadata?.userId) userIds.push(user.metadata.userId);
      else if (user.metadata?.email) emails.push(user.metadata.email);
    });

    const params = {
      sharedPathname: '/',
      sharedPathQuery: JSON.stringify({
        panel: GlobalPanelKeys.GlobalMarketplaceDetail,
        productId: selected.id,
      }),
      link: `/?panel=${GlobalPanelKeys.GlobalMarketplaceDetail}&productId=${selected.id}`,
      sid: userShareSid,
      receiverUserIds: userIds,
      shareUserId: userId,
      emails,
      tag: SharedNotificationTag.DmSharedAvatarGlobal,
      extraInfo: {
        avatarName: selected.name,
        avatarImage: selected.imageUrl,
        productId: selected.id,
      },
    };

    return call(apis.shared.sharePage(params));
  };

  return (
    <SearchUserPanel
      title="Share Avatar"
      titleIcon={<OtherShareToDirectIcon width={16} height={16} />}
      buttonTestId="share-avatar"
      tip="You can share this page to Aha users or others by email"
      renderAction={({ selectedCount, sending, onActionClick }) => (
        <Button
          onClick={onActionClick}
          loading={sending}
          disabled={!selectedCount}
        >
          Share ({selectedCount})
        </Button>
      )}
      onActionClick={handleSend}
    />
  );
}
