/* eslint-disable import/prefer-default-export */

import { isNumber } from '@front/ui';

// Keep the sub name short to fit the logo width
const NAME_MAX_LEN = 7;

// TODO: replace displayName with club's slug

const getFontSize = (size: number) => {
  if (size <= 3) return 48;
  if (size === 4) return 44;
  if (size === 5) return 40;
  if (size === 6) return 28;
  if (size === 7) return 24;
  return 24;
};

export const getBgolor = (clubName: string) => {
  if (clubName.toUpperCase().includes('ACT ')) return '#00D1FF';
  if (clubName.toUpperCase().includes('CBSE 10')) return '#0072DD';
  if (clubName.toUpperCase().includes('CBSE 12')) return '#00C398';
  if (clubName.toUpperCase().includes('GRE ')) return '#9747FF';
  if (clubName.toUpperCase().includes('IELTS ')) return '#6113E0';
  if (clubName.toUpperCase().includes('ISEE ')) return '#009E2F';
  if (clubName.toUpperCase().includes('JEE ')) return '#E62937';
  if (clubName.toUpperCase().includes('NEET ')) return '#FFCB00';
  if (clubName.toUpperCase().includes('PSAT ')) return '#FF4E8E';
  if (clubName.toUpperCase().includes('SAT ')) return '#FF7401';
  if (clubName.toUpperCase().includes('TOEFL ')) return '#00D1FF';
  if (clubName.toUpperCase().includes('TOEIC ')) return '#0072DD';
  if (clubName.toUpperCase().includes('AP ')) return '#A32857';
  return '';
};

const lengthNormalize = (str = '') => {
  if (str.length > NAME_MAX_LEN) {
    return str.charAt(0);
  }
  return str;
};

const getClubIconLongName = (displayName: string) => {
  const [firstWord, ...otherWords] = displayName.split(' ');
  const examName = lengthNormalize(firstWord).toUpperCase();

  let subName = '';
  if (otherWords.length === 1) {
    subName = lengthNormalize(otherWords[0]).toUpperCase();
  } else {
    subName = otherWords
      .map((w) => w.charAt(0))
      .join('')
      .slice(0, NAME_MAX_LEN)
      .toUpperCase();
  }

  const clubName = [examName, subName]
    .filter((s) => !!s)
    .join(examName.length < 2 && subName.length < 2 ? '' : '-');

  return {
    examName,
    subName,
    clubName,
  };
};

const getClubIconShortName = (displayName: string) => {
  const nameArr = displayName.split(' ');

  let examName = '';
  let subName = '';

  nameArr.forEach((name, i) => {
    // exam name
    if (i === 0) examName = name.toUpperCase();
    // grade
    else if (isNumber(name)) subName += name;
    else if (/^[0-9]+(st|nd|rd|th)/i.test(name))
      subName += name.replace(/[^\d.-]/g, '');
    // special case
    else if (name === 'No') subName += 'n';
    else if (name === 'Section') subName += '.';
    // If line 2 is a single word (single letter acronym), select the first 3 characters of the word.
    else if (nameArr.length === 2) subName += name.slice(0, 3).toUpperCase();
    else if (/^[a-zA-Z]+$/i.test(name))
      subName += name.slice(0, 1).toUpperCase();

    // 最後剩下的是特殊符號，不處理
  });

  const clubName = [examName, subName].filter((s) => !!s).join('-');

  return {
    examName,
    subName,
    clubName,
  };
};

export const getClubIconName = (displayName: string) => {
  const nameArr = displayName.split(' ');
  if (nameArr.length > 2) return getClubIconLongName(displayName);
  const [firstWord, secondWord] = nameArr;
  if (firstWord?.length > NAME_MAX_LEN || secondWord?.length > NAME_MAX_LEN) {
    return getClubIconLongName(displayName);
  }
  return getClubIconShortName(displayName);
};

export const getClubIconPath = (displayName = '', disabled = false) => {
  const { clubName, examName, subName } = getClubIconName(displayName);

  const maxTextLength = Math.max(examName.length, subName.length);
  const fontSize = getFontSize(maxTextLength);
  const bgColor = disabled ? '#808080' : getBgolor(displayName);

  return `${
    process.env.APP_URL
  }/api/image/club/${clubName}?size=160&fontSize=${fontSize}&bgcolor=${encodeURIComponent(
    bgColor
  )}`;
};

const getNameAbbr = (name: string) => {
  const nameArr = name.split(' ');
  return (
    nameArr.length < 2
      ? nameArr[0].slice(0, 1)
      : `${nameArr[0][0]}${nameArr[1]?.[0] || ''}`
  ).toUpperCase();
};

export const getClubGroupIconPath = (displayName: string) => {
  const nameAbbr = getNameAbbr(displayName);

  return `${
    process.env.APP_URL
  }/api/image/icon/${nameAbbr}?size=160&fontSize=60&background=${encodeURIComponent(
    '#FFFFFF'
  )}&color=${encodeURIComponent('#080808')}`;
};
