import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { alpha, Button, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { EditorAI as EditorAIIcon } from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import { useAuth } from '@lib/web/apis';
import { useMyAiTokens } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import useMainLayout from '../../hooks/useMainLayout';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    flex: 1,
  },
  bottom: {
    flex: '0 0 61px',
    px: '12px',
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '1fr auto',
    gap: 1,
    borderTop: '1px solid',
    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  bottomItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    svg: {
      verticalAlign: 'middle',
    },
    typography: 'numberBody2',
  },
  bottomSubItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  button: {
    ml: 'auto',
    py: '7.5px',
    px: 2,
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 0,
    borderRadius: 1,
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        boxShadow: 'inset 0px 0px 0px 3px rgba(255, 255, 255, 0.3)',
      },
      '&:not(:disabled):active': {
        color: 'white',
        background:
          'linear-gradient(92.61deg, rgba(0, 209, 255, 0.5) 0%, rgba(0, 81, 249, 0.5) 48.44%, rgba(112, 0, 255, 0.5) 100%)',
        boxShadow: 'inset 0px 0px 0px 3px rgba(0, 0, 0, 0.3)',
      },
    },
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      maxWidth: 216,
      ml: '12px',
    },
    '&.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip': {
      mb: 0.5,
    },
  },
};

type MemberTierWrapProps = {
  children: ReactNode;
};
export default function MemberTierWrap({ children }: MemberTierWrapProps) {
  const { t } = useTranslation('profile');
  const { renderDisplayTokens, tokens, hasInfinityTokens } = useMyAiTokens();

  const { isPaid, tier } = useMemberSubscription();

  const { isLogged } = useAuth();

  const { temporaryMenuTarget } = useMainLayout();

  if (
    !isLogged ||
    temporaryMenuTarget === 'notifications' ||
    temporaryMenuTarget === 'addFriends' ||
    temporaryMenuTarget === 'newDirectMessage'
  )
    return <>{children}</>;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.menu}>{children}</Box>
      <SimpleTooltip
        slotProps={{
          popper: { sx: styles.popper },
        }}
        title={t('wallet.indicator', {
          availableTokens: hasInfinityTokens
            ? t('wallet.availableTokens_unlimited')
            : t('wallet.availableTokens', {
                count: tokens,
              }),
        })}
      >
        <Box sx={styles.bottom}>
          <Box sx={styles.bottomItem}>
            <EditorAIIcon width="16" height="16" />
            {renderDisplayTokens}
          </Box>
          {!isPaid && !!tier && (
            <Button
              sx={styles.button}
              component={Link}
              href="/extend-trial"
              onClick={() =>
                trackEvent('click', {
                  elementName: 'upgrade-button',
                  fromLocation: 'lhs',
                })
              }
            >
              Upgrade
            </Button>
          )}
        </Box>
      </SimpleTooltip>
    </Box>
  );
}
