import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardMedia, Skeleton, Typography } from '@mui/material';
import ProductEmojis, {
  ProductEmojisHandler,
} from '@app/web/src/components/ProductEmojis/ProductEmojis';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherDollar as OtherDollarIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  Logo,
  NumberCard,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import {
  AhaPointSourceType,
  useAhaPointRewardPlan,
  useMemberInfo,
  useProductOwner,
} from '@lib/web/apis';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '@lib/web/utils';

import FavoriteButton from '../../components/FavoriteButton';
import useMarketplace from '../../hooks/useMarketplace';

const styles = {
  imageWrapper: {
    display: 'grid',
    gap: 0,
    mt: 1,
    px: 1.5,
    py: 1,
  },
  imageCard: {
    borderRadius: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 2,
    overflow: 'hidden',
    gap: 1,
  },
  detailWrapper: {
    mt: 2,
  },
  subTitle: {
    fontSize: { xs: 12 },
    opacity: 0.5,
    py: 1,
  },
  cardGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: 2,
    mt: 2,
    maxWidth: '100%',
  },
  description: {
    fontSize: 16,
    opacity: 0.75,
  },
  owner: {
    display: 'flex',
    flexDirection: 'column',
    height: {
      xs: 54,
      sm: 72,
    },
  },
  ownerAvatar: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    minHeight: 54,
  },
};

type OwnerAvatarProps = {
  member?: GetMemberInfoRes;
};

function OwnerAvatar({ member }: OwnerAvatarProps) {
  if (!member) {
    return (
      <Box sx={styles.owner}>
        <Box sx={styles.ownerAvatar}>
          <Logo />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.owner}>
      <Box sx={styles.ownerAvatar}>
        <SquareAvatar src={member.nftAvatar || member.avatar} size={40}>
          {member.displayName || member.distinctName}
        </SquareAvatar>
      </Box>
    </Box>
  );
}

type OwnerAvatarCardProps = {
  owner?: GetProductOwnerRes;
  loading: boolean;
};

function OwnerAvatarCard({ owner, loading }: OwnerAvatarCardProps) {
  const { t } = useTranslation('marketplace');
  const { data, isLoading: memberLoading } = useMemberInfo(
    null,
    owner?.memberId
  );
  const member = data?.data;
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const handleOwnerClick = () => {
    if (!member) return;

    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: member.userId,
    });
  };

  return (
    <NumberCard
      title={t('Owner')}
      titleIcon="MainProfileSolid"
      onClick={member?.userId ? handleOwnerClick : undefined}
      actionIcon={null}
      loading={loading || memberLoading}
    >
      <NumberCard.Value>
        <OwnerAvatar member={member} />
      </NumberCard.Value>
      <NumberCard.Description>
        {member ? member?.displayName || member?.distinctName : 'Aha'}
      </NumberCard.Description>
    </NumberCard>
  );
}

export default function AvatarDetail({
  product,
}: {
  product?: GetSanityProductRes | null;
}) {
  const { t } = useTranslation('marketplace');
  const emojisRef = useRef<ProductEmojisHandler>(null);
  const { autoFavorite, isMyAvatar } = useMarketplace();
  const { data: pointData } = useAhaPointRewardPlan();
  const { data: owner, isLoading } = useProductOwner(product?.id);

  const rewardPoint = pointData?.data.find(
    (d) => d.sourceType === AhaPointSourceType.EarnByAvatar
  )?.ahaPointAmount;

  const emojis = product?.properties.map((p) => p.unicode);
  const price = product?.prices.find((p) => p.currency === DEFAULT_CURRENCY);
  const formatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
    minimumFractionDigits: 2,
  });

  return (
    <BaseLayoutRightPanel.ScrollArea>
      <Box sx={styles.imageWrapper}>
        <Card sx={styles.imageCard}>
          {product ? (
            <CardMedia
              component="img"
              image={product.imageUrl}
              alt={product.name}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={295} />
          )}
        </Card>
        <Box
          sx={styles.title}
          onMouseEnter={() => emojisRef.current?.slideToRight()}
          onMouseLeave={() => emojisRef.current?.slideToLeft()}
        >
          <ProductEmojis
            emojis={emojis}
            size={36}
            borderRadius={3}
            gap="3px"
            fontSize={20}
            ref={emojisRef}
          />
          {product && (
            <FavoriteButton
              productId={product.id}
              tooltipPlacement="top-end"
              autoFavorite={autoFavorite}
            />
          )}
        </Box>
        <Box sx={styles.detailWrapper}>
          <Typography variant="subtitle1" sx={styles.subTitle}>
            {t('Avatar Details')}
          </Typography>
          <NumberCard
            title={
              product && isMyAvatar(product.id) ? t('Sold Price') : t('Price')
            }
            titleIcon="OtherCoinsAlt"
            singleValue
            loading={!price}
          >
            <NumberCard.Value
              variant="currency"
              prefix={<OtherDollarIcon width={28} height={28} />}
            >
              {price && formatter.format(price.price)}
            </NumberCard.Value>
          </NumberCard>
          <Box sx={styles.cardGroup}>
            <NumberCard
              title={t('Earn')}
              titleIcon="NFTGift"
              borderLeft="gradient.primaryVertical"
              singleValue
              loading={!rewardPoint}
            >
              <NumberCard.Value
                variant="currency"
                prefix={<OtherDollarIcon width={28} height={28} />}
              >
                {rewardPoint}
              </NumberCard.Value>
              <NumberCard.Description>{t('Aha Points')}</NumberCard.Description>
            </NumberCard>
            <OwnerAvatarCard loading={isLoading} owner={owner?.data} />
          </Box>
        </Box>
        <Box sx={styles.detailWrapper}>
          <Typography variant="subtitle1" sx={styles.subTitle}>
            {t('Description')}
          </Typography>
          <Typography variant="body1" sx={styles.description}>
            {product?.description ?? <Skeleton width="100%" />}
          </Typography>
        </Box>
      </Box>
    </BaseLayoutRightPanel.ScrollArea>
  );
}
