import React, {
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  SyntheticEvent,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { Icon, Scrollbar, SquareAvatar, TextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';

import {
  IaCellValueChangedEvent,
  TableLayoutAutoCompleteCell,
  TableLayoutAutoCompleteOption,
  TableLayoutRow,
} from '../../types';

export type IaAutocompleteCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutAutoCompleteCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  autoComplete: {
    '& .MuiInputBase-input': {
      px: 0,
    },
    '& .textfield-main': {
      border: 'none !important',
      backgroundColor: 'transparent',
      outlineWidth: '0 !important',
      height: '32px',
    },
  },
  menu: {
    typography: 'body2',
    height: '28px',
    px: 1.5,
    py: '3.5px',
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
    '& p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

/**
 * use this component to use our own Scrollbar, add ref to make keyboard navigation work correctly
 */
const ListboxComponent = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children, ...props }, ref) => (
    <div ref={ref}>
      <Scrollbar {...props}>{children}</Scrollbar>
    </div>
  )
);

ListboxComponent.displayName = 'ListboxComponent';

export default function IaAutoCompleteCell({
  row,
  columnKey,
  cell,
}: IaAutocompleteCellProps) {
  const {
    value,
    options,
    isOptionsAsync,
    sx,
    placeholder,
    disabled,
    actionMap,
    defaultOpen,
  } = cell;
  const { getCellSelectedState, setSelectedState } = useTableSelectionContext();
  const { getIaAction } = useIaAction();
  const selectedState = getCellSelectedState(row.id, columnKey);
  const cellRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const deferredSelectedState = useDeferredValue(selectedState);
  const [open, setOpen] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  /**
   * when selected state changed, we make it open automatically
   */
  useEffect(() => {
    if (deferredSelectedState !== 'active' && selectedState === 'active') {
      setOpen(true);
      inputRef.current?.focus();
    }
  }, [deferredSelectedState, selectedState]);

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);

      inputRef.current?.focus();

      const scrollEl = inputRef.current?.closest(
        '.simplebar-content-wrapper:not(.table-layout-horizontal-scroll)'
      );
      if (!scrollEl) return;

      setTimeout(() => {
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight,
        });
      });
    }
  }, [defaultOpen]);

  const handleChange = (
    event: SyntheticEvent,
    newValue: TableLayoutAutoCompleteOption | null
  ) => {
    if (!actionMap?.valueChange) return;
    const action = getIaAction<
      IaCellValueChangedEvent<TableLayoutAutoCompleteOption | null>
    >(actionMap.valueChange);
    action?.action({ value: newValue, row, columnKey });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setSelectedState('focused');
      inputRef.current?.blur();
    }
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
    if (event.key === 'Tab') {
      inputRef.current?.blur();
    }
  };
  const handleOptionClick =
    (onClick?: MouseEventHandler) => (event: MouseEvent) => {
      onClick?.(event);

      setSelectedState('focused');
      inputRef.current?.blur();
    };

  const handleBlur = () => {
    if (actionMap?.inputBlur) {
      const action = getIaAction<
        IaCellValueChangedEvent<TableLayoutAutoCompleteOption | null>
      >(actionMap.inputBlur);
      action?.action({ value: null, row, columnKey });
    }
  };

  return (
    <Box sx={[styles.root, ...sxProps]} ref={cellRef}>
      <Autocomplete<TableLayoutAutoCompleteOption>
        sx={styles.autoComplete}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            sx: {
              width: '241px !important',
            },
          },
        }}
        onKeyDown={handleKeyDown}
        open={open}
        value={value}
        onClick={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        onBlur={handleBlur}
        isOptionEqualToValue={(option, optionValue) =>
          option.value === optionValue.value
        }
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        options={options}
        filterOptions={isOptionsAsync ? (opts) => opts : undefined}
        clearOnBlur
        ListboxComponent={ListboxComponent}
        renderOption={(props, option) => {
          const { key, onClick, ...rest } =
            props as HTMLAttributes<HTMLLIElement> & {
              key: string;
            };
          return (
            <Box
              sx={styles.menu}
              component="li"
              key={key}
              onClick={handleOptionClick(onClick)}
              {...rest}
            >
              {option.iconType === 'image' && (
                <SquareAvatar src={option.icon} size={16}>
                  {option.label}
                </SquareAvatar>
              )}
              {option.iconType === 'icon' && option.icon && (
                <Icon name={option.icon} width={16} height={16} />
              )}
              {option.iconType === 'capital-avatar' && (
                <SquareAvatar src={option.icon} size={16}>
                  {`${option.label
                    ?.split(' ')?.[0][0]
                    .toUpperCase()} ${option.label
                    ?.split(' ')?.[1][0]
                    .toUpperCase()}`}
                </SquareAvatar>
              )}
              <p>{option.label}</p>
            </Box>
          );
        }}
        renderInput={({
          InputProps,
          inputProps,
          InputLabelProps,
          size,
          ...params
        }) => (
          <TextField
            inputRef={(ref) => {
              if (typeof InputProps.ref === 'function') InputProps.ref(ref);
              inputRef.current = ref;
            }}
            startAdornment={InputProps.startAdornment}
            {...params}
            inputProps={inputProps}
            placeholder={placeholder}
            onClick={() => setOpen(true)}
          />
        )}
      />
    </Box>
  );
}
