import {
  AsyncTaskStatus,
  ClubMemberRole,
  useClubAnswerFormatPreference,
} from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useCreatorQuestionInfoData } from './useCreatorQuestionInfoData';
import { useCreatorQuestionListData } from './useCreatorQuestionListData';

export default function useDisableEditAnswerFormat(questionId: string) {
  const { club } = useCurrentIaClub();
  const { data: clubAnswerFormatData } = useClubAnswerFormatPreference(
    club?.clubId || ''
  );
  const { infoData } = useCreatorQuestionInfoData(questionId);
  const { selectQuestion } = useCreatorQuestionListData();
  const question = selectQuestion(questionId);

  const isAsyncTaskInProgress = [
    AsyncTaskStatus.Created,
    AsyncTaskStatus.Pending,
    AsyncTaskStatus.Processing,
  ].includes(question?.asyncTaskStatus);

  const isAllowUserEdit =
    clubAnswerFormatData?.data.find(
      (clubAnswerFormat) =>
        clubAnswerFormat.answerFormatType === infoData?.answerFormatType
    )?.isAllowUserEdit ?? false;

  const hasEditAuth =
    club?.role === ClubMemberRole.Admin ||
    (isAllowUserEdit && club?.role === ClubMemberRole.Editor);

  const disableEditAnswerFormat = !hasEditAuth || isAsyncTaskInProgress;

  return {
    disableEditAnswerFormat,
    disableStatus: {
      noAllowUserEdit: !isAllowUserEdit || isAsyncTaskInProgress,
      noPermission: !hasEditAuth,
    },
  };
}
