import { MouseEvent } from 'react';
import { alpha, ButtonBase, Theme, Typography } from '@mui/material';
import {
  MarketplaceKeys,
  MarketplacePanelParams,
} from '@app/web/src/types/panel';
import { ProductCheckoutProvider } from '@app/web/src/widgets/MarketplacePage/contexts/checkoutContext';
import { MarketplaceProvider } from '@app/web/src/widgets/MarketplacePage/contexts/context';
import useMarketplace from '@app/web/src/widgets/MarketplacePage/hooks/useMarketplace';
import MarketplaceRightPanels from '@app/web/src/widgets/MarketplacePage/MarketplaceRightPanels';
import ProductCheckoutRightPanel from '@app/web/src/widgets/MarketplacePage/ProductCheckoutRightPanel';
import { SquareAvatar, useBaseLayout } from '@front/ui';
import { useProduct } from '@lib/web/apis';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: '6px',
    px: '2px',
    pb: '2px',
    borderRadius: 1,
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  name: {
    textDecoration: 'underline',
    textDecorationColor: (theme: Theme) =>
      alpha(theme.palette.text.primary, 0.3),
    textUnderlineOffset: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (hover:hover)': {
      '&:hover': {
        textDecorationColor: (theme: Theme) => theme.palette.text.primary,
      },
    },
  },
};

type AvatarDetailProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.avatar.detail' }>;
};

function AvatarDetailRoot({ cta }: AvatarDetailProps) {
  const { setSelected } = useMarketplace();
  const { data } = useProduct(cta.avatarProductId);

  const { openRightPanel } = useBaseLayout<MarketplacePanelParams>();

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!data) return;
    setSelected(data.data);
    openRightPanel(MarketplaceKeys.MarketplaceDetail, { product: data.data });
  };

  return (
    <ButtonBase
      sx={styles.root}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      disabled={!data}
    >
      <SquareAvatar src={cta.avatarImgUrl} size={16}>
        {cta.avatarName}
      </SquareAvatar>
      <Typography variant="body2" sx={styles.name}>
        {cta.avatarName}
      </Typography>
    </ButtonBase>
  );
}

export default function AvatarDetail({ cta }: AvatarDetailProps) {
  return (
    <MarketplaceProvider>
      <ProductCheckoutProvider>
        <AvatarDetailRoot cta={cta} />
        <MarketplaceRightPanels />
        <ProductCheckoutRightPanel />
      </ProductCheckoutProvider>
    </MarketplaceProvider>
  );
}
