import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useExpiresCountdown from '@app/web/src/hooks/utils/useExpiresCountdown';
import { ProfileBilling as ProfileBillingIcon } from '@front/icon';
import { BaseLayoutRightPanel, Button } from '@front/ui';
import { useProductOrderInfo } from '@lib/web/apis';
import { parseISO } from 'date-fns';

import ProductActionButton from '../../components/ProductActionButton';
import useMarketplace from '../../hooks/useMarketplace';

import AvatarDetail from './AvatarDetail';
import ShareButton from './ShareButton';

const styles = {
  timerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  timerLabel: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '22px',
    opacity: 0.64,
  },
  timer: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    '& span': {
      opacity: 0.64,
    },
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '100%',
  },
  action: {
    display: 'grid',
    justifyContent: { md: 'flex-end' },
  },
  multiAction: {
    gridTemplateColumns: '1fr 1fr',
    gap: 1,
  },
};

type ExpiresCountdownProps = {
  expireAt: string;
};

function ExpiresCountdown({ expireAt }: ExpiresCountdownProps) {
  const { setExpiresAt, ...countdown } = useExpiresCountdown();

  useEffect(() => {
    setExpiresAt(parseISO(expireAt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expireAt]);

  return (
    <Box sx={styles.timerWrapper}>
      <Typography sx={styles.timerLabel}>Payment Link Expires In</Typography>
      <Typography sx={styles.timer}>
        {countdown.minutes} <span>min</span> {countdown.seconds}{' '}
        <span>sec</span>
      </Typography>
    </Box>
  );
}

export default function DetailPanel() {
  const { selected } = useMarketplace();
  const { data: existingOrderInfo } = useProductOrderInfo(selected?.id);
  const orderInfo = existingOrderInfo?.data;

  const showPayNow =
    orderInfo && selected && orderInfo.productId === selected.id;

  if (!selected) return null;

  const handlePayNowClick = async () => {
    if (orderInfo) {
      location.href = orderInfo.paymentUrl;
      return;
    }
  };

  return (
    <BaseLayoutRightPanel
      title={selected?.name}
      toolComponent={<ShareButton />}
      actionComponent={
        <Box sx={styles.actionWrapper}>
          {showPayNow && <ExpiresCountdown expireAt={orderInfo.expireAt} />}
          <Box sx={[styles.action, !!showPayNow && styles.multiAction]}>
            <ProductActionButton
              product={selected}
              detailPanel
              dataTestId="product-detail-action"
            />
            {showPayNow && (
              <Button
                prefixIcon={<ProfileBillingIcon />}
                onClick={handlePayNowClick}
                data-testid="pay-now-button"
              >
                Pay Now
              </Button>
            )}
          </Box>
        </Box>
      }
    >
      <AvatarDetail product={selected} />
    </BaseLayoutRightPanel>
  );
}
