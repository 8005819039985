import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import { PlaylistBreadcrumb } from '@app/web/src/layouts/breadcrumbs/BreadcrumbItems';
import StepBreadcrumb from '@app/web/src/layouts/breadcrumbs/StepBreadcrumb';
import getAgentPageConfig from '@app/web/src/layouts/pageConfig/agent';
import getPlaylistsPageConfig from '@app/web/src/layouts/pageConfig/playlists';
import { Icon } from '@front/ui';
import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  _pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const isEditing = !!query.agentId;
  const prefix = query.agentId
    ? `/agent/form/${query.agentId}/edit`
    : `/agent/form`;
  const playlistsConfig = getPlaylistsPageConfig(t);
  const agentConfig = getAgentPageConfig(t);
  const getStepBreadcrumb = (step: number, page?: string) =>
    isEditing ? undefined : (
      <StepBreadcrumb total="3" step={step} page={page} />
    );
  return {
    settings: {
      text: agentConfig.settings.title,
      icon: agentConfig.settings.icon,
      href: `${prefix}/settings`,
      customizeComponent: getStepBreadcrumb(1),
    },
    prompts: {
      text: agentConfig.prompts.title,
      icon: agentConfig.prompts.icon,
      href: `${prefix}/prompts`,
      tooltip: {
        icon: 'ChatQuestionList',
        title: t('agent.create_shortcut.title'),
        content: (
          <Box>
            <Image
              src="https://aha-public.s3.ap-northeast-2.amazonaws.com/tooltip/shortcuts.png"
              width={184}
              height={146}
              alt="shortcuts"
            />
            <Box display="grid" gap={1}>
              <Typography variant="caption">
                {t('agent::create.shortcuts.tooltip.description.1')}
              </Typography>
              <Typography variant="caption">
                {t('agent::create.shortcuts.tooltip.description.2')}
              </Typography>
            </Box>
          </Box>
        ),
      },
      customizeComponent: getStepBreadcrumb(3),
    },
    dataSourcePlaylists: {
      text: agentConfig.dataSourcePlaylists.title,
      icon: agentConfig.dataSourcePlaylists.icon,
      href: `${prefix}/data-source/playlists`,
      customizeComponent: getStepBreadcrumb(
        2,
        agentConfig.dataSourcePlaylists.title
      ),
    },
    dataSourceQuestions: {
      text: agentConfig.dataSourceQuestions.title,
      icon: agentConfig.dataSourceQuestions.icon,
      href: `${prefix}/data-source/questions`,
      customizeComponent: getStepBreadcrumb(
        2,
        agentConfig.dataSourceQuestions.title
      ),
    },
    dataSourceLinks: {
      text: agentConfig.dataSourceLinks.title,
      icon: agentConfig.dataSourceLinks.icon,
      href: `${prefix}/data-source/links`,
      customizeComponent: getStepBreadcrumb(
        2,
        agentConfig.dataSourceLinks.title
      ),
    },
    dataSourceFiles: {
      text: agentConfig.dataSourceFiles.title,
      icon: agentConfig.dataSourceFiles.icon,
      href: `${prefix}/data-source/files`,
      customizeComponent: getStepBreadcrumb(
        2,
        agentConfig.dataSourceFiles.title
      ),
    },
    dataSourcePlaylistsSource: {
      customizeHeader: (
        <PlaylistBreadcrumb
          icon={
            <Icon
              name={playlistsConfig.historyDetail.icon}
              width={40}
              height={40}
            />
          }
          quizId={(query.sourceId || '') as string}
        />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.sourceId || '') as string}
        />
      ),
    },
  };
};
