import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { Button, Icon, TextButton, useBaseRightPanel } from '@front/ui';

export default function InviteFriendActions({
  hasEarningLink,
}: {
  hasEarningLink?: boolean;
}) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel();
  const handleShareCode = () => {
    openRightPanel(GlobalPanelKeys.GlobalInviteByEmail);
  };
  return (
    <Box
      width="100%"
      display="flex"
      gap={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      {!!hasEarningLink && (
        <TextButton
          prefixIcon={<Icon name="MainWallet" />}
          href="/profile/wallet"
          component={Link}
        >
          {t('button.My Earnings')}
        </TextButton>
      )}
      <Button
        color="earn"
        suffixIcon={<Icon name="OtherAhaPoints" />}
        onClick={handleShareCode}
      >
        {t('button.Invite & Earn', 'Invite & Earn')}
      </Button>
    </Box>
  );
}
