import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { WorkflowViewSlug } from '../../types/enums/workflow';
import { generateInfiniteHook, SwrHelperParams } from '../query';

export type GetIaWorkflowResMap = {
  [WorkflowViewSlug.ListCenterIaAiWorkflowDefault]: GetIaWorkflowDefaultRes;
  [WorkflowViewSlug.ListCenterIaAiWorkflowSingle]: GetIaWorkflowSingleRes;
};

export function patchIaWorkflowQuery<T>(query: IaSearchReq<T>): IaSearchReq<T> {
  let expand: string | undefined = query.expand;

  if (query.viewSlug === WorkflowViewSlug.ListCenterIaAiWorkflowDefault) {
    expand = 'club';
  }

  if (query.viewSlug === WorkflowViewSlug.ListCenterIaAiWorkflowSingle) {
    expand = 'club';
  }

  return {
    ...query,
    expand,
  };
}

export function useIaWorkflows<
  K = GetIaWorkflowDefaultRes,
  T extends WorkflowViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends WorkflowViewSlug ? GetIaWorkflowResMap[T] : K
  >(
    (index) =>
      `v2/ia/ai-workflows?${getIaQueryString({
        ...patchIaWorkflowQuery(query),
        page: index + 1,
        limit,
      })}`,
    limit,
    {
      auth: true,
      ...config,
    }
  );
}
