import { useEffect, useState } from 'react';
import axios from 'axios';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { updateViewedSlug } from '@app/web/src/actions/layoutActions';
import { useAppDispatch } from '@app/web/src/hooks/redux';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import useBodyClass from '@app/web/src/hooks/utils/useBodyClass';
import useLandingPageLink from '@app/web/src/hooks/utils/useLandingPageLink';
import {
  AHA_STORE_QUIZ_ANSWERS_KEY,
  AHA_STORE_QUIZ_DURATION_KEY,
} from '@app/web/src/reducers/quizReducer/quizReducerUtils';
import { ActionArrowLeft as ActionArrowLeftIcon } from '@front/icon';
import { EmphasizeButton, TextButton } from '@front/ui';
import { isDev, useAuth } from '@lib/web/apis';

import { AHA_SIGN_UP_EVENT_SENT } from '../../GlobalTrack';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5000,
    backdropFilter: 'blur(4px)',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'background.default',
    opacity: 0.7,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.6,
    span: {
      display: { xs: 'block', sm: 'inline' },
    },
    b: {
      color: 'primary.light',
    },
  },
  action: {
    mt: 5,
    display: 'grid',
    gap: 3,
    gridTemplateColumns: {
      sm: 'repeat(2, minmax(min-content, 155px))',
    },
    '& > a': {
      maxWidth: 375,
      width: '100%',
      mx: 'auto',
      minWidth: 'auto',
    },
  },
  footer: {
    mt: 4,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textTransform: 'lowerCase',
  },
};

function clearUserData() {
  axios.get('/api/auth/clear-cookie').catch(() => {
    // console.error(err);
  });
  localStorage.removeItem(AHA_STORE_QUIZ_ANSWERS_KEY);
  localStorage.removeItem(AHA_STORE_QUIZ_DURATION_KEY);
  localStorage.removeItem(AHA_SIGN_UP_EVENT_SENT);
}

const Login = () => {
  const { t } = useTranslation();
  const loginFailed = Router.query.loginFailed as string;
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);
  const { signUpUrl, loginUrl } = useAuthLinks();
  const landingPageLink = useLandingPageLink();
  useEffect(() => {
    setShow(true);

    return () => setShow(false);
  }, []);

  useBodyClass(show, 'login-lock');

  useEffect(() => {
    clearUserData();
    dispatch(updateViewedSlug(''));
  }, [dispatch]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.inner} />
      <Container sx={styles.container}>
        <Typography sx={styles.title} variant="h5">
          <Trans
            i18nKey="login.blocker.htmlTitle"
            components={{ 1: <span />, b: <b /> }}
          />
        </Typography>
        <Typography variant="body1" color="alpha.lightA75">
          {t('login.blocker.content', 'Start Practicing for Free')}
        </Typography>
        {typeof loginFailed === 'string' && (
          <Box mt={1}>
            <Typography variant="body2" color="error.dark">
              {isDev && loginFailed
                ? loginFailed
                : 'Login failed. Please try again.'}
            </Typography>
          </Box>
        )}
        <Box sx={styles.action}>
          <EmphasizeButton
            data-testid="signup-button"
            variant="filled"
            component={Link}
            href={signUpUrl}
          >
            {t('login.blocker.cta.signUp', 'Free Sign Up')}
          </EmphasizeButton>
          <EmphasizeButton
            data-testid="login-button"
            variant="outlined"
            component={Link}
            href={loginUrl}
          >
            {t('login.blocker.cta.login', 'Log In')}
          </EmphasizeButton>
        </Box>
        <Box sx={styles.footer}>
          <TextButton
            component="a"
            sx={styles.link}
            href={landingPageLink}
            rel="noopener noreferrer"
            prefixIcon={<ActionArrowLeftIcon />}
          >
            earnaha.com
          </TextButton>
        </Box>
      </Container>
    </Box>
  );
};

const HIDE_PATHNAMES_REGEX =
  /\/paywall|\/public|\/shared|\/signup|\/login|\/marketplace/i;
const EXCLUDE_PATHNAMES = ['/club/[slug]/shared/[sid]/invitation'];
const PUBLIC_PATHNAMES = ['/club/[slug]/q/[shortId]', '/404'];
export default function LoginBlocker() {
  const { isLoading, isLoggedOut } = useAuth();
  const { ready } = useTranslation();
  const pathname = useRouter().pathname;

  if (
    isLoading ||
    !ready ||
    (HIDE_PATHNAMES_REGEX.test(pathname || '') &&
      !EXCLUDE_PATHNAMES.includes(pathname)) ||
    PUBLIC_PATHNAMES.includes(pathname)
  )
    return null;
  if (isLoggedOut) return <Login />;

  return null;
}
