import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getIndicators, nonNullable } from '@front/helper';
import { MessageItemChip } from '@lib/ia/src/layouts/ChannelLayout/types';
import { useAuth } from '@lib/web/apis';
import { useAskForHelpMembers } from '@lib/web/ui';
import { getAgentIconPath } from '@lib/web/utils';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import { StreamChatGenerics } from '../../types';

import { useMessageInformation } from './useMessageInformation';

export const sharedChips = [
  {
    id: 'take_a_look',
    messageKey: 'message.shared.chips_1',
    defaultText: '🧐 I’ll take a look.',
  },
  {
    id: 'check_it_out',
    messageKey: 'message.shared.chips_2',
    defaultText: '👍 Awesome, I’ll check it out!',
  },
  {
    id: 'thanks_a_lot',
    messageKey: 'message.shared.chips_3',
    defaultText: '😁 Thanks a lot!',
  },
  {
    id: 'thanks_for_sharing',
    messageKey: 'message.shared.chips_4',
    defaultText: '😃 Thanks for sharing!',
  },
  {
    id: 'appreciate_it',
    messageKey: 'message.shared.chips_5',
    defaultText: '😊 Appreciate it!',
  },
];

const agentApiDataToChip = ({
  agent,
  message,
  disabled,
}: {
  agent: {
    agentId: string;
    agentName: string;
    agentUserName: string;
    agentPhoto: string;
  };
  message: StreamMessage<StreamChatGenerics>;
  disabled?: boolean;
}): MessageItemChip => ({
  label: {
    type: 'avatar',
    name: agent.agentName,
    avatarUrl: getAgentIconPath(agent),
    indicators: [
      {
        icon: 'ProfileClubAgent',
        name: 'AI',
        sx: {
          color: 'grey.500',
        },
      },
    ],
  },
  suffixIcon: 'OtherShareToDirect',
  eventMap: {
    click: {
      type: 'event',
      value: 'askForHelpChipClick',
    },
  },
  metadata: {
    threadMemberId: `agent_${agent.agentId}`,
    messageId: message.id,
  },
  disabled,
});

const userApiDataToChip = ({
  user,
  message,
  disabled,
}: {
  user: GetSuggestionClubMemberRes;
  message: StreamMessage<StreamChatGenerics>;
  disabled?: boolean;
}): MessageItemChip => ({
  label: {
    type: 'avatar',
    name: user.displayName || user.distinctName,
    avatarUrl: user.nftAvatar || user.avatar || '',
    indicators: getIndicators(user.indicator),
  },
  suffixIcon: 'OtherShareToDirect',
  eventMap: {
    click: {
      type: 'event',
      value: 'askForHelpChipClick',
    },
  },
  metadata: {
    threadMemberId: user.memberId,
    messageId: message.id,
  },
  disabled,
});

export const useMessageChips = ({
  message,
  channel,
  isLastItem,
}: {
  message: StreamMessage<StreamChatGenerics>;
  channel: Channel<StreamChatGenerics>;
  isLastItem?: boolean;
}) => {
  const { isBlocking } = useAuth();
  const { getMessageInformation } = useMessageInformation();
  const { t } = useTranslation('thread');
  const internalTag = channel.data?.internalTag;

  const askForHelpMembers = useAskForHelpMembers({
    clubId: channel.data?.clubId,
    enable: internalTag === 'ask-for-help',
  });

  const getMessageChips = useCallback((): MessageItemChip[] => {
    const { messageCreator, isAgentMessage } = getMessageInformation(message);
    if (message.customNotificationTag?.includes('dm.shared') && isLastItem) {
      return sharedChips.map((chip) => ({
        label: {
          type: 'text',
          value: t(chip.messageKey, chip.defaultText),
        },
        eventMap: {
          click: {
            type: 'event',
            value: 'sharedChipClick',
          },
        },

        disabled: isBlocking,
        metadata: {
          text: t(chip.messageKey, chip.defaultText),
        },
      }));
    }
    if (internalTag === 'ask-for-help' && isAgentMessage) {
      const { helpBot, clubAgents, suggestionMembers } = askForHelpMembers;

      return [
        ...(helpBot && messageCreator !== `agent_${helpBot.id}`
          ? [
              agentApiDataToChip({
                agent: {
                  agentId: helpBot.id,
                  agentName: helpBot.displayName,
                  agentUserName: helpBot.userName,
                  agentPhoto: helpBot.photoPath,
                },
                message,
                disabled: isBlocking,
              }),
            ]
          : []),
        ...clubAgents
          .filter((agent) => messageCreator !== `agent_${agent.agentId}`)
          .map((agent) =>
            agentApiDataToChip({
              agent,
              message,
              disabled: isBlocking,
            })
          ),
        ...suggestionMembers
          .filter((member) => messageCreator !== member.memberId)
          .map((user) =>
            userApiDataToChip({
              user,
              message,
              disabled: isBlocking,
            })
          ),
      ]
        .filter(nonNullable)
        .slice(0, 5);
    }

    return [];
  }, [
    getMessageInformation,
    message,
    isLastItem,
    internalTag,
    t,
    isBlocking,
    askForHelpMembers,
  ]);

  return useMemo(() => ({ getMessageChips }), [getMessageChips]);
};
