import { useCallback, useContext, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import { SharedUserContext } from '@lib/web/contexts';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadMemberTitle from '@lib/web/thread/ThreadMemberTitle';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import {
  isChannelAncestorInTheSameFilter,
  isChannelInTheSameFilter,
} from '@lib/web/thread/utils/channelUtils';
import { MenuComps } from '@lib/web/ui';

import DMMenuThreadViewOption from './DMMenuThreadViewOption';

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  titleText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unreadDot: {
    position: 'absolute',
    top: 0,
    right: '36px',
    '& circle': {
      r: 6, // radius 6 will make it 8px (from design)
    },
    '& svg': {
      circle: {
        fill: (theme: Theme) => theme.palette.error.dark,
      },
    },
  },
  unreadViewTitle: {
    fontWeight: 700,
  },
};

export type DmMenuThreadViewProps = {
  view: GetThreadViewRes;
};

export default function DmMenuThreadView({ view }: DmMenuThreadViewProps) {
  const { unreadChannels, chatClient } = useThread();
  const { viewName, channelFilters, viewMemberIds, viewNameMembers } =
    useThreadViewDetail(view);
  const { sharePath } = useContext(SharedUserContext);
  const [isHovered, setIsHovered] = useState(false);

  const viewHasUnreadChannels = useMemo(
    () =>
      unreadChannels?.some(
        (channel) =>
          isChannelInTheSameFilter(channel, channelFilters) ||
          isChannelAncestorInTheSameFilter(chatClient, channel, channelFilters)
      ),
    [unreadChannels, channelFilters, chatClient]
  );

  const handleMouseLeave = useCallback((): void => {
    setIsHovered(false);
  }, []);

  const handleMouseEnter = useCallback((): void => {
    setIsHovered(true);
  }, []);

  /**
   * XXX: ideally it should not happen, because every dm view should have at least 1 member (which is self)
   * It might comes from some edge case which we don't know how to reproduce for now
   */
  if (viewMemberIds.length === 0) return;

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<ThreadViewIcon view={view} size="sm" />}
          href={`${sharePath}/direct-messages/view/${view.id}`}
          extraComponent={
            <>
              {isHovered && (
                <DMMenuThreadViewOption
                  isHovered={isHovered}
                  resetHovered={handleMouseLeave}
                  view={view}
                />
              )}
              {viewHasUnreadChannels && (
                <Box component="span" sx={styles.unreadDot}>
                  <ChatNotificationDotIcon />
                </Box>
              )}
            </>
          }
        >
          <Box sx={styles.title}>
            {viewNameMembers?.length ? (
              <ThreadMemberTitle
                members={viewNameMembers}
                typographyVariant="body2"
                badgeSize={16}
              />
            ) : (
              <Box sx={styles.titleText}>
                {viewHasUnreadChannels ? (
                  <Typography sx={styles.unreadViewTitle}>
                    {viewName}
                  </Typography>
                ) : (
                  viewName
                )}
              </Box>
            )}
          </Box>
        </MenuComps.Button>
      </MenuComps.Section>
    </Box>
  );
}
