import React, { useCallback, useContext } from 'react';
import IaDataContext from '@lib/ia/src/core/IaData/IaDataContext';

export default function useInputGuard(guard?: string) {
  const { authGuardCheck } = useContext(IaDataContext);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (guard === 'auth' && authGuardCheck) {
        if (!authGuardCheck({ event })) {
          event.preventDefault();
          return;
        }
      }
    },
    [guard, authGuardCheck]
  );

  return {
    onMouseDown: handleMouseDown,
    onTouchStart: handleMouseDown,
  };
}
