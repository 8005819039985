import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Portal } from '@mui/material';
import { getPinChatVariant, PinArrowDirection, PinChat } from '@front/ui';
import { PinChatReferenceId, PinChatUserStatus, useAuth } from '@lib/web/apis';

import useOnboardingData from '../../hooks/utils/useOnboardingData';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 'tooltip',
  },
};

export default function Onboarding({
  target,
  positions,
  onlyShowCurrentStep,
  onFinish,
  onHide,
  onNext,
  ...rest
}: {
  target: PinChatReferenceId;
  positions: Record<
    number,
    {
      x?: number;
      y?: number;
      arrowOffsetY?: number;
      arrowOffsetX?: number;
      arrowDirection: PinArrowDirection;
    }
  >;
  onFinish: () => void;
  onHide: () => void;
  onNext?: (nextStep: number) => void;
  onlyShowCurrentStep?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  const [allChecked, setAllChecked] = useState(false);
  const onboardingData = useOnboardingData(target);
  const [step, setStep] = useState(0);
  const { member } = useAuth();
  const userId = member?.userId || '';

  const handleNext = (pin: GetIaPinChatOverlayDefaultViewRes) => {
    onboardingData?.viewPin(pin.pinChatId);
    setStep(step + 1);
    onNext?.(step + 1);
  };

  const handleFinish = async (pin: GetIaPinChatOverlayDefaultViewRes) => {
    setAllChecked(true);
    await onboardingData?.viewPin(pin.pinChatId);
    onFinish();
  };

  useEffect(() => {
    if (onboardingData && onboardingData.defaultStepNum > step) {
      setStep(onboardingData.defaultStepNum);
    }
  }, [onboardingData, step]);

  if (allChecked) return null;

  return (
    <Portal>
      <Box sx={styles.root} {...rest}>
        {onboardingData?.pins.map((pin) => {
          const userStatus = pin.pinChatUsers.find(
            (user) => user.userId === userId
          )?.status;
          if (onlyShowCurrentStep && pin.stepNum !== step) return null;
          return (
            <PinChat
              x={positions[pin.stepNum]?.x}
              y={positions[pin.stepNum]?.y}
              arrowOffsetX={positions[pin.stepNum]?.arrowOffsetX}
              arrowOffsetY={positions[pin.stepNum]?.arrowOffsetY}
              arrowDirection={positions[pin.stepNum]?.arrowDirection}
              key={pin.stepNum}
              step={pin.stepNum}
              variant={
                userStatus === PinChatUserStatus.Checked && pin.stepNum !== step
                  ? 'viewed'
                  : getPinChatVariant(pin.stepNum, step)
              }
              message={pin.desc}
              totalStep={onboardingData?.pins.length}
              onNext={() => handleNext(pin)}
              onFinish={() => handleFinish(pin)}
              onHide={onHide}
              doneText={t('button.Done')}
              nextText={t('button.Next')}
              hideText={t('button.Hide')}
            />
          );
        })}
      </Box>
    </Portal>
  );
}
