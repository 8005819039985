import { useRef, useState } from 'react';
import Router from 'next/router';
import { Box } from '@mui/material';
import { ProductEmojis } from '@app/web/src/components/ProductEmojis';
import { ProductEmojisHandler } from '@app/web/src/components/ProductEmojis/ProductEmojis';
import { CheckoutPanelKeys } from '@app/web/src/types/panel';
import { BaseLayoutRightPanelPortal, useBaseLayout } from '@front/ui';
import {
  DiscountCodePurchaseType,
  useAhaPointBalance,
  useProductOrderInfo,
} from '@lib/web/apis';
import { parseISO } from 'date-fns';

import CheckoutPanel from '../../CommonPanels/CheckoutPanel';
import DiscountCodePanel from '../../CommonPanels/DiscountCodePanel';
import PaymentLinkPanel from '../../CommonPanels/PaymentLinkPanel';
import useMarketplace from '../hooks/useMarketplace';
import useProductCheckout from '../hooks/useProductCheckout';

const styles = {
  summary: {
    width: '100%',
    display: 'flex',
  },
};

export default function ProductCheckoutRightPanel() {
  const { rightPanelTarget, openRightPanel, rightPanelParams } =
    useBaseLayout();

  const {
    currency,
    activeProduct,
    orderInfo,
    shareInfo,
    discountCode,
    isUseAhaPoint,
    setDiscountCode,
    setUseAhaPoint,
    createCheckoutOrder,
    loadOrderInfo,
    checkout,
  } = useProductCheckout();
  const { refreshMyProducts } = useMarketplace();
  const [linkLoading, setLinkLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const { mutate: reloadOrderInfo } = useProductOrderInfo(activeProduct?.id);
  const emojisRef = useRef<ProductEmojisHandler>(null);
  const { data: ahaPoint, mutate: reloadAhaPoint } = useAhaPointBalance();
  const points = ahaPoint ? ahaPoint.data.ahaPointTotalAmount : null;

  const price =
    activeProduct?.prices.find((item) => item.currency === currency)?.price ||
    0;

  const emojis = activeProduct?.properties.map((p) => p.unicode);

  const handleDiscountCodeSelected = (code?: GetMyPromoCodeRes) => {
    setDiscountCode(code);
    if (code) {
      openRightPanel(CheckoutPanelKeys.Checkout, { ...rightPanelParams });
    }
  };

  const handleRemoveDiscountCode = () => {
    setDiscountCode(undefined);
  };

  const handleCopyLink = async () => {
    setLinkLoading(true);
    const checkoutOrder = await createCheckoutOrder(true);
    if (checkoutOrder) {
      const orderId = checkoutOrder.orderId;
      const loaded = await loadOrderInfo(orderId);
      if (loaded) {
        openRightPanel(CheckoutPanelKeys.PaymentLink, { ...rightPanelParams });
      }
    }
    setLinkLoading(false);
    reloadAhaPoint();

    // to invalidate cache for the order info with the product id
    reloadOrderInfo();
  };

  const handleCheckout = async () => {
    setCheckoutLoading(true);
    const result = await checkout();
    setCheckoutLoading(false);
    if (result) {
      await refreshMyProducts();
      Router.push(
        `/marketplace/my-avatars?productId=${activeProduct?.id}&purchased=true`
      );
    }
    reloadAhaPoint();
  };

  const handleUsePointChange = (value: boolean) => {
    if (value !== isUseAhaPoint) {
      setUseAhaPoint(value);
    }
  };

  if (!activeProduct) return null;

  return (
    <BaseLayoutRightPanelPortal>
      {rightPanelTarget === CheckoutPanelKeys.Checkout && (
        <CheckoutPanel
          discountCode={discountCode}
          onRemoveDiscountCode={handleRemoveDiscountCode}
          currency={currency}
          price={price}
          onCopyLink={handleCopyLink}
          onCheckout={handleCheckout}
          onUseAhaPointChange={handleUsePointChange}
          linkLoading={linkLoading}
          checkoutLoading={checkoutLoading}
          isUseAhaPoint={isUseAhaPoint}
          ahaPoints={points}
          summary={
            <Box
              sx={styles.summary}
              onMouseEnter={() => emojisRef.current?.slideToRight()}
              onMouseLeave={() => emojisRef.current?.slideToLeft()}
            >
              <ProductEmojis
                emojis={emojis}
                size={40}
                borderRadius={4}
                gap="3px"
                fontSize={20}
                ref={emojisRef}
              />
            </Box>
          }
        />
      )}
      {rightPanelTarget === CheckoutPanelKeys.DiscountCode && (
        <DiscountCodePanel
          onSelect={handleDiscountCodeSelected}
          selected={discountCode}
          purchaseType={DiscountCodePurchaseType.AvatarOnly}
        />
      )}
      {rightPanelTarget === CheckoutPanelKeys.PaymentLink &&
        orderInfo &&
        shareInfo && (
          <PaymentLinkPanel
            paymentLink={`${process.env.APP_URL}/public/${shareInfo.sid}/payment-link/product`}
            expiresAt={parseISO(orderInfo.expireAt)}
          />
        )}
    </BaseLayoutRightPanelPortal>
  );
}
