import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import usePlanConfigs from '@app/web/src/widgets/MyPlanPage/hooks/usePlanConfigs';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
    mt: 1,
  },
};

export type SubscriptionPlanInfoListProps = {
  body: {
    subscriptionPlanId: string;
    subscriptionPlanCode: string;
    subscriptionPlanName: string;
  };
};

export default function SubscriptionPlanInfoList({
  body,
}: SubscriptionPlanInfoListProps) {
  const { t } = useTranslation('notification');
  const { getPlan } = usePlanConfigs();
  const plan = getPlan(body?.subscriptionPlanId);

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 152px 70px';
    const columnOrder = ['plan', 'subscription', 'total'];
    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'ProfileMyPlan',
                label: t('subscription.plan.info.table.Plan', 'Plan'),
              },
              {
                type: 'default',
                icon: 'OtherCalendarDay',
                label: t(
                  'subscription.plan.info.table.Subscription',
                  'Subscription'
                ),
              },
              {
                type: 'default',
                icon: 'PropertyTypeTag',
                label: t('subscription.plan.info.table.Total', 'Total'),
              },
            ],
          },
          rows: [
            {
              id: 'name',
              cells: {
                plan: {
                  type: 'iconText',
                  text: plan?.title,
                  icon: {
                    type: 'icon',
                    value: plan?.badgeIcon || 'BadgeBasicPlan',
                  },
                },
                subscription: {
                  type: 'text',
                  text: 'Monthly',
                },
                total: {
                  type: 'text',
                  text: `$${plan?.amount}`,
                  textSxProps: {
                    textAlign: 'right',
                    typography: 'numberBody3',
                  },
                },
              },
            },
          ],
        },
        settings: {
          rowHeight: 32,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [t, plan]);

  return (
    <Box sx={styles.root}>
      <IaLayouts layouts={config} />
    </Box>
  );
}
