import { MouseEvent, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { countWords } from '@front/helper';
import {
  ActionArrowLeft as ActionArrowLeftIcon,
  ActionArrowRight as ActionArrowRightIcon,
  TestSolution as TestSolutionIcon,
} from '@front/icon';
import { Button, useBaseLayout } from '@front/ui';
import { AnswerFormatType, ExamMode } from '@lib/web/apis';

import usePracticeFlowEvent from '../../../hooks/usePracticeFlowEvent';
import usePracticeLayout from '../../../hooks/usePracticeLayout';
import usePracticeQuestion from '../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionResult from '../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../hooks/usePracticeStaticData';
import usePracticeStatus from '../../../hooks/usePracticeStatus';

import AskForHelpButton from './AskForHelpButton';
import SubmitButton from './SubmitButton';

const styles = {
  buttons: {
    position: 'relative',
    px: 2.5,
    py: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    '& button:not(.icon-button)': {
      flex: { xs: 1, md: 'unset' },
      minWidth: 'unset',
    },
    '& button.icon-button': {
      width: { xs: 42, md: 36 },
    },
    '& button': {
      height: { xs: 42, md: 36 },
    },
  },
  nextButton: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      flex: 1,
      maxWidth: '207px !important',
    },
  }),
};

export const useFormatSpecificSubmittable = () => {
  const { mode } = usePracticeStaticData();
  const { answerFormatType, creatorQuestionInfo } = usePracticeQuestion();
  const { answers } = usePracticeQuestionAnswers();

  return useMemo(() => {
    if (answerFormatType === AnswerFormatType.Essay) {
      if (!creatorQuestionInfo?.isOfferWordLimit) return true;
      const wordCount = answers.length > 0 ? countWords(answers[0]) : 0;
      const limitMin = creatorQuestionInfo?.essayAnswerWordLimitMin || 0;
      const limitMax = creatorQuestionInfo?.essayAnswerWordLimitMax || 0;
      const minValid = limitMin === 0 || wordCount >= limitMin;
      const maxValid = limitMax === 0 || wordCount <= limitMax;

      if (mode === ExamMode.MockExam) {
        return maxValid;
      }
      return minValid && maxValid;
    }

    if (answerFormatType === AnswerFormatType.FreeResponse) {
      if (!creatorQuestionInfo?.isOfferWordLimit) return true;
      const wordCount = answers.length > 0 ? countWords(answers[0]) : 0;
      const limitMin = creatorQuestionInfo?.freeResponseAnswerWordLimitMin || 0;
      const limitMax = creatorQuestionInfo?.freeResponseAnswerWordLimitMax || 0;
      const minValid = limitMin === 0 || wordCount >= limitMin;
      const maxValid = limitMax === 0 || wordCount <= limitMax;

      if (mode === ExamMode.MockExam) {
        return maxValid;
      }
      return minValid && maxValid;
    }

    return true;
  }, [
    answerFormatType,
    creatorQuestionInfo?.isOfferWordLimit,
    creatorQuestionInfo?.essayAnswerWordLimitMin,
    creatorQuestionInfo?.essayAnswerWordLimitMax,
    creatorQuestionInfo?.freeResponseAnswerWordLimitMin,
    creatorQuestionInfo?.freeResponseAnswerWordLimitMax,
    answers,
    mode,
  ]);
};

type FooterActionsProps = {
  sx?: BoxProps['sx'];
};

export default function FooterActions({ sx }: FooterActionsProps) {
  const { t } = useTranslation('quiz');

  const { redirectPage } = usePracticeLayout();
  const { questionCount, questionIndex, isPaused } = usePracticeStatus();

  const {
    onNextQuestion,
    onPreviousQuestion,
    onSubmitQuestion,
    onLazySubmitQuestion,
    onCancelSubmitQuestion,
    onQuizResult,
  } = usePracticeFlowEvent();

  const { isPendingSubmit, isLoading, isSubmitted } =
    usePracticeQuestionStatus();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { isCorrect } = usePracticeQuestionResult();
  const { answers } = usePracticeQuestionAnswers();
  const { correctAnswerIds, correctAnswerValues } = usePracticeQuestionResult();
  const { panelKeys, mode } = usePracticeStaticData();
  const hasAnswer = answers.length > 0;
  const hasCorrectAnswer =
    correctAnswerIds.length > 0 || correctAnswerValues.length > 0;
  const formatSpecificSubmittable = useFormatSpecificSubmittable();

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { rightPanelOpened, rightPanelTarget, toggleRightPanel } =
    useBaseLayout();
  const solutionOpened =
    rightPanelOpened && rightPanelTarget === panelKeys.solution;

  const handleClick = (
    ev: MouseEvent<HTMLButtonElement>,
    fc?: (ev: MouseEvent<HTMLButtonElement>) => void
  ) => {
    ev.currentTarget.blur();

    if (fc) fc(ev);
  };

  const color = isCorrect ? 'success' : 'error';

  if (solutionOpened) {
    return (
      <Box sx={[styles.buttons, ...sxProps]}>
        <Button
          variant="outlined"
          onClick={(ev) => handleClick(ev, onPreviousQuestion)}
          disabled={questionIndex === 0}
          icon
        >
          <ActionArrowLeftIcon />
        </Button>

        {questionIndex === questionCount - 1 ? (
          <Button
            data-toast-anchor
            className="question-footer-result-button"
            variant="outlined"
            sx={styles.nextButton}
            disabled={isLoading}
            onClick={(ev) => handleClick(ev, onQuizResult)}
            suffixIcon={<ActionArrowRightIcon />}
          >
            {t('button.Results')}
          </Button>
        ) : (
          <Button
            data-toast-anchor
            sx={styles.nextButton}
            variant="outlined"
            onClick={(ev) => handleClick(ev, onNextQuestion)}
            suffixIcon={<ActionArrowRightIcon />}
          >
            {t('button.Next')}
          </Button>
        )}
      </Box>
    );
  }
  if (isSubmitted) {
    return (
      <Box sx={[styles.buttons, ...sxProps]}>
        <Button
          variant="outlined"
          disabled={questionIndex === 0}
          icon
          onClick={(ev) => handleClick(ev, onPreviousQuestion)}
        >
          <ActionArrowLeftIcon />
        </Button>

        {questionIndex === questionCount - 1 ? (
          <Button
            className="question-footer-result-button"
            variant="outlined"
            disabled={isLoading}
            loading={redirectPage === 'result'}
            suffixIcon={<ActionArrowRightIcon />}
            onClick={(ev) => handleClick(ev, onQuizResult)}
          >
            {t('button.Results')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={(ev) => handleClick(ev, onNextQuestion)}
            suffixIcon={<ActionArrowRightIcon />}
          >
            {t('button.Next')}
          </Button>
        )}
        <Button
          data-toast-anchor
          data-testid="quiz-solution-button"
          color={color}
          prefixIcon={<TestSolutionIcon />}
          onClick={(ev) =>
            handleClick(ev, () => toggleRightPanel(panelKeys.solution))
          }
        >
          {t('button.Solution')}
        </Button>
      </Box>
    );
  }

  const disabled = isPaused;

  return (
    <Box sx={[styles.buttons, ...sxProps]}>
      {mode === ExamMode.Practice && mdUp && <AskForHelpButton />}

      {!isPendingSubmit && (
        <>
          <Button
            variant="outlined"
            onClick={onPreviousQuestion}
            disabled={isLoading || questionIndex === 0}
            icon
          >
            <ActionArrowLeftIcon />
          </Button>

          {questionIndex === questionCount - 1 ? (
            <Button
              className="question-footer-result-button"
              variant="outlined"
              disabled={isLoading}
              loading={redirectPage === 'result'}
              suffixIcon={<ActionArrowRightIcon />}
              onClick={(ev) => handleClick(ev, onQuizResult)}
            >
              {t('button.Results')}
            </Button>
          ) : (
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={(ev) => handleClick(ev, onNextQuestion)}
              suffixIcon={<ActionArrowRightIcon />}
            >
              {t('button.Skip')}
            </Button>
          )}
        </>
      )}

      <SubmitButton
        data-toast-anchor
        data-testid="quiz-submit-button"
        disabled={
          !hasAnswer ||
          disabled ||
          !formatSpecificSubmittable ||
          !hasCorrectAnswer
        }
        loading={isLoading}
        isPendingSubmit={isPendingSubmit}
        lazySubmit={onLazySubmitQuestion}
        cancelSubmit={onCancelSubmitQuestion}
        submitAnswer={onSubmitQuestion}
      >
        {t('button.Submit')}
      </SubmitButton>
    </Box>
  );
}
