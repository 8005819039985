import getWorkflowPageConfig from '@app/web/src/layouts/pageConfig/workflow';
import { ParsedUrlQuery } from 'querystring';

import WorkflowDetailHeader from '../components/Headers/AgentWorkflowHeader';

export const getAllBreadcrumbsValues = (
  _pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const workflowId = (query.workflowId || '') as string;
  const prefix = `/workflow/${workflowId}`;
  const workflowConfig = getWorkflowPageConfig(t);
  return {
    entry: {
      text: workflowConfig.entry.title,
      icon: workflowConfig.entry.icon,
      href: `${prefix}`,
      customizeHeader: <WorkflowDetailHeader workflowId={workflowId} />,
    },
  };
};
