import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface ProductCheckoutValue {
  activeProduct?: GetSanityProductRes;
  orderInfo?: GetProductOrderInfoRes;
  shareInfo?: ShareProductOrderInfoRes;
  isUseAhaPoint: boolean;
  discountCode?: GetMyPromoCodeRes;
}

const initialValue = {
  isUseAhaPoint: true,
};

type ProductCheckoutContextValue = [
  ProductCheckoutValue,
  Dispatch<SetStateAction<ProductCheckoutValue>>
];

type MyPlanProviderProps = {
  children: ReactNode;
};

export const ProductCheckoutContext =
  createContext<ProductCheckoutContextValue>([initialValue, () => {}]);

export function ProductCheckoutProvider({ children }: MyPlanProviderProps) {
  const providerValue = useState<ProductCheckoutValue>(initialValue);

  return (
    <ProductCheckoutContext.Provider value={providerValue}>
      {children}
    </ProductCheckoutContext.Provider>
  );
}

export const ProductCheckoutConsumer = ProductCheckoutContext.Consumer;
