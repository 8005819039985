import { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { apis } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { useClubSlug } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import { getShareSettingsByPage } from '../utils/share';

import useCurrentUserId from './useCurrentUserId';

type SendToDirectOptions = {
  userIds?: string[];
  emails?: string[];
};

export default function useShareToDirect(virtualRouter?: VirtualRouter) {
  const nextRouter = useRouter();
  const router = virtualRouter || nextRouter;
  const { userShareSid } = useContext(SharedUserContext);
  const userId = useCurrentUserId();
  const pageShareParams = useMemo(
    () => getShareSettingsByPage(router),
    [router]
  );
  const clubSlug = useClubSlug();

  const shareToDirect = useCallback(
    ({ userIds = [], emails = [] }: SendToDirectOptions) => {
      if (!userShareSid || !pageShareParams) return Promise.reject();

      const params = {
        sharedPathname: router.pathname,
        sharedPathQuery: JSON.stringify(router.query),
        link: router.asPath,
        clubSlug,
        sid: userShareSid,
        receiverUserIds: userIds,
        shareUserId: userId,
        emails,
        ...pageShareParams,
      };

      return call(apis.shared.sharePage(params));
    },
    [
      clubSlug,
      pageShareParams,
      router.asPath,
      router.pathname,
      router.query,
      userId,
      userShareSid,
    ]
  );

  return {
    shareToDirect,
    pageShareParams,
    userShareSid,
  };
}
