import { Box, Theme, useMediaQuery } from '@mui/material';

import RankingItem, { RankingItemProps } from './RankingItem';

type FriendRankingListProps = {
  items: GetFollowerRes[];
  loading?: boolean;
};

export default function FriendRankingList({
  items,
  loading,
}: FriendRankingListProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const ctaCol = mdUp ? '16px 101px' : '8px 32px';
  const gridTemplateColumns = `1fr 8px 16px 16px 32px 16px 34px 16px 34px ${ctaCol}`;

  const dataItems: RankingItemProps[] = items.map((item) => {
    return {
      userInfo: {
        userId: item.userId,
        memberId: item.memberId,
        avatarUrl: item.nftAvatar || item.avatar,
        displayName: item.displayName,
        distinctName: item.userName,
        indicator: item.indicator,
        isFollower: item.isFollower,
        isFollowing: item.isFollowing,
      },
      data: {
        leagueTier: item.leagueInfo?.leagueTier,
        rank: item.leagueInfo?.rank,
        score: item.leagueInfo?.score,
        zone: item.leagueInfo?.zone,
        longestStreaks: item.leagueInfo?.longestStreaks,
      },
      settings: {
        gridTemplateColumns,
      },
    };
  });

  return (
    <Box>
      {dataItems.map((item, index) => (
        <RankingItem key={index} {...item} />
      ))}
      {loading && <RankingItem.Skeleton />}
    </Box>
  );
}
