import { ClubMenuVariant } from '../../../../context';
import useCurrentMenu from '../../../../hooks/useCurrentMenu';

import AnalyticsMenu from './AnalyticsMenu';
import PlaylistsMenu from './PlaylistsMenu';
import ThreadsMenu from './ThreadsMenu';

const getVariantMenu = (variant: ClubMenuVariant | null) => {
  switch (variant) {
    case 'threads':
      return <ThreadsMenu />;
    case 'analytics':
      return <AnalyticsMenu />;
    case 'playlists':
      return <PlaylistsMenu />;

    default:
      return null;
  }
};

export default function DynamicClubMenu() {
  const { currentClubMenu } = useCurrentMenu();

  return getVariantMenu(currentClubMenu);
}
