import { TFunction } from 'i18next';

const getClubPageConfig = (t: TFunction) => ({
  startPractice: {
    title: t('club.startPractice.title'),
    shortTitle: t('club.startPractice.shortTitle'),
    icon: 'TestStartPractice',
  },
  allThreads: {
    title: t('club.allThreads.title'),
    shortTitle: t('club.allThreads.shortTitle'),
    icon: 'ThreadsThreadView',
  },
  rankings: {
    title: t('club.rankings.title'),
    icon: 'TestTrophy',
  },
  addFriends: {
    title: t('club.addFriends.title'),
    shortTitle: t('club.addFriends.shortTitle'),
    icon: 'OtherAdd',
  },
  settings: {
    title: t('club.settings.title'),
    shortTitle: t('club.settings.shortTitle'),
    icon: 'ProfileSetting',
  },
  settingsClub: {
    title: t('club.settings_club.title'),
    icon: 'MainApps',
  },
  settingsExamSettings: {
    title: t('club.settings_examSettings.title'),
    icon: 'ProfileSetting',
  },
  settingsEditorSettings: {
    title: t('club.settings_editorSettings.title'),
    icon: 'ProfileSetting',
  },
  settingsFormats: {
    title: t('club.settings_formats.title'),
    icon: 'ChatQuestionList',
  },
  settingsMembers: {
    title: t('club.settings_members.title'),
    icon: 'PrivacyFriends',
  },
  settingsManageAI: {
    title: t('club.settings_manageAI.title'),
    icon: 'ProfileManageAIBot',
  },
  settingsRequests: {
    title: t('club.settings_requests.title'),
    icon: 'OtherAddFriend',
  },
  settingsLeaveClub: {
    title: t('club.settings_leaveClub.title'),
    icon: 'OtherAddFriend',
  },
});

export default getClubPageConfig;
