import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import Box from '@mui/material/Box';
import {
  FollowButton,
  SendButton,
} from '@app/web/src/components/FriendButtons';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import {
  OtherAddFriend as OtherAddFriendIcon,
  OtherFollowing as OtherFollowingIcon,
} from '@front/icon';
import {
  Button,
  DropdownButton,
  DropdownButtonOption,
  IconButton,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  apis,
  getUserProfileSocialKey,
  useMemberInfos,
  useUserProfileSocial,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    gap: 2,
  },
  tableContainer: {
    display: 'flex',
    '& .display-table-layout': {
      p: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

type DisplayTableFollowButtonProps = {
  userId: string;
  onToggleFollow: () => void;
};

function DisplayTableFollowButton({
  userId,
  onToggleFollow,
}: DisplayTableFollowButtonProps) {
  const { data } = useUserProfileSocial(userId);
  if (!data) return <IconButton loading customSize={24} />;
  const userSocial = data.data;

  if (userSocial.isFollowing) {
    return (
      <SendButton
        userId={userId}
        memberId={userSocial.memberId}
        buttonType="iconButton"
        challengeDisabled={true}
      />
    );
  }

  const handleToggleFollow = (_: string, promise: Promise<any>) => {
    promise.then(() => onToggleFollow());
  };

  return (
    <FollowButton
      isFollowing={userSocial.isFollowing}
      isFollower={userSocial.isFollower}
      userId={userId}
      buttonType="iconButton"
      autoFetchSocialData
      onToggleFollow={handleToggleFollow}
    />
  );
}

export type SuggestedUsersToFollowProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.follow.suggest.list' }>;
};

export default function SuggestedUsersToFollow({
  cta,
}: SuggestedUsersToFollowProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);
  const [loading, setLoading] = useState(false);
  const userIds = cta.users.map((item) => item.userId);
  const { data, mutate: multiUserMutate } = useMemberInfos({
    userIds,
    isGetFollowInfo: true,
  });
  const { mutate } = useSWRConfig();
  const { openProfile } = useOpenGlobalProfilePanel();

  const config = useMemo<IaLayoutConfig[]>(() => {
    const gridTemplateColumns = '1fr 107px 125px 32px';
    const columnOrder = ['name', 'location', 'dreamSchool', 'action'];

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'MainProfileSolid',
                label: t('follow.suggested.users.table.name', 'Name'),
              },
              {
                type: 'default',
                icon: 'OtherLocation',
                label: t('follow.suggested.users.table.location', 'Location'),
              },
              {
                type: 'default',
                icon: 'ProfileDreamCollege',
                label: t(
                  'follow.suggested.users.table.dreamSchool',
                  'Dream School'
                ),
              },
              {
                type: 'default',
              },
            ],
          },
          rows: cta.users
            .filter((_, index) => (collapse ? index === 0 : true)) // 1 if collapsed => show 1st row
            .map((user) => {
              return {
                id: user.userId,
                cells: {
                  name: {
                    type: 'avatarText',
                    userId: user.userId,
                  },
                  location: {
                    type: 'text',
                    text:
                      user.city && user.country
                        ? `${user.city}, ${user.country}`
                        : '',
                  },
                  dreamSchool: {
                    type: 'avatarText',
                    text: user.dreamSchoolName,
                  },
                  action: {
                    type: 'custom',
                    renderKey: 'followButton',
                    metadata: {
                      userId: user.userId,
                    } as DisplayTableFollowButtonProps,
                  },
                },
                clickAction: {
                  type: 'event',
                  value: 'showProfile',
                },
                metadata: {
                  userId: user.userId,
                },
              };
            }),
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'textButton',
                action: 'toggleCollapse',
                text: collapse
                  ? t('follow.suggested.users.table.seeMore', 'See more')
                  : t('follow.suggested.users.table.hide', 'Hide'),
                suffixIcon: collapse
                  ? 'ActionChevronRightSmall'
                  : 'ActionChevronUp',
                sx: { ml: 0 },
                containerSx: { pt: 1 },
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [collapse, cta.users, t]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
      showProfile: {
        action: ({ metadata }: { metadata: { userId: string } }) => {
          openProfile(metadata.userId);
        },
      },
    }),
    [openProfile]
  );

  const renders = useMemo(() => {
    return {
      followButton: (ev: DisplayTableFollowButtonProps) => (
        <DisplayTableFollowButton
          userId={ev.userId}
          onToggleFollow={multiUserMutate}
        />
      ),
    };
  }, [multiUserMutate]);

  const isFollowAll = useMemo(() => {
    const users = data?.data || [];
    return !users.some((user) => !user.isFollowing);
  }, [data?.data]);

  const handleUnfollowAll = useCallback(async () => {
    setLoading(true);
    const users = data?.data || [];
    const followingUsers = users.filter((user) => user.isFollowing);
    const followingUserApis = followingUsers.map((user) =>
      call(apis.member.unfollowUser(user.userId))
    );
    await Promise.all(followingUserApis);
    followingUsers.forEach((user) => {
      mutate(getUserProfileSocialKey({ userId: user.userId }));
    });
    await multiUserMutate();
    setLoading(false);
  }, [data?.data, multiUserMutate, mutate]);

  const handleFollowAll = useCallback(async () => {
    setLoading(true);
    const users = data?.data || [];
    const unfollowUsers = users.filter((user) => !user.isFollowing);
    const unfollowUserApis = unfollowUsers.map((user) =>
      call(apis.member.followUser(user.userId))
    );
    await Promise.all(unfollowUserApis);
    unfollowUsers.forEach((user) => {
      mutate(getUserProfileSocialKey({ userId: user.userId }));
    });
    await multiUserMutate();
    setLoading(false);
  }, [data?.data, multiUserMutate, mutate]);

  const dropdownOptions = useMemo((): DropdownButtonOption[] => {
    if (!isFollowAll) return [];
    return [
      {
        display: t('follow.suggested.users.button.Unfollow All'),
        icon: 'OtherUnfollow',
        onClick: handleUnfollowAll,
      },
    ];
  }, [handleUnfollowAll, isFollowAll, t]);

  return (
    <Box sx={styles.root}>
      <Box>
        <IaRenderContextProvider value={renders}>
          <IaActionContextProvider availableActions={availableActions}>
            <IaLayouts layouts={config} />
          </IaActionContextProvider>
        </IaRenderContextProvider>
      </Box>
      <Box sx={styles.buttonContainer}>
        <DropdownButton options={dropdownOptions}>
          <Button
            prefixIcon={
              isFollowAll ? <OtherFollowingIcon /> : <OtherAddFriendIcon />
            }
            loading={loading}
            onClick={isFollowAll ? undefined : handleFollowAll}
          >
            {isFollowAll
              ? t('follow.suggested.users.button.Following All')
              : t('follow.suggested.users.button.Follow All')}
          </Button>
        </DropdownButton>
      </Box>
    </Box>
  );
}
