import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CreateQuizPanelButton from '@app/web/src/components/CreateQuiz/CreateQuizPanelButton';
import { CreateQuizFormPartTypes } from '@app/web/src/components/CreateQuiz/type';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  ChatHashtag as ChatHashtagIcon,
  ProfilePlaylist as ProfilePlaylistIcon,
} from '@front/icon';
import {
  BaseLayoutAction,
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  EmphasizeButton,
  NumberCard,
  useBaseRightPanel,
} from '@front/ui';
import { useAnalyticsWeaknessTopic } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import useQuizHashtagAndEmojiActions from '../hooks/useQuizHashtagAndEmojiActions';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    px: { xs: 2.5, md: '12px' },
    position: 'relative',
  },
  title: { mb: 2, alignItems: 'flex-start' },
  infoCards: { display: 'grid', gap: 2 },
  layoutAction: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 1,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      whiteSpace: 'nowrap',
      '& .em-button-base': {
        px: 0,
      },
    },
  },
  card: {
    minHeight: 'unset',
  },
};

const disabledParts = ['topics', 'difficulty'] as CreateQuizFormPartTypes[];

export default function HashtagDetailPanel() {
  const { t } = useTranslation('analytics');
  const { getRightParams, rightPanelTarget } =
    useBaseRightPanel<GlobalPanelParams>();
  const { title, tagId } = getRightParams(GlobalPanelKeys.GlobalHashtagsDetail);
  const { sectionId } = useCurrentIaClub();
  const { data } = useAnalyticsWeaknessTopic(sectionId);

  const { mistakeRatio, overtimeRatio, totalCount, tagCode } = useMemo(() => {
    const {
      mistake = [],
      overtime = [],
      notPracticedYet = [],
    } = data?.data ?? {};

    const mistakeTag = mistake.find((tag) => tag.tagId === tagId);
    const overtimeTag = overtime.find((tag) => tag.tagId === tagId);
    const notPracticedYetTag = notPracticedYet.find(
      (tag) => tag.tagId === tagId
    );

    return {
      mistakeRatio: Math.round((mistakeTag?.questionRatioByType ?? 0) * 100),
      overtimeRatio: Math.round((overtimeTag?.questionRatioByType ?? 0) * 100),
      totalCount: mistakeTag?.questionCount || overtimeTag?.questionCount || 0,
      tagCode:
        mistakeTag?.tagCode ||
        overtimeTag?.tagCode ||
        notPracticedYetTag?.tagCode,
    };
  }, [data, tagId]);

  const { goPlaylistPage } = useQuizHashtagAndEmojiActions();

  return (
    <BaseLayoutRightPanel
      titleIcon={<ChatHashtagIcon width="16" height="16" />}
      title={title}
      inactive={rightPanelTarget !== GlobalPanelKeys.GlobalHashtagsDetail}
    >
      <Box sx={styles.wrapper}>
        <BaseLayoutScrollContainer sx={styles.main}>
          <Box sx={styles.infoCards}>
            <NumberCard
              sx={styles.card}
              title={t('hashtag.RHS.question.title', 'Total Questions Taken')}
              titleIcon="ChatQuestionList"
              loading={!data}
            >
              <NumberCard.Value>{totalCount}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.question.description', 'Questions')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.mistake.title', 'Mistakes')}
              titleIcon="ActionCloseThick"
              loading={!data}
            >
              <NumberCard.Value suffix=" %">{mistakeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.mistake.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.overtime.title', 'Overtime')}
              titleIcon="TestClockSolid"
              loading={!data}
            >
              <NumberCard.Value suffix=" %">{overtimeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.overtime.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
          </Box>
        </BaseLayoutScrollContainer>
        <BaseLayoutAction sx={styles.layoutAction}>
          <EmphasizeButton
            variant="outlined"
            onClick={goPlaylistPage}
            prefixIcon={<ProfilePlaylistIcon />}
          >
            {t('hashtag.RHS.btn.view', 'View Playlist')}
          </EmphasizeButton>
          <CreateQuizPanelButton
            withPanel
            panelKeyPrefix={GlobalPanelKeys.GlobalHashtagsDetail}
            defaultValues={{
              tags: tagCode ? [tagCode] : undefined,
            }}
            loading={!tagCode && !!tagId}
            disabledParts={disabledParts}
          >
            {t('hashtag.RHS.btn.start', 'Start')}
          </CreateQuizPanelButton>
        </BaseLayoutAction>
      </Box>
    </BaseLayoutRightPanel>
  );
}
