import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Typography } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import useStripeProduct from '@app/web/src/hooks/stripe/useStripeProduct';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import {
  CheckoutPanelKeys,
  CheckoutPanelParams,
} from '@app/web/src/types/panel';
import { ProfileProPlan as ProfileProPlanIcon } from '@front/icon';
import {
  BaseLayoutRightPanelPortal,
  SquareAvatar,
  useBaseLayout,
} from '@front/ui';
import {
  apis,
  DiscountCodePurchaseType,
  SubscriptionAction,
  useAhaPointBalance,
  useAiTokenBalance,
  useAuth,
} from '@lib/web/apis';
import { callWithToast, trackEvent } from '@lib/web/utils';
import { parseISO } from 'date-fns';

import CheckoutPanel from '../../CommonPanels/CheckoutPanel';
import DiscountCodePanel from '../../CommonPanels/DiscountCodePanel';
import PaymentLinkPanel from '../../CommonPanels/PaymentLinkPanel';
import { getSubscriptionAction } from '../config';
import useSubscriptionCheckout from '../hooks/useSubscriptionCheckout';

const styles = {
  summaryBoxWrapper: {
    display: 'flow-root',
    position: 'relative',
    borderRadius: '18px',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid transparent',
      background:
        'linear-gradient(135deg, rgba(255,255,255, 0.35) 0%, rgba(255,255,255,0.05) 100%) border-box',
      WebkitMask:
        'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
      borderRadius: '18px',
    },
  },
  summaryBox: {
    width: '100%',
    display: 'flex',
    gap: 1,
    p: '12px',
  },
  planIcon: {
    my: '3px',
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    '& svg': {
      color: 'text.primary',
    },
  },
  summaryBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  summaryBoxLabel: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '22px',
  },
  summaryBoxDescription: {
    fontSize: 12,
    fontWeight: 400,
  },
};

const RETRY_COUNT = 5;
const RETRY_TIMEOUT = 1000;

export default function SubscriptionCheckoutRightPanel() {
  const { t } = useTranslation('profile');
  const { rightPanelTarget, getRightParams, openRightPanel, closeRightPanel } =
    useBaseLayout<CheckoutPanelParams>();
  const {
    action,
    activePlan,
    shareInfo,
    discountCode,
    setDiscountCode,
    setUseAhaPoint,
    isUseAhaPoint,
    currency,
    checkout,
    createShareableCheckoutLink,
  } = useSubscriptionCheckout();
  const checkoutParams = getRightParams(CheckoutPanelKeys.Checkout);
  const { member } = useAuth();
  const [linkLoading, setLinkLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const { subscriptionCheckoutSession, reloadMembership } =
    useMemberSubscription();
  const { data: ahaPoint, mutate: reloadAhaPoint } = useAhaPointBalance();
  const points = ahaPoint ? ahaPoint.data.ahaPointTotalAmount : null;
  const reloadMembershipRef = useRef<NodeJS.Timeout>();
  const { mutate: reloadAiTokenBalance } = useAiTokenBalance();

  const myMemberId = member?.memberId || '';

  const { price, isLoading: isPriceLoading } = useStripeProduct(
    activePlan?.product?.productId
  );

  const handleDiscountCodeSelected = (code?: GetMyPromoCodeRes) => {
    setDiscountCode(code);
    if (code) {
      openRightPanel(CheckoutPanelKeys.Checkout);
    }
  };

  const handleRemoveDiscountCode = () => {
    setDiscountCode(undefined);
  };

  const handleCopyLink = async () => {
    setLinkLoading(true);
    await createShareableCheckoutLink();
    await reloadMembership();
    openRightPanel(CheckoutPanelKeys.PaymentLink);
    setLinkLoading(false);
    void reloadAhaPoint();
  };

  const completeUpgrade = async () => {
    void reloadAiTokenBalance();
    await callWithToast(
      apis.func.threadUpdateUpgradeMessage({ requestUserId: myMemberId }),
      {
        showLoading: false,
      }
    );
  };

  const handleReloadMembership = (retryCountLeft = RETRY_COUNT) => {
    clearTimeout(reloadMembershipRef.current);
    reloadMembershipRef.current = setTimeout(async () => {
      retryCountLeft -= 1;
      const data = await reloadMembership();
      const subscriptionInfo = data?.data.subscriptionInfo;
      if (
        subscriptionInfo &&
        subscriptionInfo.productId === activePlan?.product?.productId
      ) {
        await completeUpgrade();
        closeRightPanel();
        setCheckoutLoading(false);
        return;
      }
      if (retryCountLeft > 0) {
        handleReloadMembership(retryCountLeft);
      }
    }, RETRY_TIMEOUT);
  };

  const handleCheckout = async () => {
    setCheckoutLoading(true);
    const subscriptionAction = getSubscriptionAction(action, activePlan);
    trackEvent('click', {
      elementName: 'checkout-button',
      action: subscriptionAction || undefined,
    });
    const success = await checkout();
    void reloadAhaPoint();
    if (!success) {
      setCheckoutLoading(false);
      return;
    }
    if (subscriptionAction === SubscriptionAction.Cancel) {
      // downgrade from any plan to the Free plan
      await reloadMembership();
      closeRightPanel();
      setCheckoutLoading(false);
      return;
    }
    if (subscriptionAction === SubscriptionAction.Update) {
      handleReloadMembership();
    }
  };

  const handleUsePointChange = (value: boolean) => {
    if (value !== isUseAhaPoint) {
      setUseAhaPoint(value);
    }
  };

  const handleCancel = () => {
    closeRightPanel();
  };

  useEffect(() => {
    return () => {
      clearTimeout(reloadMembershipRef.current);
    };
  }, []);

  useEffect(() => {
    if (checkoutParams.useToken) {
      setUseAhaPoint(true);
    }
  }, [checkoutParams.useToken, setUseAhaPoint]);

  const isCreateSubscription = action === 'subscribe';
  const isUpgrade = action === 'upgrade' || action === 'switchToYearly';
  let checkoutProps = undefined;
  if (action === 'downgrade') {
    checkoutProps = { text: t('button.Subscribe_downgrade', 'Downgrade') };
  } else if (action === 'switchToMonthly' || action === 'switchToYearly') {
    checkoutProps = { text: t('button.Subscribe_update', 'Update') };
  } else if (action === 'upgrade') {
    checkoutProps = { text: t('button.Subscribe_upgrade', 'Upgrade') };
  }
  let billingCycleLabel;
  if (activePlan?.type === 'forever') {
    billingCycleLabel = t('subscription.forever', 'Forever');
  } else if (activePlan?.type === 'monthly') {
    billingCycleLabel = t('subscription.payMonthly', 'Pay Monthly');
  } else if (activePlan?.type === 'yearly') {
    billingCycleLabel = t('subscription.payAnnual', 'Pay Annual');
  }

  if (!activePlan) return null;
  return (
    <BaseLayoutRightPanelPortal>
      {rightPanelTarget === CheckoutPanelKeys.Checkout && (
        <CheckoutPanel
          discountCode={discountCode}
          onRemoveDiscountCode={handleRemoveDiscountCode}
          currency={currency}
          price={price}
          onCopyLink={handleCopyLink}
          onCheckout={handleCheckout}
          onCancel={handleCancel}
          onUseAhaPointChange={handleUsePointChange}
          linkLoading={linkLoading}
          checkoutLoading={checkoutLoading || isPriceLoading}
          isUseAhaPoint={isUseAhaPoint}
          ahaPoints={points}
          autoHidePaymentLink={false}
          alwaysHidePaymentLink={!isCreateSubscription}
          discountDisabled={!isCreateSubscription && !isUpgrade}
          showCancel={!isCreateSubscription}
          checkoutProps={checkoutProps}
          summary={
            <Box sx={styles.summaryBoxWrapper}>
              <Box sx={styles.summaryBox}>
                <SquareAvatar sx={styles.planIcon} size={16}>
                  <ProfileProPlanIcon width={9.6} height={9.6} />
                </SquareAvatar>
                <Box sx={styles.summaryBoxContent}>
                  <Typography sx={styles.summaryBoxLabel}>Plan</Typography>
                  {billingCycleLabel && (
                    <Typography sx={styles.summaryBoxDescription}>
                      {billingCycleLabel}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          }
        />
      )}
      {rightPanelTarget === CheckoutPanelKeys.DiscountCode && (
        <DiscountCodePanel
          onSelect={handleDiscountCodeSelected}
          selected={discountCode}
          purchaseType={DiscountCodePurchaseType.SubscriptionOnly}
        />
      )}
      {rightPanelTarget === CheckoutPanelKeys.PaymentLink &&
        subscriptionCheckoutSession &&
        shareInfo && (
          <PaymentLinkPanel
            paymentLink={`${process.env.APP_URL}/public/${shareInfo.sid}/payment-link/subscription`}
            expiresAt={parseISO(subscriptionCheckoutSession.expireAt)}
          />
        )}
    </BaseLayoutRightPanelPortal>
  );
}
