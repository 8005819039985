import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutPanelKeys,
  CheckoutPanelParams,
} from '@app/web/src/types/panel';
import { ProductCheckoutProvider } from '@app/web/src/widgets/MarketplacePage/contexts/checkoutContext';
import { MarketplaceProvider } from '@app/web/src/widgets/MarketplacePage/contexts/context';
import useMarketplace from '@app/web/src/widgets/MarketplacePage/hooks/useMarketplace';
import useProductCheckout from '@app/web/src/widgets/MarketplacePage/hooks/useProductCheckout';
import MarketplaceRightPanels from '@app/web/src/widgets/MarketplacePage/MarketplaceRightPanels';
import ProductCheckoutRightPanel from '@app/web/src/widgets/MarketplacePage/ProductCheckoutRightPanel';
import { OtherResend as OtherResendIcon } from '@front/icon';
import { Button, useBaseLayout } from '@front/ui';
import { useProduct } from '@lib/web/apis';

const styles = {
  alignRight: {
    ml: 'auto',
  },
};

type AvatarCheckoutProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.marketplace.order.retry' }>;
};

function AvatarCheckoutRoot({ cta }: AvatarCheckoutProps) {
  const { t } = useTranslation('notification');
  const { setSelected } = useMarketplace();
  const { setActiveProduct } = useProductCheckout();
  const { data } = useProduct(cta.avatarProductId);

  const { openRightPanel } = useBaseLayout<CheckoutPanelParams>();

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!data) return;
    setSelected(data.data);
    setActiveProduct(data.data);
    setTimeout(() => {
      openRightPanel(CheckoutPanelKeys.Checkout);
    });
  };

  return (
    <Button
      prefixIcon={<OtherResendIcon />}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      sx={styles.alignRight}
      loading={!data}
    >
      {t('cta.marketplace.order.retry.button.Retry', 'Retry')}
    </Button>
  );
}

export default function AvatarCheckout({ cta }: AvatarCheckoutProps) {
  return (
    <MarketplaceProvider>
      <ProductCheckoutProvider>
        <AvatarCheckoutRoot cta={cta} />
        <MarketplaceRightPanels />
        <ProductCheckoutRightPanel />
      </ProductCheckoutProvider>
    </MarketplaceProvider>
  );
}
