import { NextRouter } from 'next/router';
import { getAgentIconPath } from '@front/ui';
import { IaRichText, IaRichTextTagObj } from '@lib/ia/src/core/types';
import { InternalLinkType } from '@lib/ia/src/hooks/useLinkInternalDetail/types';
import {
  SharedNotificationPathname,
  SharedNotificationTag,
} from '@lib/web/apis';
import { getClubGroupIconPath, getClubIconPath } from '@lib/web/utils';
import { TFunction } from 'i18next';

const validQueries = [
  'slug',
  'quizId',
  'roundNo',
  'questionId',
  'userId',
  'category',
  'agentId',
  'groupId',
  'productId',
  'panel',
];
export const getQueries = (
  payload: GetThreadMessageNotificationPayloadRes
): Record<string, string> => {
  const { sharedPathQuery } = payload;
  let query = {};

  try {
    const urlQuery = JSON.parse(sharedPathQuery || '{}');

    query = Object.entries(urlQuery).reduce(
      (acc, [key, value]) =>
        validQueries.includes(key)
          ? {
              ...acc,
              [key]: String(value),
            }
          : acc,
      {}
    );
  } catch (error) {
    /* do nothing */
  }

  return query;
};

const getOriginalSharedPath = (
  path: string,
  queries: Record<string, string | undefined>
) => {
  Object.entries(queries).forEach(([key, value]) => {
    if (value) path = path.replace(`[${key}]`, String(value));
  });

  return path;
};

const createLinkMessage = (
  pathnameSettings: { icon?: string; title: string },
  asPath: string,
  sid: string,
  badgeSrc?: string,
  badgeText?: string
): IaRichTextTagObj => {
  return {
    type: 'link' as const,
    icon: pathnameSettings.icon,
    text: pathnameSettings.title,
    action: { type: 'link', value: `/shared/${sid}${asPath}` },
    badgeSrc,
    badgeText,
  };
};

export const messageSomeoneSharePage = (
  t: TFunction,
  payload: GetThreadMessageNotificationPayloadRes,
  getPathnameSettings: (
    t: TFunction,
    router: NextRouter | VirtualRouter
  ) => {
    icon?: string;
    title: string;
  } | null
): IaRichText | undefined => {
  if (!payload.sharedPathname || !payload.sid) return undefined;

  const query = getQueries(payload);
  const asPath = getOriginalSharedPath(payload.sharedPathname, query);
  const pathnameSettings = getPathnameSettings(t, {
    pathname: payload.sharedPathname,
    query,
    asPath,
  });

  if (!pathnameSettings || asPath.includes('[')) return undefined;
  const baseMessage = createLinkMessage(
    pathnameSettings,
    asPath,
    payload.sid,
    payload.owner?.avatarUrl || '',
    payload.owner?.fullName || payload.owner?.userName
  );

  const clubLink = payload.club
    ? [
        {
          type: 'link' as const,
          src:
            payload.club.clubIcon ||
            getClubIconPath(payload.club.clubName || ''),
          action: {
            type: 'link' as const,
            value: `/club/${payload.club.clubSlug}`,
          },
          text: payload.club.clubName,
          tooltipLinkType: InternalLinkType.Club,
          tooltipLinkSourceId: payload.club.clubId,
        },
      ]
    : [];
  switch (payload.tag) {
    case SharedNotificationTag.DmSharedAgentGlobal: {
      baseMessage.tooltipLinkSourceId = payload.agent?.agentId;
      baseMessage.tooltipLinkType = InternalLinkType.Agent;
      baseMessage.text = payload.agent?.agentName || pathnameSettings.title;
      baseMessage.badgeSrc = undefined;
      baseMessage.src =
        payload.agent?.agentIcon || getAgentIconPath(payload.agent);
      baseMessage.icon =
        payload.agent?.agentIcon === undefined ? baseMessage.icon : undefined;
      return [baseMessage];
    }
    case SharedNotificationTag.DmSharedClubGlobal: {
      baseMessage.text = payload.club?.clubName || pathnameSettings.title;
      baseMessage.src =
        payload.club?.clubIcon || getClubIconPath(payload.club?.clubName || '');
      baseMessage.badgeSrc = undefined;
      baseMessage.badgeText = undefined;
      baseMessage.tooltipLinkType = InternalLinkType.Club;
      baseMessage.tooltipLinkSourceId = payload.club?.clubId;
      return [baseMessage];
    }
    case SharedNotificationTag.DmSharedClubGroupGlobal: {
      baseMessage.text =
        payload.clubGroup?.clubGroupName || pathnameSettings.title;
      baseMessage.src =
        payload.clubGroup?.clubGroupIcon ||
        getClubGroupIconPath(payload.clubGroup?.clubGroupName || '');
      baseMessage.badgeSrc = undefined;
      baseMessage.badgeText = undefined;
      baseMessage.tooltipLinkType = InternalLinkType.ClubGroup;
      baseMessage.tooltipLinkSourceId = payload.clubGroup?.clubGroupId;
      return [baseMessage];
    }
    case SharedNotificationTag.DmSharedGeneralPageGlobal: {
      return [baseMessage];
    }
    case SharedNotificationTag.DmSharedGeneralPageClub: {
      if (
        payload.sharedPathname ===
        SharedNotificationPathname.ClubReviewUserQuestions
      ) {
        baseMessage.text =
          payload.owner?.fullName ||
          payload.owner?.userName ||
          baseMessage.text;
        baseMessage.badgeSrc = clubLink?.[0].src;
        baseMessage.src = payload.owner?.avatarUrl || '';
        baseMessage.icon =
          payload.owner?.avatarUrl === undefined ? baseMessage.icon : undefined;

        return [baseMessage, ...clubLink];
      }

      return [baseMessage, ...clubLink];
    }

    case SharedNotificationTag.DmSharedChallengeClub: {
      baseMessage.text =
        payload.challenge?.challengeName || pathnameSettings.title;
      return [baseMessage, ...clubLink];
    }
    case SharedNotificationTag.DmSharedDynamicPlaylistClub: {
      baseMessage.text = payload.quiz?.quizName || pathnameSettings.title;
      return [baseMessage, ...clubLink];
    }

    case SharedNotificationTag.DmSharedEditorQuestionClub:
    case SharedNotificationTag.DmSharedPlaylistClub:
    case SharedNotificationTag.DmSharedResultClub: {
      return [baseMessage, ...clubLink];
    }

    case SharedNotificationTag.DmSharedQuestionClub: {
      baseMessage.text = `${payload.question?.quizName} Q${payload.question?.questionIndex}`;

      return [baseMessage, ...clubLink];
    }
    default: {
      return undefined;
    }
  }
};
