import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box } from '@mui/material';

import ProductEmoji, { ProductEmojiProps } from './ProductEmoji';

const styles = {
  emojis: {
    flex: 1,
    overflow: { xs: 'auto', md: 'hidden' },
    position: 'relative',
  },
  emojisAnimated: {
    display: 'flex',
    alignItems: 'center',
    transitionDuration: '1s',
  },
};

export type ProductEmojisHandler = {
  slideToRight: () => void;
  slideToLeft: () => void;
};

export type ProductEmojisProps = Omit<ProductEmojiProps, 'emoji'> & {
  emojis?: string[];
  gap?: string | number;
};

const ProductEmojis = forwardRef<ProductEmojisHandler, ProductEmojisProps>(
  ({ emojis = [], gap = '2px', ...rest }, ref) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [offset, setOffset] = useState(0);

    const slideToRight = () => {
      if (wrapperRef.current) {
        setOffset(
          wrapperRef.current.scrollWidth - wrapperRef.current.offsetWidth
        );
      }
    };

    const slideToLeft = () => {
      setOffset(0);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          slideToLeft,
          slideToRight,
        };
      },
      []
    );
    return (
      <Box sx={styles.emojis} className="emojis">
        <Box
          sx={[
            styles.emojisAnimated,
            { transform: `translateX(-${offset}px)` },
            { gap: gap },
          ]}
          ref={wrapperRef}
        >
          {emojis.map((emoji, index) => (
            <ProductEmoji key={index} emoji={emoji} {...rest} />
          ))}
        </Box>
      </Box>
    );
  }
);

ProductEmojis.displayName = 'ProductEmojis';
export default ProductEmojis;
