import { useCallback } from 'react';
import { getUserStatus, useOnlineStatus } from '@lib/web/hooks';

export default function useUserAvatarOnlineStatus() {
  const { getUserOnlineStatus } = useOnlineStatus();

  return useCallback(
    (id: string) => {
      const statusCode = getUserOnlineStatus(id);
      return getUserStatus(statusCode);
    },
    [getUserOnlineStatus]
  );
}
