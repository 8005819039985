import React, { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { AccountBlockerType, useAuth } from '@lib/web/apis';
import { shortEmail } from '@lib/web/utils';

type ReturnValue = {
  icon: string;
  title: ReactNode;
  content: ReactNode;
  action: {
    icon: string;
    text: string;
  };
};
export const useAccountFeatureBlockConfig = ({
  type,
}: {
  type: AccountBlockerType;
}): ReturnValue => {
  const { t } = useTranslation();
  const { member } = useAuth();

  return useMemo(() => {
    return {
      [AccountBlockerType.Unverified]: {
        icon: 'SocialMediaEmail',
        title: (
          <Trans
            i18nKey="unverified.blocker.title"
            defaults="Verify your email to access full features."
          />
        ),
        content: (
          <Trans
            i18nKey="unverified.blocker.content"
            values={{ email: shortEmail(member?.email || '') }}
            defaults="Verification email has been sent to {{email}}. If you did not receive the email or if it expired, you can resend the email."
          />
        ),
        action: {
          text: t('unverified.blocker.cta', 'Resend Email'),
          icon: 'OtherResend',
        },
      },
      [AccountBlockerType.Unclaimed]: {
        icon: 'ProfileLogin',
        title: (
          <Trans
            i18nKey="unclaimed.blocker.title"
            defaults="Sign up to access full features."
          />
        ),
        content: (
          <Trans
            i18nKey="unclaimed.blocker.content"
            defaults="Create a free account to continue studying and master your exam."
          />
        ),
        action: {
          text: t('unclaimed.blocker.cta', 'Sign up'),
          icon: 'ProfileLogin',
        },
      },
    }[type];
  }, [member?.email, t, type]);
};
