import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Box } from '@mui/material';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type SeeJoinRequestsButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.see.all.request' }>;
};

export default function SeeJoinRequestsButton({
  cta,
}: SeeJoinRequestsButtonProps) {
  const { t } = useTranslation('notification');

  return (
    <Box sx={styles.root}>
      <Button
        prefixIcon={<OtherAddFriendIcon />}
        component={Link}
        href={`/club/form/${cta.clubSlug}/requests`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {t('cta.start.practice.button.See Join Requests', 'See Join Requests')}
      </Button>
    </Box>
  );
}
