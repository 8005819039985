import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme, useMediaQuery } from '@mui/material';
import {
  FollowButton,
  SendButton,
} from '@app/web/src/components/FriendButtons';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import useUserAvatarOnlineStatus from '@app/web/src/hooks/utils/useUserAvatarOnlineStatus';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { SquareAvatarStatus, useBaseRightPanel, UserAvatar } from '@front/ui';
import { getIndicators } from '@lib/web/utils';

const styles = {
  root: {
    display: 'grid',
    px: {
      xs: '20px',
      md: '12px',
    },
    height: 53,
    width: '100%',
    textAlign: 'left',
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    width: 'auto',
    '& .MuiTypography-h6': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      lineHeight: 1.5,
    },
  },

  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dropdown: {
    '& .popper-content': {
      minWidth: 120,
    },
  },
};

type CtaProps = {
  userId: string;
  memberId: string;
  isFollowing: boolean;
  isFollower: boolean;
  messageDisabled?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
};

function Cta({
  userId,
  memberId,
  isFollowing,
  isFollower,
  messageDisabled,
  onToggleFollow,
}: CtaProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const buttonType = mdUp ? 'textButton' : 'iconButton';
  return (
    <Box sx={styles.action}>
      {isFollowing && (
        <SendButton
          userId={userId}
          memberId={memberId}
          size="sm"
          messageDisabled={messageDisabled}
          buttonType={buttonType}
          dropdownSx={styles.dropdown}
        />
      )}
      {!isFollowing && (
        <FollowButton
          userId={userId}
          isFollowing={isFollowing}
          isFollower={isFollower}
          size="sm"
          onToggleFollow={onToggleFollow}
          buttonType={buttonType}
        />
      )}
    </Box>
  );
}

function Spacing() {
  return <Box />;
}

export type SuggestionItemProps = {
  userInfo: {
    userId: string;
    memberId: string;
    displayName: string;
    distinctName: string;
    avatarUrl: string;
    indicator?: BaseMemberInfo['indicator'];
    isFollowing: boolean;
    isFollower: boolean;
  };
  settings: {
    hideLeague?: boolean;
    gridTemplateColumns: string;
  };
  messageDisabled?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
};

export default function SuggestionItem({
  userInfo,
  settings,
  messageDisabled,
  onToggleFollow,
}: SuggestionItemProps) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { showUserIdProfile } = useFloatingProfile();

  const handleClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: userInfo.userId,
    });
  };

  const getAvatarOnlyStatus = useUserAvatarOnlineStatus();

  const handleMouseEnter = (ev: MouseEvent) => {
    showUserIdProfile({
      userId: userInfo.userId,
      anchorEl: ev.currentTarget,
      options: {
        spacing: 8,
      },
    });
  };

  return (
    <ButtonBase
      sx={[styles.root, { gridTemplateColumns: settings.gridTemplateColumns }]}
      onClick={handleClick}
    >
      <Box sx={styles.avatarWrapper}>
        <UserAvatar
          sx={styles.avatar}
          src={userInfo.avatarUrl}
          title={userInfo.displayName || userInfo.distinctName}
          subTitle={`@${userInfo.distinctName}`}
          size="md"
          indicators={getIndicators(userInfo.indicator)}
          className="floating-avatar-anchor"
          titleClassName="floating-avatar-anchor"
          onMouseEnter={handleMouseEnter}
          onTitleMouseEnter={handleMouseEnter}
          showIndicator
          status={getAvatarOnlyStatus(userInfo.userId)}
          statusInfo={{
            [SquareAvatarStatus.Busy]: t('user.status.busy'),
          }}
        />
      </Box>
      <Spacing />
      <Cta
        userId={userInfo.userId}
        memberId={userInfo.memberId}
        isFollower={userInfo.isFollower}
        isFollowing={userInfo.isFollowing}
        messageDisabled={messageDisabled}
        onToggleFollow={onToggleFollow}
      />
    </ButtonBase>
  );
}
