import { useEffect, useState } from 'react';

const getCountdownValues = (timeDiff: number) => {
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  return [minutes > 0 ? minutes : 0, seconds > 0 ? seconds : 0];
};

export default function useExpiresCountdown(initExpiresAt?: Date) {
  const [expiresAt, setExpiresAt] = useState(initExpiresAt || new Date());
  const [countdown, setCountdown] = useState(
    getCountdownValues(expiresAt.getTime() - new Date().getTime())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(
        getCountdownValues(expiresAt.getTime() - new Date().getTime())
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [expiresAt]);

  const [minutes, seconds] = countdown;
  const finished = minutes <= 0 && seconds <= 0;

  return {
    minutes,
    seconds,
    finished,
    setExpiresAt,
  };
}
