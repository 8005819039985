import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxProps } from '@mui/material';
import { useClubSlug } from '@lib/web/hooks';

import ProfileButton from '.';

function getVisibleType(
  isInClub: boolean,
  messageDisabled = false,
  challengeDisabled = false
): 'dropdown' | 'messageOnly' | 'challengeOnly' | 'none' {
  // if not in a club => only message is available
  if (!isInClub) {
    if (messageDisabled) return 'none';
    return 'messageOnly';
  }
  if (challengeDisabled && messageDisabled) return 'none';
  if (messageDisabled && !challengeDisabled) return 'challengeOnly';
  if (!messageDisabled && challengeDisabled) return 'messageOnly';
  return 'dropdown';
}

type ProfileSendButtonProps = {
  size?: 'sm' | 'md';
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button' | 'textButton';
  loading?: boolean;
  messageDisabled?: boolean;
  challengeDisabled?: boolean;
  dropdownSx?: BoxProps['sx'];
  onMessageClick?: () => void;
  onChallengeClick?: () => void;
  beforeClickCheck?: (ev?: MouseEvent, target?: Element) => boolean;
};

export default function ProfileSendButton({
  size,
  buttonType,
  messageDisabled = false,
  challengeDisabled = false,
  loading = false,
  dropdownSx,
  onMessageClick,
  onChallengeClick,
  beforeClickCheck,
}: ProfileSendButtonProps) {
  const { t } = useTranslation();
  const clubSlug = useClubSlug();
  const isInClub = !!clubSlug;
  const visibleType = getVisibleType(
    isInClub,
    messageDisabled,
    challengeDisabled
  );

  const handleClick = useCallback(async () => {
    if (visibleType === 'challengeOnly') {
      onChallengeClick?.();
      return;
    }
    if (visibleType === 'messageOnly') {
      onMessageClick?.();
      return;
    }
  }, [visibleType, onChallengeClick, onMessageClick]);

  const dropdownOptions = useMemo(() => {
    if (visibleType !== 'dropdown') return [];
    return [
      {
        display: 'Message',
        icon: 'ThreadsDirectMessages',
        onClick: onMessageClick,
      },
      {
        display: 'Challenge',
        icon: 'MainChallenge',
        onClick: onChallengeClick,
      },
    ];
  }, [visibleType, onMessageClick, onChallengeClick]);

  if (visibleType === 'none') return null;

  const label =
    visibleType === 'dropdown'
      ? t('button.Send')
      : visibleType === 'challengeOnly'
      ? t('button.Challenge')
      : visibleType === 'messageOnly'
      ? t('button.Message')
      : '';

  const icon =
    visibleType === 'dropdown'
      ? undefined
      : visibleType === 'challengeOnly'
      ? 'MainChallenge'
      : visibleType === 'messageOnly'
      ? 'ThreadsDirectMessages'
      : undefined;

  const variant = visibleType === 'dropdown' ? 'outlined' : 'filled';

  return (
    <ProfileButton
      label={label}
      icon={icon}
      loading={loading}
      onClick={handleClick}
      beforeClickCheck={beforeClickCheck}
      dropdownOptions={dropdownOptions}
      variant={variant}
      size={size}
      buttonType={buttonType}
      dropdownSx={dropdownSx}
    />
  );
}
