import Router from 'next/router';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';
import useVerifyEmailButton from '@app/web/src/widgets/AccountPage/hooks/useVerifyEmailButton';
import { timeFormat } from '@front/helper';
import { AccountFeatureBlocker, AccountFeatureBlockerProps } from '@lib/web/ui';

import useBlockerOffset from '../../CommonBlocker/hooks/useBlockerOffset';

export default function AccountBlocker({
  ...rest
}: Omit<
  AccountFeatureBlockerProps,
  'onSignUp' | 'onResend' | 'loading' | 'disabled' | 'actionText'
>) {
  const signUpUrl = useClaimSignUpUrl();
  const { seconds, verifyEmail } = useVerifyEmailButton({
    countdownDelay: 2000,
  });

  const handleSignUp = async () => {
    await Router.push(signUpUrl);
  };
  const handleResend = async () => {
    await verifyEmail();
  };
  const { top } = useBlockerOffset();

  return (
    <AccountFeatureBlocker
      onSignUp={handleSignUp}
      onResend={handleResend}
      disabled={!!seconds}
      actionText={seconds ? timeFormat(seconds) : undefined}
      modalOffsetTop={rest.placement === 'center' ? top : 0}
      {...rest}
    />
  );
}
