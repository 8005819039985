import { ReactNode, useMemo } from 'react';

import IaDataContext, { IaDataContextValueType } from './IaDataContext';

type IaDataContextProviderProps = IaDataContextValueType & {
  children: ReactNode;
};

export default function IaDataContextProvider({
  token,
  sid,
  apiUrl,
  children,
  authGuardCheck,
}: IaDataContextProviderProps) {
  const value = useMemo(
    () => ({ token, sid, apiUrl, authGuardCheck }),
    [apiUrl, authGuardCheck, sid, token]
  );
  return (
    <IaDataContext.Provider value={value}>{children}</IaDataContext.Provider>
  );
}
