import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useClubSlug } from '@lib/web/hooks';
import { getClubIconPath, getLeagueBadgeIconName } from '@lib/web/utils';

import DisplayTableChallengeButton, {
  DisplayTableChallengeButtonProps,
} from './components/DisplayTableChallengeButton';

const styles = {
  root: {
    width: '100%',
    '& .display-table-layout': {
      p: 0,
    },
  },
};

export type ChallengeRecommendListProps = {
  body: GetChallengeGoodResultNotificationRes['data']['payload']['body'];
  cta:
    | Extract<NotificationCtaType, { type: 'cta.challenge.recommend.list' }>
    | Extract<NotificationCtaType, { type: 'cta.recommend.challenger.list' }>;
  clubSlug?: string;
};

export default function ChallengeRecommendList({
  body,
  cta,
  clubSlug: clubSlugProp,
}: ChallengeRecommendListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(true);

  const routerClubSlug = useClubSlug();
  const clubSlug = clubSlugProp || routerClubSlug;

  const config = useMemo<IaLayoutConfig[]>(() => {
    const fromClubId = body.clubId;
    const showClubColumn = cta.users.some((u) => u.clubId !== fromClubId);

    const gridTemplateColumns = showClubColumn
      ? '1fr 100px 100px 32px'
      : '1fr 170px 32px';

    const columnOrder = showClubColumn
      ? ['name', 'club', 'league', 'action']
      : ['name', 'league', 'action'];

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: {
            gridTemplateColumns,
            cells: [
              {
                type: 'default',
                icon: 'MainProfileSolid',
                label: t('challenge.good.result.table.name', 'Name'),
              },
              ...(showClubColumn
                ? [
                    {
                      type: 'default',
                      icon: 'MainApps',
                      label: t('challenge.good.result.table.club', 'Club'),
                    },
                  ]
                : []),
              {
                type: 'default',
                icon: 'TestTrophy',
                label: t('challenge.good.result.table.league', 'League'),
              },
              {
                type: 'default',
              },
            ],
          },
          rows: cta.users
            .filter((_, index) => (collapse ? index === 0 : true)) // 1 if collapsed => show 1st row
            .map((user) => {
              return {
                id: user.userId,
                cells: {
                  name: {
                    type: 'avatarText',
                    userId: user.userId,
                  },
                  club: {
                    type: 'avatarText',
                    avatar:
                      user.clubIcon || getClubIconPath(user.clubName || ''),
                    text: user.clubName,
                  },
                  league: {
                    type: 'iconText',
                    icon: {
                      type: 'icon',

                      value: getLeagueBadgeIconName(user.leagueName),
                    },
                    text: t('club::profile.league.rank', {
                      count: user.leagueRank,
                      ordinal: true,
                      name: user.leagueName,
                    }),
                  },
                  action: {
                    type: 'custom',
                    renderKey: 'challengeButton',
                    metadata: {
                      userId: user.userId,
                      clubSlug,
                    } as DisplayTableChallengeButtonProps,
                  },
                },
              };
            }),
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'textButton',
                action: 'toggleCollapse',
                text: collapse ? t('button.See more') : t('button.Hide'),
                suffixIcon: collapse
                  ? 'ActionChevronRightSmall'
                  : 'ActionChevronUp',
                sx: { ml: 0 },
                containerSx: { pt: 1 },
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
          hoverable: true,
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [body.clubId, collapse, cta.users, t, clubSlug]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
    }),
    []
  );

  const renders = useMemo(() => {
    return {
      challengeButton: (ev: DisplayTableChallengeButtonProps) => (
        <DisplayTableChallengeButton {...ev} />
      ),
    };
  }, []);

  return (
    <Box sx={styles.root}>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={config} />
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </Box>
  );
}
