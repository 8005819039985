import { useCallback } from 'react';
import { useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import {
  useBuildMessageData,
  useThreadMessageExternalPayload,
} from '../../hooks';
import { useChannelSystemMessage } from '../../hooks/channel/useChannelSystemMessage';
import { useThread } from '../../hooks/core/useThread';
import { useSendMessageToChannel } from '../../hooks/messages/useSendMessageToChannel';
import { useMaybeRedirectUserToNewDmView } from '../../hooks/view/useMaybeRedirectUserToNewDmView';
import { StreamChatGenerics } from '../../types';

export default function useThreadSendMessage() {
  const { member } = useAuth();
  const myMemberId = member?.memberId;
  const { buildMessageData } = useBuildMessageData();
  const { setThreadMessageExternalPayload } = useThreadMessageExternalPayload();
  const { sendMessageToChannel } = useSendMessageToChannel();
  const { maybeSendAgentStatusErrorMessage } = useChannelSystemMessage();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();
  const { resetThreadMessageSortFilter } = useThread();

  return useCallback(
    async (params: {
      channel: Channel<StreamChatGenerics>;
      threadMemberIds?: string[];
      agentId?: string;
      text?: string;
      blocks?: ComposerBlock[];
      onBeforeSubmit?: (message: StreamMessage<StreamChatGenerics>) => void;
    }) => {
      const customId = v4();

      const messageData = buildMessageData({
        text: params.text ?? '',
        blocks: params.blocks || [],
      });

      params.onBeforeSubmit?.({
        id: customId,
        customId,
        type: 'regular',
        user: { id: myMemberId || '' },
        created_at: new Date().toISOString(),
        ...messageData,
      });

      if (messageData.externalPayloadId) {
        void setThreadMessageExternalPayload({
          payloadId: messageData.externalPayloadId,
          payload: {
            customBlocks: params.blocks,
          },
          threadMemberId: myMemberId || '',
          channelCid: params.channel.cid,
        });
      }

      const { viewAfterId } = await sendMessageToChannel({
        channel: params.channel,
        channelMemberIds: params.threadMemberIds,
        text: params.text ?? '',
        blocks: params.blocks || [],
        agentId: params.agentId,
        customId,
      });

      maybeSendAgentStatusErrorMessage(params.channel);

      if (viewAfterId) {
        await maybeRedirectUserToNewDmView({
          channel: params.channel,
          newViewId: viewAfterId,
        });
      }
      resetThreadMessageSortFilter.current();
    },
    [
      buildMessageData,
      maybeRedirectUserToNewDmView,
      maybeSendAgentStatusErrorMessage,
      myMemberId,
      resetThreadMessageSortFilter,
      sendMessageToChannel,
      setThreadMessageExternalPayload,
    ]
  );
}
