import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import useThreadSortFilter from '@app/web/src/hooks/utils/useThreadSortFilter';
import GroupDmSubtitle from '@app/web/src/widgets/ThreadPage/components/GroupDmSubtitle';
import OneOnOneDmSubtitle, {
  isDmOneOnOneWithPerson,
} from '@app/web/src/widgets/ThreadPage/components/OneOnOneDmSubtitle';
import { ElementPresenceProvider } from '@front/helper';
import {
  BaseLayoutAction,
  BaseLayoutContainer,
  BaseLayoutScrollArea,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { FilterConditionConfig } from '@lib/ia/src/filter/types';
import { ChannelLayoutContext } from '@lib/ia/src/layouts/ChannelLayout/contexts/channelLayoutContext';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ThreadViewType, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { useReversedInfiniteScroll } from '@lib/web/hooks';
import { QuizAudioProvider } from '@lib/web/practice';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { ThreadComposerProvider } from '@lib/web/thread/contexts/threadComposerContext';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useMaybeMarkChannelsRead } from '@lib/web/thread/hooks/channels/useMaybeMarkChannelsRead';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { clearRouteParams, extractTextFromHtml } from '@lib/web/utils';
import { Channel } from 'stream-chat';
import { v4 } from 'uuid';

import useSortActions from '../../../CommonPanels/SortResponsivePanel/hooks/useSortActions';
import useSetFilterSortConditions from '../../hooks/useSetFilterSortConditions';
import useThreadRender from '../../hooks/useThreadRender';
import ThreadViewTitle from '../ThreadViewTitle';

import { useThreadViewChannelAvailableAction } from './hooks/useThreadViewChannelAvailableAction';
import { useThreadViewChannelLayoutConfig } from './hooks/useThreadViewChannelLayoutConfig';

const styles = {
  pageTitle: {
    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    mb: 3,
  },
  scroll: {
    '& .simplebar-content': {
      display: 'flex',

      flexDirection: 'column-reverse',
    },
  },
};

const useCreateTempChannel = () => {
  const { member } = useAuth();
  const { chatClient } = useThread();
  const createTempChannel = useCallback(
    ({
      text,
      blocks,
      sendPublicly,
    }: {
      text: string;
      blocks: ComposerBlock[];
      sendPublicly: boolean;
    }): Channel<StreamChatGenerics> | undefined => {
      if (!chatClient || !member) return;

      const createdAt = streamDateToString(new Date());

      const channel = chatClient.channel(
        sendPublicly ? 'public' : 'team',
        v4()
      );

      /**
       * some data (e.g. created_at) we cannot directly set, so we use proxy to return them
       */
      return new Proxy(channel, {
        get(target, prop: keyof Channel<StreamChatGenerics>) {
          if (prop === 'data') {
            return {
              ...target.data,
              created_at: createdAt,
              created_by: {
                id: member.memberId,
              },
              firstMessageText: extractTextFromHtml(text),
              firstMessageCustomBlocks: blocks,
              firstMessageCreatedAt: createdAt,
            };
          }
          return target[prop];
        },
      });
    },
    [chatClient, member]
  );

  return {
    createTempChannel,
  };
};

export type ThreadComposerContainerProps = {
  view: GetThreadViewRes;
  scrollToBottom: () => void;
  setChannelsCreating: Dispatch<SetStateAction<Channel<StreamChatGenerics>[]>>;
};

export type ThreadComposerContainerHandler = {
  sendMessage(params: { text?: string; blocks?: ComposerBlock[] }): void;
};

const ThreadComposerContainer = ({
  view,
  scrollToBottom,
  setChannelsCreating,
}: ThreadComposerContainerProps) => {
  const { query } = useRouter();
  const { rightPanelParams, clearRightParams } = useBaseRightPanel();
  const { resetThreadChannelSortFilter } = useThread();
  const { channelData } = useThreadViewDetail(view);

  const { isBlocking, blockerTip } = useAuth();
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const { getThreadComposerCustomProps } = useContext(
    ThreadComposerCustomContext
  );
  const { text, setText, aiActionState, sendPublicly, setSelectedAction } =
    useThreadComposer();
  const { createTempChannel } = useCreateTempChannel();
  const {
    defaultAgentId,
    selectedAgentId,
    setSelectedAgent,
    ensureLoadingFinish,
  } = aiActionState;

  /**
   * Before channel created, we will show a temp channel in the UI for better user experience
   */
  const createAndAppendTempChannel = useCallback(
    (blocks: ComposerBlock[]) => {
      const channel = createTempChannel({ text, blocks, sendPublicly });
      if (!channel) return;

      setChannelsCreating((prev) =>
        prev.some((c) => c.id === channel.id) ? prev : [...prev, channel]
      );
      setTimeout(() => {
        scrollToBottom();
      });
      return channel;
    },
    [createTempChannel, text, sendPublicly, setChannelsCreating, scrollToBottom]
  );

  const handleSubmit = useCallback<
    (props: { blocks: ComposerBlock[] }) => Promise<void>
  >(
    async ({ blocks }) => {
      setText('');

      const tempChannel = createAndAppendTempChannel(blocks);

      const newChannelResult = await createNewChannelWithMessage({
        channelId: tempChannel?.id,
        message: text,
        blocks,
        sendPublicly,
        channelData,
        agentId: selectedAgentId,
      });

      if (!newChannelResult) {
        return;
      }

      resetThreadChannelSortFilter.current();

      if (newChannelResult.viewAfterId) {
        await maybeRedirectUserToNewDmView({
          channel: newChannelResult.newChannel,
          newViewId: newChannelResult.viewAfterId,
        });
      }
    },
    [
      createAndAppendTempChannel,
      channelData,
      createNewChannelWithMessage,
      maybeRedirectUserToNewDmView,
      resetThreadChannelSortFilter,
      selectedAgentId,
      sendPublicly,
      setText,
      text,
    ]
  );

  const updateSelectedAgent = useCallback(
    (openAgentMemberId: string, onFinish?: () => void) => {
      ensureLoadingFinish((agentsData) => {
        onFinish?.();

        const { data } = agentsData;
        const targetAgent = data?.[0].data.items.find(
          (a) => `agent_${a.agent.agentId}` === openAgentMemberId
        );
        if (targetAgent) {
          setSelectedAgent(targetAgent);
          defaultAgentId.current = targetAgent.agent.agentId;
        }
      });
    },
    [defaultAgentId, ensureLoadingFinish, setSelectedAgent]
  );

  const { openAgentMemberId: openAgentMemberIdParam } = rightPanelParams;
  useEffect(() => {
    if (
      typeof query.openAgentMemberId === 'string' &&
      query.openAgentMemberId.startsWith('agent_')
    ) {
      setSelectedAction('ai');
      updateSelectedAgent(query.openAgentMemberId, () => {
        void clearRouteParams(['openAgentMemberId']);
      });
      return;
    }
    if (
      typeof openAgentMemberIdParam === 'string' &&
      openAgentMemberIdParam.startsWith('agent_')
    ) {
      setSelectedAction('ai');
      updateSelectedAgent(openAgentMemberIdParam);
    }
  }, [
    defaultAgentId,
    ensureLoadingFinish,
    query.openAgentMemberId,
    setSelectedAgent,
    openAgentMemberIdParam,
    clearRightParams,
    updateSelectedAgent,
    setSelectedAction,
  ]);

  return (
    <ThreadComposer
      autoFocus
      onChange={setText}
      onSubmit={({ blocks }) => handleSubmit({ blocks })}
      disabled={isBlocking}
      disabledTip={blockerTip}
      disableSubmitWhileSubmitting={false}
      {...getThreadComposerCustomProps({ view })}
    />
  );
};

export type ThreadViewChannelLayoutProps = {
  view: GetThreadViewRes;
  clubId?: string;
  scope?: string;
  extraFilterConditions?: FilterConditionConfig[];
};

export default function ThreadViewChannelLayout({
  view,
  clubId,
  scope,
  extraFilterConditions,
}: ThreadViewChannelLayoutProps) {
  const { t } = useTranslation('thread');
  const { resetThreadChannelSortFilter } = useThread();
  const { viewMemberIds, viewTag, viewType, myMemberId } =
    useThreadViewDetail(view);

  const { useChannelSortFilter } = useThreadSortFilter(scope);
  const {
    key,
    sort,
    resetSort,
    filters,
    resetFilter,
    completeInitPartial,
    isInitializing,
  } = useChannelSortFilter(view);

  const { reset: resetSortAction } = useSortActions();

  const { setDefaultSortCriteria } = useSetFilterSortConditions({
    type: 'channel',
    view,
    viewMemberIds,
    scope,
    completeInitPartial,
    extraFilterConditions,
  });

  const initStatus = Object.keys(sort).length === 1 && sort.created_at === 1; // Channel Layout init sort condition
  const channelsData = useFilteredChannels({
    scope: 'centre-channel-view',
    key,
    filters,
    sort: initStatus ? { created_at: -1 } : sort, // init should get data from 'desc' order, because UI will render from the chatroom bottom
    enable: !isInitializing,
  });
  const {
    data,
    dataset: rawChannels,
    isLoadingInitialData,
    isLoadingMore,
  } = channelsData;

  const { firstLevelChannels } = useChannelsHierarchyDisplay({
    channels: rawChannels,
  });
  const [channelsCreating, setChannelsCreating] = useState<
    Channel<StreamChatGenerics>[]
  >([]);
  const channels = useMemo(() => {
    const createdChannelIds = new Set(firstLevelChannels.map((c) => c.id));
    return [
      ...firstLevelChannels,
      ...channelsCreating.filter((c) => !createdChannelIds.has(c.id)),
    ];
  }, [channelsCreating, firstLevelChannels]);

  const { scrollRef } = useReversedInfiniteScroll({
    infiniteHookResponse: channelsData,
  });

  const [text, setText] = useState('');
  const [loaded, setLoaded] = useState(false);
  const { defaultSendPublicly } = useContext(ThreadCurrentLocationContext);
  const renders = useThreadRender();
  const config = useThreadViewChannelLayoutConfig({
    channels,
    isLoadingInitialData,
    sort,
  });
  const availableActions = useThreadViewChannelAvailableAction({
    threadMemberIds: viewMemberIds,
  });

  useMaybeMarkChannelsRead({ channels, refToCheckVisibility: scrollRef });

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
    }
  }, [scrollRef]);

  useEffect(() => {
    if (data && !isLoadingInitialData && !isLoadingMore && !loaded) {
      setLoaded(true);
      scrollToBottom();
    }
  }, [data, isLoadingInitialData, isLoadingMore, loaded, scrollToBottom]);

  useEffect(() => {
    resetThreadChannelSortFilter.current = (): void => {
      resetSort();
      resetSortAction();
      resetFilter();

      setDefaultSortCriteria(); // sort criteria will be clear, so set default sort criteria here
    };
  }, [
    resetFilter,
    resetSort,
    resetSortAction,
    resetThreadChannelSortFilter,
    setDefaultSortCriteria,
  ]);

  /**
   * remove temp channel after it's created
   */
  useEffect(() => {
    const createdChannelIds = new Set(firstLevelChannels.map((c) => c.id));
    if (channelsCreating.some((c) => createdChannelIds.has(c.id))) {
      setChannelsCreating((prev) =>
        prev.filter((c) => !createdChannelIds.has(c.id))
      );
    }
  }, [channelsCreating, firstLevelChannels]);

  const { pinMessageItems } = useContext(ChannelLayoutContext);

  const noThreadTitleVisible =
    !isLoadingInitialData &&
    channels.length === 0 &&
    pinMessageItems.length === 0 &&
    !isDmOneOnOneWithPerson(viewType, viewMemberIds, myMemberId) &&
    viewType !== ThreadViewType.DmGroup;

  return (
    <QuizAudioProvider>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <BaseLayoutScrollArea sx={styles.scroll} scrollRef={scrollRef}>
            <ElementPresenceProvider elementRef={scrollRef}>
              <EditorThreadComposerRenderer styleProvider>
                <IaLayouts layouts={config} />
              </EditorThreadComposerRenderer>
            </ElementPresenceProvider>
            <BaseLayoutContainer fullWidth sx={styles.pageTitle}>
              <>
                <ThreadViewTitle
                  view={view}
                  clubId={clubId}
                  subtitleText={
                    noThreadTitleVisible
                      ? t('layout.channel.subtitle.noThread')
                      : undefined
                  }
                />
                <OneOnOneDmSubtitle view={view} />
                <GroupDmSubtitle view={view} />
              </>
            </BaseLayoutContainer>
          </BaseLayoutScrollArea>
          <BaseLayoutAction sx={{ p: 0 }}>
            <ThreadComposerProvider
              text={text}
              setText={setText}
              sendPubliclyCheckboxEnable={
                view.type === ThreadViewType.ClubAllThread ||
                view.type === ThreadViewType.Club ||
                view.type === ThreadViewType.DmEveryone ||
                view.type === ThreadViewType.Location
              }
              sendPubliclyDefault={
                view.type === ThreadViewType.DmEveryone ||
                defaultSendPublicly ||
                viewTag === 'solution'
              }
              sendPubliclyDisabled={
                view.type === ThreadViewType.DmEveryone ||
                viewTag === 'solution'
              }
              threadMemberIds={viewMemberIds}
            >
              <ThreadComposerContainer
                view={view}
                scrollToBottom={scrollToBottom}
                setChannelsCreating={setChannelsCreating}
              />
            </ThreadComposerProvider>
          </BaseLayoutAction>
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </QuizAudioProvider>
  );
}
