import { useContext, useEffect } from 'react';
import { useMyFavoriteProducts, useMyOwnProducts } from '@lib/web/apis';

import { MarketplaceContext } from '../contexts/context';

export default function useMarketplace() {
  const [value, setValue] = useContext(MarketplaceContext);
  const {
    data: favoriteProducts,
    isLoading: favoriteLoading,
    mutate: favoriteMutate,
  } = useMyFavoriteProducts();
  const {
    data: myProducts,
    isLoading: myProductsLoading,
    mutate: myProductsMutate,
  } = useMyOwnProducts();

  useEffect(() => {
    if (favoriteProducts) {
      const favoriteIds = favoriteProducts?.data.items.map((p) => p.productId);
      setValue((st) => ({
        ...st,
        favoriteIds,
      }));
    }
  }, [favoriteProducts, setValue]);

  useEffect(() => {
    if (myProducts) {
      const myProductIds = myProducts?.data.items.map((p) => p.productId);
      setValue((st) => ({
        ...st,
        myProductIds,
      }));
    }
  }, [myProducts, setValue]);

  const setSelected = (product: GetSanityProductRes | null) => {
    setValue((st) => ({
      ...st,
      selected: product,
    }));
  };

  const setAutoFavorite = (autoFavorite: boolean) => {
    setValue((st) => ({
      ...st,
      autoFavorite,
    }));
  };

  /**
   * refreshFavorite when a product is added or removed from favorites
   */
  const refreshFavorite = () => {
    favoriteMutate();
  };

  const refreshMyProducts = () => {
    return myProductsMutate();
  };

  const myProductIds = value.myProductIds || [];
  const isMyAvatar = (productId?: string) => {
    if (!productId || myProductIds.length === 0) return false;
    return myProductIds.includes(productId);
  };

  return {
    ...value,
    setSelected,
    setAutoFavorite,
    refreshFavorite,
    refreshMyProducts,
    favoriteLoading,
    myProductsLoading,
    isMyAvatar,
  };
}
