import GenericOnboarding from '@app/web/src/components/GenericOnboarding';
import { clearRouteParams } from '@lib/web/utils';

const stepConfig = {
  1: {
    selector: () => {
      const elements = document.querySelectorAll<HTMLElement>(
        '[data-testid="earned-from-referral-receiver"]'
      );
      return elements[elements.length - 1];
    },
    xOffset: ({ rect }: { rect: DOMRect }) => rect.width / 2 - 20,
    yOffset: ({ rect }: { rect: DOMRect }) => rect.height / 2,
    arrowOffsetY: 90,
    arrowDirection: 'left-top' as const,
  },
};

export default function EarnedTokenPinChats() {
  const handleFinish = () => {
    clearRouteParams(['notification']);
  };
  return (
    <GenericOnboarding
      target="notifications/welcome-bonus"
      stepConfig={stepConfig}
      onFinish={handleFinish}
      onHide={handleFinish}
    />
  );
}
