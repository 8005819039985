import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '@front/helper';
import {
  DisplayTableLayoutConfig,
  DisplayTableLayoutRow,
  DisplayTableLayoutSection,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import { useAuth, useIaClubs } from '@lib/web/apis';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { getClubIconPath } from '@lib/web/utils';

const useViewClub = (clubId: string | null) => {
  const { data } = useIaClubs(
    {
      filter: 'clubLogo;clubName',
      search: `clubId:${clubId}`,
      searchFields: 'clubId:eq',
      limit: 1,
    },
    {
      enable: !!clubId,
    }
  );
  return data?.data.items[0];
};

export const useThreadViewPropertiesLayoutConfig = ({
  view,
  mode,
  sendPublicly,
  customMemberIds,
}: {
  view: GetThreadViewRes;
  mode: 'expandableBrief' | 'expandableFull' | 'full';
  sendPublicly: boolean;
  expandable?: boolean;
  customMemberIds?: string[];
}) => {
  const { displayDatetimeFormat } = useDateFormat();
  const { getThreadUser, getThreadLocationDisplay } = useThread();
  const { viewClubId, viewMemberIds, viewTag } = useThreadViewDetail(view);
  const viewClub = useViewClub(viewClubId);
  const { currentLocationDetail } = useContext(ThreadCurrentLocationContext);
  const { member } = useAuth();
  const myMemberId = member?.memberId;
  const { t } = useTranslation('thread');

  return useMemo(() => {
    const now = new Date().toString();
    const meUser = getThreadUser(myMemberId);
    const locationDetail = currentLocationDetail;
    const memberIds = customMemberIds || viewMemberIds;

    const rowCreatedBy: DisplayTableLayoutRow = {
      id: 'createdBy',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: t('properties.createdBy', 'Created By'),
        },
        value: {
          type: 'avatarText',
          avatar: meUser?.image,
          text: meUser?.name,
        },
      },
      state: 'active',
    };

    const rowCreatedTime: DisplayTableLayoutRow = {
      id: 'createdTime',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: t('properties.createdTime', 'Created Time'),
        },
        value: {
          type: 'text',
          text: displayDatetimeFormat(now),
        },
      },
    };

    const rowVisibility: DisplayTableLayoutRow = {
      id: 'visibility',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'LoginSee',
          },
          text: t('properties.visibility', 'Visibility'),
        },
        value: {
          type: 'tag',
          text: sendPublicly ? 'Public' : 'Private',
        },
      },
    };
    const rowMembers: DisplayTableLayoutRow = {
      id: 'members',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: t('properties.members', 'Members'),
        },
        value: sendPublicly
          ? {
              type: 'tag',
              icon: {
                type: 'icon',
                value: 'TestRanking',
              },
              text: t('properties.everyone', 'Everyone'),
            }
          : {
              type: 'avatarGroup',
              avatars: memberIds.map((memberId) => {
                const memberUser = getThreadUser(memberId);
                return {
                  name: memberUser?.name || '',
                  avatarUrl: memberUser?.image || '',
                };
              }),
              maxDisplayNumber: 5,
            },
      },
    };
    const rowActiveMembers: DisplayTableLayoutRow = {
      id: 'activeMembers',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: t('properties.activeMembers', 'Active Members'),
        },
        value: {
          type: 'avatarGroup',
          avatars: [
            {
              name: meUser?.name || '',
              avatarUrl: meUser?.image || '',
            },
          ],
          maxDisplayNumber: 5,
        },
      },
    };
    const rowReplies: DisplayTableLayoutRow = {
      id: 'replies',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: t('properties.replies', 'Replies'),
        },
        value: {
          type: 'text',
          text: '0',
        },
      },
    };
    const rowLastReply: DisplayTableLayoutRow = {
      id: 'lastReply',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: t('properties.lastReply', 'Last Reply'),
        },
        value: { type: 'emptyText' },
      },
    };
    const rowLastActivity: DisplayTableLayoutRow = {
      id: 'lastActivity',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: t('properties.lastActivityTime', 'Last Activity Time'),
        },
        value: { type: 'emptyText' },
      },
    };
    const rowClub: DisplayTableLayoutRow = {
      id: 'club',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainApps',
          },
          text: t('properties.threadsClub', 'Thread’s Club'),
        },
        value: viewClub
          ? {
              type: 'avatarTag',
              avatarUrl:
                viewClub.clubLogo || getClubIconPath(viewClub.clubName),
              text: viewClub.clubName,
              clickAction: 'openClub',
              metadata: {
                clubSlug: viewClub.clubSlug,
              },
            }
          : { type: 'emptyText' },
      },
    };
    const locationDisplay = getThreadLocationDisplay(locationDetail);
    const rowLocation: DisplayTableLayoutRow = {
      id: 'location',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'TextEditorLineLocationPoint',
          },
          text: t('properties.threadsLocation', 'Thread’s Location'),
        },
        value: locationDisplay
          ? locationDisplay.type === 'icon'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: locationDisplay.icon,
                },
                text: locationDisplay.name,
              }
            : {
                type: 'avatarTag',
                text: locationDisplay.name,
                avatarUrl: locationDisplay.avatarUrl,
              }
          : { type: 'emptyText' },
      },
    };

    const rowQuestionId: DisplayTableLayoutRow = {
      id: 'questionId',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatQuestionList',
          },
          text: t('properties.questionId', 'Question ID'),
        },
        value:
          viewClub && locationDetail.shortId
            ? {
                type: 'avatarTag',
                avatarUrl:
                  viewClub.clubLogo || getClubIconPath(viewClub.clubName),
                text: `Question ${locationDetail.shortId}`,
                clickAction: 'openQuestion',
                metadata: {
                  detail: locationDetail,
                },
              }
            : { type: 'emptyText' },
      },
    };

    const rowHeadThumbnail: DisplayTableLayoutRow = {
      id: 'headThumbnail',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatThread',
          },
          text: t('properties.headThumbnail', 'Head Thumbnail'),
        },
        value: {
          type: 'tag',
          text: 'Created By',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
        },
      },
    };

    const rowThreadTag: DisplayTableLayoutRow = {
      id: 'threadTag',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeTag',
          },
          text: t('properties.threadTag', 'Thread Tag'),
        },
        value:
          viewTag === 'solution'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: 'TestSolution',
                },
                text: t('properties.solution', 'Solution'),
              }
            : { type: 'emptyText' },
      },
    };

    const rowHideButton = {
      gridTemplateColumns: '80px 1fr',
      cells: [
        {
          type: 'textButton',
          action: 'hide',
          text: t('button.Hide'),
          suffixIcon: 'ActionChevronUp',
        },
      ],
    };

    const sections: DisplayTableLayoutSection[] = [
      {
        id: 'overview',
        title: t('section.overview', 'Overview'),
        defaultOpen: true,
        rows: [
          rowClub,
          rowLocation,
          rowQuestionId,
          rowVisibility,
          rowHeadThumbnail,
          rowThreadTag,
        ],
      },
      {
        id: 'activity',
        title: t('section.activity', 'Activity'),
        rows: [
          rowCreatedBy,
          rowCreatedTime,
          rowMembers,
          rowActiveMembers,
          rowReplies,
          rowLastReply,
          rowLastActivity,
        ],
      },
    ];

    if (mode === 'expandableFull' || mode === 'full') {
      return [
        {
          layout: 'display-table-layout',
          table: {
            gridTemplateColumns: {
              xs: 'minmax(0, 1fr) minmax(0, 2fr)',
              md: 'minmax(0, 1fr) minmax(0, 1fr)',
            },
            columnOrder: ['label', 'value'],
            sections,
            footerRow: rowHideButton,
          },
          settings: {
            rowHeight: 33,
          },
        } as DisplayTableLayoutConfig,
      ];
    }

    const collapsedRow: DisplayTableLayoutRow = {
      id: 'location',
      cells: {
        showMore: {
          type: 'button',
          buttonComponent: 'textButton',
          text: 'See more',
          suffixIcon: 'ActionChevronRightSmall',
          action: 'show',
        },
        location: locationDisplay
          ? locationDisplay.type === 'icon'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: locationDisplay.icon,
                },
                text: locationDisplay.name,
              }
            : {
                type: 'avatarTag',
                text: locationDisplay.name,
                avatarUrl: locationDisplay.avatarUrl,
              }
          : { type: 'empty' },
        members: sendPublicly
          ? {
              type: 'tag',
              icon: {
                type: 'icon',
                value: 'TestRanking',
              },
              text: t('properties.everyone', 'Everyone'),
            }
          : {
              type: 'avatarGroup',
              avatars: memberIds.map((memberId) => {
                const memberUser = getThreadUser(memberId);
                return {
                  name: memberUser?.name || '',
                  avatarUrl: memberUser?.image || '',
                };
              }),
              maxDisplayNumber: 5,
            },
      },
    };

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns: '100px 1fr auto',
          columnOrder: ['showMore', 'location', 'members'],
          rows: [collapsedRow],
        },
        settings: {
          disableScroll: true,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [
    t,
    currentLocationDetail,
    customMemberIds,
    displayDatetimeFormat,
    getThreadLocationDisplay,
    getThreadUser,
    mode,
    myMemberId,
    sendPublicly,
    viewClub,
    viewMemberIds,
    viewTag,
  ]);
};
