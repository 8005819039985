import React, { MouseEvent, ReactNode } from 'react';
import { SimpleTooltip } from '@front/ui';
import { AccountBlockerType, useAuth } from '@lib/web/apis';
import { AccountFeatureBlockerPlacement } from '@lib/web/ui';

import useBlocker from '../BlockerControl/hooks/useBlocker';

export default function AuthGuard({
  children,
  authFeatureEnabled,
  additionalBlockerTip,
  excludeBlockerTypes,
  placement,
}: {
  children: ((ev: { disabled: boolean }) => ReactNode) | ReactNode;
  authFeatureEnabled?: boolean;
  additionalBlockerTip?: string;
  excludeBlockerTypes?: AccountBlockerType[];
  placement?: AccountFeatureBlockerPlacement;
}) {
  const { isBlocking, blockerType } = useAuth();
  const { openBlocker } = useBlocker();
  if (!isBlocking && !additionalBlockerTip)
    return typeof children === 'function'
      ? children({ disabled: false })
      : children;

  const handleClick = (ev: MouseEvent, onClick?: (ev: MouseEvent) => void) => {
    if (
      !blockerType ||
      authFeatureEnabled ||
      (blockerType && excludeBlockerTypes?.includes(blockerType))
    ) {
      onClick?.(ev);
      return;
    }

    ev.stopPropagation();
    ev.preventDefault();

    if (placement) {
      openBlocker(blockerType, placement);
      return;
    }
    if (
      ev.target instanceof Element &&
      ev.target.closest('.base-layout-right-panel')
    ) {
      openBlocker(blockerType, 'rhs');
      return;
    }
    if (
      ev.target instanceof Element &&
      ev.target.closest('.menu-content-wrap')
    ) {
      openBlocker(blockerType, 'menu');
      return;
    }
    openBlocker(blockerType, 'center');
  };

  const inner =
    typeof children === 'function'
      ? children({ disabled: true })
      : React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child, {
            ...child.props,
            disabled: additionalBlockerTip ? true : child.props.disabled,
            onClick: (ev: MouseEvent) => handleClick(ev, child.props.onClick),
          });
        });

  if (additionalBlockerTip)
    return (
      <SimpleTooltip title={additionalBlockerTip}>
        <span>{inner}</span>
      </SimpleTooltip>
    );

  return inner;
}
