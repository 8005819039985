import { useCallback, useContext } from 'react';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { updatePracticeIncognito } from '@app/web/src/actions/layoutActions';
import { useAppDispatch } from '@app/web/src/hooks/redux';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { useLatestValueRef } from '@front/helper';
import { toast } from '@front/ui';
import { apis, ExamMode } from '@lib/web/apis';
import { useClubSlug, useCurrentExam } from '@lib/web/hooks';
import { call, callWithToast } from '@lib/web/utils';
import { TFunction } from 'i18next';

import CreateQuizContext from '../context';
import { CreateQuizFormValue } from '../type';
import getActiveQuizButtonEl from '../utils/getActiveQuizButtonEl';
import getCreateQuizParams from '../utils/getCreateQuizParams';

const confirmNonFriends = async (t: TFunction, challengers: string[]) => {
  const [res] = await call(
    apis.member.getInfos({ userIds: challengers, isGetFollowInfo: true })
  );

  const hasNonFriends = res?.data.some((d) => d.isFollowing === false);

  if (hasNonFriends) {
    const checked = await toast.confirm(
      t('Challenging users whom you do not follow'),
      {
        confirmText: t('Send Challenge'),
        desktopSx: { maxWidth: 300 },
        anchorEl: getActiveQuizButtonEl(),
      },
      { id: 'check-send-friend-request', duration: Infinity }
    );
    return checked;
  }
  return true;
};

const handleResponse = (
  t: TFunction,
  res: Response<GetChallengeByTagRes | GetQuizByTagRes> | null | undefined
) => {
  if (res) {
    const { quizShortId, roundNo = 1 } = res.data;
    return { quizShortId, roundNo };
  }
  return { quizShortId: '', roundNo: 1 };
};

export type OnBeforeGetQuizReturn =
  | boolean
  | void
  | { mode: ExamMode; quizId: string; roundNo: number };

// empty string: stop processing
// null: api failure
export default function useStartQuiz({
  onGetQuizUrl,
  onBeforeGetQuiz,
}: {
  onGetQuizUrl: (url: string | null) => void;
  onBeforeGetQuiz?: (
    data: CreateQuizFormValue
  ) => Promise<OnBeforeGetQuizReturn> | OnBeforeGetQuizReturn;
}) {
  const { t } = useTranslation('quiz');
  const [createQuizSettings] = useContext(CreateQuizContext);
  const { officialQuestionCount, clubSlug: providedClubSlug } =
    createQuizSettings;
  const routerClubSlug = useClubSlug();
  const clubSlug = providedClubSlug || routerClubSlug;
  const { sectionId } = useCurrentExam(clubSlug);
  const { isFreeUser } = useMemberSubscription();

  const functionCallback = useLatestValueRef({
    onGetQuizUrl,
    onBeforeGetQuiz,
  });
  const dispatch = useAppDispatch();
  return useCallback(
    async (data: CreateQuizFormValue) => {
      if (!sectionId) return;

      dispatch(updatePracticeIncognito(isFreeUser ? false : data.isIncognito));

      if (functionCallback.current.onBeforeGetQuiz) {
        const quizRes = await functionCallback.current.onBeforeGetQuiz(data);

        if (quizRes === false) return;

        // from existing playlist
        if (typeof quizRes === 'object') {
          const targetUrl =
            quizRes.mode === ExamMode.MockExam ? 'exam' : 'practice';
          await functionCallback.current.onGetQuizUrl?.(
            `/club/${clubSlug}/${targetUrl}/${quizRes.quizId}/${quizRes.roundNo}#1`
          );
          return;
        }
      }

      // basic flow
      const isChallenge =
        data.challengers.length > 0 || data.randomChallengerCount > 0;
      const isMockExam = data.mode === ExamMode.MockExam;
      const params = getCreateQuizParams(data, {
        isChallenge,
        isMockExam,
        sectionId,
        officialQuestionCount,
      });
      const targetUrl = isMockExam ? 'exam' : 'practice';

      if (isChallenge) {
        const isConfirmed = await confirmNonFriends(t, data.challengers);

        if (!isConfirmed) {
          await functionCallback.current.onGetQuizUrl?.('');
          return;
        }
      }

      /**
       * Instead of waiting for quiz generation to complete, we redirect the user
       * to countdown screen (3,2,1) while the quiz is still being generated
       */
      await functionCallback.current.onGetQuizUrl?.(
        `/club/${clubSlug}/${targetUrl}/preparing/1`
      );

      const api = isChallenge
        ? () => apis.challenge.challengeByTag(params as ChallengeByTagReq)
        : () => apis.quiz.quizByTag(params as QuizByTagReq);

      const [res, err] = await callWithToast(api);
      const { quizShortId, roundNo } = handleResponse(t, res);

      /**
       * We add fromPreparing=true to skip the countdown since we already showed it
       * during quiz preparation
       */
      if (quizShortId) {
        Router.replace(
          `/club/${clubSlug}/${targetUrl}/${quizShortId}/${roundNo}?fromPreparing=true#1`,
          undefined,
          { shallow: true }
        );
      }
      if (err) {
        Router.back();
      }
    },
    [
      clubSlug,
      dispatch,
      isFreeUser,
      officialQuestionCount,
      functionCallback,
      sectionId,
      t,
    ]
  );
}
