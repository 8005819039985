import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { CheckoutPanelKeys } from '@app/web/src/types/panel';
import { getPlanAction } from '@app/web/src/widgets/MyPlanPage/config';
import { MyPlanCheckoutProvider } from '@app/web/src/widgets/MyPlanPage/contexts/checkoutContext';
import usePlanConfigs from '@app/web/src/widgets/MyPlanPage/hooks/usePlanConfigs';
import useSubscriptionCheckout from '@app/web/src/widgets/MyPlanPage/hooks/useSubscriptionCheckout';
import SubscriptionCheckoutRightPanel from '@app/web/src/widgets/MyPlanPage/SubscriptionCheckoutRightPanel';
import { Button, useBaseLayout } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type RetryToSubscribePlanButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.retry.subscribe' }>;
};

function RetryToSubscribePlanButtonCta({
  cta,
}: RetryToSubscribePlanButtonProps) {
  const { t } = useTranslation('notification');
  const { openRightPanel } = useBaseLayout();
  const { setActivePlan } = useSubscriptionCheckout();
  const { getPlan, isLoading: isPlanConfigLoading } = usePlanConfigs();
  const { subscriptionInfo, isLoading: isSubscriptionInfoLoading } =
    useMemberSubscription();

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    const plan = getPlan(cta.planId);
    if (!plan) return;
    const action = getPlanAction(plan, subscriptionInfo);
    if (!action) return;
    setActivePlan(plan, action);
    openRightPanel(CheckoutPanelKeys.Checkout);
  };

  return (
    <>
      <Box sx={styles.root}>
        <Button
          onMouseDown={(e) => e.stopPropagation()}
          loading={isPlanConfigLoading || isSubscriptionInfoLoading}
          onClick={handleClick}
        >
          {t('retry.subscribe.button.Retry')}
        </Button>
      </Box>
      <SubscriptionCheckoutRightPanel />
    </>
  );
}

export default function RetryToSubscribePlanButton({
  ...rest
}: RetryToSubscribePlanButtonProps) {
  return (
    <MyPlanCheckoutProvider>
      <RetryToSubscribePlanButtonCta {...rest} />
    </MyPlanCheckoutProvider>
  );
}
