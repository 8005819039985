import { useCallback, useEffect, useState } from 'react';

export default function useIaSuggest<T>(searchResults: T[] | boolean) {
  const [suggestItem, setSuggestItem] = useState<T | null>(null);

  useEffect(() => {
    if (Array.isArray(searchResults)) {
      setSuggestItem(searchResults[0] || null);
    } else {
      setSuggestItem(null);
    }
  }, [searchResults]);

  const updateSuggestItem = useCallback((value: T) => {
    setSuggestItem(value);
  }, []);
  return {
    suggestItem,
    updateSuggestItem,
  };
}
