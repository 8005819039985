import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Skeleton } from '@mui/material';
import { TestAdd as TestAddIcon } from '@front/icon';
import { useIaClubStatus } from '@lib/web/hooks';
import { featureEnable } from '@lib/web/thread/config/constants';
import { useUpdateClubThreadView } from '@lib/web/thread/hooks/view/useUpdateClubThreadView';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';
import ClubMenuThreadView from '../Thread/ClubMenuThreadView';

export default function ThreadsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const { views, reloadViews: reloadClubThreadViews } = useClubThreadViews({
    clubId: club?.clubId,
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const { createClubThreadView } = useUpdateClubThreadView({
    clubId: club?.clubId,
  });

  const handleCreateView = useCallback(async () => {
    const { newViewId } = await createClubThreadView({});

    if (newViewId) {
      await Router.push(`/club/${clubSlug}/thread/${newViewId}`);
      await reloadClubThreadViews();
    }
  }, [clubSlug, createClubThreadView, reloadClubThreadViews]);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps
      title={t('club.allThreads.title')}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <MenuComps.Section>
        {featureEnable.addThreadView && (
          <MenuComps.Button icon={<TestAddIcon />} onClick={handleCreateView}>
            {t('club.newView.title')}
          </MenuComps.Button>
        )}
        {views.map((view) => (
          <ClubMenuThreadView
            key={view.id}
            clubSlug={clubSlug}
            view={view}
            scrollRef={scrollRef}
          />
        ))}
      </MenuComps.Section>
    </MenuComps>
  );
}
