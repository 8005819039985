import { Icon, TooltipList, TooltipListItem } from '@front/ui';
import { ParsedUrlQuery } from 'querystring';

import getAnalyticsPageConfig from '../pageConfig/analytics';
import getClubPageConfig from '../pageConfig/club';
import getMainPageConfig from '../pageConfig/main';
import getMarketplacePageConfig from '../pageConfig/marketplace';
import getPlaylistsPageConfig from '../pageConfig/playlists';
import getPracticePageConfig from '../pageConfig/practice';
import getProfilePageConfig from '../pageConfig/profile';

import PlaylistHeader from './components/PlaylistHeader';
import { ThreadViewBreadcrumb } from './components/ThreadViewBreadcrumb';
import {
  AgentBreadcrumb,
  AnalyticsSummaryCustomDateBreadcrumb,
  AwardCategoryBreadcrumb,
  ChallengeBreadcrumb,
  ClubBreadcrumb,
  ClubGroupBreadcrumb,
  CreatorPlaylistBreadcrumb,
  InvitePhoneNumberBreadcrumb,
  PlaylistBreadcrumb,
  ReviewQuestionDetailBreadcrumb,
} from './BreadcrumbItems';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const mainConfig = getMainPageConfig(t);
  const analyticsConfig = getAnalyticsPageConfig(t);
  const playlistsConfig = getPlaylistsPageConfig(t);
  const practiceConfig = getPracticePageConfig(t);
  const profileConfig = getProfilePageConfig(t);
  const marketplaceConfig = getMarketplacePageConfig(t);
  const clubConfig = getClubPageConfig(t);
  return {
    home: {
      text: mainConfig.allClubs.title,
      icon: 'MainApps',
      href: '/',
      tooltip: mainConfig.allClubs.tooltip,
    },
    clubGroups: {
      text: mainConfig.clubGroups.title,
      icon: mainConfig.clubGroups.icon,
      href: '/club-group/',
      tooltip: mainConfig.clubGroups.tooltip,
    },
    clubGroupDetail: {
      customizeComponent: (
        <ClubGroupBreadcrumb groupId={(query.groupId || '') as string} />
      ),
      href: `/club-group/${query.groupId}`,
    },
    myCreatedClubs: {
      text: mainConfig.myCreatedClubs.title,
      icon: mainConfig.myCreatedClubs.icon,
      href: '/club/my-created',
      tooltip: mainConfig.myCreatedClubs.tooltip,
    },
    joinedClubs: {
      text: mainConfig.joinedClubs.title,
      icon: mainConfig.joinedClubs.icon,
      href: '/club/joined',
      tooltip: mainConfig.joinedClubs.tooltip,
    },

    agents: {
      text: mainConfig.agents.title,
      icon: mainConfig.agents.icon,
      href: `/agents`,
      tooltip: mainConfig.agents.tooltip,
    },
    agentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },
    myAgents: {
      text: mainConfig.myAgents.title,
      icon: mainConfig.myAgents.icon,
      href: `/agents/my-agents`,
      tooltip: mainConfig.myAgents.tooltip,
    },
    workflow: {
      text: mainConfig.workflow.title,
      icon: mainConfig.workflow.icon,
      href: `/agents/workflow`,
    },
    clubAgentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },

    club: {
      customizeComponent: (
        <ClubBreadcrumb slug={(query.slug || '') as string} />
      ),
    },
    directMessages: {
      text: mainConfig.directMessages.title,
      icon: mainConfig.directMessages.icon,
      href: `/direct-messages`,
    },

    directMessagesNewThread: {
      text: mainConfig.directMessagesNewThread.title,
      icon: mainConfig.directMessagesNewThread.icon,
      href: `/direct-messages/new-thread`,
    },
    directMessagesView: {
      customizeComponent: (
        <ThreadViewBreadcrumb viewId={(query.viewId || '') as string} />
      ),
    },

    qBankReporter: {
      text: mainConfig.qBankReporter.title,
      icon: mainConfig.qBankReporter.icon,
      href: `/club/${query.slug}/qbank-reporter`,
    },

    extendTrial: {
      text: mainConfig.extendTrial.title,
      icon: mainConfig.extendTrial.icon,
      href: '/extend-trial',
    },

    // Club
    clubStartPractice: {
      text: clubConfig.startPractice.title,
      icon: clubConfig.startPractice.icon,
      href: `/club/${query.slug}/start`,
    },
    clubRankings: {
      text: clubConfig.rankings.title,
      icon: clubConfig.rankings.icon,
      href: `/club/${query.slug}/rankings`,
      tooltip: {
        icon: clubConfig.rankings.icon,
        title: clubConfig.rankings.title,
        content: (
          <TooltipList>
            <TooltipListItem
              value={{
                type: 'text',
                text: t('club.rankings.tooltip_1'),
                wrap: true,
              }}
            />
            <TooltipListItem
              value={{
                type: 'text',
                text: t('club.rankings.tooltip_2'),
                wrap: true,
              }}
            />
          </TooltipList>
        ),
      },
    },
    // Profile
    profileAccount: {
      text: profileConfig.account.title,
      icon: profileConfig.account.icon,
      href: '/profile/account',
    },

    profileAccountEdit: {
      text: profileConfig.accountEdit.title,
      icon: profileConfig.accountEdit.icon,
      href: '/profile/account/edit',
    },

    profileDiscountCode: {
      text: profileConfig.discountCode.title,
      icon: profileConfig.discountCode.icon,
      href: '/profile/discount-code',
    },

    profileDiscountCodeManagement: {
      text: profileConfig.discountCodeManagement.title,
      icon: profileConfig.discountCodeManagement.icon,
      href: '/profile/discount-code',
    },

    profileMyPlan: {
      text: profileConfig.myPlan.title,
      icon: profileConfig.myPlan.icon,
      href: '/profile/my-plan',
    },

    profileParentPhoneNumber: {
      customizeComponent: <InvitePhoneNumberBreadcrumb />,
      href: '/profile/parent-phone-number',
    },

    profileAwards: {
      text: profileConfig.awards.title,
      icon: profileConfig.awards.icon,
      href: '/profile/awards',
    },

    profileAwardsAlmostThere: {
      text: profileConfig.awardsAlmostThere.title,
      icon: profileConfig.awardsAlmostThere.icon,
      href: '/profile/awards/almost-there',
    },

    profileAwardsByCategory: {
      text: profileConfig.awardsByCategory.title,
      icon: profileConfig.awardsByCategory.icon,
      customizeHeader: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
          iconSize={40}
        />
      ),
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },

    profileMyAvatars: {
      text: profileConfig.myAvatars.title,
      icon: profileConfig.myAvatars.icon,
      href: '/profile/my-avatars',
    },

    profileSettings: {
      text: profileConfig.settings.title,
      icon: profileConfig.settings.icon,
      href: '/profile/settings',
    },

    profileWallet: {
      text: profileConfig.wallet.title,
      icon: profileConfig.wallet.icon,
      href: '/profile/wallet',
    },
    profileWalletAhaPoints: {
      text: profileConfig.walletAhaPoints.title,
      icon: profileConfig.walletAhaPoints.icon,
      href: '/profile/wallet/aha-points',
    },
    inviteFriends: {
      text: profileConfig.inviteFriends.title,
      icon: profileConfig.inviteFriends.icon,
      href: '/profile/invite-friends',
      tooltip: {
        icon: profileConfig.inviteFriends.icon,
        title: profileConfig.inviteFriends.title,
        content: (
          <TooltipList>
            <TooltipListItem
              value={{
                type: 'text',
                text: t('profile.inviteFriends.tooltip_1'),
                sxProps: { mt: 1 },
                wrap: true,
              }}
            />
            <TooltipListItem
              value={{
                type: 'iconText',
                icon: 'TestSolution',
                text: t('profile.inviteFriends.tooltip_2', {
                  count: 100,
                }),
                wrap: true,
              }}
            />
            <TooltipListItem
              value={{
                type: 'iconText',
                icon: 'OtherAhaPoints',
                text: t('profile.inviteFriends.tooltip_3'),
                wrap: true,
              }}
            />
          </TooltipList>
        ),
      },
    },

    // Playlists
    playlistsHistory: {
      text: playlistsConfig.history.title,
      icon: playlistsConfig.history.icon,
      href: `/club/${query.slug}/playlists/history`,
    },
    playlistsHistoryMistakes: {
      text: playlistsConfig.historyMistakes.title,
      icon: playlistsConfig.historyMistakes.icon,
      href: `/club/${query.slug}/playlists/mistakes/${query.markId}`,
    },
    playlistsHistoryOvertime: {
      text: playlistsConfig.historyOvertime.title,
      icon: playlistsConfig.historyOvertime.icon,
      href: `/club/${query.slug}/playlists/overtime/${query.markId}`,
    },
    playlistsHistoryDetail: {
      text: playlistsConfig.historyDetail.title,
      icon: playlistsConfig.historyDetail.icon,
      customizeHeader: (
        <PlaylistHeader quizId={(query.quizId || '') as string} />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.quizId || '') as string}
        />
      ),
      // XXX: use a correct way to build query string,
      // (currently we don't need this href because the opened page doesn't need to be clicked)
      // href: `/club/${query.slug}/playlist/${query.quizId}?variant=${query.variant}`
    },
    playlistsChallenges: {
      text: playlistsConfig.challenges.title,
      icon: playlistsConfig.challenges.icon,
      href: `/club/${query.slug}/challenges`,
    },
    playlistsChallengesDetail: {
      text: playlistsConfig.challenges.title,
      icon: playlistsConfig.challenges.icon,
      customizeHeader: (
        <PlaylistHeader quizId={(query.quizId || '') as string} />
      ),
      customizeComponent: (
        <ChallengeBreadcrumb quizId={(query.quizId || '') as string} />
      ),
      href: `/club/${query.slug}/challenge/${query.quizId}?status=${query.status}`,
    },
    playlistsChallengeChallengers: {
      text: playlistsConfig.challengesChallengers.title,
      icon: playlistsConfig.challengesChallengers.icon,
    },
    playlistsSavedSessions: {
      text: playlistsConfig.savedSessions.title,
      icon: playlistsConfig.savedSessions.icon,
      href: `/club/${query.slug}/playlists/saved`,
    },
    playlistsReaction: {
      text: playlistsConfig.reaction.title,
      icon: playlistsConfig.reaction.icon,
      href: `/club/${query.slug}/playlists/emoji`,
    },
    playlistsReactionDetail: {
      text: (query.title as string) || playlistsConfig.reaction.title, // if query has title, only use icon to display this emoji
      href: `/club/${query.slug}/playlists/emoji/${query.markId}`,
    },
    playlistsFollowing: {
      text: playlistsConfig.following.title,
      icon: playlistsConfig.following.icon,
      href: `/club/${query.slug}/playlists/friends-playlists`,
    },
    playlistsFollowingDetail: {
      text: (query.title as string) || playlistsConfig.following.title,
      icon: playlistsConfig.historyDetail.icon,
      customizeHeader: (
        <PlaylistBreadcrumb
          icon={
            <Icon
              name={playlistsConfig.historyDetail.icon}
              width={40}
              height={40}
            />
          }
          quizId={(query.quizId || '') as string}
        />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.quizId || '') as string}
        />
      ),
    },
    playlistsHashtags: {
      text: playlistsConfig.topics.title,
      icon: playlistsConfig.topics.icon,
      href: `/club/${query.slug}/playlists/hashtags`,
    },

    playlistsHashtagsDetail: {
      text: (query.title as string) || playlistsConfig.topics.title,
      icon: playlistsConfig.topics.icon,
      href: `/club/${query.slug}/playlists/hashtags/${query.markId}`,
    },
    playlistsCreators: {
      text: playlistsConfig.creators.title,
      icon: playlistsConfig.creators.icon,
      href: `/club/${query.slug}/playlists/creators`,
    },
    playlistsCreatorsDetail: {
      customizeHeader: (
        <CreatorPlaylistBreadcrumb
          quizId={(query.quizId || '') as string}
          iconSize={40}
        />
      ),
      customizeComponent: (
        <CreatorPlaylistBreadcrumb quizId={(query.quizId || '') as string} />
      ),
    },
    playlistsMyQuestions: {
      text: playlistsConfig.myQuestions.title,
      icon: playlistsConfig.myQuestions.icon,
      href: `/club/${query.slug}/create-questions`,
    },
    playlistsReviewQuestions: {
      text: playlistsConfig.reviewQuestions.title,
      icon: playlistsConfig.reviewQuestions.icon,
      href: `/club/${query.slug}/review-questions`,
    },
    playlistsReviewQuestionsAll: {
      text: playlistsConfig.reviewQuestionsAll.title,
      icon: playlistsConfig.reviewQuestionsAll.icon,
      href: `/club/${query.slug}/review-questions/all`,
    },
    playlistsReviewQuestionsDetail: {
      customizeHeader: (
        <ReviewQuestionDetailBreadcrumb
          iconSize={40}
          userId={query.userId as string}
        />
      ),
      customizeComponent: (
        <ReviewQuestionDetailBreadcrumb userId={query.userId as string} />
      ),
    },

    // Analytics
    analyticsSummary: {
      text: analyticsConfig.summary.title,
      icon: analyticsConfig.summary.icon,
      tooltip: analyticsConfig.summary.tooltip,
      href: `/club/${query.slug}/summary`,
    },
    analyticsSummaryWallet: {
      text: analyticsConfig.summaryWallet.title,
      icon: analyticsConfig.summaryWallet.icon,
      href: `/club/${query.slug}/summary/points-activity`,
    },
    analyticsSummaryChallenges: {
      text: analyticsConfig.summaryChallenges.title,
      icon: analyticsConfig.summaryChallenges.icon,
      href: `/club/${query.slug}/summary/challenges`,
    },
    analyticsSummaryPlaylist: {
      text: analyticsConfig.summaryPlaylists.title,
      icon: analyticsConfig.summaryPlaylists.icon,
      href: `/club/${query.slug}/summary/playlists`,
    },
    analyticsSummaryAwards: {
      text: analyticsConfig.summaryAwards.title,
      icon: analyticsConfig.summaryAwards.icon,
      href: `/club/${query.slug}/summary/awards`,
    },
    analyticsSummaryCustomDate: {
      href: `/club/${query.slug}/summary?customDate=${query.customDate}`,
      customizeHeader: (
        <AnalyticsSummaryCustomDateBreadcrumb
          date={query.customDate as string}
          iconSize={40}
        />
      ),
      customizeComponent: (
        <AnalyticsSummaryCustomDateBreadcrumb
          date={query.customDate as string}
        />
      ),
    },
    analyticsDailyGoals: {
      text: analyticsConfig.dailyGoals.title,
      icon: analyticsConfig.dailyGoals.icon,
      tooltip: analyticsConfig.dailyGoals.tooltip,
      href: `/club/${query.slug}/analytics/goals`,
    },
    analyticsMyPath: {
      text: analyticsConfig.comparePath.title,
      icon: analyticsConfig.comparePath.icon,
      tooltip: analyticsConfig.comparePath.tooltip,
      href: `/club/${query.slug}/analytics/my-path`,
    },
    analyticsWeaknessMistakes: {
      text: analyticsConfig.weaknessMistakes.title,
      icon: analyticsConfig.weaknessMistakes.icon,
      tooltip: analyticsConfig.weaknessMistakes.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessOvertime: {
      text: analyticsConfig.weaknessOvertime.title,
      icon: analyticsConfig.weaknessOvertime.icon,
      tooltip: analyticsConfig.weaknessOvertime.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessNotPracticed: {
      text: analyticsConfig.weaknessNotPracticed.title,
      icon: analyticsConfig.weaknessNotPracticed.icon,
      tooltip: analyticsConfig.weaknessNotPracticed.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsOverallMistakes: {
      text: analyticsConfig.overallMistakes.title,
      icon: analyticsConfig.overallMistakes.icon,
      tooltip: analyticsConfig.overallMistakes.tooltip,
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },
    analyticsOverallOvertime: {
      text: analyticsConfig.overallOvertime.title,
      icon: analyticsConfig.overallOvertime.icon,
      tooltip: analyticsConfig.overallOvertime.tooltip,
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },

    // Practice
    practiceResult: {
      text: practiceConfig.practiceResults.title,
      icon: practiceConfig.practiceResults.icon,
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result`,
    },
    practiceResultAwards: {
      text: practiceConfig.practiceResultsAwards.title,
      icon: practiceConfig.practiceResultsAwards.icon,
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result/awards`,
    },
    practiceResultAwardsByCategory: {
      customizeHeader: (
        <AwardCategoryBreadcrumb
          iconSize={40}
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },
    examResult: {
      text: practiceConfig.practiceResults.title,
      icon: practiceConfig.practiceResults.icon,
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result`,
    },
    examResultAwards: {
      text: practiceConfig.practiceResultsAwards.title,
      icon: practiceConfig.practiceResultsAwards.icon,
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result/awards`,
    },
    examResultAwardsByCategory: {
      customizeHeader: (
        <AwardCategoryBreadcrumb
          iconSize={40}
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },

    // Marketplace
    marketplaceAvailable: {
      text: marketplaceConfig.available.title,
      icon: marketplaceConfig.available.icon,
      href: '/marketplace/available',
    },

    marketplaceUnavailable: {
      text: marketplaceConfig.unavailable.title,
      icon: marketplaceConfig.unavailable.title,
      href: '/marketplace/unavailable',
    },

    marketplaceComingSoon: {
      text: marketplaceConfig.comingSoon.title,
      icon: marketplaceConfig.comingSoon.title,
      href: '/marketplace/coming-soon',
    },

    marketplaceMyFavorite: {
      text: marketplaceConfig.myFavorite.title,
      icon: marketplaceConfig.myFavorite.title,
      href: '/marketplace/my-favorite',
    },

    marketplaceMyAvatars: {
      text: marketplaceConfig.myAvatars.title,
      icon: marketplaceConfig.myAvatars.title,
      href: '/marketplace/my-avatars',
    },
  };
};
