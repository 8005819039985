import { useMemo } from 'react';
import { ExamMode, useClubSetting } from '@lib/web/apis';
import { getAnswerOptionIdToContentHtmlMap } from '@lib/web/editor';
import { useClubSlug } from '@lib/web/hooks';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../../../hooks/usePracticeStaticData';
import { useAnswerOptions } from '../hooks';
import PracticeMultipleChoices from '../PracticeMultipleChoices';

type MultipleChoicesProps = {
  selectionType: 'single' | 'multiple';
  readonly?: boolean;
};
export default function QuizAnswerMultipleChoices({
  selectionType,
  readonly,
}: MultipleChoicesProps) {
  const { pinned, horizontal } = useAnswerOptions();
  const { onChangeAnswers, onChangeElimination } = usePracticeQuestionEvent();
  const { answers: currentAnswers, eliminatedAnswers } =
    usePracticeQuestionAnswers();
  const { isLoading, isSubmitted, startAt } = usePracticeQuestionStatus();
  const { isCorrect, correctAnswerIds } = usePracticeQuestionResult();
  const { id, details, creatorQuestionInfo, contentEnricher } =
    usePracticeQuestion();

  const clubSlug = useClubSlug();
  const { data: clubSettingData } = useClubSetting(clubSlug);
  const clubSetting = clubSettingData?.data;

  const { statistic } = usePracticeQuestionResult();
  const { mode } = usePracticeStaticData();

  const options = useMemo(() => {
    const questionDetails = [...details].sort((a, b) => a.order - b.order);

    const answerOptionIdToContentMap = getAnswerOptionIdToContentHtmlMap(
      creatorQuestionInfo?.components
    );

    return questionDetails.map((option, index) => {
      return {
        id: option.id,
        key: String.fromCharCode(65 + index),
        value: contentEnricher(
          answerOptionIdToContentMap[option.linkedComponentId]
        ), // for user-create option, the content is the id which link to the corresponding creator detail
        selectedRatio:
          statistic?.find((stat) => stat.id === option.examQuestionDetailId)
            ?.selectedRatio || 0,
      };
    });
  }, [contentEnricher, creatorQuestionInfo?.components, details, statistic]);

  const disabled = isSubmitted || isLoading;
  const blankOptionStatistic = statistic?.find((item) => item.id === null);

  return (
    <PracticeMultipleChoices
      questionId={id}
      value={currentAnswers}
      options={options}
      variant={selectionType === 'multiple' ? 'square' : 'circle'}
      minSelectedCount={
        selectionType === 'multiple'
          ? creatorQuestionInfo?.mrqMinCorrectAnswerNum
          : 1
      }
      maxSelectedCount={
        selectionType === 'multiple'
          ? creatorQuestionInfo?.mrqMaxCorrectAnswerNum
          : 1
      }
      submitted={isSubmitted}
      incorrect={!isCorrect}
      correctAnswers={correctAnswerIds}
      disabled={!startAt || disabled}
      onChange={onChangeAnswers}
      scrollable={!pinned}
      mode={mode}
      onEliminationChange={onChangeElimination}
      eliminatedAnswers={eliminatedAnswers}
      horizontal={horizontal}
      disableSortedByEliminatedAnswers
      blankOptionEnabled={
        mode === ExamMode.Practice &&
        clubSetting?.isEnableNegativeScoring &&
        creatorQuestionInfo?.isOfferBlankOption
      }
      blankOptionStatistic={blankOptionStatistic}
      readonly={readonly}
      disableStatistic={!statistic}
    />
  );
}
