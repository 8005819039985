import { alpha, Palette } from '@mui/material/styles';

import { ButtonColorType, ButtonSizeType, ButtonVariantType } from './types';

type ButtonTheme = {
  variant: ButtonVariantType;
  borderColor: string;
  bgcolor: string;
  color: string;
  hoverBorderColor: string;
  hoverBgcolor: string;
  hoverColor: string;
  activeBorderColor: string;
  activeBgcolor: string;
  activeColor: string;
  loadingBorderColor?: string;
  loadingBgcolor?: string;
  loadingColor?: string;
  disabledBorderColor?: string;
  disabledBgcolor?: string;
  disabledColor?: string;
};

export const getSize = (size?: ButtonSizeType, isGradient?: boolean) => {
  if (size === 'md') return { minWidth: 155 };
  if (size === 'lg') return { minWidth: 200 };
  if (isGradient) return { minWidth: 42 };
  return { minWidth: 120 };
};

export const getIconSize = () => {
  return { minWidth: 36, minHeight: 36, px: 0 };
};

export const getDefaultStyle = (theme: ButtonTheme) => ({
  background: theme.bgcolor,
  color: theme.color,
  borderColor: theme.borderColor,
  '&.is-disabled': {
    background: theme.disabledBgcolor,
    color: theme.disabledColor,
    borderColor: theme.disabledBorderColor,
  },
});

export const getHoverStyle = (theme: ButtonTheme) => ({
  '@media (hover: hover)': {
    '&:hover': {
      color: theme.hoverColor,
      background: theme.hoverBgcolor,
      borderColor: theme.hoverBorderColor,
    },
  },
  '&:active': {
    color: theme.activeColor,
    background: theme.activeBgcolor,
    borderColor: theme.activeBorderColor,
  },
});

export const getLoadingStyle = (theme: ButtonTheme) => ({
  color: theme.loadingColor || theme.activeColor,
  background: theme.loadingBgcolor || theme.activeBgcolor,
  borderColor: theme.loadingBorderColor || theme.activeBorderColor,
});

export const getTheme = (
  palette: Palette,
  color: ButtonColorType,
  variant: ButtonVariantType
) => {
  const isDark = palette.mode === 'dark';

  if (variant === 'outlined') {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          borderColor: alpha(mainColor, 0.5),
          bgcolor: palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: mainColor,
          hoverBgcolor: alpha(mainColor, 0.15),
          hoverColor: palette.text.primary,

          activeBorderColor: 'transparent',
          activeBgcolor: alpha(mainColor, 0.15),
          activeColor: palette.text.primary,

          disabledColor: alpha(palette.text.primary, 0.5),
          disabledBorderColor: alpha(mainColor, 0.3),
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          borderColor: alpha(mainColor, 0.5),
          bgcolor: palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: mainColor,
          hoverBgcolor: alpha(mainColor, 0.15),
          hoverColor: palette.text.primary,

          activeBorderColor: 'transparent',
          activeBgcolor: alpha(mainColor, 0.15),
          activeColor: palette.text.primary,

          disabledColor: alpha(palette.text.primary, 0.5),
          disabledBorderColor: alpha(mainColor, 0.3),
        };
      }
      default: {
        return {
          variant,
          borderColor: alpha(palette.text.primary, 0.5),
          bgcolor: palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: isDark
            ? palette.alpha.lightA00
            : palette.text.primary,
          hoverBgcolor: alpha(palette.text.primary, 0.1),
          hoverColor: palette.text.primary,

          activeBorderColor: 'transparent',
          activeBgcolor: alpha(palette.text.primary, 0.1),
          activeColor: palette.text.primary,

          ...(isDark
            ? {
                disabledColor: alpha(palette.text.primary, 0.5),
                disabledBorderColor: alpha(palette.text.primary, 0.1),
              }
            : {
                disabledBorderColor: alpha(palette.text.primary, 0.1),
                disabledColor: alpha(palette.text.primary, 0.5),
              }),
        };
      }
    }
  } else {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          borderColor: 'transparent',
          bgcolor: mainColor,
          color: isDark ? palette.background.darker : palette.text.primary,

          hoverBorderColor: 'transparent',
          hoverBgcolor: palette.button.successHoverBackground,
          hoverColor: isDark ? palette.background.darker : palette.text.primary,

          activeBorderColor: 'transparent',
          activeBgcolor: palette.button.successActiveBackground,
          activeColor: isDark
            ? palette.background.darker
            : palette.text.primary,

          disabledColor: isDark
            ? palette.background.darker
            : palette.text.primary,
          disabledBorderColor: 'transparent',
          disabledBgcolor: isDark
            ? alpha(palette.alpha.successLightA50, 1)
            : alpha(palette.alpha.successDarkA50, 1),
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          borderColor: 'transparent',
          bgcolor: mainColor,
          color: isDark ? palette.background.darker : palette.text.primary,

          hoverBorderColor: 'transparent',
          hoverBgcolor: palette.button.errorHoverBackground,
          hoverColor: isDark ? palette.background.darker : palette.text.primary,

          activeBorderColor: 'transparent',
          activeBgcolor: palette.button.errorActiveBackground,
          activeColor: isDark
            ? palette.background.darker
            : palette.text.primary,

          disabledColor: isDark
            ? palette.background.darker
            : palette.text.primary,
          disabledBorderColor: 'transparent',
          disabledBgcolor: alpha(palette.alpha.errorDarkA50, 1),
        };
      }
      case 'earn': {
        return {
          variant,
          borderColor: 'transparent',
          bgcolor: palette.gradient.primaryDark,
          color: palette.common.white,

          hoverBorderColor: 'transparent',
          hoverBgcolor:
            'linear-gradient(0deg, rgba(8, 8, 8, 0.3), rgba(8, 8, 8, 0.3)), linear-gradient(269.99deg, #00DEEB 0.01%, #0072DD 99.99%)',
          hoverColor: palette.common.white,

          activeBorderColor: 'transparent',
          activeBgcolor:
            'linear-gradient(0deg, rgba(8, 8, 8, 0.1), rgba(8, 8, 8, 0.1)), linear-gradient(269.99deg, #00DEEB 0.01%, #0072DD 99.99%)',
          activeColor: palette.common.white,

          disabledColor: 'rgba(8, 8, 8, 0.5)',
          disabledBorderColor: 'transparent',
          disabledBgcolor: alpha(palette.common.white, 0.5),
        };
      }

      case 'pay': {
        return {
          variant,
          borderColor: 'transparent',
          bgcolor: palette.gradient.primaryGradientHorizontalPay,
          color: palette.common.white,

          hoverBorderColor: 'transparent',
          hoverBgcolor:
            'linear-gradient(0deg, rgba(8, 8, 8, 0.3), rgba(8, 8, 8, 0.3)), linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
          hoverColor: palette.common.white,

          activeBorderColor: 'transparent',
          activeBgcolor:
            'linear-gradient(0deg, rgba(8, 8, 8, 0.1), rgba(8, 8, 8, 0.1)), linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
          activeColor: palette.common.white,

          disabledColor: 'rgba(8, 8, 8, 0.5)',
          disabledBorderColor: 'transparent',
          disabledBgcolor: alpha(palette.common.white, 0.5),
        };
      }
      default: {
        return {
          variant,
          borderColor: 'transparent',
          bgcolor: palette.text.primary,
          color: palette.background.darker,

          hoverBorderColor: 'transparent',
          hoverBgcolor: palette.button.hoverBackground,
          hoverColor: palette.background.darker,

          activeBorderColor: 'transparent',
          activeBgcolor: palette.button.activeBackground,
          activeColor: palette.background.darker,

          ...(isDark
            ? {
                disabledBgcolor: alpha(palette.common.white, 1),
                disabledColor: alpha(palette.background.darker, 0.5),
              }
            : {
                disabledBorderColor: 'transparent',
                disabledBgcolor: alpha(palette.button.hoverColor, 1),
                disabledColor: alpha(palette.background.darker, 0.5),
              }),
        };
      }
    }
  }
};
