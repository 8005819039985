import { Trans } from 'react-i18next';
import { appConfig } from '@front/config';
import { OtherAhaPoints as OtherAhaPointsIcon } from '@front/icon';
import {
  AuthMemberShipTier,
  PlanProductName,
  SubscriptionAction,
} from '@lib/web/apis';
import { TFunction } from 'i18next';

import { isDev } from '../../config/constants';

import { PlanAction, PlanFeatureGroup, PlanItem, PlansInfo } from './types';

export const getPlanFeatures = (t: TFunction): PlanFeatureGroup[] => {
  return [
    {
      title: t('Learn'),
      features: [
        {
          content: t('Access'),
          free: {
            type: 'clubs',
          },
          basic: {
            type: 'clubs',
          },
          pro: {
            type: 'clubs',
          },
          unlimited: {
            type: 'clubs',
          },
        },
        {
          content: t('Unlimited Question Bank'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Unlimited Answer Solutions'),
          free: false,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('View Answer Solutions'),
          free: isDev ? (
            <Trans
              i18nKey="profile::subscription.token.solution"
              defaults="<1></1>{{token}}  per Solution"
              components={{
                1: <OtherAhaPointsIcon width={16} />,
              }}
              values={{
                token: appConfig.referRewardToken,
              }}
            />
          ) : (
            t('100 Solutions')
          ),
          basic: t('Unlimited'),
          pro: t('Unlimited'),
          unlimited: t('Unlimited'),
        },
        {
          content: t('Practice Mode'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Challenge Mode'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Super Question Access'),
          free: isDev ? (
            <Trans
              i18nKey="profile::subscription.token.question"
              defaults="<1></1>{{token}}  per Super question"
              components={{
                1: <OtherAhaPointsIcon width={16} />,
              }}
              values={{
                token: appConfig.referRewardToken,
              }}
            />
          ) : (
            t('100 Questions')
          ),
          basic: t('Unlimited'),
          pro: t('Unlimited'),
          unlimited: t('Unlimited'),
        },
        {
          content: t('Incognito Mode'),
          free: false,
          basic: true,
          pro: true,
          unlimited: true,
        },
      ],
    },
    {
      title: 'Create',
      features: [
        {
          content: t('Create Questions'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Create Clubs'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Create Club Groups'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
      ],
    },
    {
      title: t('Collaborate'),
      features: [
        {
          content: t('Chat with Friends'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Chat with AI Bots'),
          free: false,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('AI Response'),
          free: isDev ? (
            <Trans
              i18nKey="profile::subscription.token.response"
              defaults="<1></1>{{token}}  per Response"
              components={{
                1: <OtherAhaPointsIcon width={16} />,
              }}
              values={{
                token: appConfig.referRewardToken,
              }}
            />
          ) : (
            '0'
          ),
          basic: isDev ? t('Free') : '10,000',
          pro: isDev ? t('Free') : '20,000',
          unlimited: isDev ? t('Free') : t('Unlimited'),
        },
      ],
    },
    {
      title: t('Others'),
      features: [
        {
          content: t('Pro Badge'),
          free: false,
          basic: true,
          pro: true,
          unlimited: true,
        },
        {
          content: t('Collect Reward Tokens'),
          free: true,
          basic: true,
          pro: true,
          unlimited: true,
        },
      ],
    },
  ];
};

export const getPlansInfo = (t: TFunction): PlansInfo => {
  return {
    free: {
      title: t('Free'),
      description: '',
      mainFeatures: [
        t('Unlimited Question Bank'),
        t('Personalized Analytics'),
        t('Chat with Friends'),
      ],
    },
    basic: {
      title: t('Premium'),
      description: '',
      featureTitle: t('Everything in Free'),
      mainFeatures: [t('Unlimited Solutions'), t('Pro Badge')],
    },
    pro: {
      title: t('Pro'),
      description: t('Pro plan_desc', { value: '20,000' }),
      featureTitle: t('Everything in Premium'),
      mainFeatures: [t('## Tokens', { value: '20,000' })],
    },
    unlimited: {
      title: t('Unlimited'),
      description: t('Unlimited plan_desc'),
      featureTitle: t('Everything in Pro'),
      mainFeatures: [t('Unlimited Tokens')],
    },
  };
};

const PLAN_PRODUCT_WEIGHT_ORDERED: Record<string, number> = {
  [PlanProductName.BasicMonthly]: 10,
  [PlanProductName.BasicYearly]: 11,
  [PlanProductName.ProMonthly]: 100,
  [PlanProductName.ProYearly]: 101,
  [PlanProductName.UnlimitedMonthly]: 1000,
  [PlanProductName.UnlimitedYearly]: 1001,
};

export const getPlanAction = (
  plan: PlanItem,
  currentSubscription?: SubscriptionInfo | null
): PlanAction | undefined => {
  if (!currentSubscription) {
    if (plan.id === 'free') return 'currentPlan';
    return 'subscribe';
  }

  if (currentSubscription.isScheduledCancel && plan.id === 'free') {
    return 'selectedPlan';
  }

  if (plan.id === 'free') return 'downgrade';
  if (!plan.product) return undefined;

  const currentWeight =
    PLAN_PRODUCT_WEIGHT_ORDERED[currentSubscription.productName];
  const planWeight = PLAN_PRODUCT_WEIGHT_ORDERED[plan.product.name];

  if (!currentWeight || !planWeight) return undefined;

  const steps = currentWeight - planWeight;

  if (steps === 0) return 'currentPlan';
  if (steps === 1) return 'switchToMonthly';
  if (steps === -1) return 'switchToYearly';
  if (steps < -1) return 'upgrade';
  if (steps > 1) return 'downgrade';

  return undefined;
};

export const getSubscriptionAction = (
  planAction?: PlanAction,
  selectedPlan?: PlanItem
) => {
  if (!planAction || !selectedPlan) {
    return null;
  }
  switch (planAction) {
    case 'subscribe':
      return SubscriptionAction.Create;
    case 'upgrade':
    case 'switchToMonthly':
    case 'switchToYearly':
      return SubscriptionAction.Update;
    case 'downgrade':
      if (selectedPlan.id === 'free') {
        return SubscriptionAction.Cancel;
      }
      return SubscriptionAction.Update;
    default:
      return null;
  }
};

export const getTierName = (t: TFunction, tier: AuthMemberShipTier): string => {
  switch (tier) {
    case AuthMemberShipTier.PlanTrial:
      return t('Free Trial');
    case AuthMemberShipTier.PlanFree:
      return t('Free');
    case AuthMemberShipTier.PlanBasic:
      return t('Premium');
    case AuthMemberShipTier.PlanPro:
      return t('Pro');
    case AuthMemberShipTier.PlanUnlimited:
      return t('Unlimited');
  }
};
