import { MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';
import { GuestContext } from '@front/helper';
import { Icon, popup } from '@front/ui';
import { Role, useAuth } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { MenuComps } from '@lib/web/ui';

import getMarketplacePageConfig from '../../../pageConfig/marketplace';
import getProfilePageConfig from '../../../pageConfig/profile';

export default function ProfileMenu() {
  const { t } = useTranslation();
  const router = useRouter();
  const { sharePath } = useContext(SharedUserContext);
  const profilePageConfig = getProfilePageConfig(t);
  const marketplacePageConfig = getMarketplacePageConfig(t);
  const { isGuest, hasRoles } = useAuth();
  const signUpUrl = useClaimSignUpUrl();
  const { logout: guestLogout } = useContext(GuestContext);
  const hasMarketingRole = hasRoles([Role.Editor, Role.Marketing]);

  const logout = async (ev: MouseEvent) => {
    await popup.promise({
      content: isGuest
        ? t(
            'profile::logout.guest.tip',
            'You will lose this account if you log out. Are you sure you want to log out?'
          )
        : t('profile::logout.default.tip', 'Are you sure you want to log out?'),
      anchorEl: ev.currentTarget,
      popupOptions: {
        sx: {
          ml: -1,
        },
      },
      cancelText: isGuest
        ? t('profile::logout.default.cta', 'Log Out')
        : undefined,
      confirmText: isGuest
        ? t('profile::logout.guest.cta', 'Sign Up')
        : t('profile::logout.default.cta', 'Log Out'),
      popupType: 'warning',
      onCancel: (cancelType) => {
        if (cancelType === 'button') {
          router.push('/api/auth/logout');
          guestLogout();
        }
      },
      onConfirm: () => {
        if (isGuest) {
          return router.push(signUpUrl);
        }
        return router.push('/api/auth/logout');
      },
    });
  };

  return (
    <MenuComps title={profilePageConfig.account.shortTitle}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.account.icon} />}
          href={`${sharePath}/profile/account`}
        >
          {profilePageConfig.account.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.awards.icon} />}
          href={`${sharePath}/profile/awards`}
        >
          {profilePageConfig.awards.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.myPlan.icon} />}
          href={`${sharePath}/profile/my-plan`}
        >
          {profilePageConfig.myPlan.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.inviteFriends.icon} />}
          href={`${sharePath}/profile/invite-friends`}
        >
          {profilePageConfig.inviteFriends.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.wallet.icon} />}
          href={`${sharePath}/profile/wallet`}
        >
          {profilePageConfig.wallet.title}
        </MenuComps.Button>
        {hasMarketingRole ? (
          <MenuComps.Group
            title={profilePageConfig.discountCode.title}
            icon={<Icon name={profilePageConfig.discountCode.icon} />}
          >
            <MenuComps.Button
              icon={<Icon name={profilePageConfig.discountCode.icon} />}
              href={`${sharePath}/profile/discount-code`}
            >
              {profilePageConfig.discountCode.title}
            </MenuComps.Button>
            <MenuComps.Button
              icon={
                <Icon name={profilePageConfig.discountCodeManagement.icon} />
              }
              href={`${sharePath}/profile/discount-code/management`}
            >
              {profilePageConfig.discountCodeManagement.title}
            </MenuComps.Button>
          </MenuComps.Group>
        ) : (
          <MenuComps.Button
            icon={<Icon name={profilePageConfig.discountCode.icon} />}
            href={`${sharePath}/profile/discount-code`}
          >
            {profilePageConfig.discountCode.title}
          </MenuComps.Button>
        )}
        <MenuComps.Group
          title={t('marketplace.title')}
          icon={<Icon name="ProfileNFTs" />}
        >
          <MenuComps.Button
            icon={<Icon name={marketplacePageConfig.available.icon} />}
            href={`${sharePath}/marketplace/available`}
          >
            {marketplacePageConfig.available.title}
          </MenuComps.Button>
          <MenuComps.Button
            icon={<Icon name={marketplacePageConfig.unavailable.icon} />}
            href={`${sharePath}/marketplace/unavailable`}
          >
            {marketplacePageConfig.unavailable.title}
          </MenuComps.Button>
          <MenuComps.Button
            icon={<Icon name={marketplacePageConfig.comingSoon.icon} />}
            href={`${sharePath}/marketplace/coming-soon`}
          >
            {marketplacePageConfig.comingSoon.title}
          </MenuComps.Button>
          <MenuComps.Button
            icon={<Icon name={marketplacePageConfig.myFavorite.icon} />}
            href={`${sharePath}/marketplace/my-favorite`}
          >
            {marketplacePageConfig.myFavorite.title}
          </MenuComps.Button>
          <MenuComps.Button
            icon={<Icon name={marketplacePageConfig.myAvatars.icon} />}
            href={`${sharePath}/marketplace/my-avatars`}
          >
            {marketplacePageConfig.myAvatars.title}
          </MenuComps.Button>
        </MenuComps.Group>
        <MenuComps.Button
          icon={<Icon name={profilePageConfig.settings.icon} />}
          href={`${sharePath}/profile/settings`}
        >
          {profilePageConfig.settings.title}
        </MenuComps.Button>
        <MenuComps.Button
          data-testid="logout-button"
          icon={<Icon name="ProfileLogout" />}
          onClick={logout}
        >
          {t('menu.Log Out')}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
