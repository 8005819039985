import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import { useAgentWorkflowDetailData } from '@app/web/src/widgets/AgentWorkflowPage/AgentWorkflowDetail/hooks/useAgentWorkflowDetailData';
import { useQueueWorker } from '@front/helper';
import {
  AgentWorkflow as AgentWorkflowIcon,
  OtherEdit as OtherEditIcon,
} from '@front/icon';
import { NameInput } from '@front/ui';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

const styles = {
  inputPrefix: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

export default function AgentWorkflowHeader({
  workflowId,
}: {
  workflowId: string;
}) {
  const { t } = useTranslation();
  const { workflow, mutateWorkflowOptimistic } = useAgentWorkflowDetailData({
    workflowId,
  });
  const { addTask } = useQueueWorker();

  if (!workflow) {
    return (
      <>
        <Box />
        <Skeleton width={400} />
      </>
    );
  }

  const handleNameChange = (name: string) => {
    mutateWorkflowOptimistic({
      optimisticWorkflow: {
        ...workflow,
        name,
      },
      revalidate: false,
    });

    void addTask({
      scope: 'update-workflow-name',
      taskKey: workflowId,
      task: () => {
        callWithToast(
          apis.workflow.updateWorkflow({
            id: workflowId,
            name: name.trim() || 'Untitled',
          })
        );
      },
      debounceTime: 1000,
    });
  };

  return (
    <>
      <Box />
      <NameInput
        value={workflow.name}
        onChange={handleNameChange}
        prefix={
          <Box sx={styles.inputPrefix}>
            <AgentWorkflowIcon width={32} height={32} />
          </Box>
        }
        customFloatingButtons={[
          {
            icon: <OtherEditIcon />,
            tooltip: {
              title: workflow.name,
              content: t('toolbar.click to rename workflow'),
            },
          },
        ]}
      />
    </>
  );
}
