import axios from 'axios';

import api from './client';

const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};

export const changePassword = (email: string) =>
  fetch('/api/auth/change-password', {
    method: 'POST',
    headers,
    body: JSON.stringify({ email }),
  });

export const checkToken = () =>
  fetch('/api/auth/check-token', {
    method: 'GET',
    headers,
  });

const authApi = {
  changePassword,
  checkToken,
  sendVerifyEmail: (params: { redirectTo: string }) =>
    api.post('auth/v3/verify-email/send', params),
  onboarding: (params: OnboardingAccountReq) =>
    axios.post<Response<OnboardingAccountRes>>('/api/auth/onboarding', params),
};
export default authApi;
