import { useTranslation } from 'react-i18next';
import { TestAdd as TestAddIcon } from '@front/icon';
import { useBaseLayout } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { AhaPointSourceType, useAhaPointEarned } from '@lib/web/apis';

import InviteNewUsers from '../../ReferFriendsPage/InviteNewUsers';

const styles = {
  lightBulb: {
    svg: {
      path: { fill: 'url(#primary-gradient-linear-horizontal-dark)' },
    },
    '& .MuiBox-root': {
      color: 'text.primary',
    },
  },
};

export default function InviteByEmailPanel() {
  const { t } = useTranslation('profile');
  const { mutate: earnedMutate } = useAhaPointEarned({
    type: AhaPointSourceType.EarnRefer,
  });
  const { closeRightPanel } = useBaseLayout();

  const handleSentSuccess = () => {
    void earnedMutate();
    closeRightPanel();
  };

  return (
    <InviteNewUsers
      panelTitleIcon={<Icon name="OtherAddFriend" width="16" height="16" />}
      panelTitle={t('invite.friends.RHS.title', 'Invite by Email')}
      searchPlaceholder={t(
        'search.placeholder_friendEmail',
        'Enter friend’s email...'
      )}
      searchIcon={<TestAddIcon width={16} height={16} />}
      lightbulbSx={styles.lightBulb}
      lightbulbIcon={<Icon name="OtherAhaPoints" />}
      lightbulbText={t(
        'invite.friends.RHS.tip',
        'Both you and your friend earn Aha Points when they accept your invite.'
      )}
      onSentSuccess={handleSentSuccess}
    />
  );
}
