import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchState } from '@app/web/src/utils/search';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';

export default function useSearchDisplayDataset({
  state,
  initialResult,
  searchResult,
  excludeMembers,
  newUser,
  searchLoading,
  i18n,
  ignoreNewUserInvite,
}: {
  state: SearchState | null;
  initialResult: GetFollowerRes[];
  searchResult: GetFriendUserRes[];
  newUser: {
    email: string;
    isAccepted: boolean;
    isPending: boolean;
    resendAble: boolean;
  } | null;
  searchLoading: boolean;
  excludeMembers?: { userId: string }[];
  i18n: {
    excludeKey: string;
  };
  ignoreNewUserInvite?: boolean;
}): IconListLayoutItemObj[] {
  const { t } = useTranslation();
  return useMemo(() => {
    if (state === SearchState.Searching) {
      if (newUser) {
        return ignoreNewUserInvite
          ? [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  select: {
                    value: 'userSelected',
                  },
                },
                metadata: {
                  email: newUser.email,
                },
              },
            ]
          : [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t('button.Invite To Aha'),
                    actionType: 'textButton',
                  },
                },
                metadata: {
                  email: newUser.email,
                },
              },
            ];
      }

      if (searchLoading) {
        return [];
      }
      return searchResult.map((result) => {
        const hasJoined =
          i18n.excludeKey &&
          excludeMembers?.some((member) => member.userId === result.userId);
        return {
          id: result.userId,
          title: result.displayName,
          titleAction: { type: 'event', value: 'titleClick' },
          description: hasJoined ? t(i18n.excludeKey) : `@${result.userName}`,
          avatarBlackAndWhite: !result.userId,
          avatarUrl: result.nftAvatar || result.avatar,
          actionMap: hasJoined
            ? undefined
            : {
                select: {
                  value: 'userSelected',
                },
                hover: {
                  value: 'userHovered',
                },
              },
          metadata: {
            userId: result.userId,
            memberId: result.memberId,
          },
        };
      });
    }
    if (state === SearchState.Initial) {
      return initialResult.map((result) => {
        const hasJoined =
          i18n.excludeKey &&
          excludeMembers?.some((member) => member.userId === result.userId);

        return {
          id: result.userId,
          title: result.displayName,
          titleAction: { type: 'event', value: 'titleClick' },
          avatarBlackAndWhite: !result.userId,
          description: hasJoined ? t(i18n.excludeKey) : `@${result.userName}`,
          avatarUrl: result.nftAvatar || result.avatar,
          actionMap: hasJoined
            ? undefined
            : {
                select: {
                  value: 'userSelected',
                },
                hover: {
                  value: 'userHovered',
                },
              },
          metadata: {
            userId: result.userId,
            memberId: result.memberId,
          },
        };
      });
    }

    return [];
  }, [
    state,
    newUser,
    searchLoading,
    searchResult,
    ignoreNewUserInvite,
    t,
    i18n.excludeKey,
    excludeMembers,
    initialResult,
  ]);
}
