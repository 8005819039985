import { AnswerFormatType } from '@lib/web/apis';

export const validateAnswer = (
  userAnswer: string[] | undefined,
  question: {
    creatorQuestionInfo: { answerFormatType: AnswerFormatType };
    correctAnswerIds: string[];
    correctAnswerValues: string[];
  }
): boolean => {
  if (!userAnswer?.length) return false;

  const { answerFormatType } = question.creatorQuestionInfo;

  switch (answerFormatType) {
    case AnswerFormatType.MultipleChoice:
    case AnswerFormatType.MultipleResponse:
    case AnswerFormatType.TrueFalseNotGiven: {
      if (!question.correctAnswerIds?.length) return false;

      // Sort both arrays to ensure order doesn't matter
      const sortedUserAnswer = [...userAnswer].sort();
      const sortedCorrectAnswer = [...question.correctAnswerIds].sort();

      return (
        sortedUserAnswer.length === sortedCorrectAnswer.length &&
        sortedUserAnswer.every((id, index) => id === sortedCorrectAnswer[index])
      );
    }

    case AnswerFormatType.GridIn: {
      if (!question.correctAnswerValues?.length) return false;

      const userValue = userAnswer[0]?.trim().toLowerCase();
      if (!userValue) return false;

      return question.correctAnswerValues.some(
        (value) => value?.trim().toLowerCase() === userValue
      );
    }

    case AnswerFormatType.Unscramble: {
      if (!question.correctAnswerIds?.length) return false;

      // For unscramble, order matters so don't sort
      return (
        userAnswer.length === question.correctAnswerIds.length &&
        userAnswer.every((id, index) => id === question.correctAnswerIds[index])
      );
    }

    case AnswerFormatType.FreeResponse:
    case AnswerFormatType.Essay:
      // FreeResponse and Essay questions always correct for now util we have AI grading
      return true;

    default:
      console.warn('Unsupported answer format type:', answerFormatType);
      return false;
  }
};
