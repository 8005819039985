import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import useExpiresCountdown from '@app/web/src/hooks/utils/useExpiresCountdown';
import { OtherLink as OtherLinkIcon } from '@front/icon';
import {
  BackIconType,
  BaseLayoutRightPanel,
  Button,
  Scrollbar,
  UserAvatar,
} from '@front/ui';
import { useAuth, useMemberInfo } from '@lib/web/apis';
import { useCopyToClipboard } from '@lib/web/hooks';
import { getIndicators } from '@lib/web/utils';
import { QRCodeSVG } from 'qrcode.react';

const styles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '112px',
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  content: {
    px: '12px',
  },
  paymentLinkQr: {
    display: 'flex',
    justifyContent: 'center',
    py: 2,
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 'auto',
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    opacity: 0.64,
    mt: 2,
  },
  steps: {
    mt: 3,
    pb: '20px',
  },
  step: {
    pb: 3,
    pl: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '4px',
      left: 0,
      width: 16,
      height: 16,
      bgcolor: (theme: Theme) => theme.palette.primary.light,
      borderRadius: '50%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '4px',
      left: '8px',
      width: '1px',
      height: '100%',
      bgcolor: (theme: Theme) => alpha(theme.palette.primary.light, 0.5),
    },
    '&:last-child': {
      pb: 0,
      '&:after': {
        display: 'none',
      },
    },
  },
  stepTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  stepDescription: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.4px',
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    p: 1.5,
    button: {
      ml: { md: 'auto' },
    },
  },
  timerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  timerLabel: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '22px',
    opacity: 0.64,
  },
  timer: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    '& span': {
      opacity: 0.64,
    },
  },
  copyActive: {
    opacity: 1,
    color: 'background.darker',
    bgcolor: 'success.light',
  },
};

type PaymentLinkPanelProps = {
  paymentLink: string;
  expiresAt: Date;
  backIcon?: BackIconType;
  onIconClick?: () => void;
};
export default function PaymentLinkPanel({
  paymentLink,
  expiresAt,
  backIcon,
  onIconClick,
}: PaymentLinkPanelProps) {
  const { t } = useTranslation('marketplace');
  const countdown = useExpiresCountdown(expiresAt);
  const [, copy] = useCopyToClipboard();
  const { member } = useAuth();
  const { data: userData } = useMemberInfo(member?.userId, member?.memberId);
  const [copied, setCopied] = useState(false);
  const copyTimeRef = useRef<NodeJS.Timeout>();

  const handleCopyLink = async () => {
    await copy(paymentLink || '');
    setCopied(true);
    if (copyTimeRef.current) {
      clearTimeout(copyTimeRef.current);
    }
    copyTimeRef.current = setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(
    () => () => {
      if (copyTimeRef.current) clearTimeout(copyTimeRef.current);
    },
    []
  );

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherLinkIcon width="16" height="16" />}
      title={t('Payment Link')}
      backIcon={backIcon}
      onIconClick={onIconClick}
    >
      <Scrollbar sx={styles.scroll}>
        <Box sx={styles.content}>
          <Box sx={styles.paymentLinkQr}>
            <QRCodeSVG
              value={paymentLink}
              size={168}
              bgColor="transparent"
              fgColor="#fff"
            />
          </Box>
          <Box sx={styles.avatarWrapper}>
            <UserAvatar
              sx={styles.avatar}
              src={userData?.data.nftAvatar || userData?.data.avatar}
              title={
                userData?.data.displayName || userData?.data.distinctName || ''
              }
              subTitle={`@${userData?.data.distinctName}`}
              size="md"
              indicators={getIndicators(userData?.data.indicator)}
            />
          </Box>
          <Typography sx={styles.description}>
            {t('Scan to pay on your other devices')}
          </Typography>
          <Box sx={styles.steps}>
            <Box sx={styles.step}>
              <Typography sx={styles.stepTitle} variant="h6">
                {t('Get Help Buying')}
              </Typography>
              <Typography sx={styles.stepDescription} variant="body1">
                {t('Copy the link and share with parents')}
              </Typography>
            </Box>
            <Box sx={styles.step}>
              <Typography sx={styles.stepTitle} variant="h6">
                {t('Time-Sensitive Reservation')}
              </Typography>
              <Typography sx={styles.stepDescription} variant="body1">
                {t('The avatar will be on hold')}
              </Typography>
            </Box>
            <Box sx={styles.step}>
              <Typography sx={styles.stepTitle} variant="h6">
                {t('Track Your Purchase')}
              </Typography>
              <Typography sx={styles.stepDescription} variant="body1">
                {t('You will receive a notification confirming')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Scrollbar>
      <Box sx={styles.action}>
        <Box sx={styles.timerWrapper}>
          <Typography sx={styles.timerLabel}>
            {t('Payment Link Expires In')}
          </Typography>
          <Typography sx={styles.timer}>
            {countdown.minutes} <span>min</span> {countdown.seconds}{' '}
            <span>sec</span>
          </Typography>
        </Box>
        <Button
          prefixIcon={<OtherLinkIcon />}
          onClick={handleCopyLink}
          variant="outlined"
          data-testid="copy-link-button"
          sx={[copied && styles.copyActive]}
        >
          {copied ? t('button.Link Copied') : t('button.Copy Link')}
        </Button>
      </Box>
    </BaseLayoutRightPanel>
  );
}
