import api from './client';

const workflowApi = {
  createWorkflow: (params: CreateWorkflowReq) =>
    api.post<Response<CreateWorkflowRes>>('v2/ai/workflow', params),
  createWorkflowRecipe: (params: CreateWorkflowRecipeReq) =>
    api.post<Response<CreateWorkflowRecipeRes>>(
      'v2/ai/workflow/recipe',
      params
    ),
  updateWorkflow: (params: UpdateWorkflowReq) =>
    api.put<Response<UpdateWorkflowRes>>('v2/ai/workflow', params),
  updateWorkflowRecipe: (params: UpdateWorkflowRecipeReq) =>
    api.put<Response<UpdateWorkflowRecipeRes>>('v2/ai/workflow/recipe', params),
  deleteWorkflowRecipe: (params: DeleteWorkflowRecipeReq) =>
    api.delete<Response<{ count: number }>>('v2/ai/workflow/recipe', {
      data: params,
    }),
  createWorkflowRecipePrompt: (params: CreateWorkflowRecipePromptReq) =>
    api.post<Response<CreateWorkflowRecipePromptRes>>(
      'v2/ai/workflow/recipe/prompt',
      params
    ),
  updateWorkflowRecipePrompt: (params: UpdateWorkflowRecipePromptReq) =>
    api.put<Response<UpdateWorkflowRecipePromptRes>>(
      'v2/ai/workflow/recipe/prompt',
      params
    ),
  deleteWorkflowRecipePrompt: (params: DeleteWorkflowRecipePromptReq) =>
    api.delete<Response<{ count: number }>>('v2/ai/workflow/recipe/prompt', {
      data: params,
    }),
  updateWorkflowRecipePromptOrder: (
    params: UpdateWorkflowRecipePromptOrderReq
  ) =>
    api.post<Response<UpdateWorkflowRecipePromptOrderRes>>(
      'v2/ai/workflow/recipe/prompt/order',
      params
    ),
  startWorkflow: (params: StartWorkflowReq) =>
    api.post<Response<StartWorkflowRes>>('func/ai/workflow/start', params),
};

export default workflowApi;
