import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Box } from '@mui/material';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type StartPracticeButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.start.practice' }>;
};

export default function StartPracticeButton({ cta }: StartPracticeButtonProps) {
  const { t } = useTranslation('notification');

  return (
    <Box sx={styles.root}>
      <Button
        prefixIcon={<OtherPlayIcon />}
        component={Link}
        href={`/club/${cta.clubSlug}/start`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {t('cta.start.practice.button.Start Practice', 'Start Practice')}
      </Button>
    </Box>
  );
}
