import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Portal, Theme, useMediaQuery, useTheme } from '@mui/material';
import useOnboardingData from '@app/web/src/hooks/utils/useOnboardingData';
import { useLatestValueRef } from '@front/helper';
import {
  getPinChatVariant,
  PinArrowDirection,
  PinChat,
  PinChatProps,
  PinDialogAlign,
} from '@front/ui';
import { PinChatReferenceId, PinChatUserStatus, useAuth } from '@lib/web/apis';
import { isEmpty } from 'lodash';

type Offset = ((params: { rect: DOMRect }) => number) | number;
type PositionConfig = {
  selector: string | (() => HTMLElement);
  arrowDirection?: PinArrowDirection;
  dialogAlign?: PinDialogAlign;
  xOffset?: Offset | { xs: Offset; md: Offset };
  yOffset?: Offset | { xs: Offset; md: Offset };
  arrowOffsetX?: number | { xs: number; md: number };
  arrowOffsetY?: number | { xs: number; md: number };
  message?: string;
};

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 'tooltip',
  },
};

const getOffsetValue = (
  offset: Offset | { xs: Offset; md: Offset } | undefined,
  params: { rect: DOMRect },
  isMobile: boolean
): number => {
  if (!offset) return 0;

  if (typeof offset === 'object' && 'xs' in offset) {
    const currentOffset = isMobile ? offset.xs : offset.md;
    return typeof currentOffset === 'function'
      ? currentOffset(params)
      : currentOffset;
  }

  return typeof offset === 'function' ? offset(params) : offset;
};

function getResponsiveValue<V>(
  value: V | { xs: V; md: V } | undefined,
  isMobile: boolean
) {
  if (!value) return value;

  if (typeof value === 'object' && 'xs' in value) {
    const currentOffset = isMobile ? value.xs : value.md;
    return currentOffset;
  }

  return value;
}

function OnboardingPin({
  config,
  pin,
  userStatus,
  currentStep,
  totalStep,
  x,
  y,
  onUpdatePosition,
  ...rest
}: {
  config: PositionConfig;
  pin: GetIaPinChatOverlayDefaultViewRes;
  userStatus: PinChatUserStatus;
  currentStep: number;
  totalStep: number;
  x: number;
  y: number;
  onNext: PinChatProps['onNext'];
  onFinish: PinChatProps['onFinish'];
  onHide: PinChatProps['onHide'];
  onUpdatePosition: () => void;
}) {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLElement | null>(null);
  const active = currentStep === pin.stepNum;
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const onUpdatePositionRef = useLatestValueRef(onUpdatePosition);
  useEffect(() => {
    targetRef.current =
      typeof config.selector === 'string'
        ? document.querySelector(config.selector)
        : config.selector();

    const scrollToTarget = () => {
      targetRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
      setTimeout(() => {
        onUpdatePositionRef.current();
      }, 500);
    };
    const resizeObserver = new ResizeObserver(onUpdatePositionRef.current);

    const intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (!entry.isIntersecting && active) {
        scrollToTarget();
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
      intersectionObserver.observe(targetRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      intersectionObserver.disconnect();
    };
  }, [active, config, onUpdatePositionRef, mdUp]);

  return (
    <PinChat
      x={x}
      y={y}
      arrowOffsetX={getResponsiveValue(config.arrowOffsetX, !mdUp)}
      arrowOffsetY={getResponsiveValue(config.arrowOffsetY, !mdUp)}
      arrowDirection={config.arrowDirection}
      dialogAlign={config.dialogAlign}
      key={pin.stepNum}
      step={pin.stepNum}
      variant={
        userStatus === PinChatUserStatus.Checked && pin.stepNum !== currentStep
          ? 'viewed'
          : getPinChatVariant(pin.stepNum, currentStep)
      }
      message={config.message || pin.desc}
      totalStep={totalStep}
      doneText={t('button.Done')}
      nextText={t('button.Next')}
      hideText={t('button.Hide')}
      {...rest}
    />
  );
}
function GenericOnboarding({
  stepConfig,
  target,
  onNext,
  onHide,
  onFinish,
  onFailed,
  onlyShowCurrentStep,
}: {
  target: PinChatReferenceId;
  stepConfig: Record<number, PositionConfig>;
  onNext?: (step: number) => void;
  onHide?: () => void;
  onFinish?: () => void;
  onFailed?: () => void;
  onlyShowCurrentStep?: boolean;
}) {
  const [positions, setPositions] = useState<
    Record<number, { x: number; y: number }>
  >({});
  const [allChecked, setAllChecked] = useState(false);
  const onboardingData = useOnboardingData(target);
  const [step, setStep] = useState(0);
  const { member } = useAuth();
  const userId = member?.userId || '';
  const failed =
    onboardingData?.pins.some((pin) => !stepConfig[pin.stepNum]) ?? false;
  const latestOnFailedRef = useLatestValueRef(onFailed);
  const handleNext = (pin: GetIaPinChatOverlayDefaultViewRes) => {
    onboardingData?.viewPin(pin.pinChatId);
    setStep(step + 1);
    onNext?.(step + 1);
  };
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleFinish = async (pin: GetIaPinChatOverlayDefaultViewRes) => {
    setAllChecked(true);
    await onboardingData?.viewPin(pin.pinChatId);
    onFinish?.();
  };

  useEffect(() => {
    if (onboardingData && onboardingData.defaultStepNum > step) {
      setStep(onboardingData.defaultStepNum);
    }
  }, [onboardingData, step]);

  useEffect(() => {
    if (failed) {
      latestOnFailedRef.current?.();
    }
  }, [failed, latestOnFailedRef]);

  const updatePinPosition = useCallback(() => {
    Object.entries(stepConfig).forEach(([stepNum, config]) => {
      const dom =
        typeof config.selector === 'string'
          ? document.querySelector(config.selector)
          : config.selector();

      if (!dom) {
        setPositions({});
        return;
      }
      const rect = dom.getBoundingClientRect();
      const offsetX = getOffsetValue(config.xOffset, { rect }, !mdUp);
      const offsetY = getOffsetValue(config.yOffset, { rect }, !mdUp);
      setPositions((prev) => ({
        ...prev,
        [stepNum]: {
          x: rect.x + offsetX + window.scrollX,
          y: rect.y + offsetY + window.scrollY,
        },
      }));
    });
  }, [mdUp, stepConfig]);

  useEffect(() => {
    window.addEventListener('resize', updatePinPosition);
    window.addEventListener('scroll', updatePinPosition);
    updatePinPosition();
    return () => {
      window.removeEventListener('resize', updatePinPosition);
      window.removeEventListener('scroll', updatePinPosition);
    };
  }, [updatePinPosition]);

  if (allChecked || !onboardingData?.pins.length || isEmpty(positions))
    return null;
  if (failed) return null;

  return (
    <Portal>
      <Box sx={styles.root}>
        {onboardingData.pins.map((pin) => {
          const userStatus = pin.pinChatUsers.find(
            (user) => user.userId === userId
          )?.status;
          if (onlyShowCurrentStep && pin.stepNum !== step) return null;
          return (
            <OnboardingPin
              key={pin.stepNum}
              x={positions[pin.stepNum]?.x}
              y={positions[pin.stepNum]?.y}
              pin={pin}
              config={stepConfig[pin.stepNum]}
              userStatus={userStatus}
              currentStep={step}
              totalStep={onboardingData?.pins.length}
              onNext={() => handleNext(pin)}
              onFinish={() => handleFinish(pin)}
              onHide={onHide}
              onUpdatePosition={updatePinPosition}
            />
          );
        })}
      </Box>
    </Portal>
  );
}

export default function GenericOnboardingRoot({
  target,
  stepConfig,
  onFailed,
  onFinish,
  onHide,
}: {
  target: PinChatReferenceId;
  stepConfig: Record<number, PositionConfig>;
  onFailed?: () => void;
  onFinish?: () => void;
  onHide?: () => void;
}) {
  const onboardingData = useOnboardingData(target);
  const [hide, setHide] = useState(false);

  if (!onboardingData || onboardingData.finished || hide) return null;

  const handleHide = () => {
    setHide(true);
    onHide?.();
  };

  const handleFinish = () => {
    setHide(true);
    onFinish?.();
  };

  return (
    <GenericOnboarding
      target={target}
      stepConfig={stepConfig}
      onHide={handleHide}
      onFinish={handleFinish}
      onFailed={onFailed}
    />
  );
}
