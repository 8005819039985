import { useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { trackEvent } from '@lib/web/utils';

export const AHA_SIGN_UP_EVENT_SENT = 'aha-sign-up-event-sent';

const useSendSignUpEvent = () => {
  const { user } = useUser();

  useEffect(() => {
    if (!user?.isSignup) return;

    const eventSent = localStorage.getItem(AHA_SIGN_UP_EVENT_SENT);
    if (eventSent) return;

    trackEvent('sign_up');
    localStorage.setItem(AHA_SIGN_UP_EVENT_SENT, 'true');
  }, [user?.isSignup]);
};

export default function GlobalTrack() {
  useSendSignUpEvent();
  return null;
}
