import React from 'react';
import { Box, Theme } from '@mui/material';
import {
  IconButton,
  ResponsiveTooltip,
  ResponsiveTooltipProps,
} from '@front/ui';

const styles = {
  floatingButtonsWrap: {
    display: 'flex',
    alignItems: 'center',
    bgcolor: 'background.menu',
    borderRadius: 1,
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${
        theme.palette.mode === 'light'
          ? 'rgba(8, 8, 8, 0.05)'
          : 'rgba(255, 255, 255, 0.1)'
      }`,
    p: 0.25,
  },
  iconButton: {
    minWidth: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export interface FloatingButton {
  icon?: React.ReactNode;
  tooltip?: {
    title?: string;
    content?: React.ReactNode;
    tooltipProps?: ResponsiveTooltipProps['tooltipProps'];
  };
  onClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onHover?: (e?: Element) => void;
  onPress?: (e?: Element) => void;
  component?: React.ReactElement;
}

export type FloatingButtonsProps = {
  buttons: FloatingButton[];
};

export function FloatingButtons({ buttons }: FloatingButtonsProps) {
  const handleButtonClick = (ev: React.MouseEvent, button: FloatingButton) => {
    ev.stopPropagation();
    button.onClick?.(ev);
  };

  const renderFloatingButton = (button: FloatingButton) => {
    if (button.component) return button.component;

    const buttonContent = (
      <Box
        sx={styles.iconButton}
        onMouseDown={button.onMouseDown}
        onClick={(ev) => handleButtonClick(ev, button)}
      >
        <IconButton customSize={24}>{button.icon}</IconButton>
      </Box>
    );

    if (button.tooltip) {
      return (
        <ResponsiveTooltip
          title={button.tooltip.title}
          content={button.tooltip.content}
          tooltipProps={{
            followCursor: true,
            disableInteractive: true,
            ...button.tooltip.tooltipProps,
          }}
        >
          {buttonContent}
        </ResponsiveTooltip>
      );
    }

    return buttonContent;
  };

  return (
    <Box sx={styles.floatingButtonsWrap}>
      {buttons.map((button, index) => (
        <React.Fragment key={index}>
          {renderFloatingButton(button)}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default FloatingButtons;
