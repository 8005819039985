import { createContext, MouseEvent, TouchEvent } from 'react';

export const iaDataContextInitialValue = {
  token: '',
  sid: '',
  apiUrl: '',
};

export type IaDataContextValueType = {
  token?: string;
  sid?: string;
  apiUrl?: string;

  authGuardCheck?: (ev: {
    event?: MouseEvent | TouchEvent;
    placement?: 'rhs' | 'center';
  }) => boolean;
};
type IaDataContextValue = {
  token?: string;
  sid?: string;
  apiUrl?: string;

  authGuardCheck?: (ev: {
    event?: MouseEvent | TouchEvent;
    placement?: 'rhs' | 'center';
  }) => boolean;
};
const IaDataContext = createContext<IaDataContextValue>(
  iaDataContextInitialValue
);

export default IaDataContext;
