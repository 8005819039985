import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Router from 'next/router';
import { Box } from '@mui/material';
import { useDateFormat } from '@front/helper';
import {
  MainPractice as MainPracticeIcon,
  StreakFireColor as StreakFireColorIcon,
} from '@front/icon';
import { Button, NumberCard } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';
import { subHours } from 'date-fns';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  streakBadge: {
    display: 'flex',
    alignItems: 'center',
  },
  streakRank: {
    pt: 1,
    fontWeight: 350,
    fontSize: '48px',
  },
  streakIcon: {
    position: 'absolute',
    right: -21.57,
    bottom: -10.75,
    opacity: '0.5',
    zIndex: -1,
  },
};

type StreakCardProps = {
  tag: GetNotificationRes['tag'];
  type: string;
  streakCount: number;
  streakDateStart: string | null;
  streakDateEnd: string | null;
  clubSlug?: string;
};

function patchStreakDateEnd(date: string | null) {
  /**
   * since we use utc to store streak period in backend, which doesn't consider user timezone
   * for example, when user achieve streak at 1/16 15:00 the streak will look like
   * 1/16 00:00 ~ 1/16 23:59
   *
   * we ignore the timezone of streak date, because it's hard to predict
   * but we assume most of us now is based on tw time, so we do some trick for the streak end date, make it a little resonable by shift it to the middle of day instead of end of day
   */
  if (!date) return;
  return subHours(new Date(date), 12);
}

export default function StreakCard({
  tag,
  type,
  streakDateEnd,
  streakCount,
  clubSlug: clubSlugProp,
}: StreakCardProps) {
  const { t } = useTranslation('notification');
  const routerClubSlug = useClubSlug();
  const clubSlug = clubSlugProp || routerClubSlug;

  const { displayDateFormat } = useDateFormat();
  if (
    tag === 'streak.reminder.broken.member.club' &&
    type === 'cta.streak.reminder'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Longest Streak')}
        titleIcon="EmojiFire"
      >
        <NumberCard.Tooltip
          title={t('streak.longest.tooltip.title', 'Longest Streak')}
          titleIcon="EmojiFire"
          content={t('streak.longest.tooltip.content')}
        />
        <NumberCard.Value suffix={t('streak.days', { count: streakCount })}>
          {streakCount}
        </NumberCard.Value>
        <NumberCard.Description>
          {t('streak.ended', {
            date: displayDateFormat(patchStreakDateEnd(streakDateEnd)),
          })}
        </NumberCard.Description>
        <Box sx={styles.streakIcon}>
          <StreakFireColorIcon width={112.5} height={112.5} />
        </Box>
      </NumberCard>
    );
  }

  if (type === 'cta.streak.longest') {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Longest Streak')}
        titleIcon="EmojiFire"
      >
        <NumberCard.Tooltip
          title={t('streak.longest.tooltip.title', 'Longest Streak')}
          titleIcon="EmojiFire"
          content={t('streak.longest.tooltip.content')}
        />
        <NumberCard.Value suffix={t('streak.days', { count: streakCount })}>
          {streakCount}
        </NumberCard.Value>
        <NumberCard.Description>
          {t('streak.ended', {
            date: displayDateFormat(patchStreakDateEnd(streakDateEnd)),
          })}
        </NumberCard.Description>
        <Box sx={styles.streakIcon}>
          <StreakFireColorIcon width={112.5} height={112.5} />
        </Box>
      </NumberCard>
    );
  }

  if (tag === 'streak.reminder.daily.member.club') {
    return (
      <Button
        prefixIcon={<MainPracticeIcon />}
        component={Link}
        href={`/club/${clubSlug}/start`}
      >
        {t('button.Start Practice')}
      </Button>
    );
  }

  if (
    tag === 'streak.achieved.single.day.member.club' &&
    type === 'cta.streak.achieved'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Current Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          void Router.push(`/club/${clubSlug}/summary`);
        }}
        actionIcon="ActionArrowRightUp"
      >
        <NumberCard.Tooltip
          title={t('streak.current.tooltip.title', 'Current Streak')}
          titleIcon="EmojiFire"
          content={t('streak.current.tooltip.content')}
        />
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {t('streak.Days', {
            count: streakCount,
          })}
        </NumberCard.Description>
        <Box sx={styles.streakIcon}>
          <StreakFireColorIcon width={112.5} height={112.5} />
        </Box>
      </NumberCard>
    );
  }

  if (
    tag === 'streak.achieved.multiple.day.member.club' &&
    type === 'cta.streak.reminder'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Current Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          void Router.push(`/club/${clubSlug}/summary`);
        }}
        actionIcon="ActionArrowRightUp"
      >
        <NumberCard.Tooltip
          title={t('streak.current.tooltip.title', 'Current Streak')}
          titleIcon="EmojiFire"
          content={t('streak.current.tooltip.content')}
        />
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {t('streak.Days', {
            count: streakCount,
          })}
        </NumberCard.Description>
        <Box sx={styles.streakIcon}>
          <StreakFireColorIcon width={112.5} height={112.5} />
        </Box>
      </NumberCard>
    );
  }

  if (type === 'cta.streak.current') {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Current Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          void Router.push(`/club/${clubSlug}/summary`);
        }}
        actionIcon="ActionArrowRightUp"
      >
        <NumberCard.Tooltip
          title={t('streak.current.tooltip.title', 'Current Streak')}
          titleIcon="EmojiFire"
          content={t('streak.current.tooltip.content')}
        />
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {t('streak.Days', {
            count: streakCount,
          })}
        </NumberCard.Description>
        <Box sx={styles.streakIcon}>
          <StreakFireColorIcon width={112.5} height={112.5} />
        </Box>
      </NumberCard>
    );
  }

  return null;
}
