import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { alpha, Box, Theme, Typography } from '@mui/material';
import {
  Button,
  Buttons,
  Icon,
  PopupOverlay,
  PopupOverlayProps,
  TextButton,
} from '@front/ui';
import { AccountBlockerType, isDev } from '@lib/web/apis';

import { useAccountFeatureBlockConfig } from './hooks/useAccountFeatureBlockConfig';
import { AccountFeatureBlockerPlacement } from './types';

const styles = {
  rhs: {
    top: 48,
  },
  modal: (top: number, left: number) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left,
    '& .MuiBackdrop-root': {
      position: 'absolute',
      top: {
        xs: top,
        md: `calc(${top}px + var(--announcement-bar-height))`,
      },
      left,
      background: (theme: Theme) =>
        alpha(theme.palette.background.darker, 0.01),
    },
  }),
  overlay: (placement: AccountFeatureBlockerPlacement | null) => ({
    width: '100%',
    height: { xs: 'auto', md: 'auto' }, // this is to clear the PopupOverlay default behavior
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    maxWidth: 796,
    '& .popup-overlay-title': {
      typography: 'appH1',
    },
    '& .popup-overlay-body': {
      span: {
        color: 'text.primary',
      },
    },
    '& .popup-overlay-emoji': {
      width: 40,
      height: 40,
      '& svg': {
        width: 40,
        height: 40,
      },
    },
    '& .popup-overlay-content': {
      gap: 1,
      py: 0,
    },
    '& .popup-overlay-close': {
      top: placement === 'rhs' ? 16 : 40,
      right: placement === 'rhs' ? 16 : 50,
    },
  }),
  card: {
    mt: 2,
  },
  actions: {
    pointerEvents: 'auto',
  },
  textButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
  },
};

export type AccountFeatureBlockerProps = {
  type: AccountBlockerType;
  placement: AccountFeatureBlockerPlacement;
  onClose?: PopupOverlayProps['onClose'];
  modalOffsetTop?: number;
  modalOffsetLeft?: number;
  onSignUp?: () => Promise<void>;
  onResend?: () => Promise<void>;
  actionText?: string;
  disabled?: boolean;
};

export default function AccountFeatureBlocker({
  type,
  placement,
  onClose,
  modalOffsetTop = 0,
  modalOffsetLeft = 0,
  onSignUp,
  onResend,
  actionText,
  disabled,
}: AccountFeatureBlockerProps) {
  const { t } = useTranslation();
  const config = useAccountFeatureBlockConfig({ type });
  const [loading, setLoading] = useState(false);
  const loginFailed = Router.query.loginFailed as string;

  const blockerBody = useMemo(() => {
    if (!config) return null;
    return (
      <>
        {config.content}

        {typeof loginFailed === 'string' && (
          <Box mt={1}>
            <Typography variant="body2" color="error.dark">
              {isDev && loginFailed
                ? loginFailed
                : 'Login failed. Please try again.'}
            </Typography>
          </Box>
        )}
      </>
    );
  }, [config, loginFailed]);

  const handleCtaClick = async () => {
    setLoading(true);
    if (type === AccountBlockerType.Unclaimed) await onSignUp?.();
    else await onResend?.();

    setLoading(false);
  };

  return (
    <PopupOverlay
      open
      title={config.title}
      body={blockerBody}
      emojiComponent={<Icon name={config.icon} />}
      modalProps={{
        sx: [
          styles.modal(modalOffsetTop, modalOffsetLeft),
          placement === 'rhs' && styles.rhs,
        ],
        container: document.querySelector(
          placement === 'menu'
            ? '.menu-content-wrap'
            : placement === 'rhs'
            ? '[data-testid="base-layout-right-panel"].is-active'
            : '[data-testid="base-layout-content"]'
        ) as Element,
      }}
      sx={styles.overlay(placement)}
      onClose={onClose}
      actionComponent={
        <Buttons sx={styles.actions}>
          {!!onClose && (
            <Box sx={styles.textButtonWrap}>
              <TextButton onClick={onClose}>
                {t('account.blocker.dismiss', 'Dismiss')}
              </TextButton>
            </Box>
          )}
          <Button
            variant="filled"
            prefixIcon={<Icon name={config.action.icon} />}
            onClick={handleCtaClick}
            loading={loading}
            disabled={disabled}
          >
            {actionText || config.action.text}
          </Button>
        </Buttons>
      }
    />
  );
}
