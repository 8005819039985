import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Router from 'next/router';
import AuthGuard from '@app/web/src/components/AuthGuard';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { useIaAgentActions, useIaAgentUserAction } from '@app/web/src/ia/agent';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ProfileManageAIBot as ProfileManageAIBotIcon } from '@front/icon';
import { BaseLayoutRightPanel, Button, useBaseRightPanel } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  AgentIsAdd,
  AgentUserViewSlug,
  AgentViewSlug,
  AgentVisibility,
  buildInfiniteHookMutate,
  useAgents,
  useAgentUsers,
  useAuth,
} from '@lib/web/apis';
import { useInfiniteScroll } from '@lib/web/hooks';
import { AccountFeatureBlocker, SharedDropdown } from '@lib/web/ui';
import { getAgentIconPath } from '@lib/web/utils';
import { keyBy } from 'lodash';

import ManageAgentsToolbar from './ManageAgentsToolbar';

export default function ManageAgentsPanel() {
  const { t } = useTranslation(['agent', 'club']);
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [ctaButtonLoading, setCtaButtonLoading] = useState(false);
  const [shareOpened, setShareOpened] = useState(false);
  const [sharedDropdownAnchorEl, setSharedDropdownAnchorEl] =
    useState<HTMLElement | null>();
  const [selectedShareAgent, setSelectedShareAgent] =
    useState<GetIaAgentUserIconRhsAgentUserDefaultRes>();
  const { hideCreateAgents, directMessageOnRhs } = getRightParams(
    GlobalPanelKeys.GlobalManageAgents
  );

  const { blockerType } = useAuth();

  const userId = useCurrentUserId();
  const agentsData = useAgentUsers({
    viewSlug: AgentUserViewSlug.ListRhsAgentUserDefault,
    search: `userId:${userId}`,
    searchFields: 'userId:eq',
    orderBy: 'order',
    sortBy: 'asc',
  });

  const unAddAgentsData = useAgents(
    {
      viewSlug: AgentViewSlug.ProfileRhsAgentDefault,
      search: `isAdd:${AgentIsAdd.NotAdded}`,
      searchFields: 'isAdd:eq',
      limit: 20,
    },
    { enable: agentsData.totalCount === 0 }
  );

  const { dataset, mutate, isLoadingInitialData, isLoading, isLoadingMore } =
    agentsData;

  const loaded = !isLoadingInitialData && !isLoading;
  const iaAgentAction = useIaAgentActions();

  const reloadAgentUsers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: { ...agentsData, isReachingEnd: true },
  });

  const layouts: IaLayoutConfig[] = useMemo(() => {
    if (!loaded)
      return [
        {
          layout: 'icon-list-layout-skeleton' as const,
          settings: {
            avatarSize: 36,
          },
        },
      ];

    const loadingMoreItems = isLoadingMore
      ? [
          {
            layout: 'icon-list-layout-skeleton' as const,
            settings: {
              avatarSize: 36,
            },
          },
        ]
      : [];

    if (agentsData.totalCount === 0) {
      return [
        {
          layout: 'empty-layout',
          settings: {
            sx: {
              px: { xs: 2.5, md: '12px' },
            },
          },
          text: t(
            'manage.agents.empty.text',
            '<b>No AI followed.</b></br>Follow AIs suggested below or create your own for personalized help.'
          ),
          action: {
            type: 'link',
            icon: 'TestAdd',
            text: t('manage.agents.empty.cta', 'Create AI'),
            value: '/agent/form/settings',
          },
        },
        ...(unAddAgentsData.dataset.length === 0
          ? []
          : [
              {
                layout: 'icon-list-layout' as const,
                items: [
                  t('club::suggestion.subtitle', 'Suggested for you'),
                  ...unAddAgentsData.dataset.map((agent) => ({
                    id: agent.agentId,
                    title: agent.agentName,
                    titleAction: {
                      type: 'event' as const,
                      value: 'openAgentProfile',
                    },
                    titleSuffixIcon: 'ProfileClubAgent',
                    titleSuffixTooltipTitle: 'AI',
                    description: `@${agent.agentUserName}`,
                    avatarSize: 36,
                    avatarUrl: agent.agentPhoto || getAgentIconPath(agent),
                    actionMap: {
                      click: {
                        type: 'event' as const,
                        value: 'addMyAgent',
                        icon: 'OtherAddFriend',
                        text: t('button.Follow'),
                        actionType: 'textButton' as const,
                      },
                    },
                    metadata: {
                      agentId: agent.agentId,
                    },
                  })),
                ],
              },
            ]),
      ];
    }
    return [
      {
        layout: 'icon-list-layout' as const,
        items: [
          t('manage.agents.count', '{{count}} AI Followed', {
            count: agentsData.totalCount,
          }),
          ...agentsData.dataset.map((agentUser) => {
            const agent = agentUser.agent;

            return {
              id: agentUser.agentUserId,
              title: agent.agentName,
              titleAction: {
                type: 'event' as const,
                value: 'openAgentProfile',
              },
              titleSuffixIcon: 'ProfileClubAgent',
              titleSuffixTooltipTitle: 'AI',
              description: `@${agent.agentUserName}`,
              avatarSize: 36,
              avatarUrl: agent.agentPhoto || getAgentIconPath(agent),
              multipleActions: {
                showOnHovered: true,
                buttons: [
                  {
                    actionType: 'iconButton' as const,
                    type: 'event' as const,
                    icon:
                      agentUser.visibility === AgentVisibility.Shown
                        ? 'LoginHide'
                        : 'LoginSee',
                    value:
                      agentUser.visibility === AgentVisibility.Shown
                        ? 'hideAgent'
                        : 'showAgent',
                    text:
                      agentUser.visibility === AgentVisibility.Shown
                        ? t('Hide')
                        : t('Show'),
                  },
                  {
                    actionType: 'iconButton' as const,
                    type: 'event' as const,
                    icon: 'OtherShareToDirect',
                    value: 'shareAgent',
                    text: t('Share'),
                  },
                ],
              },
            };
          }),
        ],
      },
      ...loadingMoreItems,
    ];
  }, [
    loaded,
    isLoadingMore,
    t,
    agentsData.totalCount,
    agentsData.dataset,
    unAddAgentsData.dataset,
  ]);

  const idToAgentUser = useMemo(
    () => keyBy(dataset, (d) => d.agentUserId),
    [dataset]
  );
  const iaAgentUserActions = useIaAgentUserAction();
  const availableActions = {
    openAgentProfile: {
      action: ({
        id,
        metadata,
      }: {
        id: string;
        metadata?: { agentId: string };
      }) => {
        const agentId = idToAgentUser[id]?.agent.agentId || metadata?.agentId;
        if (!agentId) return;
        openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId,
          directMessageOnRhs,
          returnPanel: GlobalPanelKeys.GlobalManageAgents,
        });
      },
    },
    showAgent: {
      action: async ({ id }: { id: string }) => {
        void reloadAgentUsers(iaAgentUserActions.showAgent({ id }), {
          optimisticData: dataset.map((d) =>
            d.agentUserId === id
              ? {
                  ...d,
                  visibility: AgentVisibility.Shown,
                }
              : d
          ),
        });
      },
    },
    hideAgent: {
      action: async ({ id }: { id: string }) => {
        void reloadAgentUsers(iaAgentUserActions.hideAgent({ id }), {
          optimisticData: dataset.map((d) =>
            d.agentUserId === id
              ? {
                  ...d,
                  visibility: AgentVisibility.Hidden,
                }
              : d
          ),
        });
      },
    },
    shareAgent: {
      action: ({ id }: { id: string }) => {
        const targetAnchorElement = document.querySelector(
          `[data-item-id="${id}"]`
        ) as HTMLElement | null;
        const agentUser = idToAgentUser[id];
        setSharedDropdownAnchorEl(targetAnchorElement);
        setSelectedShareAgent(agentUser);
        setShareOpened(true);
      },
    },
    addMyAgent: {
      action: async ({ id }: { id: string }) => {
        setProcessingIds((st) => [...st, id]);
        await iaAgentAction.addMyAgent({
          id,
        });
        setProcessingIds((st) => st.filter((i) => i !== id));
      },
      inProgress: ({ id }: { id: string }) => processingIds.includes(id),
    },
  };

  const handleCreateAgentButtonClick = () => {
    setCtaButtonLoading(true);
    void Router.push(`/agent/form/settings`);
  };

  const onCloseSharedDropdown = useCallback((): void => {
    setSharedDropdownAnchorEl(null);
    setSelectedShareAgent(undefined);
    setShareOpened(false);
  }, []);

  const virtualRouter = useMemo(() => {
    return {
      pathname: '/agents/[agentId]',
      query: {
        agentId: selectedShareAgent?.agent.agentId ?? '',
        clubSlug: selectedShareAgent?.agentClub.clubSlug ?? '',
      },
      asPath: `/agents/${selectedShareAgent?.agent.agentId}`,
    };
  }, [selectedShareAgent]);

  return (
    <>
      <BaseLayoutRightPanel
        titleIcon={<ProfileManageAIBotIcon width={16} height={16} />}
        title={t('Manage Agents')}
        toolComponent={<ManageAgentsToolbar />}
        actionComponent={
          !hideCreateAgents &&
          dataset.length > 0 && (
            <AuthGuard>
              <Button
                prefixIcon={<Icon name="TestAdd" width={16} height={16} />}
                onClick={handleCreateAgentButtonClick}
                component={Link}
                href="/agent/form/settings"
                loading={ctaButtonLoading}
              >
                {t('Create Agents')}
              </Button>
            </AuthGuard>
          )
        }
      >
        <BaseLayoutRightPanel.ScrollArea
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <IaActionContextProvider availableActions={availableActions}>
            <IaLayouts layouts={layouts} />
          </IaActionContextProvider>
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
      <SharedDropdown
        menuAnchorEl={sharedDropdownAnchorEl}
        open={shareOpened}
        onClose={onCloseSharedDropdown}
        virtualRouter={virtualRouter}
      />
      {!!blockerType && (
        <AccountFeatureBlocker type={blockerType} placement="rhs" />
      )}
    </>
  );
}
