var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aha-frontend@1.117.3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  release: `${process.env.NAME}@${process.env.VERSION}`,
  environment: process.env.ENV_CODE,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false, // default sentry will mask all text
    }),
    Sentry.captureConsoleIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // to save the quota, we only need error replay
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'License expired',
    'Please ignore this error',
    'Cancel rendering route',
    'Failed to load client build manifest',
    'Non-Error promise rejection captured',
    'Abort fetching component for route',
  ],
  beforeSend(event, hint) {
    /**
     * skip show report dialog to user when error is silent
     */
    if (hint.captureContext?.extra.silent) {
      return event;
    }

    const response = hint?.originalException?.response;
    if (response && response.status && response.status === 429) {
      return null;
    }

    if (event.exception) {
      /**
       * when error occurs, we store the error event in window.lastSentryErrorEvent
       * so we can inspect it later even if network panel was not open
       * this helps debug what caused the Sentry dialog to appear
       */
      window.lastSentryErrorEvent = event;
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  tracesSampler(samplingContext) {
    const routeName = samplingContext.transactionContext.name;
    if (/\[exam\]|paywall/i.test(routeName)) {
      return 0;
    }
    if (/\/.*?\/(profile|browse)/i.test(routeName)) {
      return 0.01;
    }
    return 0.1;
  },
});
