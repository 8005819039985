import { PropsWithChildren } from 'react';
import { useDateFormat } from '@front/helper';
import { currencyFormat, getClubIconPath, ResponsiveTooltip } from '@front/ui';

import {
  ClubOfficialType,
  LinkInternalClubDetail,
} from '../../hooks/useLinkInternalDetail/types';
import {
  ListTooltip,
  ListTooltipLayoutProps,
} from '../../layouts/ListTooltipLayout/ListTooltipLayout';

function getCreatorAvatar(name: string, src?: string) {
  if (name === 'Aha') {
    return '/logo.png';
  }
  return src;
}

export default function ClubLinkTooltip({
  club,
  children,
}: PropsWithChildren<{
  club: LinkInternalClubDetail;
}>) {
  const { dateFormat } = useDateFormat();
  const isOfficial = club.officialType === ClubOfficialType.Official;

  const creatorAvatar =
    getCreatorAvatar(club.creator.name, club.creator.avatar) || '';

  const config: ListTooltipLayoutProps = {
    title: {
      content: club.name,
      prefix: {
        type: 'avatar',
        avatarUrl: club.logo || getClubIconPath(club.name),
        avatarText: club.name,
      },
      suffix: isOfficial
        ? {
            type: 'icon',
            name: 'ProfileOfficial',
          }
        : undefined,
    },
    properties: [
      {
        name: {
          icon: 'PropertyTypePerson',
          text: 'Club Creator',
        },
        value: {
          type: 'avatarText',
          avatarText: club.creator.name,
          avatarUrl: creatorAvatar,
        },
      },
      {
        name: {
          icon: 'PrivacyFriends',
          text: 'Members',
        },
        value: {
          type: 'text',
          text: currencyFormat(club.memberCount),
        },
      },
      {
        name: {
          icon: 'UserOnline',
          text: 'Online',
          sxProps: {
            '& svg path': {
              fill: '#00C398',
            },
          },
        },
        value: {
          type: 'text',
          text: currencyFormat(club.onlineMemberCount),
        },
      },
      {
        name: {
          icon: 'ChatQuestionList',
          text: 'Questions',
        },
        value: isOfficial
          ? {
              type: 'icon',
              icon: 'TestInfinity',
            }
          : {
              type: 'text',
              text: currencyFormat(club.questionCount),
            },
      },
      {
        name: {
          icon: 'OtherCalendarDay',
          text: 'Club Created',
        },
        value: {
          type: 'text',
          text: dateFormat(club.createdAt),
        },
      },
    ],
  };

  return (
    <ResponsiveTooltip content={<ListTooltip {...config} />}>
      <span>{children}</span>
    </ResponsiveTooltip>
  );
}
