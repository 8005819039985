import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Theme, useMediaQuery } from '@mui/material';
import DmNewThreadLeftPanel from '@app/web/src/widgets/LeftPanels/DmNewThreadLeftPanel';
import { TestAdd as TestAddIcon } from '@front/icon';
import { Button } from '@front/ui';
import { apis, ThreadViewType, useAuth } from '@lib/web/apis';
import { ThreadComposerProvider } from '@lib/web/thread';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { hashDmViewReferenceId } from '@lib/web/thread/hooks/view/utils';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { generateDmViewConfig } from '@lib/web/thread/utils/viewUtils';
import { MenuComps } from '@lib/web/ui';
import { call, callWithToast } from '@lib/web/utils';

import useMainLayout from '../../hooks/useMainLayout';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    flex: 1,
    '& .menu-title-bar': {
      px: '20px',
    },
    '& .search-bar': {
      pl: 1,
    },
    '& .ia-icon-list-layout_item': {
      pr: '19.5px !important',
    },
  },
  bottom: {
    bgcolor: '#151515',
    flex: '0 0 60px',
    px: '12px',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'end',
  },
};

type SendDmButtonProps = {
  onSuccess: () => void;
};

function SendDmButton({ onSuccess }: SendDmButtonProps) {
  const { t } = useTranslation('thread');
  const { member, isBlocking } = useAuth();
  const { allViews, views, reloadViews } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();
  const { sendPublicly, threadMemberIds } = useThreadComposer();
  const [loading, setLoading] = useState(false);

  const myMemberId = member?.memberId || '';

  const channelMembersIds = useMemo(() => {
    if (!member) return [];

    // if send publicly, members only include those users who have sent message, so we don't need to add them at beginning
    if (sendPublicly) return [member.memberId];

    return threadMemberIds || [member.memberId];
  }, [member, sendPublicly, threadMemberIds]);

  const handleSendDmOneOnOne = useCallback(() => {
    const memberId = channelMembersIds.find((id) => id !== myMemberId);
    const existingView = views.find((view) => {
      const memberIds = getThreadViewDetail(view).viewMemberIds;
      return memberIds.length === 2 && memberIds.includes(memberId || '');
    });
    onSuccess?.();
    if (existingView) {
      void Router.push(`/direct-messages/view/${existingView.id}`);
    } else {
      void Router.push(`/direct-messages/view/${memberId}`);
    }
  }, [channelMembersIds, getThreadViewDetail, myMemberId, onSuccess, views]);

  const handleSendDmGroup = useCallback(async () => {
    const channelReferenceId = hashDmViewReferenceId(channelMembersIds);
    const existingView = allViews.find(
      (view) => view.referenceId === channelReferenceId
    );
    if (existingView) {
      if (existingView.hideAt) {
        await call(
          apis.thread.setThreadView({
            threadViewId: existingView.id,
            type: ThreadViewType.DmOneOnOne,
            hideAt: null,
          })
        );
      }
      onSuccess?.();
      void Router.push(`/direct-messages/view/${existingView.id}`);
    }

    const payload: SetThreadViewReq = {
      ...generateDmViewConfig({ memberIds: channelMembersIds }),
    };
    const [res] = await callWithToast(apis.thread.setThreadView(payload));
    await reloadViews();
    onSuccess?.();
    void Router.push(`/direct-messages/view/${res?.data.id}`);
    setLoading(false);
  }, [allViews, channelMembersIds, onSuccess, reloadViews]);

  const handleSendDm = useCallback(async () => {
    if (channelMembersIds.length === 0) return;
    setLoading(true);
    // members includes myself and one other
    if (channelMembersIds.length === 2) {
      handleSendDmOneOnOne();
      setLoading(false);
      return;
    }
    await handleSendDmGroup();
    setLoading(false);
  }, [channelMembersIds.length, handleSendDmGroup, handleSendDmOneOnOne]);

  const isGroup = threadMemberIds && threadMemberIds.length > 2; // current user + 2 others
  const disabled =
    isBlocking || !threadMemberIds || threadMemberIds.length === 0;
  return (
    <Button
      prefixIcon={<TestAddIcon />}
      disabled={disabled}
      onClick={handleSendDm}
      loading={loading}
    >
      {isGroup
        ? t('dm.newThread.button.Create Group Chat')
        : t('dm.newThread.button.Send DM')}
    </Button>
  );
}

export default function NewDirectMessageMenu() {
  const { t } = useTranslation('thread');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { clearTemporaryMenu } = useMainLayout();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [threadMemberIds, setThreadMemberIds] = useState<string[]>([]);

  const handleCollapse = () => {
    clearTemporaryMenu();
  };

  const handleCreateDmSuccess = (): void => {
    clearTemporaryMenu();
  };

  return (
    <ThreadComposerProvider
      threadMemberIds={threadMemberIds}
      text=""
      setText={() => {}}
    >
      <Box sx={styles.root}>
        <MenuComps
          title={
            <MenuComps.ResponsiveTitle
              title={t('menu.New Direct Message')}
              onCollapse={handleCollapse}
              navigation={{
                onBack: clearTemporaryMenu,
              }}
            />
          }
          disabledCollapse={!mdUp}
          onCollapse={handleCollapse}
          sx={styles.menu}
          scrollProps={{
            scrollableNodeProps: {
              ref: scrollRef,
            },
          }}
          titleToolTip={false}
        >
          <DmNewThreadLeftPanel oncMembersChanged={setThreadMemberIds} />
        </MenuComps>
        <Box sx={styles.bottom}>
          <SendDmButton onSuccess={handleCreateDmSuccess} />
        </Box>
      </Box>
    </ThreadComposerProvider>
  );
}
