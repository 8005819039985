import { useMemo } from 'react';
import SubscriptionPlanInfoList from '@app/web/src/widgets/NotificationPage/CustomContents/SubscriptionPlanInfoList';
import { IaRenderContextValue } from '@lib/ia/src/core/IaRender/IaRenderContext';

export default function useNotificationCustomizeContents() {
  const renders: IaRenderContextValue = useMemo(() => {
    return {
      'subscription.cancel.payment.member.global.v2': SubscriptionPlanInfoList,
      'subscription.success.payment.member.global.v2': SubscriptionPlanInfoList,
    };
  }, []);

  return renders;
}
