import React from 'react';
import Box from '@mui/material/Box';

import SquareAvatar, { SquareAvatarProps } from '../SquareAvatar';

function getSize(size: number) {
  if (size > 79) return size + 20;
  if (size > 63) return size + 16;
  if (size > 39) return size + 10;
  if (size > 31) return size + 8;
  return size + 6;
}

export type SquareAvatarProgressProps = SquareAvatarProps & {
  ratio?: number;
  gradient?: boolean;
  progressMaskUrl?: string;
};

const styles = {
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-90deg)',
  },
};
export default function SquareAvatarProgress({
  size = 40,
  ratio = 0,
  gradient = false,
  progressMaskUrl = '/mask-avatar-progress.svg',
  ...rest
}: SquareAvatarProgressProps) {
  const progressSize = getSize(size);
  const disableProgress = ratio === undefined;

  const circumference = 2 * Math.PI * (size / 2);
  const circleStyle = {
    strokeDasharray: circumference.toFixed(3),
    strokeDashoffset: `${((1 - ratio) * circumference).toFixed(3)}px`,
  };

  return (
    <Box position="relative">
      <SquareAvatar size={size} {...rest} />
      {!disableProgress && (
        <Box
          sx={[
            styles.progress,
            {
              mask: `url(${progressMaskUrl}) center center / contain no-repeat`,
            },
          ]}
          width={progressSize}
          height={progressSize}
        >
          <svg viewBox={`0 0 ${size} ${size}`}>
            <circle
              cx={size / 2}
              cy={size / 2}
              r={size / 2}
              stroke={
                gradient
                  ? 'url(#primary-gradient-linear-vertical)'
                  : 'currentColor'
              }
              {...circleStyle}
              fill="none"
              strokeWidth={size}
            />
          </svg>
        </Box>
      )}
    </Box>
  );
}
