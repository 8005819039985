import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import useCloudfront from '@app/web/src/hooks/utils/useCloudfront';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { popup } from '@front/ui';
import {
  apis,
  ProductOrderDiscountType,
  SubscriptionAction,
  useAuth,
  useMyCurrency,
} from '@lib/web/apis';
import { useSubscriptionPlans } from '@lib/web/hooks';
import { callWithToast, getCurrencyCode } from '@lib/web/utils';
import { isPast, parseISO } from 'date-fns';

import { getSubscriptionAction } from '../config';
import {
  MyPlanCheckoutContext,
  MyPlanCheckoutValue,
} from '../contexts/checkoutContext';
import { PlanAction, PlanItem } from '../types';

export default function useSubscriptionCheckout() {
  const { t } = useTranslation('profile');
  const [value, setValue] = useContext(MyPlanCheckoutContext);
  const {
    subscriptionInfo,
    subscriptionOrderInfo,
    subscriptionCheckoutSession,
  } = useMemberSubscription();
  const { getPlanByName } = useSubscriptionPlans();
  const { member } = useAuth();
  const myMemberId = member?.memberId;

  const { data: currencyData } = useMyCurrency();
  const { country } = useCloudfront();
  const currency =
    currencyData?.data.currency?.toUpperCase() || getCurrencyCode(country);

  const setActivePlan = (activePlan: PlanItem, action: PlanAction) => {
    setValue((st) => ({
      ...st,
      activePlan,
      action,
    }));
  };

  const setDiscountCode = (discountCode?: GetMyPromoCodeRes) => {
    setValue((st) => ({
      ...st,
      discountCode,
    }));
  };

  const setUseAhaPoint = useCallback(
    (isUseAhaPoint: boolean) => {
      setValue((st) => ({
        ...st,
        isUseAhaPoint,
      }));
    },
    [setValue]
  );

  const maybeConfirmOldSubscriptionOrder = async (): Promise<
    'no-old-order' | 'create-new-order' | 'use-old-order' | 'cancel' | 'expired'
  > => {
    if (!subscriptionCheckoutSession) return 'no-old-order';

    if (
      subscriptionCheckoutSession.expireAt &&
      isPast(parseISO(subscriptionCheckoutSession.expireAt))
    ) {
      return 'expired';
    }

    if (
      subscriptionCheckoutSession.discountType !==
      ProductOrderDiscountType.Unknown
    ) {
      /**
       * If the user creates a new subscription, the discount (discount code or AHA points) in the old checkout session will be lost.
       * Therefore, we need to confirm this with the user.
       */
      return new Promise<'create-new-order' | 'use-old-order' | 'cancel'>(
        (resolve) => {
          popup.open({
            title: t('subscription.existingOrder.confirm.title'),
            content: t('subscription.existingOrder.confirm.content'),
            confirmText: t('subscription.existingOrder.confirm.confirmButton'),
            cancelText: t('subscription.existingOrder.confirm.cancelButton'),
            cancelIcon: 'close',
            anchorEl: document.querySelector(
              '[data-testid="checkout-button"]'
            ) as Element,
            onConfirm: () => resolve('create-new-order'),
            onCancel: (cancelType) =>
              resolve(cancelType === 'button' ? 'use-old-order' : 'cancel'),
            popupOptions: {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              sx: {
                mt: -0.5,
              },
            },
          });
        }
      );
    }

    return 'create-new-order';
  };

  const performCreateSubscription = async (
    checkoutValue: MyPlanCheckoutValue
  ) => {
    const { activePlan, isUseAhaPoint, discountCode } = checkoutValue;

    if (!activePlan?.product) return;

    const confirmResult = await maybeConfirmOldSubscriptionOrder();

    if (confirmResult === 'cancel') return;
    if (confirmResult === 'use-old-order') {
      return subscriptionOrderInfo?.url;
    }

    const isAnnual = activePlan.type === 'yearly';

    const [res] = await callWithToast(
      apis.marketplace.createSubscription({
        subscriptionPlanId: activePlan.product.id,
        currency,
        isUseAhaPoint,
        promoCode: isUseAhaPoint ? undefined : discountCode?.code || undefined,
        successUrl: `${process.env.APP_URL}/profile/my-plan?annual=${isAnnual}&purchased=true&plan=${activePlan.product.name}`,
        cancelUrl: `${process.env.APP_URL}/profile/my-plan?annual=${isAnnual}&checkout=true&plan=${activePlan.product.name}`,
        isCancelUnpaidOrder:
          confirmResult === 'create-new-order' || confirmResult === 'expired',
      }),
      {
        showLoading: false,
        errorMsg: 'Oops! Something went wrong. Please try again.',
      }
    );
    return res?.data.url;
  };

  const performCreateShareableSubscription = async (
    checkoutValue: MyPlanCheckoutValue
  ) => {
    if (!myMemberId) return;

    const { activePlan, isUseAhaPoint, discountCode } = checkoutValue;

    if (!activePlan?.product) return;

    const confirmResult = await maybeConfirmOldSubscriptionOrder();

    if (confirmResult === 'cancel') return;

    let orderId;

    if (confirmResult === 'use-old-order') {
      orderId = subscriptionOrderInfo?.orderId;
    } else {
      const [subscriptionRes] = await callWithToast(
        apis.marketplace.createSubscription({
          subscriptionPlanId: activePlan.product.id,
          currency,
          isUseAhaPoint,
          promoCode: isUseAhaPoint
            ? undefined
            : discountCode?.code || undefined,
          successUrl: `${process.env.APP_URL}/public/payment-link/subscription?memberId=${myMemberId}&sourceId={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${process.env.APP_URL}/public/payment-link/subscription?cancelled=true&memberId=${myMemberId}&sourceId={CHECKOUT_SESSION_ID}`,
          isCancelUnpaidOrder:
            confirmResult === 'create-new-order' || confirmResult === 'expired',
        }),
        {
          showLoading: false,
          errorMsg: 'Oops! Something went wrong. Please try again.!',
        }
      );
      orderId = subscriptionRes?.data.orderId;
    }

    if (!orderId) return;

    const [shareRes] = await callWithToast(
      apis.marketplace.shareSubscriptionOrderInfo({ orderId }),
      {
        showLoading: false,
        errorMsg: 'Oops! Something went wrong. Please try again.2',
      }
    );
    return shareRes?.data.sid;
  };

  const performUpdateSubscription = async (
    checkoutValue: MyPlanCheckoutValue
  ) => {
    const { activePlan, isUseAhaPoint, discountCode } = checkoutValue;

    if (!activePlan?.product) return;

    const [res] = await callWithToast(
      apis.marketplace.updateSubscription({
        subscriptionPlanId: activePlan.product.id,
        currency,
        isUseAhaPoint,
        promoCode: isUseAhaPoint ? undefined : discountCode?.code || undefined,
      }),
      {
        showLoading: false,
        errorMsg: 'Oops! Something went wrong. Please try again.',
      }
    );
    return !!res;
  };

  const performCancelSubscription = async () => {
    if (!subscriptionInfo) return;
    const plan = getPlanByName(subscriptionInfo.productName);
    const [res] = await callWithToast(
      apis.marketplace.cancelSubscription({
        subscriptionPlanId: plan?.id || '',
      }),
      {
        showLoading: false,
        errorMsg: 'Oops! Something went wrong. Please try again.',
      }
    );
    return !!res;
  };

  const checkout = async () => {
    const { activePlan, action } = value;

    const subscriptionAction = getSubscriptionAction(action, activePlan);

    if (subscriptionAction === SubscriptionAction.Create) {
      const paymentUrl = await performCreateSubscription(value);

      if (paymentUrl) {
        void Router.push(paymentUrl);
        return;
      }
    }
    if (subscriptionAction === SubscriptionAction.Update) {
      return performUpdateSubscription(value);
    }
    if (subscriptionAction === SubscriptionAction.Cancel) {
      return performCancelSubscription();
    }
  };

  const createShareableCheckoutLink = async () => {
    const sid = await performCreateShareableSubscription(value);

    if (!sid) return;

    setValue((st) => ({ ...st, shareInfo: { sid } }));
  };

  return {
    ...value,
    currency,
    setActivePlan,
    setDiscountCode,
    setUseAhaPoint,
    checkout,
    createShareableCheckoutLink,
  };
}
