import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { useDateFormat } from '@front/helper';
import { Button, Icon, TextButton } from '@front/ui';
import { DiscountCodePurchaseType, PromoCodeType } from '@lib/web/apis';
import { COUNTRY_LOCALE_MAP } from '@lib/web/utils';
import { isPast, parseISO } from 'date-fns';

const styles = {
  root: {
    pt: '10px',
    px: 2.5,
    pb: 2.5,
    borderRadius: 2,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  description: {
    color: 'grey.300',
  },
  action: {
    pt: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 2,
  },
  purchaseType: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  applyButton: {
    minWidth: 42,
  },
};

type DiscountCodeCardProps = {
  code: {
    id?: string;
    code?: string;
    discountCode: string;
    percentOff: number;
    amountOff: number;
    isActive?: boolean;
    timesRedeemed?: string;
    expireAt?: string;
    promoCodeType?: PromoCodeType;
    purchaseType?: DiscountCodePurchaseType;
  };
  hideAction?: boolean;
  isAdded?: boolean;
  isUsed?: boolean;
  disabled?: boolean;

  onSave?: (ev: MouseEvent) => Promise<void>;
  onApply?: (ev: MouseEvent) => Promise<void>;
};

const iconMaps: Record<DiscountCodePurchaseType, string> = {
  [DiscountCodePurchaseType.All]: 'ProfileNFTs',
  [DiscountCodePurchaseType.AvatarOnly]: 'ProfileNFTs',
  [DiscountCodePurchaseType.SubscriptionOnly]: 'CryptoCurrencyAha',
};
export default function DiscountCodeCard({
  code,
  hideAction = false,
  isAdded = false,
  isUsed = false,
  disabled = false,
  onSave,
  onApply,
}: DiscountCodeCardProps) {
  const { t } = useTranslation('profile');
  const { dateFormat } = useDateFormat();
  const [loadingType, setLoadingType] = useState<'save' | 'apply' | null>(null);
  const formatter = new Intl.NumberFormat(COUNTRY_LOCALE_MAP.USD, {
    minimumFractionDigits: 2,
  });
  const emptyActions = !onSave && !onApply;
  const handleSave = async (ev: MouseEvent) => {
    if (!onSave) return;
    setLoadingType('save');
    await onSave(ev);
    setLoadingType(null);
  };
  const handleApply = async (ev: MouseEvent) => {
    if (!onApply) return;
    setLoadingType('apply');
    await onApply(ev);
    setLoadingType(null);
  };

  const expireAt = code.expireAt ? parseISO(code.expireAt) : undefined;
  const hasExpired = expireAt && isPast(expireAt);
  const description = expireAt && (
    <Box sx={styles.description}>
      {code.purchaseType !== DiscountCodePurchaseType.All && (
        <Typography variant={'caption'} sx={styles.purchaseType}>
          <Icon
            name={iconMaps[code.purchaseType as DiscountCodePurchaseType]}
            width={16}
            height={16}
          />
          <span>{t(`discount.purchaseType${code.purchaseType}.label`)}</span>
        </Typography>
      )}
      <Typography variant={'caption'} mt={'auto'}>
        {t(`discount.expireAt.label`, {
          date: dateFormat(expireAt, { hideSameYear: true }),
          context: hasExpired ? 'expired' : 'active',
        })}
      </Typography>
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="h3">
        {code.amountOff
          ? formatter.format(code.amountOff / 100)
          : `${code.percentOff}%`}{' '}
        <Typography variant="caption">off</Typography>
      </Typography>
      <Box sx={styles.description}>
        <Typography variant="body2">
          {code.discountCode.toUpperCase()}
        </Typography>
        <Typography variant="body2">
          {t('discount.use.description', 'for discount on any transaction.')}
        </Typography>
      </Box>
      {!hasExpired && !hideAction && !emptyActions && (
        <Box sx={styles.action}>
          {!!onSave && (
            <TextButton
              prefixIcon={
                <Icon name={isAdded ? 'TextEditorCheck' : 'TestAdd'} />
              }
              disabled={disabled || isAdded || !!loadingType}
              onClick={handleSave}
              loading={loadingType === 'save'}
            >
              {isAdded ? t('button.Saved', 'Saved') : t('button.Save', 'Save')}
            </TextButton>
          )}
          {!!onApply && (
            <Button
              sx={styles.applyButton}
              color={isUsed ? 'default' : 'pay'}
              prefixIcon={<Icon name="TextEditorCheck" />}
              onClick={handleApply}
              disabled={disabled || isUsed || !!loadingType}
              loading={loadingType === 'apply'}
            >
              {isUsed ? t('button.Used', 'Used') : t('button.Apply', 'Apply')}
            </Button>
          )}
        </Box>
      )}

      {!!description && (
        <Typography variant="caption">{description}</Typography>
      )}
    </Box>
  );
}
