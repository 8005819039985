import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { featureEnable } from '@app/web/src/config/constants';
import { Icon } from '@front/ui';
import { Role, useAuth } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { MenuComps } from '@lib/web/ui';

import getMainPageConfig from '../../../pageConfig/main';

export default function AgentsMenu() {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { sharePath } = useContext(SharedUserContext);
  const { hasRole } = useAuth();
  const mainConfig = getMainPageConfig(t);

  return (
    <MenuComps
      title={t('main.AI', 'AI')}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('main.Discover AI', 'Discover AI')} />
        <MenuComps.Button
          icon={<Icon name={mainConfig.agents.icon} />}
          href={`${sharePath}/agents`}
        >
          {mainConfig.agents.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={mainConfig.myAgents.icon} />}
          href={`${sharePath}/agents/my-agents`}
        >
          {mainConfig.myAgents.title}
        </MenuComps.Button>
        {featureEnable.agentWorkflow &&
          (hasRole(Role.Editor) || process.env.ENV_CODE !== 'prod') && (
            <MenuComps.Button
              icon={<Icon name={mainConfig.workflow.icon} />}
              href={`${sharePath}/agents/workflow`}
            >
              {mainConfig.workflow.title}
            </MenuComps.Button>
          )}
      </MenuComps.Section>
    </MenuComps>
  );
}
