import { useTranslation } from 'next-i18next';
import { alpha, ButtonBase, Theme, Typography } from '@mui/material';
import { SimpleTooltip, SquareAvatar } from '@front/ui';
import { useIaClub } from '@lib/web/hooks';
import { getClubIconPath } from '@lib/web/utils';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: '6px',
    px: '2px',
    pb: '2px',
    borderRadius: 1,
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  name: {
    textDecoration: 'underline',
    textDecorationColor: (theme: Theme) =>
      alpha(theme.palette.text.primary, 0.3),
    textUnderlineOffset: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (hover:hover)': {
      '&:hover': {
        textDecorationColor: (theme: Theme) => theme.palette.text.primary,
      },
    },
  },
};

type ClubHomeProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.club.home' }>;
};

export default function ClubHome({ cta }: ClubHomeProps) {
  const { t } = useTranslation('notification');
  const { isEmpty, isLoading } = useIaClub(cta.clubSlug);
  const clubIcon = cta.clubIcon || getClubIconPath(cta.clubName);
  return (
    <SimpleTooltip
      title={
        isEmpty
          ? t(
              'club.home.deleted.tooltip.content',
              'This club has been deleted.'
            )
          : ''
      }
    >
      <span>
        <ButtonBase
          sx={styles.root}
          href={`/club/${cta.clubSlug}`}
          disabled={isLoading || isEmpty}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <SquareAvatar src={clubIcon} size={16}>
            {cta.clubName}
          </SquareAvatar>
          <Typography variant="body2" sx={styles.name}>
            {cta.clubName}
          </Typography>
        </ButtonBase>
      </span>
    </SimpleTooltip>
  );
}
