import { Box } from '@mui/material';
import RichText from '@lib/ia/src/components/RichText';

import { CtaRichText } from '../types';

const styles = {
  container: { mt: 0 },
};

type FeedCtaRichTextProps = CtaRichText;

export default function FeedCtaRichText({ value }: FeedCtaRichTextProps) {
  return (
    <Box sx={styles.container}>
      <RichText text={value} variant="body2" inline />
    </Box>
  );
}
