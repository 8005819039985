import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreadSort } from '@app/web/src/types/threadFilterSort';
import { nonNullable } from '@front/helper';
import {
  ChannelLayoutConfig,
  ChannelLayoutLoadingConfig,
  ComposerBlock,
  MessageItemConfig,
  MessageItemEvent,
  MessageMode,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { useAuth } from '@lib/web/apis';
import { sharedChips } from '@lib/web/thread';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useEditMessage } from '@lib/web/thread/hooks/message/useEditMessage';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

// https://getstream.io/chat/docs/javascript/query_channels/#query-options
// Stream Chat will return up to 25 messages by default.
const CHANNEL_MAX_MESSAGES = 25;

export const useThreadViewChannelLayoutConfig = ({
  channels,
  isLoadingInitialData,
  sort,
}: {
  channels: Channel<StreamChatGenerics>[];
  isLoadingInitialData: boolean;
  sort: ThreadSort;
}) => {
  const { t } = useTranslation('thread');
  const { member } = useAuth();
  const { getThreadUser, chatClient } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { getChannelReadState } = useReadState();
  const { editMessage } = useEditMessage();

  const myMemberId = member?.memberId || '';
  const [editingMessageKey, setEditingMessageKey] = useState<string | null>(
    null
  );
  const [firstMessages, setFirstMessages] = useState<
    Record<string, StreamMessage<StreamChatGenerics>>
  >({});

  useEffect(() => {
    const fetchFirstMessages = async (): Promise<void> => {
      if (!chatClient || channels.length === 0) return;

      const channelsNeedFetch = channels.filter(
        (channel) =>
          channel.data?.firstMessageId &&
          (channel.state.messages.length >= CHANNEL_MAX_MESSAGES ||
            channel.state.messages[0]?.id !== channel.data.firstMessageId)
      );

      if (channelsNeedFetch.length === 0) return;

      const messages = await Promise.all(
        channelsNeedFetch.map(async (channel) => {
          try {
            const response = await chatClient.getMessage(
              channel.data?.firstMessageId || ''
            );
            return response.message;
          } catch (error) {
            console.error('Error fetching first message:', error);
            return null;
          }
        })
      );

      const newFirstMessages = messages.reduce((acc, message, index) => {
        if (message) {
          acc[channelsNeedFetch[index].data?.firstMessageId || ''] = message;
        }
        return acc;
      }, {} as Record<string, StreamMessage<StreamChatGenerics>>);

      setFirstMessages((prev) => ({ ...prev, ...newFirstMessages }));
    };

    void fetchFirstMessages();
  }, [channels, chatClient]);

  return useMemo(() => {
    if (isLoadingInitialData) {
      return [
        {
          layout: 'channel-layout-skeleton',
        } as ChannelLayoutLoadingConfig,
      ];
    }

    let sortedChannels = channels;
    const initStatus = Object.keys(sort).length === 1 && sort.created_at === 1; // Channel Layout init sort condition
    if (initStatus) {
      sortedChannels = channels.sort((a, b) => {
        const aDate = streamDateToString(a.data?.created_at as string);
        const bDate = streamDateToString(b.data?.created_at as string);
        return aDate > bDate ? 1 : -1;
      });
    }

    return [
      {
        layout: 'channel-layout',
        items: sortedChannels.map((channel, index): MessageItemConfig => {
          const {
            channelCreator,
            firstMessageText,
            firstMessageCreatedAt,
            firstMessageCta,
            firstMessageTag,
            lastMessageCreatedAt,
            replies,
            channelEngagedMemberIds,
            dateDividerTime,
          } = getChannelInformation(channel);

          const previousChannel: Channel = sortedChannels[index - 1];
          const channelReadState = getChannelReadState(channel);
          const previousChannelInformation =
            previousChannel && getChannelInformation(previousChannel);
          const previousChannelReadState =
            previousChannel && getChannelReadState(previousChannel);
          const channelCreatorUser = getThreadUser(channelCreator);

          const isFirstMessageDeleted = ((): boolean => {
            // If we have fetched the first message separately
            if (
              channel.data?.firstMessageId &&
              firstMessages[channel.data.firstMessageId]
            ) {
              return (
                firstMessages[channel.data.firstMessageId].type === 'deleted'
              );
            }
            // Fallback to checking channel.state.messages
            return channel.state.messages[0]?.type === 'deleted';
          })();

          const shouldShowSharedMessageChips =
            !isFirstMessageDeleted &&
            !replies &&
            firstMessageTag?.includes('dm.shared') &&
            index === sortedChannels.length - 1;

          const messageKey = [
            channel.cid,
            channel.state.last_message_at?.toString(),
            firstMessageText, // message could be dynamically rendered
          ]
            .filter(nonNullable)
            .join('-');

          const events: MessageItemEvent[] = [
            {
              type: 'event',
              value: 'openThread',
              text: t('message.menu.replyInThread'),
              icon: 'ActionArrowRightUp',
            },
            ...(channelCreatorUser?.id === myMemberId &&
            channel.data?.firstMessageId &&
            !channel.data?.firstMessageNotificationTag
              ? [
                  {
                    type: 'event' as const,
                    value: 'editMessage',
                    text: t('message.menu.editMessage', 'Edit Message'),
                    icon: 'OtherEdit',
                    metadata: {
                      handler: (): void => {
                        setEditingMessageKey(messageKey);
                      },
                    },
                  },
                ]
              : []),
            ...(channelCreatorUser?.id === myMemberId
              ? [
                  {
                    type: 'event' as const,
                    value: 'deleteMessage',
                    text: t('message.menu.deleteMessage', 'Delete Message'),
                    icon: 'OtherDelete',
                    iconSxProps: { color: '#FF2951' },
                    textSxProps: { color: '#FF2951' },
                    metadata: {
                      channel,
                    },
                  },
                ]
              : []),
          ];

          return {
            id: channel.cid,
            key: messageKey,
            type: 'message',
            sender: {
              type: 'avatar',
              name: channelCreatorUser?.name ?? '',
              avatarUrl: channelCreatorUser?.image || '',
              badge: channel.type === 'team' ? 'private' : undefined,
              eventMap: {
                onMouseEnter: {
                  type: 'event',
                  value: 'viewSenderProfile',
                  metadata: {
                    user: channelCreatorUser,
                  },
                },
              },
              titleEventMap: {
                onMouseEnter: {
                  type: 'event',
                  value: 'viewSenderProfile',
                  metadata: {
                    user: channelCreatorUser,
                  },
                },
              },
              className: 'floating-avatar-anchor',
              titleClassName: 'floating-avatar-anchor',
            },
            content: !isFirstMessageDeleted
              ? firstMessageText
              : t('message.status.deleted.text', 'This message was deleted.'),
            chips:
              !isFirstMessageDeleted && shouldShowSharedMessageChips
                ? sharedChips?.map((chip) => ({
                    label: {
                      type: 'text',
                      value: t(chip.messageKey, chip.defaultText),
                    },
                    eventMap: {
                      click: {
                        type: 'event',
                        value: 'sharedChipClick',
                      },
                    },
                    metadata: {
                      channel,
                      text: t(chip.messageKey, chip.defaultText),
                    },
                  }))
                : undefined,
            contentVariant: 'chatBody',
            sentTime: firstMessageCreatedAt,
            lastActivityTime: lastMessageCreatedAt,
            dateDividerTime,
            isDeleted: isFirstMessageDeleted,
            isEdited: !!channel.data?.firstMessageTextUpdatedAt,
            readState: channelReadState,
            replies,
            showReplyThread: true,
            cta: firstMessageCta,
            members: channelEngagedMemberIds.map((memberId) => {
              const threadUser = getThreadUser(memberId);
              return {
                name: threadUser?.name || '',
                avatarUrl: threadUser?.image || '',
              };
            }),
            previousMessageProps: {
              dateDividerTime: previousChannelInformation?.dateDividerTime,
              readState: previousChannelReadState,
            },
            clickItemAction: {
              type: 'event',
              value: 'viewThread',
            },
            clickSenderAction: {
              type: 'event',
              value: 'openUserOrAgentProfile',
              metadata: { user: channelCreatorUser },
            },
            viewThreadAction: {
              type: 'event',
              value: 'viewThread',
            },
            editModeKeyDownAction: {
              type: 'event',
              value: 'editModeMessageContentOnKeyDown',
              metadata: {
                handler: async (
                  event: KeyboardEvent,
                  text: string,
                  changedBlocks: ComposerBlock[]
                ) => {
                  if (event.code === 'Escape') {
                    setEditingMessageKey(null);
                  }
                  if (event.code === 'Enter' && !event.shiftKey) {
                    await editMessage({
                      text,
                      blocks: changedBlocks,
                      channel,
                      messageId: channel.data?.firstMessageId || '',
                    });
                    setEditingMessageKey(null);
                  }
                },
              },
            },
            editModeBlurAction: {
              type: 'event',
              value: 'editModeMessageContentOnBlur',
              metadata: {
                handler: (): void => {
                  setEditingMessageKey(null);
                },
              },
            },
            eventMenuClassName: 'right-panel-floating-menu',
            events,
            defaultMessageBlocks: channel.data?.firstMessageCustomBlocks,
            messageMode:
              editingMessageKey === messageKey
                ? MessageMode.Edit
                : MessageMode.View,
          };
        }),
      } as ChannelLayoutConfig,
    ];
  }, [
    channels,
    editMessage,
    editingMessageKey,
    getChannelInformation,
    getChannelReadState,
    getThreadUser,
    isLoadingInitialData,
    myMemberId,
    sort,
    t,
    firstMessages,
  ]);
};
