import { v4 } from 'uuid';

import {
  ChargeType,
  ClubJoinedStatus,
  ClubMemberRequestStatus,
  ClubOfficialType,
  ClubPrivacy,
  ClubStatus,
  ClubVisibility,
  GoalRingTarget,
} from '../../../types/enums';

const leagueNotifications: GetNotificationRes[] = [
  {
    id: '108101a3-7a56-434b-bda8-3cb141297cc1',
    tag: 'league.enter.promotion.zone.club.v2',
    title:
      'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    body: 'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubName: 'ACT Math',
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 2,
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 1,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-09T02:03:07.351Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '0b8ed77f-55c1-4dca-af2c-c96740c7715f',
    tag: 'league.round.finish.stay.tier.club.v2',
    title:
      'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    body: 'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubName: 'ACT Math',
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000001',
    tag: 'league.round.finish.promotion.tier.club.v2',
    title:
      'Congratulations! 🎉 You’ve been promoted to the Centurion league after finishing Rank 2.',
    body: 'Congratulations! 🎉 You’ve been promoted to the Centurion league after finishing Rank 2.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          prevLeagueId: '7922c12e-8e4f-4068-a303-2a4809d43155',
          prevLeagueIdName: 'Alpha',
          prevRank: 2,
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    tag: 'league.round.finish.back.to.promotion.tier.club.v2',
    title:
      'Welcome back! 🎉 You’ve been promoted to the Centurion league after finishing Rank 2.',
    body: 'Welcome back! 🎉 You’ve been promoted to the Centurion league after finishing Rank 2.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueIdName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          prevRank: 2,
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 1,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    tag: 'league.round.finish.demotion.tier.club.v2',
    title:
      'You finished Rank 25 in the Centurion league and got demoted to Prima league. Don’t worry! Keep practicing to climb back up 💪',
    body: 'You finished Rank 25 in the Centurion league and got demoted to Prima league. Don’t worry! Keep practicing to climb back up 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueIdName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          prevRank: 14,
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 3,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    tag: 'league.enter.demotion.zone.club.v2',
    title:
      'You entered Centurion league demotion zone 😢 Let’s practice more to get out of this zone!',
    body: 'You entered Centurion league demotion zone 😢 Let’s practice more to get out of this zone!',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueIdName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          prevRank: 14,
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 3,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000005',
    tag: 'league.enter.first.time.club.v2',
    title:
      'Welcome to the Alpha league! 👋 Aim to be in the top rank by the end of the week to unlock the next league.',
    body: 'Welcome to the Alpha league! 👋 Aim to be in the top rank by the end of the week to unlock the next league.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 3,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000006',
    tag: 'league.round.finish.highest.rank.max.tier.club.v2',
    title:
      'Amazing! You achieved Rank 1 in Commandus League. You’re truly the best of the best! 🎉',
    body: 'Amazing! You achieved Rank 1 in Commandus League. You’re truly the best of the best! 🎉',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
        clubName: 'SAT Math Calculator',
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 1,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Commandus',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '00000000-0000-0000-0000-000000000007',
    tag: 'league.round.finish.highest.tier.club.v2',
    title:
      'You achieved Rank 11 and kept your position in Commandus League. Keep practicing to reach the top! 💪',
    body: 'You achieved Rank 11 and kept your position in Commandus League. Keep practicing to reach the top! 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubName: 'ACT Math',
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 1,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Commandus',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Commandus',
            clubSlug: 'act-math',
            iconPath: null,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const challengeNotification: GetNotificationRes[] = [
  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4a',
    tag: 'challenge.ending.scheduler.winner.club.v2',
    title:
      'Congratulations! 🏆 A challenge has ended, and you are the champion in',
    body: 'Congratulations! 🏆 A challenge has ended, and you are the champion in',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {},
        cta: [
          {
            type: 'cta.challenge.detail',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeName: "Abracadebra's SAT Reading Challenge 8",
            challengeStatus: 2,
            quizId: 'a0ca98a7-4da5-4e46-b485-0945245b87ae',
            quizShortId: 'yruk8idmLjbX',

            ownerUserId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerMemberId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerName: 'Nathan Harrod',
            ownerDistinctName: 'user6713',
            ownerAvatar: 'https://picsum.photos/id/64/200/200',
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.new.challenge',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4b',
    tag: 'challenge.acceptance.addressee.addressee.club.v2',
    title: 'You have joined',
    body: 'You have joined',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {},
        cta: [
          {
            type: 'cta.challenge.detail',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeName: "Abracadebra's SAT Reading Challenge 8",
            challengeStatus: 2,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',

            ownerUserId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerMemberId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerName: 'Nathan Harrod',
            ownerDistinctName: 'user6713',
            ownerAvatar: 'https://picsum.photos/id/64/200/200',
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.challenge',
            clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeMaxAttemptNum: 2,
            challengeStatus: 2,
            challengeAttemptNum: 1,
            challengeDetailStatus: 3,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',
            quizMistakeCount: 1,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4c',
    tag: 'challenge.ending.scheduler.challenger.club.v2',
    title: 'This challenge has ended 🏆 and Harry Tran is the champion in',
    body: 'This challenge has ended 🏆 and @harry.tran is the champion in',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {},
        cta: [
          {
            type: 'cta.challenge.detail',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeName: "Abracadebra's SAT Reading Challenge 8",
            challengeStatus: 2,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',

            ownerUserId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerMemberId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerName: 'Nathan Harrod',
            ownerDistinctName: 'user6713',
            ownerAvatar: 'https://picsum.photos/id/64/200/200',
          },
          {
            type: 'cta.recommend.challenger.list',
            users: [
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Incendium',
                leagueRank: 1,
                isFollower: false,
                isFollowing: true,
              },
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                userAvatar: undefined,
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Alpha',
                leagueRank: 2,
                isFollower: true,
                isFollowing: true,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.new.challenge',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b5a',
    tag: 'challenge.almost.end.scheduler.challenger.club.v2',
    title:
      'Don’t miss out on the chance to prove your skills and claim your victory! You have 24 hours left to conquer',
    body: 'Don’t miss out on the chance to prove your skills and claim your victory! You have 24 hours left to conquer',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {},
        cta: [
          {
            type: 'cta.challenge.detail',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeName: "Abracadebra's SAT Reading Challenge 8",
            challengeStatus: 2,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',

            ownerUserId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerMemberId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerName: 'Nathan Harrod',
            ownerDistinctName: 'user6713',
            ownerAvatar: 'https://picsum.photos/id/64/200/200',
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.challenge',
            clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeMaxAttemptNum: 2,
            challengeStatus: 2,
            challengeAttemptNum: 1,
            challengeDetailStatus: 3,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',
            quizMistakeCount: 1,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: '66b312bc-5063-4431-a344-42c6c64e38aa',
    tag: 'challenge.good.result.member.club.v2',
    title: 'Impressive score on',
    body: 'Impressive score on',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {},
        cta: [
          {
            type: 'cta.challenge.detail',
            challengeId: 'df5f6686-2eca-424d-87c7-7cb23226f786',
            challengeName: "Abracadebra's SAT Reading Challenge 8",
            challengeStatus: 2,
            quizId: 'cd5c75c9-ee32-4891-9a9b-8daf3172631c',
            quizShortId: 'CnpM5ExGFKku',

            ownerUserId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerMemberId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            ownerName: 'Nathan Harrod',
            ownerDistinctName: 'user6713',
            ownerAvatar: 'https://picsum.photos/id/64/200/200',
          },
          {
            type: 'cta.text',
            key: 'notification.challenge.good.result.member.club.v2.text',
            text: 'Feeling competitive? Challenge other competitors.',
          },
          {
            type: 'cta.recommend.challenger.list',
            users: [
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Spectus',
                leagueRank: 1,
                isFollower: false,
                isFollowing: true,
              },
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                userAvatar: undefined,
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Alpha',
                leagueRank: 2,
                isFollower: true,
                isFollowing: true,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.create.challenge',
            clubId: 'db049187-8031-4d5a-8817-31f6dad05630',
            clubSlug: 'act-math',
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const streakNotification: GetNotificationRes[] = [
  {
    id: '4c60714a-acaf-44a9-ab6d-ab5c266e4e02',
    tag: 'streak.achieved.multiple.day.member.club.v2',
    title:
      'Congrats on the 7-day streak 🔥 Practice more to keep your streak going!',
    body: 'Congrats on the 7-day streak 🔥 Practice more to keep your streak going!',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {
          streakCount: 7,
        },
        cta: [
          {
            type: 'cta.streak.current',
            streakCount: 2,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-12T07:14:07.779Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: 'c083849b-7714-4e14-a6b8-02db0336efc3',
    tag: 'streak.achieved.single.day.member.club.v2',
    title:
      'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    body: 'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {
          streakCount: 2,
        },
        cta: [
          {
            type: 'cta.streak.current',
            streakCount: 2,
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T03:09:16.073Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '91c5a178-eda6-4330-b5ea-fd783b1032a3',
    tag: 'streak.reminder.daily.member.club.v2',
    title: 'Complete at least 1 question to achieve your streak today 🔥',
    body: 'Complete at least 1 question to achieve your streak today 🔥',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.keep.streak',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '50bc7bb5-3126-4575-bf9c-59dd20905291',
    tag: 'streak.reminder.broken.member.club.v2',
    title:
      'Did you miss your practice yesterday? Your streak has reset to 0 🥶 let’s build it up again.',
    body: 'Did you miss your practice yesterday? Your streak has reset to 0 🥶 let’s build it up again.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.longest',
            streakCount: 5,
            streakDateEnd: '2024-01-09T23:59:59.999Z',
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const emailNotification: GetNotificationRes[] = [
  {
    id: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
    tag: 'email.verification.reminder.global',
    title:
      'Check and verify your email to continue full access or resend below.',
    body: 'Check and verify your email to continue full access or resend below.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.email.verification.remind',
          },
        ],
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: false,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
    tag: 'email.verification.success.global',
    title:
      'Your email is successfully verified ✅ You now have full access. Get started by exploring and joining a club.',
    body: 'Your email is successfully verified ✅ You now have full access. Get started by exploring and joining a club.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: false,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const reactQuestionNotification: GetNotificationRes[] = [
  {
    id: v4(),
    tag: 'someone.react.to.my.question.club',
    title: 'hsinyang reacted ❤️ to your question in',
    body: 'hsinyang reacted ❤️ to your question in',
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        senderUserId: 'e6ccf644-5618-4400-96d2-26a764584b9b',
        senderName: 'hsinyang',
        senderAvatar:
          'https://s.gravatar.com/avatar/1072bc3f8acd571300c6013a168e9195?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fah.png',
        senderIndicator: null,
        body: {
          senderUserId: 'e6ccf644-5618-4400-96d2-26a764584b9b',
          senderName: 'hsinyang',
          senderAvatar:
            'https://s.gravatar.com/avatar/1072bc3f8acd571300c6013a168e9195?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fah.png',
          senderIndicator: null,
          markId: 'b1041fbe-be88-47ef-9b2e-44394a39fc24',
          markCode: '❤️',
          quizId: '9041932a-c65f-41c0-84cb-4ed32c8b9b2f',
          quizName: "Hsinyang's Warm Up",
          quizQuestionId: '3bbf6227-dfb5-404d-b9e9-cfd0a7f42c03',
          quizQuestionNo: 1,
          creatorUserId: '5da0e321-f045-4954-a1d5-2dd9249427fd',
          creatorName: 'Your Name',
          creatorAvatar:
            'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-beta/profile/5da0e321-f045-4954-a1d5-2dd9249427fd/195333a0-f561-11ee-9a76-9350a2105919.png?AWSAccessKeyId=AKIAWXNJ4LEY362XB5FB&Expires=1731028024&Signature=3207Q305KvITB%2BBmZ0xxlat7nDM%3D',
          clubId: 'e135fef7-7249-46ec-af99-6a30dd58ec40',
          clubSlug: 'hsin-test',
          clubName: 'Hsin Test',
          clubIcon: '',
        },
      },
    },
    scheduledAt: '2023-09-03T08:34:58.938Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const goalRingNotification: GetNotificationRes[] = [
  {
    id: '10000000-0000-0000-0000-000000000001',
    tag: 'goal.ring.achieved.question.count.club.v2',
    title:
      "Fantastic! You've met your daily question practice goal. Keep practicing to complete your Goal Rings today 💪",
    body: "Fantastic! You've met your daily question practice goal. Keep practicing to complete your Goal Rings today 💪",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.goal.ring.status',
            target: GoalRingTarget.QuestionCount,
            questionCount: {
              current: 40,
              target: 30,
              ratio: 1.3333,
            },
            mockExamRoundCount: {
              current: 7,
              target: 10,
              ratio: 0.7,
            },
            practiceTimeMinute: {
              current: 30,
              target: 15,
              ratio: 0.5,
            },
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '10000000-0000-0000-0000-000000000002',
    tag: 'goal.ring.achieved.practice.time.club.v2',
    title:
      'Nice! You’ve met today’s practice time goal! Keep practicing to complete your Goal Rings today 💪 ',
    body: 'Nice! You’ve met today’s practice time goal! Keep practicing to complete your Goal Rings today 💪 ',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.goal.ring.status',
            target: GoalRingTarget.PracticeTime,
            questionCount: {
              current: 50,
              target: 100,
              ratio: 0.5,
            },
            mockExamRoundCount: {
              current: 7,
              target: 10,
              ratio: 0.77,
            },
            practiceTimeMinute: {
              current: 30,
              target: 30,
              ratio: 1,
            },
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '10000000-0000-0000-0000-000000000003',
    tag: 'goal.ring.achieved.mock.count.club.v2',
    title:
      'Great job completing your daily Mock goals! Keep practicing to complete your Goal Rings today 💪 ',
    body: 'Great job completing your daily Mock goals! Keep practicing to complete your Goal Rings today 💪 ',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.goal.ring.status',
            target: GoalRingTarget.MockRoundCount,
            questionCount: {
              current: 100,
              target: 50,
              ratio: 0.5,
            },
            mockExamRoundCount: {
              current: 7,
              target: 1,
              ratio: 7,
            },
            practiceTimeMinute: {
              current: 30,
              target: 100,
              ratio: 0.3,
            },
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '10000000-0000-0000-0000-000000000004',
    tag: 'goal.ring.completed.scheduler.practicer.club.v2',
    title:
      'Congratulations! All daily goals for questions, practice time, and mock are complete for today 🎉 ',
    body: 'Congratulations! All daily goals for questions, practice time, and mock are complete for today 🎉 ',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.goal.ring.status',
            target: GoalRingTarget.All,
            questionCount: {
              current: 40,
              target: 30,
              ratio: 1.3333,
            },
            mockExamRoundCount: {
              current: 7,
              target: 1,
              ratio: 7,
            },
            practiceTimeMinute: {
              current: 30,
              target: 30,
              ratio: 1,
            },
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const clubNotification: GetNotificationRes[] = [
  {
    id: '20000000-0000-0000-0000-000000000001',
    tag: 'club.long.time.no.practice.reminder.3days.club',
    title: 'Long time no see😢 You haven’t practiced for 3 days.',
    body: 'Long time no see😢 You haven’t practiced for 3 days.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {
          dayCount: 3,
        },
        cta: [
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.practice.more',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000002',
    tag: 'club.acceptance.addressee.addressee.club',
    title:
      'welcomes you! 👋 Check out these club members who share similar interests. Join them to practice together, share progress, ask questions, and support each other in reaching your goals.',
    body: 'welcomes you! 👋 Check out these club members who share similar interests. Join them to practice together, share progress, ask questions, and support each other in reaching your goals.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.club.welcome.recommend.list',
            requestUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
            users: [
              {
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                dreamSchoolId: 'ac40f5e3-ce0f-413b-b212-1be157cc9e78',
                dreamSchoolName: 'Brigham Young University',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Incendium',
                leagueRank: 1,
                isFollower: false,
                isFollowing: true,
                sectionId: '',
              },
              {
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                dreamSchoolId: 'ac40f5e3-ce0f-413b-b212-1be157cc9e71',
                dreamSchoolName: 'Harvard University',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Alpha',
                leagueRank: 2,
                isFollower: false,
                isFollowing: true,
                sectionId: '',
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.start.practice',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000003',
    tag: 'club.new.join.request.reminder.club',
    title: 'You have 2 new join requests.',
    body: 'You have 2 new join requests.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {
          joinRequestCount: 5,
        },
        cta: [
          {
            type: 'cta.pending.request.list',
            requests: [
              {
                clubMemberRequestId: '1',
                clubMemberRequestStatusDyna: ClubMemberRequestStatus.UserSent,
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                isJoined: false,
              },
              {
                clubMemberRequestId: '2',
                clubMemberRequestStatusDyna: ClubMemberRequestStatus.UserSent,
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                userAvatar: '',
                isJoined: false,
              },
              {
                clubMemberRequestId: '3',
                clubMemberRequestStatusDyna: ClubMemberRequestStatus.UserSent,
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Jerry Chen',
                userAvatar: '',
                isJoined: false,
              },
              {
                clubMemberRequestId: '4',
                clubMemberRequestStatusDyna: ClubMemberRequestStatus.UserSent,
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Khanh Tran',
                userAvatar: '',
                isJoined: false,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.see.all.request',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000004',
    tag: 'club.join.request.rejected.member.global',
    title:
      'has rejected your join request. Feel free to reapply or explore other clubs.',
    body: 'has rejected your join request. Feel free to reapply or explore other clubs.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'SAT Math Private Club',
        body: {},
        cta: [
          {
            type: 'cta.related.club.list',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            clubs: [
              {
                clubMemberRequestId: '1',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000001',
                clubSlug: 'act-math',
                clubName: 'ACT Math',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.Joined,
              },
              {
                clubMemberRequestId: '2',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000002',
                clubSlug: 'sat-math-calculator',
                clubName: 'SAT Math Calculator',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
              {
                clubMemberRequestId: '3',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000003',
                clubSlug: 'private',
                clubName: 'Private',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Private,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'privateprivateprivateprivate',
            clubIcon: '',
            clubName: 'SAT Math Private Club',
          },
          {
            type: 'cta.join.club.status',
            userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubMemberRequestId: '1',
            clubMemberRequestStatus: ClubMemberRequestStatus.ManagerRejected,
            clubPrivacy: ClubPrivacy.Private,
            clubOfficialType: ClubOfficialType.Official,
            clubVisibility: ClubVisibility.Listed,
            clubStatus: ClubStatus.Active,
            joinedStatus: ClubJoinedStatus.NotJoined,
          },
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'privateprivateprivateprivate-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000005',
    tag: 'club.deleted.global',
    title:
      'has been deleted. We suggest exploring these similar clubs that may interest you.',
    body: 'has been deleted. We suggest exploring these similar clubs that may interest you.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'Deleted Club',
        body: {},
        cta: [
          {
            type: 'cta.related.club.list',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            clubs: [
              {
                clubMemberRequestId: '1',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000001',
                clubSlug: 'act-math',
                clubName: 'ACT Math',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.Joined,
              },
              {
                clubMemberRequestId: '2',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000002',
                clubSlug: 'sat-math-calculator',
                clubName: 'SAT Math Calculator',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
              {
                clubMemberRequestId: '3',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000003',
                clubSlug: 'private',
                clubName: 'Private',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Private,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'deleted-club',
            clubIcon: '',
            clubName: 'Deleted Club',
          },
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'deleted-club-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000005',
    tag: 'club.member.left.global',
    title:
      'is sorry to see you leave, but we suggest exploring these similar clubs that may interest you.',
    body: 'is sorry to see you leave, but we suggest exploring these similar clubs that may interest you.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'ACT Math',
        body: {},
        cta: [
          {
            type: 'cta.related.club.list',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            clubs: [
              {
                clubMemberRequestId: '1',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000001',
                clubSlug: 'act-math',
                clubName: 'ACT Math',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.Joined,
              },
              {
                clubMemberRequestId: '2',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000002',
                clubSlug: 'sat-math-calculator',
                clubName: 'SAT Math Calculator',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
              {
                clubMemberRequestId: '3',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000003',
                clubSlug: 'private',
                clubName: 'Private',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Private,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '20000000-0000-0000-0000-000000000006',
    tag: 'club.member.removed.global',
    title:
      'You have been removed from <b>SAT Math No Calculator</b>. You can rejoin or explore other clubs that might interest you.',
    body: 'You have been removed from <b>SAT Math No Calculator</b>. You can rejoin or explore other clubs that might interest you.',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        clubIcon: '',
        clubName: 'SAT Math Private',
        body: {},
        cta: [
          {
            type: 'cta.related.club.list',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            clubs: [
              {
                clubMemberRequestId: '1',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000001',
                clubSlug: 'act-math',
                clubName: 'ACT Math',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.Joined,
              },
              {
                clubMemberRequestId: '2',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000002',
                clubSlug: 'sat-math-calculator',
                clubName: 'SAT Math Calculator',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Public,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
              {
                clubMemberRequestId: '3',
                clubMemberRequestStatus: ClubMemberRequestStatus.Unknown,
                clubId: '00000000-0000-0000-0000-000000000003',
                clubSlug: 'private',
                clubName: 'Private',
                clubIcon: '',
                clubPrivacy: ClubPrivacy.Private,
                clubOfficialType: ClubOfficialType.Official,
                clubVisibility: ClubVisibility.Listed,
                clubStatus: ClubStatus.Active,
                joinedStatus: ClubJoinedStatus.NotJoined,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'private',
            clubIcon: '',
            clubName: 'SAT Math Private',
          },
          {
            type: 'cta.join.club.status',
            userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubMemberRequestId: '1',
            clubMemberRequestStatus: ClubMemberRequestStatus.ManagerRejected,
            clubPrivacy: ClubPrivacy.Private,
            clubOfficialType: ClubOfficialType.Official,
            clubVisibility: ClubVisibility.Listed,
            clubStatus: ClubStatus.Active,
            joinedStatus: ClubJoinedStatus.NotJoined,
          },
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'private-topic',
  },
];

const friendsNotification: GetNotificationRes[] = [
  {
    id: '30000000-0000-0000-0000-000000000001',
    tag: 'follow.relation.requester.addressee.global.v2',
    title: 'started following you.',
    body: 'started following you.',
    data: {
      icon: 'user',
      isBot: true,
      payload: {
        senderUserId: 'd92a0bcc-865b-44e7-9d2f-1e8b6a486fbf',
        senderName: 'Jerry Chen 😎😺',
        senderAvatar:
          'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-beta/profile/45b74087-68ea-43e0-b1e1-15f3090a58fb/cd48b680-47fc-11ef-84d8-8de3949f8ecd.png?AWSAccessKeyId=AKIAWXNJ4LEY362XB5FB&Expires=1732783885&Signature=hL2L8zfQ6ZRww7dbuVTkWM2I7o4%3D',
        senderIndicator: {
          paid: 'PaidIndicatorEnabledUnlimited',
        },
        body: {
          userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
          name: 'Nathan Harrod',
          distinctName: 'kent.chen',
          avatar:
            'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
        },
        cta: [
          {
            type: 'cta.follower.profile',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            name: 'Nathan Harrod',
            avatar:
              'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
            dreamSchoolName: 'Harvard University',
            country: 'Vietnam',
            city: 'Hanoi',
            highSchool: 'New Jersey High School',
          },
          {
            type: 'cta.follow.followback',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '30000000-0000-0000-0000-000000000002',
    tag: 'follow.relation.requester.requester.global.v2',
    title:
      'You followed @harry.tran.15. Check out these other users who share similar interests',
    body: "You followed <a href='/profile/e32b7956-9b48-4161-bdb3-9d1bf3be7724'><img class='avatar' src='https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c' />@Kent Chen</a>. Check out these other users who share similar interests.",
    data: {
      icon: 'user',
      isBot: true,
      payload: {
        senderUserId: 'b13700b6-2490-43c7-8601-4f4b0310278e',
        senderName: 'Harry Tran',
        senderAvatar: '',
        senderIndicator: null,
        body: {
          userId: '6ace855d-9abe-4ae2-9a45-b15d8718be15',
          name: 'Harry Tran',
          avatar:
            'https://s.gravatar.com/avatar/2ad679add09455b80b57ea6859df8ade?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fxu.png',
          distinctName: 'harry.tran.15',
        },
        cta: [
          {
            type: 'cta.follow.suggest.list',
            listMaxCount: 5,
            users: [
              {
                userId: 'd11f154d-1cdc-4e39-b4f2-39e1056c5164',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a/AATXAJzgCMywxs8piSYfqgSgO6eqvtaLhmE14DD2lJWC=s96-c',
                dreamSchoolName: 'Stanford University',
                country: 'New Jersey',
                city: 'US',
                highSchool: '',
              },
              {
                userId: 'd7e98d42-d77f-4820-a2aa-e07cbbe3e150',
                userName: 'Mitch Wu',
                userAvatar:
                  'https://s.gravatar.com/avatar/1f7491cdc667cf69bd3ae59b0354c33d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmw.png',
                dreamSchoolName: 'Stanford University',
                country: 'New Jersey',
                city: 'US',
                highSchool: '',
              },
              {
                userId: '5da0e321-f045-4954-a1d5-2dd9249427fd',
                userName: 'hsinyang',
                userAvatar:
                  'https://s.gravatar.com/avatar/1072bc3f8acd571300c6013a168e9195?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fah.png',
                dreamSchoolName: 'Stanford University',
                country: 'New Jersey',
                city: 'US',
                highSchool: '',
              },
              {
                userId: 'dc966502-80ef-43a4-aad8-5c1ff7dd22bf',
                userName: 'Zeal',
                userAvatar: '',
                dreamSchoolName: 'Stanford University',
                country: 'New Jersey',
                city: 'US',
                highSchool: '',
              },
              {
                userId: '27946b22-d17d-44fb-b508-da37cffb614a',
                userName: 'Jerry Chen',
                userAvatar: '',
                dreamSchoolName: 'Stanford University',
                country: 'New Jersey',
                city: 'US',
                highSchool: '',
              },
            ],
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '30000000-0000-0000-0000-000000000003',
    tag: 'follow.friendship.requester.addressee.global.v2',
    title: 'You and @Kent Chen are follow each other 🎉',
    body: "You and <a href='/profile/e32b7956-9b48-4161-bdb3-9d1bf3be7724'><img class='avatar' src='https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c' />@Kent Chen</a> are now following each other! 🎉",
    data: {
      icon: 'user',
      isBot: true,
      payload: {
        senderUserId: 'b13700b6-2490-43c7-8601-4f4b0310278e',
        senderName: 'Harry Tran',
        senderAvatar: '',
        senderIndicator: null,
        body: {
          userId: '6ace855d-9abe-4ae2-9a45-b15d8718be15',
          name: 'Harry Tran',
          avatar:
            'https://s.gravatar.com/avatar/2ad679add09455b80b57ea6859df8ade?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fxu.png',
          distinctName: 'harry.tran.15',
        },
        cta: [
          {
            type: 'cta.follower.profile',
            name: 'Kent Chen',
            avatar:
              'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            dreamSchoolName: 'Harvard University',
            country: 'Vietnam',
            city: 'Hanoi',
            highSchool: 'New Jersey High School',
          },
          {
            type: 'cta.view.user.profile',
            userName: 'Kent Chen',
            userAvatar:
              'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '30000000-0000-0000-0000-000000000004',
    tag: 'follow.friendship.addressee.requester.global.v2',
    title: 'has followed you back. You are following each other now! 🎉',
    body: 'has followed you back. You are following each other now! 🎉',
    data: {
      icon: 'user',
      isBot: false,
      payload: {
        senderUserId: 'b13700b6-2490-43c7-8601-4f4b0310278e',
        senderName: 'Kent Chen',
        senderAvatar: '',
        senderIndicator: null,
        body: {
          userId: '6ace855d-9abe-4ae2-9a45-b15d8718be15',
          name: 'Harry Tran',
          avatar:
            'https://s.gravatar.com/avatar/2ad679add09455b80b57ea6859df8ade?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fxu.png',
          distinctName: 'harry.tran.15',
        },
        cta: [
          {
            type: 'cta.follower.profile',
            name: 'Kent Chen',
            avatar:
              'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
            dreamSchoolName: 'Harvard University',
            country: 'Vietnam',
            city: 'Hanoi',
            highSchool: 'New Jersey High School',
          },
          {
            type: 'cta.view.user.profile',
            userName: 'Kent Chen',
            userAvatar:
              'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
            userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
];

const avatarNotification: GetNotificationRes[] = [
  {
    id: '40000000-0000-0000-0000-000000000001',
    tag: 'payment.success.payment.requester.global.v2',
    title:
      'Congratulations, your transaction was successful! 🎉 Thank you for your purchase and feel free to explore more avatars in our marketplace.',
    body: 'Congratulations, your transaction was successful! 🎉 Thank you for your purchase and feel free to explore more avatars in our marketplace.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.avatar.detail',
            avatarProductId: '10e04fd9-b3a7-471e-9f85-b88666ea5c60',
            avatarImgUrl:
              'https://cdn.sanity.io/images/o3cx5pyw/martketplace-nft/b3b7693930482a0f336239f160d6b70910d22d41-670x670.png',
            avatarName: '🍄🙂',
          },
          {
            type: 'cta.marketplace.main',
          },
          {
            type: 'cta.marketplace.avatar.me',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '40000000-0000-0000-0000-000000000002',
    tag: 'payment.failed.payment.requester.global.v2',
    title:
      'We’re sorry, your order did not go through. Please try again to complete your order for',
    body: 'We’re sorry, your order did not go through. Please try again to complete your order for',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.avatar.detail',
            avatarProductId: '10e04fd9-b3a7-471e-9f85-b88666ea5c60',
            avatarImgUrl:
              'https://cdn.sanity.io/images/o3cx5pyw/martketplace-nft/b3b7693930482a0f336239f160d6b70910d22d41-670x670.png',
            avatarName: '🍄🙂',
          },
          {
            type: 'cta.marketplace.order.retry',
            avatarProductId: '10e04fd9-b3a7-471e-9f85-b88666ea5c60',
            avatarImgUrl:
              'https://cdn.sanity.io/images/o3cx5pyw/martketplace-nft/b3b7693930482a0f336239f160d6b70910d22d41-670x670.png',
            avatarName: '🍄🙂',
            avatarPrice: 816,
            avatarCurrency: 'USD',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
];

const membershipNotification: GetNotificationRes[] = [
  {
    id: '50000000-0000-0000-0000-000000000001',
    tag: 'subscription.cancel.payment.member.global.v2',
    title:
      'We’re sorry, your transaction to subscribe to Premium Plan did not go through. Please try again to complete order.',
    body: 'We’re sorry, your transaction to subscribe to Premium Plan did not go through. Please try again to complete order.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {
          subscriptionPlanId: '31ccbfc6-59d7-4b94-b941-c5ccee79ba21',
          subscriptionPlanCode: 'earnaha_pro_plan_monthly',
          subscriptionPlanName: 'Pro',
        },
        cta: [
          {
            type: 'cta.membership.plan.payment.retry',
            subscriptionPlanId: '26f53d3b-b034-4910-8f5e-f5189d1565f4',
            subscriptionPlanCode: 'earnaha_pro_plan_monthly',
            subscriptionPlanName: 'Pro',
            subscriptionPlanCurrency: 'USD',
            subscriptionPlanAmount: 1500,
            subscriptionPlanChargeType: ChargeType.RecurringMonthly,
          },
          {
            type: 'cta.membership.plan.me',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '50000000-0000-0000-0000-000000000002',
    tag: 'subscription.success.payment.member.global.v2',
    title:
      'Congratulations, your transaction was successful! 🎉 Thank you for your purchase.',
    body: 'Congratulations, your transaction was successful! 🎉 Thank you for your purchase.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {
          subscriptionPlanId: '26f53d3b-b034-4910-8f5e-f5189d1565f4',
          subscriptionPlanCode: 'earnaha_pro_plan_monthly',
          subscriptionPlanName: 'Pro',
          subscriptionPlanCurrency: 'USD',
          subscriptionPlanAmount: 1500,
          subscriptionPlanChargeType: ChargeType.RecurringMonthly,
        },
        cta: [
          {
            type: 'cta.membership.plan.me',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '50000000-0000-0000-0000-000000000003',
    tag: 'subscription.pro.update.payment.7day.reminder.member.global.v2',
    title:
      'Your Premium Plan will end in 7 days. To continue enjoying the full benefits of being a Premium user, please update your payment details.',
    body: 'Your Premium Plan will end in 7 days. To continue enjoying the full benefits of being a Premium user, please update your payment details.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.membership.update.payment',
            updatePaymentLink: '/profile/my-plan',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '50000000-0000-0000-0000-000000000004',
    tag: 'subscription.plan.expired.member.global.v2',
    title:
      'Your Premium Plan has expired. To continue practicing and pursuing your dream school, upgrade your plan.',
    body: 'Your Premium Plan has expired. To continue practicing and pursuing your dream school, upgrade your plan.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.membership.plan.upgrade',
            upgradePlanLink: '/profile/my-plan',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '50000000-0000-0000-0000-000000000005',
    tag: 'subscription.plan.downgraded.member.global.v2',
    title:
      'Your Unlimited Plan was cancelled and will expire on Sep 10. When the current date expires, you will be in the Pro Plan.',
    body: 'Your Unlimited Plan was cancelled and will expire on Sep 10. When the current date expires, you will be in the Pro Plan.',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.membership.plan.upgrade',
            upgradePlanLink: '/profile/my-plan',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
  {
    id: '50000000-0000-0000-0000-000000000006',
    tag: 'subscription.plan.upgraded.member.global.v2',
    title:
      'You’ve upgraded to the Pro Plan! Enjoy the benefits of being a Pro Plan user 🥳',
    body: 'You’ve upgraded to the Pro Plan! Enjoy the benefits of being a Pro Plan user 🥳',
    data: {
      icon: 'payment',
      isBot: true,
      payload: {
        body: {},
        cta: [
          {
            type: 'cta.membership.plan.me',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: null,
  },
];

export const mockNotifications: GetNotificationRes[] = [
  ...emailNotification,
  ...challengeNotification,
  ...leagueNotifications,
  ...streakNotification,
  ...reactQuestionNotification,
  ...goalRingNotification,
  ...clubNotification,
  ...friendsNotification,
  ...avatarNotification,
  ...membershipNotification,
];
