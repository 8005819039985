import { useCallback, useMemo } from 'react';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import {
  AnswerFormatType,
  buildInfiniteHookMutate,
  InfiniteHookResponse,
  StructureType,
  useIaWorkflows,
  useSupportedQuestionAnswerFormatType,
  WorkflowViewSlug,
} from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

const useSupportedAnswerFormatType = (clubSlug: string | undefined) => {
  const { sectionId } = useCurrentIaClub(clubSlug);
  const { data } = useSupportedQuestionAnswerFormatType(sectionId);

  return useMemo(() => {
    const allSupportedAnswerFormatTypes =
      data?.data
        .filter((d) => d.structureType !== StructureType.QuestionGroup)
        .map((d) => d.answerFormatType) || ([] as AnswerFormatType[]);

    return {
      allSupportedAnswerFormatTypes,
      firstSupportedAnswerFormatType: allSupportedAnswerFormatTypes[0],
    };
  }, [data]);
};

const useMutateWorkflowOptimistic = (
  workflow: GetIaWorkflowSingleRes | undefined,
  workflowData: InfiniteHookResponse<GetIaWorkflowSingleRes>
) => {
  const mutateWorkflow = useMemo(
    () => buildInfiniteHookMutate(workflowData.mutate),
    [workflowData.mutate]
  );

  return useCallback(
    ({
      data,
      optimisticWorkflow,
      optimisticRecipe,
      optimisticRecipePrompt,
      revalidate = true,
    }: {
      data?: Parameters<typeof mutateWorkflow>[0];
      optimisticWorkflow?: Partial<GetIaWorkflowSingleRes>;
      optimisticRecipe?: Partial<GetIaWorkflowRecipeDefaultRes>;
      optimisticRecipePrompt?: Partial<GetIaWorkflowPromptDefaultRes>;
      revalidate?: boolean;
    }) => {
      if (!workflow) return;

      mutateWorkflow(data, {
        revalidate,
        optimisticData: optimisticWorkflow
          ? [
              {
                ...workflow,
                ...optimisticWorkflow,
              },
            ]
          : optimisticRecipe
          ? [
              {
                ...workflow,
                recipes: workflow.recipes.map((r) =>
                  r.id === optimisticRecipe.id
                    ? { ...r, ...optimisticRecipe }
                    : r
                ),
              },
            ]
          : optimisticRecipePrompt
          ? [
              {
                ...workflow,
                recipes: workflow.recipes.map((r) => ({
                  ...r,
                  prompts: r.prompts.map((p) =>
                    p.id === optimisticRecipePrompt.id
                      ? { ...p, ...optimisticRecipePrompt }
                      : p
                  ),
                })),
              },
            ]
          : undefined,
      });
    },
    [mutateWorkflow, workflow]
  );
};

export const useAgentWorkflowDetailData = ({
  workflowId,
  recipeId,
}: {
  workflowId: string;
  recipeId?: string;
}) => {
  const workflowData = useIaWorkflows({
    viewSlug: WorkflowViewSlug.ListCenterIaAiWorkflowSingle,
    ...combineSearch([`id:${workflowId}`, 'id:eq']),
  });
  const workflow = workflowData.dataset?.[0] as
    | GetIaWorkflowSingleRes
    | undefined;

  const { allSupportedAnswerFormatTypes, firstSupportedAnswerFormatType } =
    useSupportedAnswerFormatType(workflow?.club.clubSlug);

  const recipe = useMemo(
    () => workflow?.recipes.find((r) => r.id === recipeId),
    [workflow, recipeId]
  );

  const mutateWorkflowOptimistic = useMutateWorkflowOptimistic(
    workflow,
    workflowData
  );

  return useMemo(
    () => ({
      workflow,
      allSupportedAnswerFormatTypes,
      firstSupportedAnswerFormatType,
      recipe,
      mutateWorkflowOptimistic,
    }),
    [
      workflow,
      allSupportedAnswerFormatTypes,
      firstSupportedAnswerFormatType,
      recipe,
      mutateWorkflowOptimistic,
    ]
  );
};
