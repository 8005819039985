import { useTranslation } from 'react-i18next';
import { PlanProductName } from '@lib/web/apis';
import { useSubscriptionPlans } from '@lib/web/hooks';

import { getPlansInfo } from '../config';
import { PlanItem } from '../types';

export default function usePlanConfigs() {
  const { plans, isLoading, plansDisplayAmountByMonth } =
    useSubscriptionPlans();
  const { t } = useTranslation('profile');
  const plansInfo = getPlansInfo(t);

  const planConfigs: PlanItem[] = [
    {
      id: 'free',
      amount: 0,
      type: 'forever',
      product: null,
      ...plansInfo.free,
      enabled: true,
    },
    {
      id: plans.basicMonthly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.BasicMonthly],
      type: 'monthly',
      isPopular: true,
      product: plans.basicMonthly,
      ...plansInfo.basic,
      enabled: true,
      badgeIcon: 'BadgeBasicPlan',
    },
    {
      id: plans.basicYearly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.BasicYearly],
      type: 'yearly',
      isPopular: true,
      product: plans.basicYearly,
      ...plansInfo.basic,
      enabled: true,
      badgeIcon: 'BadgeBasicPlan',
    },
    {
      id: plans.proMonthly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.ProMonthly],
      type: 'monthly',
      product: plans.proMonthly,
      ...plansInfo.pro,
      enabled: true,
      badgeIcon: 'BadgeProPlan',
    },
    {
      id: plans.proYearly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.ProYearly],
      type: 'yearly',
      product: plans.proYearly,
      ...plansInfo.pro,
      enabled: true,
      badgeIcon: 'BadgeProPlan',
    },
    {
      id: plans.unlimitedMonthly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.UnlimitedMonthly],
      type: 'monthly',
      product: plans.unlimitedMonthly,
      ...plansInfo.unlimited,
      enabled: true,
      badgeIcon: 'BadgeUnlimitedPlan',
    },
    {
      id: plans.unlimitedYearly?.id || null,
      amount: plansDisplayAmountByMonth[PlanProductName.UnlimitedYearly],
      type: 'yearly',
      product: plans.unlimitedYearly,
      ...plansInfo.unlimited,
      enabled: true,
      badgeIcon: 'BadgeUnlimitedPlan',
    },
  ];

  const getPlan = (id: string) => {
    return planConfigs.find((item) => item.id === id);
  };

  return {
    plans: planConfigs,
    freePlan: planConfigs[0],
    basicMonthlyPlan: planConfigs[1],
    basicYearlyPlan: planConfigs[2],
    proMonthlyPlan: planConfigs[3],
    proYearlyPlan: planConfigs[4],
    unlimitedMonthlyPlan: planConfigs[5],
    unlimitedYearlyPlan: planConfigs[6],
    isLoading,
    getPlan,
  };
}
