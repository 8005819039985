const isProd =
  process.env.ENV_CODE === 'prod' || process.env.ENV_CODE === 'gamma';
const isDev = !isProd;
const service =
  process.env.ENV_CODE === 'prod' ? 'aha' : `aha-${process.env.ENV_CODE}`;

/**
 * XXX: move this featureFlags config to somewhere we're easier to config
 * such as s3 or sanity
 * and at the same time, consider the query performance
 */

type EnvEnable = {
  prod: boolean;
  gamma: boolean;
  beta: boolean;
  dev: boolean;
};

const featureFlags: { [key: string]: EnvEnable } = {
  agentWorkflow: {
    prod: true,
    gamma: true,
    beta: true,
    dev: true,
  },
};

const featureEnable = {
  agentWorkflow:
    featureFlags.agentWorkflow[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
};

export { featureEnable, isDev, isProd, service };
