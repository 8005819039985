import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { nonNullable } from '@front/helper';
import { IaRichText, IaRichTextObj } from '@lib/ia/src/core/types';
import { NotificationCtaConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { NotificationTag, SharedNotificationTag } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { isHumanThreadUser } from '@lib/web/thread/typeGuard';
import { HumanThreadUser } from '@lib/web/thread/types';
import { TFunction } from 'i18next';

import {
  getQueries,
  messageSomeoneSharePage,
} from '../../utils/shareMessageUtils';

type NotificationMessageContent = {
  text: IaRichText;
  tag?: NotificationTag | SharedNotificationTag;
  cta?: NotificationCtaConfig[];
};

const itemMentionUser = (user: HumanThreadUser): IaRichTextObj => ({
  type: 'user',
  id: user.userId || '',
  name: `@${user.distinctName}`,
  src: user.image || '',
  action: {
    type: 'event' as const,
    value: 'viewUser',
  },
  indicators: user.indicators,
});

const messageEmpty: NotificationMessageContent = {
  text: '',
};

const messageLoading: NotificationMessageContent = {
  text: '', // XXX: to support loading skeleton
};

const messageError = (info: string): NotificationMessageContent => ({
  text: `🔴 ${info}`,
});

const messageNotSupported = (
  notificationTag: NotificationTag
): NotificationMessageContent => ({
  text: `🚧 not supported notification type: [${notificationTag}]`,
});

const messageSomeoneFollowYou = (
  someone: HumanThreadUser
): NotificationMessageContent => ({
  text: [
    itemMentionUser(someone),
    {
      type: 'text',
      value: 'followed you.',
    },
  ],
  cta: [
    {
      type: 'customize',
      value: 'cta.follow.followback',
      metadata: {
        userId: someone.userId,
      },
    },
  ],
});

const messageReceiveAChallengeInvitation = (
  t: TFunction,
  sender: HumanThreadUser,
  payload: GetThreadMessageNotificationPayloadRes
): NotificationMessageContent => ({
  text: [
    itemMentionUser(sender),
    {
      type: 'text',
      value: 'sent you a challenge invitation to',
    },
    {
      type: 'highlight',
      text: payload.challenge?.challengeName || '',
    },
    {
      type: 'text',
      value: '. You have 5 days to join the challenge.',
    },
  ],
  cta: [
    {
      type: 'customize' as const,
      value: 'cta.challenge.invitation',
      metadata: {
        challengers: [],
        link: `/club/${payload.club?.clubSlug}/challenge/${payload.quiz?.quizShortId}`,
      },
    },
    payload.challenge && {
      type: 'bigCardNumber' as const,
      title: t('Remaining'),
      titleIcon: 'TestClock',
      value: {
        content: payload.challenge.challengeDaysLeft || '',
      },
      description: {
        content: payload.challenge.challengeDaysLeft <= 1 ? 'Day' : 'Days',
      },
    },
  ].filter(nonNullable),
});

const messageReceiveAPromoCode = (
  t: TFunction,
  payload: GetThreadMessageNotificationPayloadRes
): NotificationMessageContent => ({
  text: [
    {
      type: 'text',
      value: t(
        'dm.shared.discount.code.global',
        'Use my discount code ‘{{discountCode}}’ for {{discountPercentage}}% off! 🎉',
        {
          discountCode: (
            payload.discountCode?.discountCode ?? ''
          ).toUpperCase(),
          discountPercentage: payload.discountCode?.discountPercentage,
        }
      ),
    },
  ],
  cta: [
    {
      type: 'customize',
      value: 'cta.share.discountCode',
      metadata: payload,
    },
  ],
});

const messageFriendshipMade = (): NotificationMessageContent => ({
  text: 'I have followed you back. We are friends now! 🎉',
});
export const useNotificationMessageContent = () => {
  const { t } = useTranslation('notification');

  const {
    getThreadNotificationMessagePayload,
    getThreadUser,
    getPathnameSettings,
  } = useThread();

  const getNotificationMessageContent = useCallback(
    ({
      notificationSenderId,
      notificationId,
    }: {
      notificationSenderId?: string;
      notificationId?: string;
    }): NotificationMessageContent => {
      if (!notificationId) return messageEmpty;

      const notificationPayload =
        getThreadNotificationMessagePayload(notificationId);

      if (notificationPayload === undefined) {
        return messageLoading;
      }

      if (notificationPayload === null) {
        return messageError(
          `cannot find notification payload [${notificationId}]`
        );
      }

      const notificationSender = getThreadUser(notificationSenderId);

      if (!notificationSender) return messageLoading;
      if (!isHumanThreadUser(notificationSender)) {
        return messageError('not support non-human sender of notification');
      }

      switch (notificationPayload.tag) {
        case SharedNotificationTag.DmSharedAvatarGlobal: {
          const query = getQueries(notificationPayload);
          return {
            text: [
              {
                type: 'panel',
                src: notificationPayload?.avatar?.avatarImage,
                text: notificationPayload?.avatar?.avatarName || 'Avatar',
                panelKey: query.panel,
                panelParams: notificationPayload.avatar,
              },
            ],
            tag: notificationPayload.tag,
          };
        }
        case SharedNotificationTag.DmSharedAgentGlobal:
        case SharedNotificationTag.DmSharedChallengeClub:
        case SharedNotificationTag.DmSharedClubGlobal:
        case SharedNotificationTag.DmSharedClubGroupGlobal:
        case SharedNotificationTag.DmSharedDynamicPlaylistClub:
        case SharedNotificationTag.DmSharedEditorQuestionClub:
        case SharedNotificationTag.DmSharedGeneralPageClub:
        case SharedNotificationTag.DmSharedGeneralPageGlobal:
        case SharedNotificationTag.DmSharedPlaylistClub:
        case SharedNotificationTag.DmSharedQuestionClub:
        case SharedNotificationTag.DmSharedResultClub: {
          const message = messageSomeoneSharePage(
            t,
            notificationPayload,
            getPathnameSettings
          );

          if (message) return { text: message, tag: notificationPayload.tag };
          return messageNotSupported(notificationPayload.tag);
        }
        case SharedNotificationTag.DmSharedDiscountCode:
          return {
            ...messageReceiveAPromoCode(t, notificationPayload),
            tag: notificationPayload.tag,
          };
        case NotificationTag.SomeoneFollowedYou:
          return messageSomeoneFollowYou(notificationSender);
        case NotificationTag.ReceiveAChallengeInvitation:
          return messageReceiveAChallengeInvitation(
            t,
            notificationSender,
            notificationPayload
          );
        case NotificationTag.FriendshipMade:
          return messageFriendshipMade();
        default:
          return messageNotSupported(notificationPayload.tag);
      }
    },
    [getPathnameSettings, getThreadNotificationMessagePayload, getThreadUser, t]
  );

  return {
    getNotificationMessageContent,
  };
};
