import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { updateViewedSlug } from '@app/web/src/actions/layoutActions';
import { useAppDispatch } from '@app/web/src/hooks/redux';

export default function useSwitchClub() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  return useCallback(
    (slug: string, isJoined: boolean) => {
      if (!router.query.clubSlug && !router.query.slug) {
        dispatch(updateViewedSlug(slug));
      }

      if (!isJoined) {
        router.push(`/club/${slug}`);
      } else {
        router.push(`/club/${slug}/summary`);
      }
    },
    [dispatch, router]
  );
}
