import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { AsyncTaskViewSlug } from '../../types/enums/async';
import { generateInfiniteHook, SwrHelperParams } from '../query';

export type GetIaAsyncTaskResMap = {
  [AsyncTaskViewSlug.ListCenterIaAsyncTaskDefault]: GetIaAsyncTaskDefaultRes;
  [AsyncTaskViewSlug.ListCenterIaAsyncTaskExternalAiTask]: GetIaAsyncTaskExternalAiTaskRes;
};

export function useIaAsyncTasks<
  K = GetIaAsyncTaskDefaultRes,
  T extends AsyncTaskViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends AsyncTaskViewSlug ? GetIaAsyncTaskResMap[T] : K
  >(
    (index) =>
      `v2/ia/async-tasks?${getIaQueryString({
        ...query,
        page: index + 1,
        limit,
      })}`,
    limit,
    {
      auth: true,
      ...config,
    }
  );
}
