import { QuestionDetailComponentType, StructureType } from '@lib/web/apis';
import {
  apiBlocksToHtml,
  apiBlocksToText,
  apiBlockToComposerBlock,
} from '@lib/web/editor/EditorTextComposer';

/**
 * The structure of editor response might be a little complex, so we encapsulate the logic here
 *
 * here is components response example
 * QuestionDetailComponentType {
 *   LeftQuestionArea = 11,
 *   RightQuestionArea = 12,
 *   GeneralSolution = 21,
 *   AnswerSolution = 22,
 *   Answer = 31,
 *   ReferenceFormula = 41,
 * }
 * {
 * QuestionDetailComponent {
 *   id: string;
 *   type: QuestionDetailComponentType;
 *   subType: QuestionDetailComponentSubType;
 *   linkedComponentId: string;
 *   index: number;
 *   blocks: {
 *     id: string;
 *     type: BlockType;
 *     content: string;
 *     sourceBlockId: string | null;
 *     targetBlockId: string | null;
 *     targetBlockPosition: number | null;
 *     metadata: string | null;
 *     remark: string | null;
 *     order: number;
 *     createdAt: string;
 *     updatedAt: string;
 *   }[];
 * }
 */

type ComponentMap = Partial<{
  [key in QuestionDetailComponentType]: QuestionDetailComponent[];
}>;

export const getLeftQuestionContentHtml = (componentMap?: ComponentMap) =>
  apiBlocksToHtml(
    componentMap?.[QuestionDetailComponentType.LeftQuestionArea]?.[0]?.blocks
  );

export const getLeftQuestionContentText = (componentMap?: ComponentMap) =>
  apiBlocksToText(
    componentMap?.[QuestionDetailComponentType.LeftQuestionArea]?.[0]?.blocks
  );

export const getGeneralSolutionContentHtml = (componentMap?: ComponentMap) =>
  apiBlocksToHtml(
    componentMap?.[QuestionDetailComponentType.GeneralSolution]?.[0]?.blocks
  );

export const getFormulaContentHtml = (componentMap?: ComponentMap) =>
  apiBlocksToHtml(
    componentMap?.[QuestionDetailComponentType.ReferenceFormula]?.[0]?.blocks
  );

export const getLeftQuestionComponents = (
  creatorQuestionInfo?: CreatorQuestionInfo,
  creatorQuestionGroups?: CreatorQuestionGroup[]
): ComponentMap | undefined => {
  if (
    [StructureType.SingleQuestion, StructureType.QuestionGroup].includes(
      creatorQuestionInfo?.structureType
    )
  ) {
    return creatorQuestionInfo?.components;
  }
  if (creatorQuestionInfo?.structureType === StructureType.SubQuestion) {
    // In left question area, sub-question does not have its own content, which need  QuestionGroup's value
    return creatorQuestionGroups?.find(
      (group) => group.id === creatorQuestionInfo.questionGroupId
    )?.components;
  }

  console.warn(
    'Unsupported creatorQuestionInfo.structureType: ',
    creatorQuestionInfo?.structureType
  );
  return undefined;
};

export const getRightQuestionContentHtml = (componentMap?: ComponentMap) =>
  apiBlocksToHtml(
    componentMap?.[QuestionDetailComponentType.RightQuestionArea]?.[0]?.blocks
  );

export const getRightQuestionContentText = (componentMap?: ComponentMap) =>
  apiBlocksToText(
    componentMap?.[QuestionDetailComponentType.RightQuestionArea]?.[0]?.blocks
  );

/**
 * generally we'll need a map to get corresponding content of an option
 * {
 *   [optionId1]: content1,
 *   [optionId2]: content2,
 *   ...
 * }
 */
export const getAnswerOptionIdToContentHtmlMap = (
  componentMap?: ComponentMap
): Record<string, string> =>
  componentMap?.[QuestionDetailComponentType.Answer]?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: apiBlocksToHtml(item.blocks),
    }),
    {}
  ) || {};

/**
 * the same as getAnswerOptionIdToContentHtmlMap but return text only
 */
export const getAnswerOptionIdToContentTextMap = (
  componentMap?: ComponentMap
): Record<string, string> =>
  componentMap?.[QuestionDetailComponentType.Answer]?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: apiBlocksToText(item.blocks),
    }),
    {}
  ) || {};

export const getAnswerOptionFirstContentText = (
  componentMap?: ComponentMap
) => {
  const firstComponentBlock =
    componentMap?.[QuestionDetailComponentType.Answer]?.[0]?.blocks[0];

  if (!firstComponentBlock) {
    return '';
  }
  return apiBlockToComposerBlock(firstComponentBlock).text || '';
};

export const getAnswerOptionIdToSolutionHtmlMap = (
  componentMap?: ComponentMap
): Record<string, string> =>
  componentMap?.[QuestionDetailComponentType.AnswerSolution]?.reduce(
    (acc, item) => ({
      ...acc,
      [item.linkedComponentId]: apiBlocksToHtml(item.blocks), //  AnswerSolution's linkedComponentId will link to AnswerOption's id
    }),
    {}
  ) || {};

export const getLeftQuestionDetail = (componentMap?: ComponentMap) =>
  componentMap?.[QuestionDetailComponentType.LeftQuestionArea]
    ? {
        [QuestionDetailComponentType.LeftQuestionArea]:
          componentMap[QuestionDetailComponentType.LeftQuestionArea],
      }
    : undefined;

export const getRightQuestionDetail = (componentMap?: ComponentMap) =>
  componentMap?.[QuestionDetailComponentType.RightQuestionArea]
    ? {
        [QuestionDetailComponentType.RightQuestionArea]:
          componentMap[QuestionDetailComponentType.RightQuestionArea],
      }
    : undefined;

export const getAnswerOptionDetail = (componentMap?: ComponentMap) =>
  componentMap?.[QuestionDetailComponentType.Answer]
    ? {
        [QuestionDetailComponentType.Answer]:
          componentMap[QuestionDetailComponentType.Answer],
      }
    : undefined;

export const getAnswerSolutionDetail = (componentMap?: ComponentMap) =>
  componentMap?.[QuestionDetailComponentType.AnswerSolution]
    ? {
        [QuestionDetailComponentType.AnswerSolution]:
          componentMap[QuestionDetailComponentType.AnswerSolution],
      }
    : undefined;

export const getSolutionDetail = (componentMap?: ComponentMap) =>
  componentMap?.[QuestionDetailComponentType.GeneralSolution]
    ? {
        [QuestionDetailComponentType.GeneralSolution]:
          componentMap[QuestionDetailComponentType.GeneralSolution],
      }
    : undefined;
