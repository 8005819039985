import { Children } from 'react';
import { Box, BoxProps } from '@mui/material';

const styles = {
  root: {
    display: 'grid',
    gap: { xs: 2, md: 1 },
    width: { md: 'fit-content' },
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
};
export default function Buttons({
  sx,
  children,
  mobileInline = false,
}: BoxProps & { mobileInline?: boolean }) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const count = Children.toArray(children).filter(Boolean).length;
  return (
    <Box
      sx={[
        styles.root,
        mobileInline
          ? { gridTemplateColumns: `repeat(${count}, 1fr)` }
          : { gridTemplateColumns: { md: `repeat(${count}, 1fr)` } },
        ...sxProps,
      ]}
    >
      {children}
    </Box>
  );
}
