import { NextRouter } from 'next/router';
import {
  ExamMode,
  SharedNotificationPathname,
  SharedNotificationTag,
} from '@lib/web/apis';
import { QUIZ_SHORT_ID_LENGTH } from '@lib/web/hooks';

function extractQuizId(router: NextRouter | VirtualRouter) {
  const paramQuizId = router.query.quizId as string;
  let quizShortId = undefined;
  let quizId = undefined;
  if (paramQuizId.length === QUIZ_SHORT_ID_LENGTH) {
    quizShortId = paramQuizId;
  } else {
    quizId = paramQuizId;
  }
  return {
    quizId,
    quizShortId,
  };
}

function extractQuestionNumber(router: NextRouter | VirtualRouter) {
  return router.asPath.split('#')[1];
}

export function getShareSettingsByPage(router: NextRouter | VirtualRouter): {
  tag: SharedNotificationTag;
  ownerUserId?: string;
  extraInfo?: SharePageReq['extraInfo'];
} | null {
  const clearPathname = router.pathname.replace('/shared/[sid]', '');
  switch (clearPathname) {
    case SharedNotificationPathname.ClubSummary: {
      // NOTI-465 Share Summary page
      return {
        tag: SharedNotificationTag.DmSharedGeneralPageClub,
      };
    }
    case SharedNotificationPathname.ClubAnalyticsMyPath:
    case SharedNotificationPathname.ClubAnalyticsGoals:
    case SharedNotificationPathname.ClubAnalyticsWeakness:
    case SharedNotificationPathname.ClubAnalyticsMistakes: {
      // NOTI-171 Share Analytics pages (Compare Paths, Daily Goals, Weakness, Overall)
      return {
        tag: SharedNotificationTag.DmSharedGeneralPageClub,
      };
    }
    case SharedNotificationPathname.ClubChallengeDetails: {
      // NOTI-466 Share Playlist → Challenges page (Invited, Ongoing, Expired, etc chip)
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedChallengeClub,
        extraInfo: {
          quizId,
          quizShortId,
        },
      };
    }
    case SharedNotificationPathname.ClubPlaylistDetails: {
      // NOTI-168 Share Playlist Details page
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedPlaylistClub,
        extraInfo: {
          quizId,
          quizShortId,
        },
      };
    }
    case SharedNotificationPathname.ClubPlaylistsGroupMark: {
      // NOTI-168 Share Playlist Details page (emoji, hashtags)
      return {
        tag: SharedNotificationTag.DmSharedDynamicPlaylistClub,
        extraInfo: {
          group: router.query.group as string,
          emoji:
            router.query.group === 'emoji'
              ? (router.query.markId as string)
              : undefined,
          hashtag:
            router.query.group === 'hashtags'
              ? (router.query.markId as string)
              : undefined,
        },
      };
    }
    case SharedNotificationPathname.ClubPlaylistsGroup: {
      // NOTI-168 Share Playlist Details page (mistakes, overtime)
      return {
        tag: SharedNotificationTag.DmSharedDynamicPlaylistClub,
        extraInfo: {
          group: router.query.group as string,
        },
      };
    }
    case SharedNotificationPathname.ClubExamResult: {
      // NOTI-174 Share Inside of Mock - Results
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedResultClub,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.MockExam),
        },
      };
    }
    case SharedNotificationPathname.ClubPracticeResult: {
      // NOTI-173 Share Inside of Turbo - Results
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedResultClub,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.Practice),
        },
      };
    }

    case SharedNotificationPathname.ClubEditorQuestion: {
      // NOTI-283 Share Inside of Question Editor
      return {
        tag: SharedNotificationTag.DmSharedEditorQuestionClub,
        extraInfo: {
          creatorQuestionId: router.query.questionId as string,
        },
      };
    }
    case SharedNotificationPathname.ClubReviewQuestion:
    case SharedNotificationPathname.ClubReviewUserQuestion: {
      // NOTI-287 Share Inside of Review Questions
      // NOTI-364 Share Inside of Review Questions - Person (Reviewing user’s questions)
      return {
        tag: SharedNotificationTag.DmSharedEditorQuestionClub,
        extraInfo: {
          creatorQuestionId: router.query.questionId as string,
          ownerUserId: router.query.userId as string,
        },
      };
    }
    case SharedNotificationPathname.ClubPracticeQuestion: {
      // NOTI-290 Share Question Inside of Turbo
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedQuestionClub,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.Practice),
          questionIndex: extractQuestionNumber(router),
        },
      };
    }
    case SharedNotificationPathname.ClubExamQuestion: {
      // NOTI-292 Share Question Inside of Mock
      const { quizId, quizShortId } = extractQuizId(router);
      return {
        tag: SharedNotificationTag.DmSharedQuestionClub,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.MockExam),
          questionIndex: extractQuestionNumber(router),
        },
      };
    }
    case SharedNotificationPathname.ClubRankings:
    case SharedNotificationPathname.ClubChallengePlaylists:
    case SharedNotificationPathname.ClubHistoryPlaylists:
    case SharedNotificationPathname.ClubSavedPlaylists:
    case SharedNotificationPathname.ClubEmojiPlaylists:
    case SharedNotificationPathname.ClubFriendsPlaylists:
    case SharedNotificationPathname.ClubHashtagsPlaylists:
    case SharedNotificationPathname.ClubCreatorsPlaylists:
    case SharedNotificationPathname.ClubCreateQuestions:
    case SharedNotificationPathname.ClubReviewQuestions:
    case SharedNotificationPathname.ClubReviewAllQuestions: {
      // NOTI-300 Share Rankings page
      // NOTI-466 Share Playlists → Challenges page
      // NOTI-302 Share Playlists → History page
      // NOTI-304 Share Saved Playlists page
      // NOTI-306 Share Playlists → Reaction page
      // NOTI-330 Share Playlists → Followings’ page
      // NOTI-335 Share Playlists → Topics page
      // NOTI-339 Share Playlists → Creators’ page
      // NOTI-356 Share My Questions page
      // NOTI-360 Share Review Questions page
      // NOTI-366 Share Review Questions → All Questions
      return {
        tag: SharedNotificationTag.DmSharedGeneralPageClub,
      };
    }
    case SharedNotificationPathname.ClubReviewUserQuestions: {
      // NOTI-370 Share Review Questions → Person’s page (Ex: Questions by Ray Howard that needs to be reviewed)
      // For this notification, need to set ownerUserId as the review user
      return {
        tag: SharedNotificationTag.DmSharedGeneralPageClub,
        ownerUserId: router.query.userId as string,
      };
    }
    case SharedNotificationPathname.ProfileAccount:
    case SharedNotificationPathname.ClubGroups:
    case SharedNotificationPathname.Clubs:
    case SharedNotificationPathname.MyAgents:
    case SharedNotificationPathname.Agents: {
      // NOTI-269 Share My Profile page
      // NOTI-294 Share Discover → Club Groups page
      // NOTI-295 Share Discover → Clubs page
      // NOTI-430 My AI page
      // NOTI-296 Share Discover → AI page
      return {
        tag: SharedNotificationTag.DmSharedGeneralPageGlobal,
      };
    }
    case SharedNotificationPathname.ClubGroupDetails: {
      // NOTI-497 Share Club Group Details page
      return {
        tag: SharedNotificationTag.DmSharedClubGroupGlobal,
        extraInfo: {
          clubGroupId: router.query.groupId as string,
        },
      };
    }
    case SharedNotificationPathname.ClubDetails: {
      // NOTI-298 Share Club Details page
      return {
        tag: SharedNotificationTag.DmSharedClubGlobal,
      };
    }
    case SharedNotificationPathname.AgentDetails: {
      // NOTI-299 Share AI Details page
      return {
        tag: SharedNotificationTag.DmSharedAgentGlobal,
        extraInfo: {
          agentId: router.query.agentId as string,
        },
      };
    }
  }

  return null;
}
