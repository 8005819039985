import { useContext } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import IaDataContext from '../../core/IaData/IaDataContext';

import { GetLinkInternalDetailRes, InternalLinkType } from './types';

export default function useLinkInternalDetail<T extends InternalLinkType>(
  type: T,
  sourceId: string
) {
  const { token, apiUrl = '' } = useContext(IaDataContext);
  const key = `v2/link/internal/detail?type=${type}&sourceId=${sourceId}`;
  return useSWR<{ data: GetLinkInternalDetailRes<T> }>(
    token ? key : null,
    async () => {
      return axios
        .get(key, {
          baseURL: apiUrl,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data);
    }
  );
}
