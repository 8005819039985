import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { MemberSearchCategory } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';
import { TFunction } from 'i18next';

import { SuggestionUser, SuggestionUserIconListLayoutItemObj } from './types';

export const newUserToIconListItem = (
  t: TFunction,
  newUser: {
    email: string;
    actionText: string;
  }
): IconListLayoutItemObj => {
  return {
    id: newUser.email,
    title: newUser.email,
    avatarBlackAndWhite: true,
    actionMap: {
      click: {
        type: 'event',
        value: 'inviteToAha',
        text: t(`find.user.invite.aha.label.${newUser.actionText}`),
        actionType: 'textButton',
        disabled:
          newUser.actionText === 'accepted' || newUser.actionText === 'pending',
      },
    },
  };
};

export const suggestionUserToIconListItem = <OtherChipValue = string>(
  user: SuggestionUser,
  searchMode?: OtherChipValue | MemberSearchCategory
): SuggestionUserIconListLayoutItemObj => {
  return {
    id: user.id,
    title: user.display,
    titleAction: { type: 'event', value: 'titleClick' },
    description:
      user.memberInfo &&
      searchMode !== MemberSearchCategory.Followers &&
      searchMode !== MemberSearchCategory.Followings
        ? user.memberInfo.isFollowing
          ? `Following @${user.memberInfo.distinctName}`
          : `Not Following @${user.memberInfo.distinctName}`
        : `@${user.memberInfo?.distinctName || ''}`,
    avatarUrl: user.avatar,
    avatarIcon: user.avatarIcon,
    indicators: getIndicators(user.memberInfo?.indicator),
    actionMap: {
      select: {
        value: 'selectChanged',
      },
      hover: {
        value: 'userHovered',
      },
    },
    titleHoverAction: { value: 'titleHover' },
    titleClassName: 'floating-avatar-anchor',
    avatarHoverAction: { value: 'avatarHover' },
    avatarClassName: 'floating-avatar-anchor',
    metadata: user,
  };
};

export const suggestionSelectedUserToIconListItem = (
  user: SuggestionUser
): SuggestionUserIconListLayoutItemObj => {
  return {
    ...suggestionUserToIconListItem(user),
    description: user.memberInfo ? `@${user.memberInfo.distinctName}` : '',
  };
};
