export const enum GoalRingViewSlug {
  CalendarCenterGoalRingsDefault = 'calendar_center_goal_rings_default',
  CalendarRhsGoalRingsDefault = 'calendar_rhs_goal_rings_default',
}

export const enum GoalRingTarget {
  All = 0,
  QuestionCount = 1,
  MockRoundCount = 2,
  PracticeTime = 3,
}
