import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box } from '@mui/material';
import DiscountCodeCard from '@app/web/src/components/DiscountCodeCard';
import { TestAdd as TestAddIcon } from '@front/icon';
import {
  Accordion,
  BaseLayoutRightPanel,
  Button,
  EmptySection,
  PropertyType as LabelType,
  TextField,
  useBaseRightPanel,
} from '@front/ui';
import { apis, PromoCodeQueryType, useMyPromoCode } from '@lib/web/apis';
import { call } from '@lib/web/utils';

const styles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  addButton: {
    width: { xs: '100%', md: 'auto' },
    maxWidth: 'unset !important',
  },
  discountAccordion: {
    mt: 2,
  },
  discountCards: {
    display: 'grid',
    gap: 2.5,
    pt: 0.5,
  },
};

export default function AddDiscountPanel() {
  const { t } = useTranslation('profile');
  const inputRef = useRef<HTMLInputElement>();

  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { data: availableCodes, mutate } = useMyPromoCode(
    PromoCodeQueryType.Available
  );
  const { rightPanelOpened } = useBaseRightPanel();
  const { data: usedCodes } = useMyPromoCode(PromoCodeQueryType.UsedOrInvalid);
  const handleApply = async () => {
    setLoading(true);

    const [res, err] = await call(apis.marketplace.addPromoCode(promoCode));
    if (err) {
      if (err.code === 423) {
        setError(t('discount.The promo code was already added'));
      } else {
        setError(t('discount.The promo code was not found'));
      }
    }

    if (res) {
      setPromoCode('');
      void mutate();
    }
    setLoading(false);
  };

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setPromoCode(ev.target.value);
  };

  const handleRedirectMyPlanPage = async () => {
    await Router.push('/profile/my-plan');
  };
  useEffect(() => {
    if (rightPanelOpened) {
      inputRef.current?.focus();
    }
  }, [rightPanelOpened]);
  return (
    <BaseLayoutRightPanel
      titleIcon={<TestAddIcon width="16" height="16" />}
      title={t('discount.Add Discount Code')}
    >
      <BaseLayoutRightPanel.ScrollArea>
        <BaseLayoutRightPanel.Content>
          <TextField
            inputRef={inputRef}
            placeholder={t('discount.Enter discount code here')}
            value={promoCode}
            onChange={handleChange}
            helperText={error}
            error={!!error}
            disabled={loading}
            label={t('discount.Add Discount Code')}
            labelIcon={LabelType.ShortText}
            size="rwd"
          />
          <Button
            sx={styles.addButton}
            disabled={!promoCode}
            loading={loading}
            onClick={handleApply}
          >
            {t('discount.Add Discount Code')}
          </Button>
          <Accordion
            title={t('discount.My Discounts')}
            sx={styles.discountAccordion}
            disabled={availableCodes?.data.length === 0}
          >
            <Box sx={styles.discountCards}>
              {availableCodes?.data.map((item) => (
                <DiscountCodeCard
                  key={item.id}
                  code={item}
                  onApply={handleRedirectMyPlanPage}
                />
              ))}
            </Box>
          </Accordion>
          {availableCodes?.data.length === 0 && (
            <EmptySection sx={{ pt: 1 }} text={t('discount.empty_codes')} />
          )}
          <Accordion
            title={t('discount.Used or Expired Discounts')}
            sx={styles.discountAccordion}
            disabled={usedCodes?.data.length === 0}
          >
            <Box sx={styles.discountCards}>
              {usedCodes?.data.map((item) => (
                <DiscountCodeCard key={item.id} code={item} />
              ))}
            </Box>
          </Accordion>
          {usedCodes?.data.length === 0 && (
            <EmptySection sx={{ pt: 1 }} text={t('discount.empty_used')} />
          )}
        </BaseLayoutRightPanel.Content>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
