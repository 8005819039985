import { useCallback, useEffect, useRef, useState } from 'react';
import {
  alpha,
  Box,
  keyframes,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDimension } from '@front/helper';

import { Button, TextButton } from '../../../atoms';
import BottomSheet from '../../BottomSheet';
import { PinArrowDirection, PinChatVariant, PinDialogAlign } from '../types';

const CONTAINER_WIDTH = 375;
const BOX_GAP = 8;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const styles = {
  sheet: {
    zIndex: 'tooltip',
    '& .bottom-sheet-bg': {
      bgcolor: 'transparent',
    },
  },

  hide: {
    px: '12px',
  },
  boxWrap: {
    display: 'grid',
    gap: { md: 1 },
  },
  box: (theme: Theme) => ({
    p: { xs: 2, md: 2.5 },
    bgcolor: 'background.menu',
    [theme.breakpoints.up('md')]: {
      border:
        theme.palette.mode === 'light'
          ? '1px solid rgba(8, 8, 8, 0.05)'
          : '1px solid rgba(255, 255, 255, 0.1)',
    },
  }),
  boxMessage: {
    borderTopLeftRadius: { md: 8 },
    borderTopRightRadius: { md: 8 },
  },
  boxAction: {
    pb: '6px',
    pt: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    borderBottomLeftRadius: { md: 8 },
    borderBottomRightRadius: { md: 8 },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  cta: {
    minWidth: 'unset',
  },
  progress: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  getProgressBarStyle: (progress: number) => ({
    background: (theme: Theme) => theme.palette.gradient.primary,
    width: `${progress * 100}%`,
    height: '100%',
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    animation: `${fadeIn} 0.3s ease forwards`,
  },
};

export type PinMessageProps = {
  variant?: PinChatVariant;
  top?: number | string;
  left?: number | string;
  progress: number;
  step: number;
  totalStep: number;
  message: string;
  arrowDirection?: PinArrowDirection;
  doneText?: string;
  hideText?: string;
  nextText?: string;
  onHide?: () => void;
  onNext?: () => void;
  onFinish?: () => void;
};

const PinMessage = ({
  progress,
  step,
  message,
  totalStep,
  onFinish,
  onHide,
  onNext,
  doneText = 'Done',
  hideText = 'Hide',
  nextText = 'Next',
}: PinMessageProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.boxWrap}>
        <Box sx={[styles.box, styles.boxMessage]}>
          <Typography>{message}</Typography>
        </Box>

        <Box sx={[styles.box, styles.boxAction]}>
          <Box sx={styles.progress}>
            <Box sx={styles.getProgressBarStyle(progress)} />
          </Box>
          <Typography>{`${step} of ${totalStep}`}</Typography>

          <Box sx={styles.buttons}>
            {progress === 1 ? (
              <Button sx={styles.cta} onClick={onFinish}>
                {doneText}
              </Button>
            ) : (
              <>
                <TextButton sx={styles.hide} onClick={onHide}>
                  {hideText}
                </TextButton>
                <Button sx={styles.cta} onClick={onNext}>
                  {nextText}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function DesktopPinMessage({
  arrowRef,
  arrowDirection,
  dialogAlign = 'top',
  ...rest
}: PinMessageProps & {
  arrowDirection: PinArrowDirection;
  dialogAlign?: PinDialogAlign;
  arrowRef: React.RefObject<HTMLDivElement>;
}) {
  const boxRef = useRef<HTMLDivElement>();
  const { width, height } = useDimension(boxRef);
  const [messagePosition, setMessagePosition] = useState<
    | {
        top?: number | string;
        left?: number | string;
      }
    | undefined
  >();

  const updatePosition = useCallback(() => {
    if (!arrowRef.current || !height || !width) return;
    const { innerWidth, innerHeight } = window;
    const arrowRect = arrowRef.current.getBoundingClientRect();
    let top = dialogAlign === 'top' ? arrowRef.current.offsetTop : -height;
    let left =
      arrowRef.current.offsetLeft + arrowRef.current.offsetWidth + BOX_GAP;

    if (arrowRect.x + left + width > innerWidth) {
      left = arrowRef.current.offsetLeft - width - BOX_GAP;
    }

    if (arrowRect.y + top + height > innerHeight) {
      top -= innerHeight - arrowRect.y + top;
    }

    if (arrowRect.y + top < 0) {
      top += 0 - (arrowRect.y + top);
    }

    setMessagePosition({
      top,
      left,
    });
  }, [dialogAlign, arrowRef, height, width]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [updatePosition]);

  return (
    <Box
      ref={boxRef}
      width={CONTAINER_WIDTH}
      sx={{
        transform: messagePosition
          ? `translate(${messagePosition.left}px, ${messagePosition.top}px)`
          : undefined,
      }}
    >
      <PinMessage {...rest} />
    </Box>
  );
}
export default function PinMessageRoot({
  onRevert,
  open,
  arrowRef,
  dialogAlign,
  ...rest
}: PinMessageProps & {
  onRevert: () => void;
  open: boolean;
  arrowDirection: PinArrowDirection;
  dialogAlign?: PinDialogAlign;
  arrowRef: React.RefObject<HTMLDivElement>;
}) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown) {
    return (
      <BottomSheet
        sx={styles.sheet}
        open={open}
        fixedHeight
        bottomSpacing={0}
        onClose={onRevert}
      >
        <PinMessage {...rest} />
      </BottomSheet>
    );
  }
  return (
    <DesktopPinMessage
      arrowRef={arrowRef}
      dialogAlign={dialogAlign}
      {...rest}
    />
  );
}
