import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { ProfileMyPlan as ProfileMyPlanIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

function getVariant(tag: GetNotificationRes['tag']) {
  if (tag === 'subscription.cancel.payment.member.global.v2') {
    return 'outlined';
  }
  return 'filled';
}

type MyPlanButtonProps = {
  tag: GetNotificationRes['tag'];
};

export default function MyPlanButton({ tag }: MyPlanButtonProps) {
  const { t } = useTranslation('notification');

  return (
    <Box sx={styles.root}>
      <Button
        variant={getVariant(tag)}
        prefixIcon={<ProfileMyPlanIcon />}
        component={Link}
        href="/profile/my-plan"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {t('button.My Plan')}
      </Button>
    </Box>
  );
}
