import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Icon } from '@front/ui';

const styles = {
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.3,
  },
};

const SKELETON_COUNT = 4;

export default function WorkflowLayoutSkeleton() {
  return (
    <Stack alignItems="center">
      {[...Array(SKELETON_COUNT)].map((_, index) => (
        <Box key={index}>
          <Skeleton variant="rounded" width={300} height={98} />
          {index < SKELETON_COUNT - 1 && (
            <Box sx={styles.arrow}>
              <Icon name="ActionArrowDown" />
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
}
