import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box } from '@mui/material';
import GoalRing from '@app/web/src/components/GoalRing';
import { NumberCard } from '@front/ui';
import { GoalRingTarget } from '@lib/web/apis';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  goalRing: {
    position: 'absolute',
    right: -30,
    bottom: -30,
    opacity: 0.5,
    zIndex: -1,
  },
};

type CountValue = { current: number; ratio: number; target: number };

type GoalRingCardProps = {
  type: NotificationGoalRingTypes;
  target?: GoalRingTarget;
  mockExamRoundCount: CountValue;
  practiceTimeMinute: CountValue;
  questionCount: CountValue;
  scheduledAt: string;
  clubSlug: string;
};

export default function GoalRingCard({
  type,
  target,
  mockExamRoundCount,
  practiceTimeMinute,
  questionCount,
  clubSlug,
}: GoalRingCardProps) {
  const { t } = useTranslation('notification');

  const goalRingRender = (
    <Box sx={styles.goalRing}>
      <GoalRing
        themeMode="light"
        dataset={[
          questionCount.ratio,
          practiceTimeMinute.ratio,
          mockExamRoundCount.ratio,
        ]}
        disableDefault
      />
    </Box>
  );

  const cardProps = {
    sx: styles.card,
    title: t('Goal Ring'),
    titleIcon: 'MainSetGoal',
    disableTouchRipple: true,
    actionIcon: 'ActionArrowRightUp',
    onClick: () => Router.push(`/club/${clubSlug}/analytics/goals`),
  };

  if (type === 'cta.goal.ring.complete.one') {
    let count = 0;
    if (mockExamRoundCount.ratio >= 1) count++;
    if (practiceTimeMinute.ratio >= 1) count++;
    if (questionCount.ratio >= 1) count++;
    return (
      <NumberCard {...cardProps}>
        <NumberCard.Tooltip content={t('tooltip.goal-ring.completed-rings')} />
        <NumberCard.Value suffix=" / 3">{count}</NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );
  }

  if (
    type === 'cta.goal.ring.complete.all' ||
    (type === 'cta.goal.ring.status' && target === GoalRingTarget.All)
  )
    return (
      <NumberCard {...cardProps}>
        <NumberCard.Value suffix=" / 3">3</NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.question')
    return (
      <NumberCard {...cardProps} title={t('Questions Practiced')}>
        <NumberCard.Value suffix=" %">
          {(questionCount.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (
    type === 'cta.goal.ring.status' &&
    target === GoalRingTarget.QuestionCount
  )
    return (
      <NumberCard {...cardProps} title={t('Goal Rings')}>
        <NumberCard.Value suffix={`/ ${questionCount.target}`}>
          {questionCount.current}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.mock')
    return (
      <NumberCard {...cardProps} title={t('Mock Exam')}>
        <NumberCard.Value suffix=" %">
          {(mockExamRoundCount.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (
    type === 'cta.goal.ring.status' &&
    target === GoalRingTarget.MockRoundCount
  )
    return (
      <NumberCard {...cardProps} title={t('Goal Rings')}>
        <NumberCard.Value suffix={`/ ${mockExamRoundCount.target}`}>
          {mockExamRoundCount.current}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.time')
    return (
      <NumberCard {...cardProps} title={t('Time Practiced')}>
        <NumberCard.Value suffix=" %">
          {(practiceTimeMinute.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.status' && target === GoalRingTarget.PracticeTime)
    return (
      <NumberCard {...cardProps} title={t('Goal Rings')}>
        <NumberCard.Value suffix={`/ ${practiceTimeMinute.target}`}>
          {practiceTimeMinute.current}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );
  return null;
}
