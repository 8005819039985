import { Indicator } from '@front/helper';

export const enum InternalLinkType {
  User = 1,
  Club = 2,
  ClubGroup = 3,
  Agent = 4,
}

export const enum ClubOfficialType {
  Official = 1,
  NonOfficial = -1,
}

export const enum ClubGroupOfficialType {
  Official = 1,
  NonOfficial = -1,
}

type ClubCreator = {
  id: string;
  name: string;
  distinctName: string;
  avatar: string;
  sourceId: string;
};

type UserBriefData = {
  id: string;
  name: string;
  distinctName: string;
  avatar: string;
  nftAvatar: string;
  indicator: Indicator;
};

type ClubBrief = {
  id: string;
  officialType: ClubOfficialType;
  name: string;
  slug: string;
  logo: string;
};

export type LinkInternalAgentDetail = {
  id: string;
  name: string;
  userName: string;
  userCount: number;
  club: ClubBrief;
  creator: UserBriefData;
};

export type LinkInternalClubDetail = {
  id: string;
  officialType: ClubOfficialType;
  name: string;
  slug: string;
  logo: string;
  creator: ClubCreator;
  memberCount: number;
  onlineMemberCount: number;
  questionCount: number;
  createdAt: string;
};

export type LinkInternalClubGroupDetail = {
  id: string;
  officialType: ClubGroupOfficialType;
  name: string;
  slug: string;
  logo: string;
  creator: ClubCreator;
  memberCount: number;
  onlineMemberCount: number;
  questionCount: number;
  createdAt: string;
  clubs: ClubBrief[];
};

const enum UserOnlineStatus {
  AWAY = -1,
  ONLINE = 1,
  BUSY = 2,
}

export type LinkInternalUserDetail = {
  id: string;
  memberId: string;
  email: string;
  highSchool: string;
  userName: string;
  fullName: string;
  numJoinedClubs: number;
  numJoinedUnlistedClubs: number;
  numFollowers: number;
  numFollowings: number;
  isMe: boolean;
  isFollower: boolean;
  isFollowing: boolean;
  country: string;
  city: string;
  highSchoolGraduationYear: string;
  dreamSchoolId: string;
  dreamSchoolName: string;
  photoUrl: string;
  avatarUrl: string;
  indicator: Indicator;
  onlineStatus: UserOnlineStatus;
};

export type GetLinkInternalDetailRes<T extends InternalLinkType> =
  T extends InternalLinkType.Agent
    ? { agent: LinkInternalAgentDetail | null }
    : T extends InternalLinkType.Club
    ? { club: LinkInternalClubDetail | null }
    : T extends InternalLinkType.ClubGroup
    ? { clubGroup: LinkInternalClubGroupDetail | null }
    : T extends InternalLinkType.User
    ? { user: LinkInternalUserDetail | null }
    : never;
