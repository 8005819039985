import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import { Button, SquareAvatar } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type ViewProfileButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.view.user.profile' }>;
};

export default function ViewProfileButton({ cta }: ViewProfileButtonProps) {
  const { t } = useTranslation('notification');
  const { openProfile } = useOpenGlobalProfilePanel();

  return (
    <Box sx={styles.root}>
      <Button
        prefixIcon={
          <SquareAvatar size={16} src={cta.userAvatar}>
            {cta.userName}
          </SquareAvatar>
        }
        onMouseDown={(e) => e.stopPropagation()}
        onClick={() => openProfile(cta.userId)}
      >
        {t('view.profile.View Profile', 'Start Practice')}
      </Button>
    </Box>
  );
}
