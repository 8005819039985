import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  Box,
  ButtonBase,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  FollowButton,
  SendButton,
} from '@app/web/src/components/FriendButtons';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import useUserAvatarOnlineStatus from '@app/web/src/hooks/utils/useUserAvatarOnlineStatus';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  compactNumberFormat,
  Icon,
  SquareAvatarStatus,
  useBaseRightPanel,
  UserAvatar,
} from '@front/ui';
import { LeagueTier, LeagueZone } from '@lib/web/apis';
import { getIndicators, getLeagueBadgeIconName } from '@lib/web/utils';

const getRank = (rank?: number) => {
  if (rank === undefined) return '-';
  if (rank > 999) return '+999';
  return rank;
};
const getScore = (score?: number) => {
  if (score === undefined) return '-';
  if (score > 99999) {
    return compactNumberFormat(99999);
  }
  return compactNumberFormat(score);
};
const getStreak = (streak?: number) => {
  if (streak === undefined) return '-';
  if (streak > 999) return '+999';
  return streak;
};

const getZoneStyle = (zone?: LeagueZone) => {
  switch (zone) {
    case LeagueZone.Promotion:
      return { borderColor: 'success.light' };
    case LeagueZone.Stay:
      return { borderColor: 'text.primary' };
    case LeagueZone.Demotion:
      return { borderColor: 'error.dark' };
    default:
      return { borderColor: 'alpha.lightA64' };
  }
};

const getBgcolor = (zone?: LeagueZone) => {
  switch (zone) {
    case LeagueZone.Promotion:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.success.dark, 0.3),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.success.dark, 0.5),
          },
        },
      };
    case LeagueZone.Demotion:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.5),
          },
        },
      };
    default:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
          },
        },
      };
  }
};

const getRankStyle = (rank?: number) => {
  if (rank !== undefined && rank < 1000)
    return {
      fontSize: 20,
    };

  return {
    fontSize: 16,
  };
};

const styles = {
  root: {
    display: 'grid',
    pl: {
      xs: '16px',
      md: 1,
    },
    pr: {
      xs: '20px',
      md: 1.5,
    },
    height: 61,
    width: '100%',
    textAlign: 'left',
    borderLeft: '4px solid',
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    width: 'auto',
    '& .MuiTypography-h6': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      lineHeight: 1.5,
    },
  },

  me: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 1,
    p: 1,
    ml: 1,
    height: 26,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },

  rank: {
    typography: 'body1',
    fontWeight: 700,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rankHashtag: {
    fontSize: 12,
    opacity: 0.5,
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 400,
    fontSize: 20,
    opacity: 0.64,
  },
  streak: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 400,
    fontSize: 20,
    opacity: 0.64,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ctaButton: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },

  skeleton: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    height: '45px',
    px: '12px',
  },
  skeletonContent: {
    display: 'grid',
    alignItems: 'center',
  },
  skeletonInfo: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 1,
  },
};

type CtaProps = {
  userId: string;
  memberId: string;
  isFollowing: boolean;
  isFollower: boolean;
  isMe?: boolean;
};

function Cta({ userId, memberId, isFollowing, isFollower, isMe }: CtaProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const buttonType = mdUp ? 'textButton' : 'iconButton';
  return (
    <Box sx={styles.action}>
      {!isMe && (
        <>
          {isFollowing && (
            <SendButton
              userId={userId}
              memberId={memberId}
              size="sm"
              messageDisabled
              buttonType={buttonType}
            />
          )}
          {!isFollowing && (
            <FollowButton
              userId={userId}
              isFollowing={isFollowing}
              isFollower={isFollower}
              size="sm"
              buttonType={buttonType}
            />
          )}
        </>
      )}
    </Box>
  );
}

function RankingItemSkeleton() {
  return (
    <Box sx={styles.skeleton}>
      <Skeleton variant="circular" width={40} height={40} />
      <Box sx={styles.skeletonContent}>
        <Skeleton width={90} height={20} />
        <Skeleton width={120} height={15} />
      </Box>
      <Box sx={styles.skeletonInfo}>
        <Skeleton width={50} height={25} />
      </Box>
    </Box>
  );
}

function Spacing() {
  return <Box />;
}

export type RankingItemProps = {
  data: {
    leagueTier?: LeagueTier;
    rank?: number;
    score?: number;
    longestStreaks?: number;
    zone?: LeagueZone;
  };
  userInfo: {
    userId: string;
    memberId: string;
    displayName: string;
    distinctName: string;
    avatarUrl: string;
    indicator?: BaseMemberInfo['indicator'];
    isFollowing: boolean;
    isFollower: boolean;
  };
  settings: {
    hideLeague?: boolean;
    gridTemplateColumns: string;
  };
};

export default function RankingItem({
  data,
  userInfo,
  settings,
}: RankingItemProps) {
  const { t } = useTranslation();

  const currentUserId = useCurrentUserId();
  const isMe = currentUserId === userInfo.userId;
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { showUserIdProfile } = useFloatingProfile();
  const getAvatarOnlyStatus = useUserAvatarOnlineStatus();

  const handleClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: userInfo.userId,
    });
  };

  const handleMouseEnter = (ev: MouseEvent) => {
    showUserIdProfile({
      userId: userInfo.userId,
      anchorEl: ev.currentTarget,
      options: {
        spacing: 8,
      },
    });
  };

  return (
    <ButtonBase
      sx={[
        styles.root,
        { gridTemplateColumns: settings.gridTemplateColumns },
        getZoneStyle(data.zone),
        isMe && getBgcolor(data.zone),
      ]}
      onClick={handleClick}
    >
      <Box sx={styles.avatarWrapper}>
        <UserAvatar
          sx={styles.avatar}
          src={userInfo.avatarUrl}
          title={userInfo.displayName || userInfo.distinctName}
          titleSuffix={
            isMe && (
              <Typography sx={styles.me} variant="body1">
                Me
              </Typography>
            )
          }
          subTitle={`@${userInfo.distinctName}`}
          size="md"
          indicators={getIndicators(userInfo.indicator)}
          className="floating-avatar-anchor"
          titleClassName="floating-avatar-anchor"
          onMouseEnter={handleMouseEnter}
          onTitleMouseEnter={handleMouseEnter}
          showIndicator
          status={getAvatarOnlyStatus(userInfo.userId)}
          statusInfo={{
            [SquareAvatarStatus.Busy]: t('user.status.busy'),
          }}
        />
      </Box>
      {!settings?.hideLeague && (
        <>
          <Spacing />
          <Box>
            <Icon name={getLeagueBadgeIconName(data.leagueTier)} size={24} />
          </Box>
        </>
      )}
      <Spacing />
      <Box sx={[styles.rank, getRankStyle(data.rank)]}>
        <Typography sx={styles.rankHashtag}>#</Typography>
        {getRank(data.rank)}
      </Box>
      <Spacing />
      <Typography variant="h6" sx={styles.score}>
        {getScore(data.score)}
      </Typography>
      <Spacing />
      <Typography variant="h6" sx={styles.streak}>
        {getStreak(data.longestStreaks)}
      </Typography>

      <Spacing />
      <Cta
        userId={userInfo.userId}
        memberId={userInfo.memberId}
        isFollower={userInfo.isFollower}
        isFollowing={userInfo.isFollowing}
        isMe={isMe}
      />
    </ButtonBase>
  );
}

RankingItem.Skeleton = RankingItemSkeleton;
