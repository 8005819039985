import { useEffect, useRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';

const styles = {
  root: {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export default function HorizontalTextAnimation({
  sx,
  children,
  singleTextDuration = 20,
}: {
  children: string;
  sx?: BoxProps['sx'];
  singleTextDuration?: number;
}) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const scrollRef = useRef<HTMLDivElement>(null);

  const [{ scrollLeft }, set] = useSpring(() => ({ scrollLeft: 0 }));

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    if (scrollTarget) {
      const scrollWidth = scrollTarget.scrollWidth - scrollTarget.clientWidth;
      set({
        scrollLeft: scrollWidth,
        config: { duration: children.length * singleTextDuration },
      });
    }
  }, [children.length, singleTextDuration, set]);

  return (
    <Box ref={scrollRef} sx={[styles.root, ...sxProps]}>
      <animated.div
        style={{
          transform: scrollLeft.to((value) => `translateX(-${value}px)`),
        }}
      >
        {children}
      </animated.div>
    </Box>
  );
}
