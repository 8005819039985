import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import AuthGuard from '@app/web/src/components/AuthGuard';
import useMainLayout from '@app/web/src/layouts/MainLayout/hooks/useMainLayout';
import { TestAdd as TestAddIcon } from '@front/icon';
import { Icon, InfoTooltip, useBaseLeftPanel } from '@front/ui';
import { apis, ThreadViewType } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { hashDmViewReferenceId } from '@lib/web/thread/hooks/view/utils';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { MenuComps } from '@lib/web/ui';
import { call } from '@lib/web/utils';
import { Chat } from 'stream-chat-react';

import DmMenuThreadView from './components/Thread/DmMenuThreadView';

const MISSING_VIEW_CHECK_LIST = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

const styles = {
  menuWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainMenu: {
    flex: 1,
    '& .menu-content': {
      pt: 1,
    },
  },
  title: {
    '& span': {
      fontSize: 14,
    },
  },
  groupTitle: {
    fontSize: 12,
  },
};

function DirectMessagesMenuContent() {
  const { t } = useTranslation('thread');

  const scrollRef = useRef<HTMLDivElement>(null);
  const { views, suggestionViews, reloadViews } = useDirectMessageViews();
  const { enableLeftPanel, openLeftPanel } = useBaseLeftPanel();
  const { seeNewDirectMessage } = useMainLayout();

  const { unreadChannels } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { getThreadViewDetail } = useThreadViewDetails();

  const handleNewDmClick = () => {
    seeNewDirectMessage();
    enableLeftPanel();
    openLeftPanel();
  };

  useEffect(() => {
    const maybeUpdateMissingDmViews = async () => {
      // public channel doesn't have dm views, so we can ignore them
      const filteredUnreadChannels = unreadChannels.filter(
        (channel) => channel.type !== 'public'
      );

      // Check if there are any missing views for this unread channel
      const updateDmPromises = filteredUnreadChannels.map((unreadChannel) => {
        const { channelMemberIds } = getChannelInformation(unreadChannel);
        const unreadChannelReferenceId =
          hashDmViewReferenceId(channelMemberIds);
        const haveMissingViews = !views
          .filter(
            (view) =>
              MISSING_VIEW_CHECK_LIST.includes(view.type) && view.referenceId
          )
          .some((view) => view.referenceId === unreadChannelReferenceId);
        // If there are missing views, create new view with the channel member IDs
        if (haveMissingViews) {
          return call(
            apis.thread.maybeUpdateDmThreadView({
              memberIdsBefore: [],
              memberIdsAfter: channelMemberIds,
            })
          );
        }
      });

      const validUpdatePromises = updateDmPromises.filter(Boolean);

      if (validUpdatePromises.length > 0) {
        await Promise.all(updateDmPromises);
        await reloadViews();
      }
    };

    void maybeUpdateMissingDmViews();
  }, [getChannelInformation, reloadViews, unreadChannels, views]);

  // TODO: Because for now we have not implemented other menus in PRF-3095, so I keep the everyone DM menu -> remove this later
  const dmEveryoneViews = views.filter(
    (view) => view.type === ThreadViewType.DmEveryone
  );

  const dmOneOnOneViews = views.filter(
    (view) => view.type === ThreadViewType.DmOneOnOne
  );
  const dmGroupViews = views
    .filter((view) => view.type === ThreadViewType.DmGroup)
    .sort((a, b) => {
      if (a.name && b.name) return a.name.localeCompare(b.name);
      const { viewName: aViewName } = getThreadViewDetail(a);
      const { viewName: bViewName } = getThreadViewDetail(b);
      return aViewName.localeCompare(bViewName);
    });

  return (
    <Box sx={styles.menuWrapper}>
      <MenuComps
        title={t('main.chat.title')}
        scrollProps={{
          showShadow: true,
          shadowPosition: 'top',
          scrollableNodeProps: {
            ref: scrollRef,
          },
        }}
        titleToolTip={{
          title: t('menu.chat.tooltip.title', 'Chat'),
          content: t(
            'menu.chat.tooltip.content',
            'Start exploring discussions, hot questions, share your ideas, and send direct messages to friends, all in one place with Chat.'
          ),
          icon: 'ThreadsDirectMessages',
          followCursor: true,
        }}
        sx={styles.title}
      >
        <MenuComps.Section>
          {dmEveryoneViews.map((view) => (
            <DmMenuThreadView key={view.id} view={view} />
          ))}
        </MenuComps.Section>
        <MenuComps.Section>
          <MenuComps.Group
            title={
              <InfoTooltip
                title={t('main.directMessage.tooltip.title', 'Direct Message')}
                content={t(
                  'main.directMessage.tooltip.content',
                  'Direct Message allow you to have private conversations with a friend.'
                )}
                titleIcon={
                  <Icon name="ThreadsDirectMessages" width={16} height={16} />
                }
                followCursor
              >
                <span>{t('main.directMessage.title')}</span>
              </InfoTooltip>
            }
            defaultOpen
            titleSx={styles.groupTitle}
            level={-1}
          >
            <AuthGuard>
              <MenuComps.Button
                icon={<TestAddIcon />}
                onClick={handleNewDmClick}
              >
                {t('main.directMessages_newThread.title')}
              </MenuComps.Button>
            </AuthGuard>
            {dmOneOnOneViews.map((view) => (
              <DmMenuThreadView key={view.id} view={view} />
            ))}
          </MenuComps.Group>
        </MenuComps.Section>
        {dmGroupViews.length > 0 && (
          <MenuComps.Section>
            <MenuComps.Group
              title={
                <InfoTooltip
                  title={t('main.groupChat.tooltip.title', 'Group Chat')}
                  content={t(
                    'main.groupChat.tooltip.content',
                    'Group chat allow you to have private conversations with a group of friends.'
                  )}
                  titleIcon={
                    <Icon name="ThreadsDirectMessages" width={16} height={16} />
                  }
                  followCursor
                >
                  <span>{t('main.groupChat.title')}</span>
                </InfoTooltip>
              }
              defaultOpen
              titleSx={styles.groupTitle}
              level={-1}
            >
              {dmGroupViews.map((view) => (
                <DmMenuThreadView key={view.id} view={view} />
              ))}
            </MenuComps.Group>
          </MenuComps.Section>
        )}
        {suggestionViews.length > 0 && (
          <MenuComps.Section>
            <MenuComps.SubTitle title={t('menu.Suggestions')} />
            {suggestionViews.map((view) => (
              <DmMenuThreadView key={view.id} view={view} />
            ))}
          </MenuComps.Section>
        )}
      </MenuComps>
    </Box>
  );
}

export default function DirectMessagesMenu() {
  const { chatClient } = useThread();
  const { query } = useRouter();
  const viewId = query.viewId as string;

  if (!chatClient) return null;

  return (
    <Chat client={chatClient}>
      <DirectMessagesMenuContent key={viewId} />
    </Chat>
  );
}
