import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Popper, Theme } from '@mui/material';
import { OtherEdit as OtherEditIcon } from '@front/icon';
import { ResponsiveTooltip, ResponsiveTooltipProps } from '@front/ui';

import HorizontalTextAnimation from '../../atoms/HorizontalTextAnimation';

import FloatingButtons, { FloatingButton } from './components/FloatingButtons';

const styles = {
  root: {
    position: 'relative',
    minWidth: 0,
    typography: 'appH1',
    fontWeight: 700,
  },
  nameContainer: {
    px: '4px',
    py: '2px',
    height: 42,
    overflow: 'hidden',
  },
  name: {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  h1: (theme: Theme) => ({
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    ...theme.typography.appH1,
    fontFamily: theme.typography.fontFamily,
  }),
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  input: {
    px: '4px',
    py: '2px',
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
    height: 43,
    outline: 'none',
    borderBottom: '1px solid transparent',
  },
  titleContent: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    borderBottom: '1px solid transparent',
    width: 'min-content',
  },
  titleContentHovered: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
  },
  tooltipNote: {
    opacity: 0.64,
    display: 'block',
  },
  popper: {
    zIndex: 10,
    '& .popper-content': {
      px: '2px',
      py: '1.5px',
      minWidth: 0,
    },
  },
  floatingButtonsWrap: {
    display: 'flex',
    alignItems: 'center',
    bgcolor: 'background.menu',
    borderRadius: 1,
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${
        theme.palette.mode === 'light'
          ? 'rgba(8, 8, 8, 0.05)'
          : 'rgba(255, 255, 255, 0.1)'
      }`,
  },
};

export type NameInputProps = {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  prefix?: React.ReactNode;
  customFloatingButtons?: FloatingButton[];
  tooltipTitle?: string;
  tooltipContent?: React.ReactNode;
  tooltipProps?: ResponsiveTooltipProps['tooltipProps'];
  keepHovered?: boolean;
};

export function NameInput({
  value,
  onChange,
  disabled = false,
  placeholder = 'Untitled',
  prefix,
  customFloatingButtons,
  tooltipTitle,
  tooltipContent,
  tooltipProps,
  keepHovered = false,
}: NameInputProps) {
  const { t } = useTranslation();
  const [rawHovered, setRawHovered] = useState(false);
  const hovered = keepHovered || rawHovered;
  const [editable, setEditable] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editable) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [editable]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(ev.target.value);
  };

  const handleBlur = () => {
    setEditable(false);
    setRawHovered(false);
  };

  const floatingButtons = useMemo(() => {
    if (!customFloatingButtons) {
      return [
        {
          icon: <OtherEditIcon />,
          tooltip: {
            content: t('click to rename'),
          },
          onClick: () => setEditable(true),
        },
      ];
    }

    return customFloatingButtons.map((button) => ({
      ...button,
      onClick: button.onClick || (() => setEditable(true)),
    }));
  }, [customFloatingButtons, t]);

  if (editable) {
    return (
      <Box sx={styles.inputContainer}>
        {prefix}
        <Box
          ref={inputRef}
          component="input"
          sx={[styles.h1, styles.input]}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>
    );
  }

  const titleContent = (
    <Box sx={[styles.titleContent, hovered && styles.titleContentHovered]}>
      {prefix}
      <Box sx={styles.nameContainer}>
        {hovered ? (
          <HorizontalTextAnimation sx={styles.name}>
            {value || placeholder}
          </HorizontalTextAnimation>
        ) : (
          <Box sx={styles.name}>{value || placeholder}</Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      ref={anchorRef}
      sx={styles.root}
      onMouseEnter={() => !disabled && setRawHovered(true)}
      onMouseLeave={() => !disabled && setRawHovered(false)}
      onClick={() => !disabled && setEditable(true)}
    >
      {!disabled && (
        <Popper
          open={hovered}
          anchorEl={anchorRef.current}
          placement="left"
          sx={styles.popper}
        >
          <Box sx={{ pr: 1 }}>
            <FloatingButtons buttons={floatingButtons} />
          </Box>
        </Popper>
      )}
      {tooltipTitle || tooltipContent ? (
        <ResponsiveTooltip
          title={tooltipTitle}
          content={tooltipContent}
          tooltipProps={{
            followCursor: true,
            ...tooltipProps,
          }}
        >
          {titleContent}
        </ResponsiveTooltip>
      ) : (
        titleContent
      )}
    </Box>
  );
}

export default NameInput;
