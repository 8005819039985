import React from 'react';
import { useTranslation } from 'react-i18next';
import { OtherShareToDirect as OtherShareToDirectIcon } from '@front/icon';
import { Button, Icon } from '@front/ui';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { apis } from '@lib/web/apis';
import { callWithToast, emailRegex } from '@lib/web/utils';

import SearchUserPanel from '../SearchUserPanel';

export default function ShareDiscountPanel() {
  const { t } = useTranslation('profile');

  const handleSend = async (selected: IconListLayoutItemObj[]) => {
    const userIds: string[] = [];
    const emails: string[] = [];

    selected.forEach((user) => {
      if (emailRegex.test(user.id)) {
        emails.push(user.id);
      } else if (user.metadata?.userId) userIds.push(user.metadata.userId);
      else if (user.metadata?.email) emails.push(user.metadata.email);
    });

    await callWithToast(() => apis.user.sharePromoCode({ userIds, emails }));
  };

  return (
    <SearchUserPanel
      title={t('discount.Share Discount Code')}
      titleIcon={<OtherShareToDirectIcon width={16} height={16} />}
      buttonTestId="share-discount"
      tip={t('discount.You can share your discount code')}
      ignoreNewUserInvite
      renderAction={({ selectedCount, sending, onActionClick }) => (
        <Button
          color="earn"
          prefixIcon={<Icon name="OtherShareToDirect" />}
          onClick={onActionClick}
          loading={sending}
          disabled={!selectedCount}
        >
          {t('button.Share ##', { count: selectedCount })}
        </Button>
      )}
      onActionClick={handleSend}
    />
  );
}
