import { useMemo } from 'react';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { IaHoverEvent } from '@lib/ia/src/core/types';
import { basicBlockGenerator, ComposerBlock } from '@lib/web/composer';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import useThreadSendMessage from '@lib/web/thread/ThreadChat/hooks/useThreadSendMessage';
import { StreamChatGenerics, ThreadUser } from '@lib/web/thread/types';
import { callWithToast } from '@lib/web/utils';
import { Channel } from 'stream-chat';

export const useThreadViewChannelAvailableAction = ({
  threadMemberIds,
}: {
  threadMemberIds: string[];
}) => {
  const { openRightPanel, getRightParams, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();
  const { showUserIdProfile } = useFloatingProfile();
  const { chatClient } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const sendMessage = useThreadSendMessage();

  return useMemo(
    () => ({
      viewThread: {
        action: (id: string) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      openThread: {
        action: (id: string) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      openUserOrAgentProfile: {
        action: ({ user }: { user?: ThreadUser }): void => {
          if (user?.type === 'agent') {
            openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
              agentId: user.agentId ?? '',
            });
          }
          if (user?.type === 'user') {
            openRightPanel(GlobalPanelKeys.GlobalProfile, {
              userId: user.userId,
            });
          }
        },
      },
      viewSenderProfile: {
        action: (event: IaHoverEvent<{ user?: ThreadUser }>): void => {
          if (!event.target || event.target.user?.type !== 'user') return;
          showUserIdProfile({
            anchorEl: event.anchorEl,
            userId: event.target.user.userId || '',
          });
        },
      },
      editMessage: {
        action: (_cid: string, { handler }: { handler: () => void }) => {
          handler();
        },
      },
      editModeMessageContentOnBlur: {
        action: ({ handler }: { handler: () => void }): void => {
          handler();
        },
      },
      editModeMessageContentOnKeyDown: {
        action: (
          {
            handler,
          }: {
            handler: (
              e: KeyboardEvent,
              text: string,
              changedBlocks: ComposerBlock[]
            ) => void;
          },
          event: KeyboardEvent,
          text: string,
          changedBlocks: ComposerBlock[]
        ): void => {
          handler(event, text, changedBlocks);
        },
      },
      deleteMessage: {
        action: (
          _cid: string,
          { channel }: { channel: Channel<StreamChatGenerics> }
        ) => {
          if (chatClient) {
            const { replies } = getChannelInformation(channel);
            if (replies === 0) {
              void callWithToast(channel.delete());
            } else if (channel.data?.firstMessageId) {
              void callWithToast(
                chatClient.deleteMessage(channel.data.firstMessageId)
              );
            }
          }
        },
      },
      sharedChipClick: {
        action: ({
          text,
          channel,
        }: {
          text: string;
          channel: Channel<StreamChatGenerics>;
        }) => {
          sendMessage({
            text,
            channel,
            blocks: [basicBlockGenerator.textBlock(text)],
            threadMemberIds,
          });
        },
      },
    }),
    [
      chatClient,
      getChannelInformation,
      getRightParams,
      isTargetPanelOpened,
      openRightPanel,
      sendMessage,
      showUserIdProfile,
      threadMemberIds,
    ]
  );
};
