import { useCallback, useMemo } from 'react';
import {
  buildInfiniteHookMutate,
  ClubGroupJoinedStatus,
  ClubGroupViewSlug,
  ClubJoinedStatus,
  ClubViewSlug,
  useIaClubGroups,
  useIaClubsList,
} from '@lib/web/apis';

import { clearSpecificViewedSlugs } from '../../actions/layoutActions';
import { useAppDispatch } from '../redux';

export default function useAllJoinedClubs() {
  const dispatch = useAppDispatch();
  const joinedClubs = useIaClubsList(
    {
      viewSlug: ClubViewSlug.CardCenterDefault,
      search: `joinStatus:${ClubJoinedStatus.Joined}`,
      searchFields: 'joinStatus:eq',
      limit: 0,
    },
    { auth: true }
  );

  const joinedGroups = useIaClubGroups(
    {
      viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
      search: `joinedStatus:${ClubGroupJoinedStatus.Joined};expandClubLimit:100`,
      searchFields: 'joinedStatus:eq',
    },
    { auth: true }
  );

  const joinedSlugs = useMemo(() => {
    const slugs = joinedClubs.dataset.map((club) => club.clubSlug);

    joinedGroups.dataset.forEach((group) => {
      group.clubs.forEach((club) => {
        if (
          !slugs.includes(club.clubSlug) &&
          club.joinStatus === ClubJoinedStatus.Joined
        )
          slugs.push(club.clubSlug);
      });
    });

    return slugs;
  }, [joinedClubs.dataset, joinedGroups.dataset]);

  const reloadClubs = useMemo(
    () => buildInfiniteHookMutate(joinedClubs.mutate),
    [joinedClubs.mutate]
  );

  const reloadGroups = useMemo(
    () => buildInfiniteHookMutate(joinedGroups.mutate),
    [joinedGroups.mutate]
  );

  const mutate = useCallback(async () => {
    await Promise.all([joinedClubs.mutate(), joinedGroups.mutate()]);
  }, [joinedClubs, joinedGroups]);

  const clearSpecificClubs = useCallback(
    (slugs: string[], promise: any) => {
      const filteredClubs = joinedClubs.dataset.filter(
        (club) => !slugs.includes(club.clubSlug)
      );
      dispatch(clearSpecificViewedSlugs(slugs));
      return reloadClubs(promise, {
        optimisticData: filteredClubs,
        optimisticPageData: {
          totalCount: filteredClubs.length - 1,
        },
        revalidate: false,
      });
    },
    [dispatch, joinedClubs.dataset, reloadClubs]
  );

  const clearSpecificGroup = useCallback(
    (
      { id, isLeaveAllClubs }: { id: string; isLeaveAllClubs?: boolean },
      promise: any
    ) => {
      const filteredGroups = joinedGroups.dataset.filter(
        (group) => group.id !== id
      );

      if (isLeaveAllClubs) {
        const slugs =
          joinedGroups.dataset
            .find((group) => group.id === id)
            ?.clubs.map((club) => club.clubSlug) || [];
        if (slugs.length > 0) {
          return reloadGroups(clearSpecificClubs(slugs, promise), {
            optimisticData: filteredGroups,
            optimisticPageData: {
              totalCount: filteredGroups.length,
            },
            revalidate: false,
          });
        }
      }
      return reloadGroups(promise, {
        optimisticData: filteredGroups,
        optimisticPageData: {
          totalCount: filteredGroups.length,
        },
        revalidate: false,
      });
    },
    [joinedGroups.dataset, reloadGroups, clearSpecificClubs]
  );

  return {
    joinedClubs,
    joinedGroups,
    joinedSlugs,
    mutate,

    clearSpecificClubs,
    clearSpecificGroup,
  };
}
