import { alpha, Box, Theme, Typography } from '@mui/material';

const styles = {
  emojiWrapper: {
    display: 'flow-root',
    position: 'relative',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid transparent',
      background:
        'linear-gradient(135deg, rgba(255,255,255, 0.35) 0%, rgba(255,255,255,0.05) 100%) border-box',
      WebkitMask:
        'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
    },
  },
  emoji: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const getSize = (size: number | string) => {
  return {
    width: size,
    minWidth: size,
    height: size,
  };
};

const getBorderRadius = (borderRadius: number | string) => {
  return {
    borderRadius: borderRadius,
    '&:before': {
      borderRadius: borderRadius,
    },
  };
};

export type ProductEmojiProps = {
  emoji?: string;
  size?: number | string;
  borderRadius?: number | string;
  fontSize?: number | string;
};

export default function ProductEmoji({
  emoji,
  size = 30,
  borderRadius = 4,
  fontSize = 16,
}: ProductEmojiProps) {
  if (!emoji) return null;

  return (
    <Box
      sx={[styles.emojiWrapper, getSize(size), getBorderRadius(borderRadius)]}
    >
      <Typography
        variant="subtitle1"
        sx={[styles.emoji, getSize(size), { fontSize }]}
      >
        {emoji}
      </Typography>
    </Box>
  );
}
