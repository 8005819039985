import { getClubSlug } from '@app/web/src/widgets/NotificationPage/utils/dataFormat';
import { IaAction } from '@lib/ia/src/core/types';

export const getClickAction = (
  item: GetNotificationRes
): (IaAction & { metadata?: Record<string, string> }) | undefined => {
  const clubSlug = getClubSlug(item);
  switch (item.tag) {
    case 'challenge.good.result.member.club':
    case 'challenge.ending.scheduler.winner.club.v2': {
      const body = item.data.payload.body;
      let quizShortId;
      if ('quizShortId' in body) {
        quizShortId = body.quizShortId;
      }
      return {
        type: 'event',
        value: 'goToPlaylistDetails',
        metadata: {
          quizId: body.quizId,
          quizShortId: quizShortId || '',
          clubSlug: body.clubSlug,
        },
      };
    }
    case 'challenge.good.result.member.club.v2':
    case 'challenge.almost.end.scheduler.challenger.club.v2':
    case 'challenge.ending.scheduler.challenger.club.v2':
    case 'challenge.acceptance.addressee.addressee.club.v2': {
      const cta = item.data.payload.cta;
      if (!cta || cta.length === 0) return undefined;
      const challengeDetailCta = cta.find(
        (c) => c.type === 'cta.challenge.detail'
      );
      if (!challengeDetailCta) return undefined;
      const challengeDetail = challengeDetailCta as Extract<
        NotificationCtaType,
        { type: 'cta.challenge.detail' }
      >;
      return {
        type: 'event',
        value: 'goToPlaylistDetails',
        metadata: {
          quizId: challengeDetail.quizId,
          quizShortId: challengeDetail.quizShortId,
          clubSlug: clubSlug || '',
        },
      };
    }
    case 'league.round.finish.promotion.tier.club':
    case 'league.round.finish.promotion.tier.club.v2':
    case 'league.round.finish.back.to.promotion.tier.club':
    case 'league.round.finish.back.to.promotion.tier.club.v2':
    case 'league.round.finish.demotion.tier.club':
    case 'league.round.finish.demotion.tier.club.v2':
    case 'league.enter.demotion.zone.club':
    case 'league.enter.demotion.zone.club.v2':
    case 'league.enter.first.time.club':
    case 'league.enter.first.time.club.v2':
    case 'league.round.finish.highest.rank.max.tier.club':
    case 'league.round.finish.highest.rank.max.tier.club.v2':
    case 'league.round.finish.highest.tier.club':
    case 'league.round.finish.highest.tier.club.v2':
    case 'league.round.finish.stay.tier.club':
    case 'league.round.finish.stay.tier.club.v2':
    case 'league.enter.promotion.zone.club':
    case 'league.enter.promotion.zone.club.v2': {
      return {
        type: 'event',
        value: 'goToRankings',
        metadata: {
          clubSlug: clubSlug || '',
        },
      };
    }
    case 'goal.ring.completed.scheduler.practicer.club':
    case 'goal.ring.achieved.question.count.club.v2':
    case 'goal.ring.achieved.practice.time.club.v2':
    case 'goal.ring.achieved.mock.count.club.v2':
    case 'goal.ring.completed.scheduler.practicer.club.v2':
    case 'club.long.time.no.practice.reminder.3days.club':
    case 'streak.achieved.single.day.member.club':
    case 'streak.achieved.single.day.member.club.v2':
    case 'streak.reminder.daily.member.club':
    case 'streak.reminder.daily.member.club.v2':
    case 'streak.reminder.broken.member.club':
    case 'streak.reminder.broken.member.club.v2':
    case 'streak.achieved.multiple.day.member.club':
    case 'streak.achieved.multiple.day.member.club.v2': {
      return {
        type: 'event',
        value: 'goToStartPractice',
        metadata: {
          clubSlug: clubSlug || '',
        },
      };
    }
    case 'club.joined.welcome.info.club':
    case 'club.joined.welcome.greeting.club':
    case 'club.new.join.request.reminder.club':
    case 'club.acceptance.addressee.addressee.club': {
      return {
        type: 'event',
        value: 'goToClubSummary',
        metadata: {
          clubSlug: clubSlug || '',
        },
      };
    }
    case 'club.deleted.global':
    case 'club.member.left.global':
    case 'email.verification.success.global': {
      return {
        type: 'event',
        value: 'goToDiscoverClubs',
      };
    }
    case 'follow.relation.requester.addressee.global.v2': {
      return {
        type: 'event',
        value: 'goToProfile',
      };
    }
    case 'follow.relation.requester.requester.global.v2': {
      const user = item.data.payload.body;
      return {
        type: 'event',
        value: 'openProfile',
        metadata: {
          userId: user.userId,
        },
      };
    }
    case 'follow.friendship.requester.addressee.global.v2':
    case 'follow.friendship.addressee.requester.global.v2': {
      const cta = item.data.payload.cta;
      const userCta = cta?.find((c) => c.type === 'cta.user.info') as Extract<
        NotificationCtaType,
        {
          type: 'cta.view.user.profile';
        }
      >;
      if (userCta) {
        return {
          type: 'event',
          value: 'openProfile',
          metadata: {
            userId: userCta.userId,
          },
        };
      }
      break;
    }
  }
};
