import { ReactNode, useContext } from 'react';
import {
  Control,
  FieldValues,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Box } from '@mui/material';
import { countWords } from '@front/helper';
import { replaceComponent, TextField, TipButton } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import IaDataContext from '@lib/ia/src/core/IaData/IaDataContext';

import useInputEvent from '../hooks/useInputEvent';
import useInputGuard from '../hooks/useInputGuard';
import useInputStateIndicator from '../hooks/useInputStateIndicator';
import useSuccessState from '../hooks/useSuccessState';
import {
  FormLayoutItemStatus,
  FormLayoutNote,
  FormLayoutTextItem,
} from '../types';
import { getCustomValidate, getLabelIcon } from '../utils';

import ErrorIcon from './ErrorIcon';
import MaybeWatchField from './MaybeWatchField';

type TextInputProps = {
  item: FormLayoutTextItem;
};

type TextCountDisplayProps = {
  control: Control<FieldValues, any>;
  name: string;
  total: number;
};

type WordCountDisplayProps = {
  control: Control<FieldValues, any>;
  name: string;
};

type CharCountDisplayProps = {
  count: number;
};

const styles = {
  number: {
    input: {
      typography: 'numberBody3',
    },
  },
  prefixButton: {
    typography: 'body1',
  },
  input: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    button: {
      bgcolor: 'transparent',
      color: 'inherit',
      border: 'unset',
      typography: 'caption',
      p: 0,
    },
  },
};
const TextCountDisplay = ({ control, name, total }: TextCountDisplayProps) => {
  const value = useWatch({
    control,
    name,
  });

  return `${value?.length || 0}/${total}`;
};

const WordCountDisplay = ({ control, name }: WordCountDisplayProps) => {
  const value = useWatch({
    control,
    name,
  });

  return `${countWords(value)} words`;
};

const CharCountDisplay = ({ count }: CharCountDisplayProps) => {
  return `${count} chars`;
};

function Prefix({ item }: { item: FormLayoutTextItem }) {
  const { getIaAction } = useIaAction();
  const itemPrefix = item.prefix;

  return (
    <MaybeWatchField setting={itemPrefix}>
      {(prefix: string) =>
        itemPrefix?.button ? (
          <TipButton
            customSize={24}
            sx={styles.prefixButton}
            title={itemPrefix.button.tooltip || ''}
            onClick={() =>
              getIaAction<{ prefix: string }>(
                itemPrefix.button?.clickAction
              )?.action({ prefix })
            }
          >
            {prefix}
          </TipButton>
        ) : (
          prefix
        )
      }
    </MaybeWatchField>
  );
}

function Suffix({ item }: { item: FormLayoutTextItem }) {
  const itemSuffix = item.suffix;
  if (!itemSuffix) return null;
  if (itemSuffix.type === 'text') return itemSuffix.value;
  if (itemSuffix.type === 'icon')
    return <Icon name={itemSuffix.value} width={16} height={16} />;
}

function Note({ note }: { note: FormLayoutNote }) {
  const { getIaAction } = useIaAction();

  if (typeof note === 'string') return note;
  if (!note[1].tags) {
    return <Box sx={note[1].sx}>{note[0]}</Box>;
  }

  return (
    <Box sx={note[1].sx}>
      {replaceComponent(note[0], /<(.*?)>(.*?)<\/.*?>/g, (match, i) => {
        const mappingTag = note[1].tags?.[match[1]];

        if (!mappingTag) return <span key={match[0] + i}>{match[2]}</span>;

        if ('type' in mappingTag && mappingTag.type === 'button') {
          return (
            <Box
              key={match[0] + i}
              sx={mappingTag.sx}
              component="button"
              type="button"
              onClick={() => getIaAction(mappingTag.value)?.action()}
            >
              {match[2]}
            </Box>
          );
        }

        return (
          <Box key={match[0] + i} sx={mappingTag.sx} component="span">
            {match[2]}
          </Box>
        );
      })}
    </Box>
  );
}

export default function TextInput({ item }: TextInputProps) {
  const { token } = useContext(IaDataContext);
  const stateIndicator = useInputStateIndicator({
    name: item.name,
    stateIndicator: item.stateIndicator,
  });
  const { getIaAction } = useIaAction();
  const inputGuardEvents = useInputGuard(item.guard);
  const { control, getValues } = useFormContext();

  const labelIcon = getLabelIcon(item.type, item.icon);

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues, token })
    : undefined;

  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });

  const successState = useSuccessState(item.name, isDirty);

  const showTextCount =
    !!item.rules?.maxLength && !item.rules?.maxLength.hiddenRule;

  const getLabelSuffix = (): ReactNode | undefined => {
    if (showTextCount) {
      return (
        <TextCountDisplay
          control={control}
          name={item.name}
          total={item.rules?.maxLength?.value ?? 0}
        />
      );
    }

    if (typeof item.showWordCount === 'boolean' && item.showWordCount) {
      return <WordCountDisplay control={control} name={item.name} />;
    }
    if (typeof item.showWordCount === 'number') {
      return <CharCountDisplay count={item.showWordCount} />;
    }

    return undefined;
  };

  const { handleInputBlur, handleInputChange } = useInputEvent({
    type: item.type,
    name: item.name,
    customRules: item.customRules,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const filterValue = (newValue: string) => {
    if (!item.valueFilterAction) return newValue;
    const filterAction = getIaAction<string>(item.valueFilterAction.value);
    if (!filterAction) return newValue;
    return filterAction.action(newValue);
  };

  const handleChange = async (newValue: string): Promise<void> => {
    const filteredValue = filterValue(newValue);
    await handleInputChange(filteredValue);
    void successState.handleChange();
  };

  return (
    <TextField
      className={`ia-form-layout_text ${item.className}`}
      sx={[item.type === 'number' && styles.number, styles.input]}
      fullWidth
      error={!!error}
      success={successState.success}
      label={item.label}
      labelIcon={labelIcon}
      errorIcon={
        item.errorIcon ? <ErrorIcon icon={item.errorIcon} /> : undefined
      }
      requiredMark={!!item.rules?.required}
      size={item.textFieldSize}
      labelTooltip={item.labelTooltip}
      labelSuffix={getLabelSuffix()}
      type={item.inputType}
      placeholder={item.placeholder}
      helperText={error?.message ?? item.helperText}
      prefix={item.prefix && <Prefix item={item} />}
      suffix={item.suffix && <Suffix item={item} />}
      multiline={item.type === 'textarea'}
      minRows={item.minRows}
      disabled={item.status === FormLayoutItemStatus.Disabled || item.disabled}
      value={value ?? item.defaultValue ?? ''}
      inputRef={ref}
      inputProps={{
        maxLength: item.rules?.maxLength?.value,
      }}
      onChange={(ev) => handleChange(ev.target.value)}
      onBlur={handleBlur}
      {...inputGuardEvents}
      note={item.note ? <Note note={item.note} /> : undefined}
      stateIndicator={stateIndicator}
    />
  );
}
