import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateQuizPanelSimpleButton from '@app/web/src/components/CreateQuiz/CreateQuizPanelSimpleButton';
import { FollowButton } from '@app/web/src/components/FriendButtons';
import { SuggestionUser } from '@app/web/src/components/SuggestionPanel/types';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { IconButton, SimpleTooltip } from '@front/ui';
import { useUserProfileSocial } from '@lib/web/apis';

export type DisplayTableFollowButtonProps = {
  userId: string;
  clubSlug?: string;
};

export default function DisplayTableFollowButton({
  userId,
  clubSlug,
}: DisplayTableFollowButtonProps) {
  const { t } = useTranslation();
  const [challengerSource, setChallengerSource] = useState<SuggestionUser[]>(
    []
  );
  const { data: userSocial } = useUserProfileSocial(userId);
  const socialData = userSocial?.data;
  if (!userId || !clubSlug || !socialData) return null;

  const handleChallengeClick = async () => {
    if (!socialData) return;
    setChallengerSource([
      {
        id: socialData.userId,
        display: socialData.fullName || socialData.userName || '',
        avatar: socialData.avatarUrl || socialData.photoUrl || '',
        memberInfo: {
          userId: socialData.userId,
          avatar: socialData.photoUrl || '',
          displayName: socialData.fullName,
          distinctName: socialData.userName,
          nftAvatar: socialData.avatarUrl,
          indicator: socialData.indicator,
        },
      },
    ]);
  };

  if (!socialData.isFollowing) {
    return (
      <FollowButton
        isFollowing={socialData.isFollowing}
        isFollower={socialData.isFollower}
        userId={userId}
        buttonType="iconButton"
        autoFetchSocialData
      />
    );
  }

  return (
    <CreateQuizPanelSimpleButton
      withPanel
      challengerSource={challengerSource}
      defaultValues={{ challengers: [userId] }}
      onBeforeOpenPanel={handleChallengeClick}
      panelKeyPrefix={userId}
      clubSlug={clubSlug}
      renderButton={({ onClick }) => (
        <SimpleTooltip title={t('Challenge')} disableInteractive>
          <IconButton onClick={onClick} customSize={24}>
            <MainChallengeIcon />
          </IconButton>
        </SimpleTooltip>
      )}
    />
  );
}
