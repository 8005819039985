import filterReducer from '@lib/ia/src/filter/reducers/filterReducer';
import sortReducer from '@lib/ia/src/sort/reducers/sortReducer';
import settingReducer from '@lib/web/editor/redux/reducers/settingReducer';
import { combineReducers } from 'redux';

import blockerReducer from './blockerReducer';
import hashSectionReducer from './hashSectionReducer';
import iaLayoutReducer from './iaLayoutReducer';
import iaQueryHelperReducer from './iaQueryHelperReducer';
import layoutReducer from './layoutReducer';
import quizReducer from './quizReducer';
import searchReducer from './searchReducer';
import seoQuizReducer from './seoQuizReducer';

const rootReducer = combineReducers({
  layout: layoutReducer,
  blocker: blockerReducer,
  iaLayout: iaLayoutReducer,
  quiz: quizReducer,
  seoQuiz: seoQuizReducer,
  sort: sortReducer,
  filter: filterReducer,
  search: searchReducer,
  iaQueryHelper: iaQueryHelperReducer,
  setting: settingReducer,
  hashSection: hashSectionReducer,
});

export default rootReducer;
