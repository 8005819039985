import { MouseEvent, useMemo, useRef } from 'react';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import {
  Button,
  ButtonProps,
  DropdownButton,
  DropdownButtonOption,
  EmphasizeButton,
  Icon,
  TextButton,
  TipButton,
} from '@front/ui';

import ProfileFollowButton from './ProfileFollowButton';
import ProfileSendButton from './ProfileSendButton';

const styles = {
  buttonSm: {
    minWidth: '105px',
    minHeight: '24px',
    fontSize: '12px',
    borderWidth: '2px',
  },
};

export type ProfileButtonProps = {
  icon?: string;
  label?: string;
  dropdownSx?: ButtonProps['sx'];
  dropdownOptions?: DropdownButtonOption[];
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button' | 'textButton';
  size?: 'sm' | 'md';
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  beforeClickCheck?: (ev?: MouseEvent, target?: Element) => boolean;
};

export default function ProfileButton({
  icon,
  label,
  dropdownOptions = [],
  dropdownSx,
  buttonType = 'button',
  variant = 'outlined',
  loading = false,
  disabled = false,
  size = 'md',
  sx,
  onClick,
  beforeClickCheck,
}: ProfileButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dropdownOptions.length > 0 && buttonType !== 'iconButton') {
      return;
    }

    if (beforeClickCheck) {
      const shouldContinue = beforeClickCheck(e);
      if (!shouldContinue) {
        e.preventDefault();
        return;
      }
    }
    onClick?.(e);
  };

  const handleMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const validDropdownOptions = useMemo(() => {
    return dropdownOptions.map((option) => {
      return {
        ...option,
        onClick: () => {
          if (beforeClickCheck) {
            const shouldContinue = beforeClickCheck(
              undefined,
              ref.current || undefined
            );
            if (!shouldContinue) return;
          }

          option.onClick?.();
        },
      };
    });
  }, [beforeClickCheck, dropdownOptions]);

  if (buttonType === 'iconButton') {
    return (
      <TipButton
        customSize={24}
        loading={loading}
        disabled={disabled}
        onClick={handleClick}
        title={label || ''}
      >
        {icon && <Icon name={icon} width={16} height={16} />}
      </TipButton>
    );
  }

  const ButtonComponent =
    buttonType === 'button'
      ? Button
      : buttonType === 'textButton'
      ? TextButton
      : EmphasizeButton;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const dropdownSxProps = Array.isArray(dropdownSx) ? dropdownSx : [dropdownSx];
  const buttonProps = buttonType === 'textButton' ? {} : ({ variant } as any);

  return (
    <DropdownButton
      options={validDropdownOptions}
      dropdownProps={{
        sx: [
          { minWidth: ref.current?.clientWidth || 148, width: 'max-content' },
          ...dropdownSxProps,
        ],
      }}
      buttonRef={ref}
    >
      <ButtonComponent
        prefixIcon={icon && <Icon name={icon} width={16} height={16} />}
        suffixIcon={dropdownOptions.length > 0 && <ActionChevronDownIcon />}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        loading={loading}
        sx={[
          size === 'sm' && buttonType !== 'textButton' && styles.buttonSm,
          ...sxProps,
        ]}
        {...buttonProps}
        disabled={disabled}
      >
        {label}
      </ButtonComponent>
    </DropdownButton>
  );
}

ProfileButton.Follow = ProfileFollowButton;
ProfileButton.Send = ProfileSendButton;
