import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { CurrencyCircle } from '@front/ui';

import EarnedTokenPinChats from './EarnedTokenPinChats';

export default function EarnedToken({
  ahaPointTotalAmount,
  earnedFrom,
}: {
  ahaPointTotalAmount: number;
  earnedFrom: string;
}) {
  const { t } = useTranslation();
  const [isLastElement, setIsLastElement] = useState(false);
  const boxRef = useRef();

  let redirectTo = '/profile/discount-code';
  if (earnedFrom === 'referral-receiver') {
    redirectTo = '/profile/wallet';
  } else if (earnedFrom === 'referral-inviter') {
    redirectTo = '/profile/invite-friends';
  }

  useEffect(() => {
    const elements = document.querySelectorAll<HTMLElement>(
      '[data-testid="earned-from-referral-receiver"]'
    );
    if (elements[elements.length - 1] === boxRef.current) {
      setIsLastElement(true);
    }
  }, []);

  return (
    <Box width="100%">
      <Box
        width="100%"
        component={Link}
        href={redirectTo}
        ref={boxRef}
        data-testid={`earned-from-${earnedFrom}`}
      >
        <Box display="flex" justifyContent="center" pr={4}>
          <CurrencyCircle value={ahaPointTotalAmount} label={t('Earned')} />
        </Box>
      </Box>
      {isLastElement && <EarnedTokenPinChats />}
    </Box>
  );
}
