import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SquareAvatarStatus } from '@front/ui';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItemActionMap } from '@lib/ia/src/layouts/IconListLayout/types';
import { ClubMemberRole, InfiniteHookResponse } from '@lib/web/apis';
import { getUserStatus, useOnlineStatus } from '@lib/web/hooks';

type ItemRes = GetSearchClubMembersRes;

const getActionMap = (item: ItemRes): IconListLayoutItemActionMap => {
  if (item.isMe) return {};
  if (item.isFollowing) {
    return {
      click: {
        actionType: 'iconButton' as const,
        type: 'event' as const,
        value: 'challenge',
        icon: 'MainChallenge',
        customSize: 32,
      },
    };
  }

  return {
    click: {
      actionType: 'iconButton' as const,
      type: 'event' as const,
      value: 'follow',
      icon: 'OtherAddFriend',
      customSize: 32,
    },
  };
};

const getRoleLabel = (role: ClubMemberRole) => {
  if (role === ClubMemberRole.Admin) {
    return 'Admin';
  }

  if (role === ClubMemberRole.Editor) {
    return {
      text: 'Editor',
      color: 'yellow' as const,
    };
  }

  return undefined;
};

type Options = {
  data: InfiniteHookResponse<ItemRes>;
};

export default function useClubMembersLayoutConfig({ data }: Options) {
  const { t } = useTranslation('club');
  const { getUserOnlineStatus } = useOnlineStatus();
  const getStatus = useCallback(
    (id: string) => {
      const statusCode = getUserOnlineStatus(id);
      return getUserStatus(statusCode);
    },
    [getUserOnlineStatus]
  );

  const { totalCount, dataset, isLoading } = data;

  return useMemo<IaLayoutConfig[]>(() => {
    if (isLoading) return [];
    if (totalCount === 0) {
      return [
        {
          layout: 'icon-list-layout',
          items: [t('No results found')],
        },
      ];
    }
    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('club.RHS.clubMembers.##members', {
            count: totalCount ?? 0,
          }),
          ...dataset.map((item) => ({
            id: item.userId,
            title: item.displayName,
            titleSuffix: item.isMe ? 'Me' : '',
            titleAction: {
              type: 'event' as const,
              value: 'titleClick',
            },
            label: getRoleLabel(item.role),
            description: `@${item.userName}`,
            avatarUrl: item.nftAvatar || item.avatar,
            hoverable: true,
            status: getStatus(item.userId),
            statusInfo: {
              [SquareAvatarStatus.Busy]: t('common::user.status.busy'),
            },
            actionMap: getActionMap(item),
            titleHoverAction: { value: 'titleHover' },
            titleClassName: 'floating-avatar-anchor',
            avatarHoverAction: { value: 'avatarHover' },
            avatarClassName: 'floating-avatar-anchor',
            metadata: item,
          })),
        ],
      },
    ];
  }, [isLoading, totalCount, t, dataset, getStatus]);
}
