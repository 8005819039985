import { Question, QuizState } from '@app/web/src/reducers/quizReducer/types';
import { getStorageItem } from '@app/web/src/utils/storage';
import { AnswerFormatType, QuizStatus } from '@lib/web/apis';
import { BLANK_OPTION_VALUE } from '@lib/web/practice';
import { differenceInMilliseconds } from 'date-fns';

export const AHA_STORE_QUIZ_ANSWERS_KEY = 'aha-quiz-answers';
export const AHA_STORE_QUIZ_DURATION_KEY = 'aha-quiz-duration';

export const getTimeSpent = (startAtMilliseconds: number | null) => {
  if (!startAtMilliseconds) return 0;

  return differenceInMilliseconds(Date.now(), startAtMilliseconds);
};

export const getQuestionDetails = (question: GetQuizQuestionRes) => {
  if (
    question.creatorQuestionInfo?.answerFormatType ===
    AnswerFormatType.Unscramble
  ) {
    return question.quizQuestionDetails;
  }
  // HACK: currently backend does not return anything, so we use the options value (but actually, we should not store and use this value in essay, need to fix it in the future to prevent confusing)
  if (
    question.creatorQuestionInfo?.answerFormatType === AnswerFormatType.Essay
  ) {
    return [...question.quizQuestionDetails].map((d) => ({
      ...d,
      solution: d.linkedComponentId,
    }));
  }
  return question.quizQuestionDetails;
};

export const isLeaveBlankAnswer = (currentAnswer?: string[]) => {
  if (!currentAnswer || currentAnswer.length === 0) return false;
  return currentAnswer[0] === BLANK_OPTION_VALUE;
};

export const getMappedQuestions = ({
  currentMappedQuestions,
  quizKey,
  quizQuestions,
  quizResults,
  correctAnswers,
}: {
  currentMappedQuestions?: Record<string, Question>;
  quizKey: string;
  quizQuestions?: GetQuizQuestionRes[];
  quizResults?: GetQuizResultRes[];
  correctAnswers?: GetQuizCorrectAnswersRes[];
}): Record<string, Question> => {
  let mappedQuestions = {};

  const storedDurationMap = getStorageItem(AHA_STORE_QUIZ_DURATION_KEY, {});

  quizQuestions?.forEach((question) => {
    const questionNo = question.index + 1;
    const questionResult = quizResults?.find(
      (r) => r.quizQuestionId === question.id
    );
    const correctAnswer = correctAnswers?.find(
      (a) => a.quizQuestionId === question.id
    );

    const currentMappedQuestion = currentMappedQuestions?.[questionNo];

    const updatedResult =
      questionResult && !!questionResult.id
        ? {
            isAnswered: questionResult.isAnswered,
            isOvertime: questionResult.isOvertime,
            isCorrect: questionResult.isCorrect,
            isIncorrect: !questionResult.isCorrect,
            isStreak: questionResult.isStreak,
            isSubmitted: true,
            isLeaveBlank: questionResult.isLeaveBlank,
            timeSpent: questionResult.duration * 1000,
            correctAnswerIds: questionResult.correctAnswerIds || [],
            correctAnswerValues: questionResult.correctAnswerValues || [],
            startAt: null,
            isPendingSubmit: false,
          }
        : {
            isAnswered: false,
            isOvertime: false,
            isCorrect: false,
            isIncorrect: false,
            isStreak: false,
            isSubmitted: false,
            isLeaveBlank: false,
            timeSpent: storedDurationMap[quizKey]?.[questionNo] || 0,
            correctAnswerIds: correctAnswer?.correctAnswerIds || [],
            correctAnswerValues: correctAnswer?.correctAnswerValues || [],
            startAt: currentMappedQuestion?.startAt || null,
            isPendingSubmit: currentMappedQuestion?.isPendingSubmit || false,
          };

    mappedQuestions = {
      ...mappedQuestions,
      [questionNo]: {
        id: question.id,
        examQuestionSetId: question.examQuestionSetId,
        examQuestionId: question.examQuestionId,
        question: question.question,
        questionDetails: getQuestionDetails(question),
        revision: question.revision,
        creatorQuestionInfo: question.creatorQuestionInfo,
        creatorId: question.creatorId,
        setId: question.setId,
        isDynamicDifficulty: question.isDynamicDifficulty,
        staticDifficulty: question.staticDifficulty,
        dynamicDifficulty: question.dynamicDifficulty,
        // basic states
        ...updatedResult,
        isSkipped: false,
        isLoading: false,
        questionNo,
        tpq: question.practiceTimeSec,
      } as Question,
    };
  });

  return mappedQuestions;
};

export const getMappedAnswers = ({
  quizKey,
  quizQuestions,
  quizResults,
}: {
  quizKey: string;
  quizQuestions?: GetQuizQuestionRes[];
  quizResults?: GetQuizResultRes[];
}) => {
  let mappedAnswers = {};
  const storedAnsweredMap = getStorageItem(AHA_STORE_QUIZ_ANSWERS_KEY, {});

  quizQuestions?.forEach((question) => {
    const questionNo = question.index + 1;
    const questionResult = quizResults?.find(
      (r) => r.quizQuestionId === question.id
    );

    if (questionResult) {
      let answerIds = questionResult.answerIds;
      if (questionResult.isLeaveBlank) {
        // the API returns answerIds=[null] when the blank option selected
        // use [BLANK_OPTION_VALUE] instead of that to synchronize with other places
        answerIds = [BLANK_OPTION_VALUE];
      }
      mappedAnswers = {
        ...mappedAnswers,
        [questionNo]: questionResult.answerValue
          ? [questionResult.answerValue]
          : answerIds || [],
      };
    } else if (storedAnsweredMap[quizKey]) {
      mappedAnswers = {
        ...mappedAnswers,
        [questionNo]: storedAnsweredMap[quizKey][questionNo] || [],
      };
    } else {
      mappedAnswers = {
        ...mappedAnswers,
        [questionNo]: [],
      };
    }
  });

  return mappedAnswers;
};

export const getMappedQuestionIds = ({
  quizQuestions,
}: {
  quizQuestions?: GetQuizQuestionRes[];
}) => {
  let mappedQuestionIds = {};

  quizQuestions?.forEach((question) => {
    const questionNo = question.index + 1;

    mappedQuestionIds = {
      ...mappedQuestionIds,
      [question.id]: questionNo,
    };
  });
  return mappedQuestionIds;
};

export const getTotalTimeInfo = (questions: Question[]) => {
  return {
    totalTimeSpent: questions.reduce((acc, q) => acc + q.timeSpent, 0),
    totalTime: questions.reduce((acc, q) => acc + q.tpq, 0),
  };
};

export const getQuizStateInfo = ({
  sid,
  isOnboarding,
  quizStatus,
  isCompleted,
}: {
  sid?: string;
  isOnboarding?: boolean;
  quizStatus: QuizStatus;
  isCompleted: boolean;
}) => {
  if (quizStatus === QuizStatus.Outdated) {
    return {
      state: QuizState.Review,
      loaded: true,
      loading: false,
    };
  }

  if (sid) {
    return {
      state: QuizState.Viewing,
      loaded: true,
      loading: false,
    };
  }

  if (isOnboarding) {
    return {
      state: QuizState.Onboarding,
      loaded: true,
      loading: false,
    };
  }

  if (isCompleted) {
    return {
      state: QuizState.Finish,
      loaded: true,
      loading: false,
    };
  }

  return {
    state: QuizState.Pending,
    loaded: true,
    loading: false,
  };
};

export const updateStreakStatus = (
  isCurrentQuestionCorrect: boolean,
  questionNo: number,
  mappedQuestions: Record<string, Question>
) => {
  if (!isCurrentQuestionCorrect) return;

  const currentQuestion = mappedQuestions[questionNo];
  const prevQuestion = mappedQuestions[questionNo - 1];
  const nextQuestion = mappedQuestions[questionNo + 1];

  currentQuestion.isStreak = true;

  if (prevQuestion?.isCorrect) {
    prevQuestion.isStreak = true;
  }
  if (nextQuestion?.isCorrect) {
    nextQuestion.isStreak = true;
  }
};

export const getIsCompleted = (mappedQuestions: Record<string, Question>) => {
  return Object.values(mappedQuestions).every((q) => q.isSubmitted);
};
