export const enum Role {
  Editor = 'editor',
  Marketing = 'marketing',
  Administrator = 'administrator',
}

export const enum AccountBlockerType {
  Unclaimed = 'unclaimed',
  Unverified = 'unverified',
}
