import generateHook from './swr.helper';

export const useClubGroupUnreadNotificationCount = () =>
  generateHook<GetClubGroupUnreadNotificationCountRes>(
    // 1: system
    // 2: user_dm
    `v2/club-group/notification/count/me?type=1`,
    {
      auth: true,
    }
  );
