import React from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { useProduct } from '@lib/web/apis';

import { MarketplaceProvider } from '../../MarketplacePage/contexts/context';
import AvatarDetail from '../../MarketplacePage/MarketplaceRightPanels/components/AvatarDetail';

export default function AvatarDetailPanel() {
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { avatarName, productId } = getRightParams(
    GlobalPanelKeys.GlobalMarketplaceDetail
  );

  const { data: productData } = useProduct(productId || '');

  return (
    <MarketplaceProvider>
      <BaseLayoutRightPanel title={productData?.data.name || avatarName}>
        <AvatarDetail product={productData?.data} />
      </BaseLayoutRightPanel>
    </MarketplaceProvider>
  );
}
