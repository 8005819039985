export default function AgentWorkflowContainRecipes({
  text,
  ...rest
}: {
  text?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50711 5.50711C4.47511 6.5391 4 8.39285 4 12C4 15.6071 4.47511 17.4609 5.50711 18.4929C6.5391 19.5249 8.39285 20 12 20C15.6071 20 17.4609 19.5249 18.4929 18.4929C19.5249 17.4609 20 15.6071 20 12C20 8.39285 19.5249 6.5391 18.4929 5.50711C17.4609 4.47511 15.6071 4 12 4C8.39285 4 6.5391 4.47511 5.50711 5.50711ZM3 12C3 4.8 4.8 3 12 3C19.2 3 21 4.8 21 12C21 19.2 19.2 21 12 21C4.8 21 3 19.2 3 12Z"
        fill="currentColor"
      />

      {text && (
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          fill="currentColor"
          fontSize={(rest.width || rest.height || 24) * 0.75}
          fontFamily="Ubuntu"
          fontWeight="700"
          dy=".3em"
        >
          {text}
        </text>
      )}
    </svg>
  );
}
