import Box from '@mui/material/Box';
import { gridInSettings } from '@lib/web/editor';
import { GridInOption } from '@lib/web/quiz';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../hooks/usePracticeQuestionStatus';

const styles = {
  clickable: {
    cursor: 'pointer',
  },
};

export type QuizAnswerGridInProps = {
  readonly?: boolean;
};

export default function QuizAnswerGridIn({ readonly }: QuizAnswerGridInProps) {
  const { onChangeAnswers, onSubmittedAnswerClick } =
    usePracticeQuestionEvent();
  const { answers: currentAnswers } = usePracticeQuestionAnswers();
  const { isSubmitted, startAt } = usePracticeQuestionStatus();
  const { isCorrect, correctAnswerValues } = usePracticeQuestionResult();
  const { id } = usePracticeQuestion();
  const gridInValue = currentAnswers[0] || '';
  const correctAnswer = correctAnswerValues[0] || '';

  const handleClick = () => {
    if (isSubmitted) {
      onSubmittedAnswerClick?.(true);
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={[isSubmitted && !!onSubmittedAnswerClick && styles.clickable]}
    >
      <GridInOption
        key={id}
        value={gridInValue}
        onChange={(newValue: string) => onChangeAnswers([newValue])}
        disabled={!startAt || readonly}
        inputCount={4}
        valuesRegex={gridInSettings.valuesRegex}
        acceptedRegex={gridInSettings.acceptedRegex}
        hints={gridInSettings.hints}
        answer={correctAnswer}
        isSubmitted={isSubmitted}
        success={isCorrect}
        error={!isCorrect}
        autoFocus
      />
    </Box>
  );
}
