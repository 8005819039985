import useSWRImmutable from 'swr/immutable';
import { getCurrencyCode } from '@app/web/src/utils/currency';
import { useMyCurrency } from '@lib/web/apis';

import useCloudfront from '../utils/useCloudfront';

import fetcher from './fetcher';

interface StripeProductRecurring {
  aggregate_usage?: any;
  interval: string;
  interval_count: number;
  trial_period_days?: any;
  usage_type: string;
}

interface StripeProduct {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount?: any;
  livemode: boolean;
  lookup_key?: any;
  nickname?: any;
  product: string;
  recurring: StripeProductRecurring;
  tax_behavior: string;
  tiers_mode?: any;
  transform_quantity?: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

const defaultCurrency = 'USD';

export default function useStripeProduct(productId?: NullableString) {
  const { data, error, ...rest } = useSWRImmutable<
    StripeResponse<StripeProduct[]>
  >(productId ? `/api/strapi/products/${productId}/prices` : null, fetcher);

  const { data: currencyData } = useMyCurrency();
  const { country } = useCloudfront();

  const currency =
    currencyData?.data.currency?.toUpperCase() || getCurrencyCode(country);

  const currentPrice = data?.data.length
    ? data.data.find((d) => d.currency.toUpperCase() === currency) ||
      data.data.find((d) => d.currency.toUpperCase() === defaultCurrency) ||
      data.data[0]
    : null;

  const price = currentPrice ? currentPrice.unit_amount / 100 : 0;
  const priceUnit = currentPrice ? currentPrice.recurring.interval : null;

  return {
    data,
    error,
    price,
    priceUnit,
    currency,
    ...rest,
  };
}
