import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material';
import { apis } from '@lib/web/apis';

import useFollowStatus from '../../hooks/useFollowStatus';

import ProfileButton from '.';

type ProfileFollowButtonProps = {
  userId: string;
  isFollowing: boolean;
  isFollower: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md';
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button' | 'textButton';
  beforeClickCheck?: (ev?: MouseEvent, target?: Element) => boolean;
  autoFetchSocialData?: boolean;
  sx?: ButtonProps['sx'];
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
};

export default function ProfileFollowButton({
  userId,
  isFollowing,
  isFollower,
  loading,
  disabled,
  size,
  buttonType,
  onToggleFollow,
  beforeClickCheck,
  autoFetchSocialData,
  sx,
}: ProfileFollowButtonProps) {
  const { t } = useTranslation();
  const { status: followStatus, reloadFollowStatus } = useFollowStatus({
    userId,
    initData: {
      isFollowing,
      isFollower,
    },
    disabled: !autoFetchSocialData,
  });

  const handleUnFollowClick = useCallback(async () => {
    const promise = apis.member.unfollowUser(userId);
    onToggleFollow?.(userId, promise);
    reloadFollowStatus(promise);
  }, [onToggleFollow, reloadFollowStatus, userId]);

  const handleFollowClick = useCallback(async () => {
    const promise = apis.member.followUser(userId);
    onToggleFollow?.(userId, promise);
    reloadFollowStatus(promise);
  }, [onToggleFollow, reloadFollowStatus, userId]);

  const handleClick = useCallback(() => {
    if (followStatus.isFollowing) {
      handleUnFollowClick();
      return;
    }
    handleFollowClick();
  }, [followStatus.isFollowing, handleFollowClick, handleUnFollowClick]);

  const dropdownOptions = useMemo(() => {
    if (!followStatus.isFollowing) return [];
    return [
      {
        display: 'Unfollow',
        icon: 'OtherUnfollow',
        onClick: handleUnFollowClick,
      },
    ];
  }, [followStatus.isFollowing, handleUnFollowClick]);

  const label = followStatus.isFollowing
    ? t('button.Following')
    : isFollower
    ? t('button.Follow Back')
    : t('button.Follow');

  return (
    <ProfileButton
      label={label}
      icon={followStatus.isFollowing ? 'OtherFollowing' : 'OtherAddFriend'}
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
      beforeClickCheck={beforeClickCheck}
      dropdownOptions={dropdownOptions}
      variant={dropdownOptions.length > 0 ? 'outlined' : 'filled'}
      size={size}
      buttonType={buttonType}
      sx={sx}
    />
  );
}
