import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Skeleton, Switch, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import DiscountCodeCard from '@app/web/src/components/DiscountCodeCard';
import { CheckoutPanelKeys } from '@app/web/src/types/panel';
import { DEFAULT_CURRENCY } from '@app/web/src/utils/currency';
import { appConfig } from '@front/config';
import {
  ActionArrowRight as ActionArrowRightIcon,
  OtherAhaPoints as OtherAhaPointsIcon,
  OtherDiscountNoOuter as OtherDiscountNoOuterIcon,
  OtherLink as OtherLinkIcon,
  OtherShoppingCart as OtherShoppingCartIcon,
} from '@front/icon';
import {
  BackIconType,
  BaseLayoutRightPanel,
  Button,
  Scrollbar,
  SimpleTooltip,
  SquareAvatar,
  useBaseLayout,
} from '@front/ui';
import { useDiscountAmount } from '@lib/web/apis';
import { getLocaleCode } from '@lib/web/utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    px: 1.5,
    py: 1,
    gap: '30px',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '60px',
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  cardContent: {
    p: '12px',
    pb: '12px !important',
  },
  header: {
    fontSize: 20,
    fontWeight: 700,
    mb: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  savingWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    py: 1,
  },
  ahaPointsIcon: {
    background: (theme: Theme) => theme.palette.gradient.primary,
    '& svg': {
      color: 'text.primary',
    },
  },
  discountIcon: {
    bgcolor: 'text.primary',
  },
  savingContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 0.5,
  },
  title: {
    fontSize: { xs: 16 },
    fontWeight: 400,
  },
  description: {
    fontSize: { xs: 12 },
    fontWeight: 400,
    fontFamily: 'Inconsolata',
  },
  divider: {
    '& span': {
      fontSize: 12,
      fontWeight: 400,
      display: 'inline-flex',
    },
  },
  arrowRight: {
    p: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  discountCodeWrapper: {
    cursor: 'pointer',
  },
  discountCodeDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  tooltip: {
    '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
      mt: '0 !important',
    },
  },
  discountCode: {
    cursor: 'pointer',
  },
  orderContent: {
    mb: 3,
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0.5,
    minHeight: '102px',
  },
  summaryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
  },
  summaryTitle: {
    fontSize: 16,
  },
  summaryValue: {
    fontSize: 20,
    fontFamily: 'Inconsolata',
    letterSpacing: '-2.5px',
  },
  summaryAhaPoints: {
    display: 'flex',
    alignItems: 'center',
  },
  totalDue: {
    mt: 'auto',
  },
  totalDueTitle: {
    fontWeight: 700,
  },
  totalDueValue: {
    fontWeight: 700,
  },
  action: {
    display: 'grid',
    alignItems: 'center',
    gap: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    minHeight: 60,
    px: '12px',
    '& .MuiButtonBase-root': {
      px: 0,
      minWidth: 'unset',
      letterSpacing: 'unset',
    },
  },
  multiAction: {
    gridTemplateColumns: '1fr 1fr',
  },
};

type DiscountValue = {
  expectedUsedAhaPoint: number;
  discountAmount: number;
};

const getDiscountValue = (
  price: number,
  currency: string,
  points: number | null,
  isUseAhaPoint: boolean,
  discountAmountData?: GetDiscountAmountRes,
  appliedDiscount?: GetMyPromoCodeRes,
  discountDisabled?: boolean
): DiscountValue | null => {
  // 1. do not apply discount code or ahaPoint
  if ((!isUseAhaPoint && !appliedDiscount) || discountDisabled) {
    return {
      expectedUsedAhaPoint: 0,
      discountAmount: 0,
    };
  }

  // 2. applied discount code
  if (!isUseAhaPoint && appliedDiscount) {
    if (appliedDiscount.percentOff) {
      const discountAmount = price * (appliedDiscount.percentOff / 100);
      return {
        expectedUsedAhaPoint: 0,
        discountAmount,
      };
    }
    const discountAmount = Math.min(
      price,
      (appliedDiscount.amountOff || 0) / 100 // amountOff is $cents
    );
    return {
      expectedUsedAhaPoint: 0,
      discountAmount,
    };
  }

  // 3. applied ahaPoint and currency = USD => can calculate immediately 1 ahaPoint = $1
  // => this logic is to increase discount amount calculation performance because it does not need wait for API response
  if (isUseAhaPoint && currency === DEFAULT_CURRENCY) {
    if (points === null) return null; // null = loading
    const discountAmount = Math.min(price, points);
    return {
      expectedUsedAhaPoint: discountAmount,
      discountAmount,
    };
  }

  // 4. applied ahaPoint and currency is not USD => need to get amount from API
  // 4.1. data is not ready (API is loading)
  if (!discountAmountData) return null; // null = loading

  // 4.2. get amount from API response
  return {
    discountAmount: discountAmountData.discountAmount,
    expectedUsedAhaPoint: discountAmountData.expectedUsedAhaPoint,
  };
};

type CheckoutButtonProps = {
  text?: string;
  prefixIcon?: ReactNode;
};

type CheckoutPanelProps = {
  discountCode?: GetMyPromoCodeRes;
  onRemoveDiscountCode?: () => void;
  currency: string;
  price: number;
  onCopyLink?: () => void;
  onCheckout?: () => void;
  onCancel?: () => void;
  onUseAhaPointChange?: (value: boolean) => void;
  linkLoading?: boolean;
  checkoutLoading?: boolean;
  isUseAhaPoint?: boolean;
  summary?: ReactNode;
  backIcon?: BackIconType;
  onIconClick?: () => void;
  alwaysHidePaymentLink?: boolean;
  autoHidePaymentLink?: boolean;
  ahaPoints: number | null; // null = loading
  discountDisabled?: boolean;
  showCancel?: boolean;
  checkoutProps?: CheckoutButtonProps;
};

export default function CheckoutPanel({
  discountCode,
  onRemoveDiscountCode,
  currency,
  price,
  onCopyLink,
  onCheckout,
  onCancel,
  onUseAhaPointChange,
  linkLoading = false,
  checkoutLoading = false,
  isUseAhaPoint = false,
  summary,
  backIcon,
  onIconClick,
  alwaysHidePaymentLink = false,
  autoHidePaymentLink = true,
  ahaPoints,
  discountDisabled = false,
  showCancel = false,
  checkoutProps,
}: CheckoutPanelProps) {
  const { openRightPanel, rightPanelParams } = useBaseLayout();
  const { data: discountAmountData } = useDiscountAmount(currency, price);
  const { t } = useTranslation('marketplace');
  const localeCode = getLocaleCode(currency);
  const formatter = new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency,
  });

  const defaultCheckoutProps = {
    prefixIcon: <OtherShoppingCartIcon />,
    text: t('Checkout'),
  };
  const numberFormatter = new Intl.NumberFormat(localeCode, {
    minimumFractionDigits: 2,
  });

  const handleTogglePoint = () => {
    onUseAhaPointChange?.(!isUseAhaPoint);
  };

  useEffect(() => {
    if (ahaPoints === 0 || discountDisabled) {
      onUseAhaPointChange?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ahaPoints, discountDisabled]);

  const discount = getDiscountValue(
    price,
    currency,
    ahaPoints,
    isUseAhaPoint,
    discountAmountData?.data,
    discountCode,
    discountDisabled
  );

  const totalDue = price - (discount?.discountAmount || 0);
  const showPaymentLink =
    !(totalDue === 0 && autoHidePaymentLink) && !alwaysHidePaymentLink;

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherShoppingCartIcon width="16" height="16" />}
      title={t('Checkout')}
      backIcon={backIcon}
      onIconClick={onIconClick}
    >
      <Scrollbar sx={styles.scroll}>
        <Box sx={styles.root}>
          {!discountDisabled && (
            <Card>
              <CardContent sx={styles.cardContent}>
                <Typography variant="h6" sx={styles.header}>
                  {t('Apply Savings')}
                </Typography>
                <Box sx={styles.content}>
                  <Box sx={[styles.savingWrapper]}>
                    <SquareAvatar sx={styles.ahaPointsIcon}>
                      <OtherAhaPointsIcon />
                    </SquareAvatar>
                    <Box sx={styles.savingContent}>
                      <Typography variant="h6" sx={styles.title}>
                        {t('Use Aha Points')}
                      </Typography>
                      {ahaPoints === null && (
                        <Skeleton width={100} height={18} />
                      )}
                      {ahaPoints !== null && (
                        <Typography variant="h6" sx={styles.description}>
                          {ahaPoints || 0.0} AHA = $
                          {numberFormatter.format(
                            ahaPoints / appConfig.tokenToUSD
                          )}
                        </Typography>
                      )}
                    </Box>
                    <Switch
                      onChange={handleTogglePoint}
                      disabled={!ahaPoints}
                      checked={isUseAhaPoint}
                    />
                  </Box>

                  <Divider sx={styles.divider}>or</Divider>
                  <Box
                    sx={[
                      styles.savingWrapper,
                      styles.discountCodeWrapper,
                      isUseAhaPoint && styles.discountCodeDisabled,
                    ]}
                    onClick={() =>
                      !isUseAhaPoint &&
                      openRightPanel(CheckoutPanelKeys.DiscountCode, {
                        ...rightPanelParams,
                      })
                    }
                  >
                    <SquareAvatar sx={styles.discountIcon}>
                      <OtherDiscountNoOuterIcon />
                    </SquareAvatar>
                    <Box sx={styles.savingContent}>
                      <Typography variant="h6" sx={styles.title}>
                        {t('Use Discount Code')}
                      </Typography>
                    </Box>
                    <Box sx={styles.arrowRight}>
                      <ActionArrowRightIcon />
                    </Box>
                  </Box>

                  {discountCode && !isUseAhaPoint && (
                    <SimpleTooltip
                      title={t('Click to unuse discount code')}
                      placement="bottom-end"
                      slotProps={{
                        popper: {
                          sx: styles.tooltip,
                        },
                      }}
                    >
                      <Box
                        sx={styles.discountCode}
                        onClick={onRemoveDiscountCode}
                      >
                        <DiscountCodeCard code={discountCode} />
                      </Box>
                    </SimpleTooltip>
                  )}
                </Box>
              </CardContent>
            </Card>
          )}

          <Card>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h6" sx={styles.header}>
                {t('Order Summary')}
              </Typography>
              <Box sx={styles.orderContent}>{summary}</Box>
              <Box sx={styles.summaryContent}>
                <Box sx={styles.summaryItem}>
                  <Typography variant="body1" sx={styles.summaryTitle}>
                    {t('Subtotal')}
                  </Typography>
                  <Typography variant="body1" sx={styles.summaryValue}>
                    {formatter.format(price)}
                  </Typography>
                </Box>
                {!!discountCode && !isUseAhaPoint && !discountDisabled && (
                  <Box sx={styles.summaryItem}>
                    <Typography variant="body1" sx={styles.summaryTitle}>
                      {t('Discount')}
                    </Typography>
                    <Typography variant="body1" sx={styles.summaryValue}>
                      -
                      {discountCode.percentOff
                        ? formatter.format(discount?.discountAmount || 0)
                        : formatter.format((discountCode.amountOff || 0) / 100)}
                    </Typography>
                  </Box>
                )}
                {isUseAhaPoint && (
                  <Box sx={styles.summaryItem}>
                    <Typography variant="body1" sx={styles.summaryTitle}>
                      {t('Aha Points')}
                    </Typography>
                    {discount && (
                      <Typography
                        variant="body1"
                        sx={[styles.summaryValue, styles.summaryAhaPoints]}
                      >
                        -<OtherAhaPointsIcon width={16} height={16} />{' '}
                        {discount.expectedUsedAhaPoint / appConfig.tokenToUSD}
                      </Typography>
                    )}
                    {discount === null && <Skeleton width={30} height={30} />}
                  </Box>
                )}
                <Box sx={[styles.summaryItem, styles.totalDue]}>
                  <Typography
                    variant="body1"
                    sx={[styles.summaryTitle, styles.totalDueTitle]}
                  >
                    {t('Total Due')}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={[styles.summaryValue, styles.totalDueValue]}
                  >
                    {formatter.format(totalDue)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Scrollbar>
      <Box
        sx={[
          styles.action,
          (showPaymentLink || showCancel) && styles.multiAction,
        ]}
      >
        {showPaymentLink && (
          <Button
            variant="outlined"
            prefixIcon={<OtherLinkIcon />}
            onClick={onCopyLink}
            loading={linkLoading}
            disabled={discount === null}
          >
            {t('Payment Link')}
          </Button>
        )}
        {showCancel && (
          <Button variant="outlined" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        )}
        <Button
          variant="filled"
          prefixIcon={(checkoutProps || defaultCheckoutProps).prefixIcon}
          onClick={onCheckout}
          loading={checkoutLoading}
          disabled={discount === null}
          data-testid="checkout-button"
        >
          {(checkoutProps || defaultCheckoutProps).text}
        </Button>
      </Box>
    </BaseLayoutRightPanel>
  );
}
