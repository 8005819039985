import { useEffect, useRef } from 'react';
import Router from 'next/router';
import NewDirectMessageMenu from '@app/web/src/layouts/MainLayout/components/Menu/NewDirectMessageMenu';
import { useLatestValueRef } from '@front/helper';
import { useBaseLayout } from '@front/ui';

import { MenuVariant, TemporaryMenuVariant } from '../../context';
import useCurrentMenu from '../../hooks/useCurrentMenu';
import useMainLayout from '../../hooks/useMainLayout';

import DynamicClubMenu from './components/ClubMenu/DynamicClubMenu';
import AddFriendsMenu from './AddFriendsMenu';
import AgentsMenu from './AgentsMenu';
import ClubGroupMenu from './ClubGroupMenu';
import ClubSuggestionMenu from './ClubSuggestionMenu';
import DirectMessagesMenu from './DirectMessagesMenu';
import DiscoverMenu from './DiscoverMenu';
import InternalMenu from './InternalMenu';
import MarketplaceMenu from './MarketplaceMenu';
import NotificationMenu from './NotificationMenu';
import ProfileMenu from './ProfileMenu';

const getVariantMenu = (variant: MenuVariant | null) => {
  switch (variant) {
    case 'discover':
      return <DiscoverMenu />;
    case 'agents':
      return <AgentsMenu />;
    case 'club':
      return <DynamicClubMenu />;
    case 'profile':
      return <ProfileMenu />;
    case 'directMessages':
      return <DirectMessagesMenu />;
    case 'marketplace':
      return <MarketplaceMenu />;
    case 'internal':
      return <InternalMenu />;

    default:
      return null;
  }
};

const getVariantTemporaryMenu = (variant: TemporaryMenuVariant | null) => {
  switch (variant) {
    case 'clubs':
      return <ClubGroupMenu />;
    case 'notifications':
      return <NotificationMenu />;
    case 'addFriends':
      return <AddFriendsMenu />;
    case 'club-suggestion':
      return <ClubSuggestionMenu />;
    case 'newDirectMessage':
      return <NewDirectMessageMenu />;
    default:
      return null;
  }
};

export default function Menu() {
  const {
    clearMenu,
    clearTemporaryMenu,
    temporaryMenuTarget,
    triggerTemporaryMenuType,
  } = useMainLayout();

  const { leftPanelOpened } = useBaseLayout();
  const { currentMenu } = useCurrentMenu();

  const prevUrlRef = useRef<string>();
  const disableCloseTemporaryMenuRef = useLatestValueRef(
    temporaryMenuTarget === 'clubs' && triggerTemporaryMenuType === 'clicked'
  );

  useEffect(() => {
    // When closing the left panel, clear the menu settings (for mobile)
    // Cannot be added to closeMenu for setting, because some leftPanelOpened is controlled by avlutils/ui-core
    if (!leftPanelOpened && !disableCloseTemporaryMenuRef.current) {
      clearMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPanelOpened]);

  useEffect(() => {
    const beforeHistoryChange = (ev: string) => {
      const routeUrl = ev.split('?')[0];
      if (routeUrl === prevUrlRef.current) return;

      if (prevUrlRef.current === undefined) {
        prevUrlRef.current = routeUrl;
        return;
      }

      prevUrlRef.current = routeUrl;
      if (!disableCloseTemporaryMenuRef.current) clearTemporaryMenu();
    };

    Router.events.on('beforeHistoryChange', beforeHistoryChange);

    return () => {
      Router.events.off('beforeHistoryChange', beforeHistoryChange);
    };
  }, [disableCloseTemporaryMenuRef, clearTemporaryMenu]);

  return (
    getVariantTemporaryMenu(temporaryMenuTarget) || getVariantMenu(currentMenu)
  );
}
