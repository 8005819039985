import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { Theme, useMediaQuery } from '@mui/material';
import { toast } from '@front/ui';
import { apis } from '@lib/web/apis';
import { parseFilterConfig } from '@lib/web/thread/utils/viewUtils';
import { call, callWithToast } from '@lib/web/utils';

import { useAppDispatch } from '../../hooks/redux';
import { updateLayoutType } from '../../reducers/iaLayoutReducer';

const getAgentUrl = ({ id }: { id: string }) => {
  return `/agent/form/${id}/settings`;
};

export const useIaAgentActions = () => {
  const router = useRouter();
  const { t } = useTranslation('agent');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const dispatch = useAppDispatch();

  const { mutate: globalMutate, cache } = useSWRConfig();

  const mutate = useCallback(
    <T>(id: string) => {
      for (const key of cache.keys()) {
        if (
          key.includes(id) ||
          key.includes('v2/ia/agents') ||
          key.includes('v2/agent/me') ||
          key.includes('ia/agent/users')
        ) {
          call(globalMutate<unknown, T>(key));
        }
      }
    },
    [cache, globalMutate]
  );

  return useMemo(
    () => ({
      openEditAgentPage: ({ id }: { id: string }) => {
        void router.push(getAgentUrl({ id }));
      },

      duplicateAgent: ({
        id,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        void router.push(`/agent/form/settings?&duplicateFrom=${id}`);
      },

      addMyAgent: async ({
        id,
        toastAnchorEl,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        await callWithToast(() => apis.agent.addMyAgent({ agentId: id }), {
          errorMsg: 'Add Failed',
          anchorEl: toastAnchorEl || undefined,
        });
        await mutate(id);
      },

      removeMyAgent: async ({ id }: { id: string }) => {
        await callWithToast(() => apis.agent.removeMyAgent({ agentId: id }), {
          errorMsg: 'Unadd Failed',
        });
        await mutate(id);
      },

      directMessage: async ({
        views,
        agentId,
      }: {
        views: GetThreadViewRes[];
        agentId: string;
      }) => {
        dispatch(updateLayoutType('channel'));
        const [res] = await callWithToast(
          () => apis.agent.addMyAgent({ agentId }),
          {
            errorMsg: 'Add Failed',
          }
        );
        mutate(agentId);

        if (res) {
          const openAgentMemberId = `agent_${agentId}`;
          const existingView = views.find((view) => {
            const { memberIds } = parseFilterConfig(
              view.threadViewUsers[0].filterConfig
            );
            return (
              memberIds.length === 2 && memberIds.includes(`agent_${agentId}`)
            ); // only me and target agent
          });
          if (existingView) {
            void router.push({
              pathname: `/direct-messages/view/${existingView.id}`,
              query: { openAgentMemberId },
            });
          } else {
            if (!mdUp) {
              toast.info(
                t(
                  'agent::detail.message.toast',
                  'Messages sent to a not followed AI will automatically follow it.'
                )
              );
            }
            void router.push({
              pathname: `/direct-messages/view/${openAgentMemberId}`,
              query: { openAgentMemberId },
            });
          }
        }
      },
    }),
    [dispatch, mdUp, mutate, router, t]
  );
};
