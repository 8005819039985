import { useCallback, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { useUser } from '@auth0/nextjs-auth0/client';
import { GuestContext } from '@front/helper';
import jwt from 'jsonwebtoken';

import { AccountBlockerType, Role } from '../../types/enums';

import generateHook from './swr.helper';

export const authKey = 'v2/user/me?isGetMetadata=true';

function getRoles(token: unknown) {
  if (!token) return [];
  const jwtPayload = jwt.decode(token as string, { json: true });
  return (jwtPayload?.roles as Array<Role>) || [];
}

export const useAuth = () => {
  const { user, isLoading: userLoading } = useUser();
  const { t } = useTranslation();
  const {
    guest,
    isLoading: guestLoading,
    logout: guestLogout,
    login: guestLogin,
  } = useContext(GuestContext);
  const token = user?.accessToken || guest.accessToken;

  const { data, error, mutate } = generateHook<AuthSession>(
    token ? authKey : null,
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      revalidateOnReconnect: false,
      auth: true,
      onSuccess: (response: Response<AuthSession>) => {
        localStorage.setItem(authKey, JSON.stringify(response));
      },
      onError: () => {
        localStorage.removeItem(authKey);
      },
    }
  );

  const isGuest = !user && !!guest.accessToken;
  const loading = !!token && !data && !error;
  const checked = !!data || !!error;
  const isLoading = loading || userLoading || guestLoading;
  const isLoggedOut =
    !!(
      error &&
      (error.response?.status === 401 ||
        error.response?.status === 403 ||
        error.response?.status === 440 ||
        error.response?.status === 498 ||
        error.response?.status === 499)
    ) ||
    (!isLoading && !token);

  const hasRole = useCallback(
    (role: Role) => {
      const roles = getRoles(token);
      return roles.includes(role);
    },
    [token]
  );

  const hasRoles = (requiredRoles: Role[] = []) => {
    const roles = getRoles(token);
    for (const role of requiredRoles) {
      if (!roles.includes(role)) {
        return false;
      }
    }
    return true;
  };

  return {
    userId: !checked ? undefined : data?.data.userId || '',
    member: data?.data || null,
    guest,
    isLoading,
    isLoggedOut,
    isLogged: !!data?.data,
    isGuest,
    isBlocking: isGuest || data?.data.emailVerified !== true,
    blockerTip: isGuest
      ? t('feature.disabled.guest', 'Sign up to access full features')
      : data?.data.emailVerified === false
      ? t('feature.disabled.unverified')
      : '',
    blockerType: isGuest
      ? AccountBlockerType.Unclaimed
      : data?.data.emailVerified === false
      ? AccountBlockerType.Unverified
      : null,
    mutate,
    hasRole,
    hasRoles,
    guestLogout,
    guestLogin,
  };
};
