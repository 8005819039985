import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SanityProductType, useProductOrderInfo } from '@lib/web/apis';

import useMarketplace from './useMarketplace';

export type ActionProperties = {
  label: string;
  icon?: ReactNode;
  disabled: boolean;
  action?:
    | 'buy-now'
    | 'set-profile-pic'
    | 'reset-profile-pic'
    | 'copy-payment-link'
    | 'open-detail';
  type?: 'text' | 'button';
  tooltip?: string;
  orderInfo?: GetProductOrderInfoRes;
};

export default function useProductAction(
  product: GetSanityProductRes | null,
  detailPanel?: boolean,
  currentProfilePic?: string
): ActionProperties {
  const { t } = useTranslation('marketplace');
  const { myProductIds, myProductsLoading } = useMarketplace();
  const { data: existingOrderInfo } = useProductOrderInfo(product?.id);
  const orderInfo = existingOrderInfo?.data;

  // Default: when products is loading => show Buy now but disabled
  if (!product || myProductsLoading) {
    return { label: t('button.Buy Now'), disabled: true, orderInfo };
  }
  const { id, status, quantityStock, quantitySold, imageUrl } = product;
  const owned = myProductIds && myProductIds.includes(id);

  // if the user is the owner of the product => show Owned in the product item
  if (owned && !detailPanel) {
    return { label: 'Owned', disabled: true, orderInfo };
  }

  // if the user is owner of the product and the button show in the detail panel
  if (owned && detailPanel) {
    if (currentProfilePic === imageUrl) {
      return {
        label: t('button.Current Profile Pic'),
        disabled: false,
        action: 'reset-profile-pic',
        type: 'text',
        tooltip: t('Remove avatar from profile picture'),
      };
    }
    return {
      label: t('button.Set as Profile Pic'),
      disabled: false,
      action: 'set-profile-pic',
    };
  }

  // The user has created a product order but has not yet paid (existing orderInfo)
  if (orderInfo && id === orderInfo.productId) {
    if (detailPanel) {
      // show Copy Link button in the right panel
      return {
        label: t('button.Copy Link'),
        disabled: false,
        action: 'copy-payment-link',
        orderInfo,
      };
    }
    // show Reserved in the product item (disabled = false => the user can click to open detail panel)
    return {
      label: t('button.Reserved'),
      disabled: false,
      action: 'open-detail',
      orderInfo,
    };
  }

  // if another user is making a payment => show Reserved and disabled
  if (quantityStock === 0 && quantitySold === 0) {
    return { label: t('button.Reserved'), disabled: true, orderInfo };
  }

  // Sold out
  if (
    (status === SanityProductType.Unavailable || quantityStock === 0) &&
    quantitySold >= 1
  ) {
    return { label: t('button.Sold Out'), disabled: true, orderInfo };
  }

  // Buy now: when the product is available and quantity > 0
  if (status === SanityProductType.Available && quantityStock > 0) {
    return {
      label: t('button.Buy Now'),
      disabled: false,
      action: 'buy-now',
      orderInfo,
    };
  }

  // Coming soon
  if (status === SanityProductType.ComingSoon) {
    return { label: t('button.Coming Soon'), disabled: true, orderInfo };
  }

  // Other
  return { label: t('button.Coming Soon'), disabled: true, orderInfo };
}
