import { MouseEvent, useEffect, useRef, useState } from 'react';
import Router from 'next/router';
import { Box } from '@mui/material';
import DiscountCodeCard from '@app/web/src/components/DiscountCodeCard';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { apis, PromoCodeQueryType, useMyPromoCode } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import DiscountCodePinChats from './DiscountCodePinChats';

export default function SharedDiscountCodeCard({
  discountCode,
  sender,
}: GetThreadMessageNotificationPayloadRes) {
  const boxRef = useRef();
  const currentUserId = useCurrentUserId();
  const [isLastElement, setIsLastElement] = useState(false);
  const { toggleRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const { data: availableCodes, mutate } = useMyPromoCode(
    PromoCodeQueryType.Available
  );
  const { data: usedCodes } = useMyPromoCode(PromoCodeQueryType.UsedOrInvalid);

  const codeDetail =
    availableCodes?.data.find(
      (d) => d.discountCode === discountCode?.discountCode
    ) ||
    usedCodes?.data.find((d) => d.discountCode === discountCode?.discountCode);

  const disabled = !availableCodes && !usedCodes;
  const isSelf = currentUserId === sender?.userId;
  const isAdded = !!codeDetail;
  const isUsed = usedCodes?.data.some(
    (d) => d.discountCode === discountCode?.discountCode
  );
  const savePromo = async () => {
    if (!discountCode) return;
    const [res] = await call(
      apis.marketplace.addPromoCode(discountCode.discountCode)
    );
    if (res) {
      mutate();
    }
  };
  const handleSave = async (ev: MouseEvent) => {
    ev.stopPropagation();
    await savePromo();
    toggleRightPanel(GlobalPanelKeys.GlobalAddDiscount);
  };
  const handleApply = async (ev: MouseEvent) => {
    ev.stopPropagation();
    if (!isAdded) await savePromo();
    await Router.push('/profile/my-plan');
  };

  useEffect(() => {
    const elements = document.querySelectorAll<HTMLElement>(
      '[data-testid="discount-code-receiver"]'
    );
    if (elements[elements.length - 1] === boxRef.current) {
      setIsLastElement(true);
    }
  }, []);

  return (
    <Box
      ref={boxRef}
      data-testid={isSelf ? 'discount-code-sender' : 'discount-code-receiver'}
    >
      {!!discountCode && (
        <DiscountCodeCard
          code={{
            discountCode: discountCode.discountCode,
            percentOff: discountCode.discountPercentage,
            amountOff: 0,
          }}
          onApply={handleApply}
          onSave={handleSave}
          hideAction={isSelf}
          disabled={disabled}
          isAdded={isAdded}
          isUsed={isUsed}
        />
      )}
      {isLastElement && !isSelf && <DiscountCodePinChats />}
    </Box>
  );
}
