import Link from 'next/link';
import { EmptySection, TextButton } from '@front/ui';

import Icon from '../../components/Icon';
import { useIaAction } from '../../core/IaAction/useIaAction';

import { EmptyLayoutConfig } from './types';

export default function EmptyLayout({
  settings,
  text,
  action,
}: Omit<EmptyLayoutConfig, 'layout'>) {
  const { getIaAction } = useIaAction();

  const handleClick = () => {
    if (!action) return;
    const clickAction = getIaAction(action.value);
    clickAction?.action();
  };

  const buttonProps =
    action?.type === 'link'
      ? { LinkComponent: Link, href: action.value }
      : { onClick: handleClick };
  return (
    <EmptySection
      sx={settings?.sx}
      text={text}
      actionComponent={
        action ? (
          <TextButton
            prefixIcon={action.icon ? <Icon name={action.icon} /> : undefined}
            {...buttonProps}
          >
            {action.text}
          </TextButton>
        ) : undefined
      }
    />
  );
}
