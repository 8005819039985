import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { ProfileBilling as ProfileBillingIcon } from '@front/icon';
import { Button } from '@front/ui';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type UpdatePaymentButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.membership.update.payment' }>;
};

export default function UpdatePaymentButton({ cta }: UpdatePaymentButtonProps) {
  const { t } = useTranslation('notification');

  return (
    <Box sx={styles.root}>
      <Button
        variant="filled"
        prefixIcon={<ProfileBillingIcon />}
        component={Link}
        href={cta.updatePaymentLink}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {t('button.Update Payment')}
      </Button>
    </Box>
  );
}
