import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import {
  OtherProbabilityHalf as OtherProbabilityHalfIcon,
  OtherProbabilityLikely as OtherProbabilityLikelyIcon,
  OtherProbabilityRare as OtherProbabilityRareIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import { HashtagAppearing } from '@lib/web/apis';
import { PaidFeatureBlockerType } from '@lib/web/ui';

import { usePaidFeatureBlocker } from '../../../BlockerControl';
import { CreateQuizFormValue } from '../../type';

const defaultValue = [
  HashtagAppearing.Half,
  HashtagAppearing.Likely,
  HashtagAppearing.Rare,
];

const styles = {
  blockerOverlay: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
export default function AppearingChips({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation('quiz');
  const { control } = useFormContext<CreateQuizFormValue>();
  const { openBlocker, isLoading, isFeatureBlocked } = usePaidFeatureBlocker();

  return (
    <Box position="relative">
      <Controller
        name="appearing"
        defaultValue={defaultValue}
        control={control}
        render={({ field: { value, onChange } }) => {
          const isSelected = (option: HashtagAppearing) =>
            value.includes(option);

          const toggleValue = (option: HashtagAppearing) => {
            if (isSelected(option)) {
              onChange(value.filter((v) => v !== option));
            } else {
              onChange([...value, option]);
            }
          };
          return (
            <Box display="flex" gap={1}>
              <ResponsiveTooltip
                titleIcon={<OtherProbabilityRareIcon width={16} height={16} />}
                title={t(
                  'createQuiz.settings.appearing.rare.hint.title',
                  'Probability of Appearing: Rare'
                )}
                content={t(
                  'createQuiz.settings.appearing.rare.hint.content',
                  'Less likely to appear on the official exam.'
                )}
                tooltipProps={{ followCursor: true }}
              >
                <Chip
                  label={t('createQuiz.settings.appearing.rare.title', 'Rare')}
                  icon={<OtherProbabilityRareIcon width={16} height={16} />}
                  clickable
                  disabled={disabled || isLoading}
                  variant={
                    isSelected(HashtagAppearing.Rare) ? 'filled' : 'outlined'
                  }
                  onClick={() => toggleValue(HashtagAppearing.Rare)}
                />
              </ResponsiveTooltip>
              <ResponsiveTooltip
                titleIcon={<OtherProbabilityHalfIcon width={16} height={16} />}
                title={t(
                  'createQuiz.settings.appearing.half.hint.title',
                  'Probability of Appearing: 50/50'
                )}
                content={t(
                  'createQuiz.settings.appearing.half.hint.content',
                  '50% likely to appear on the official exam.'
                )}
                tooltipProps={{ followCursor: true }}
              >
                <Chip
                  label={t('createQuiz.settings.appearing.half.title', '50/50')}
                  icon={<OtherProbabilityHalfIcon width={16} height={16} />}
                  clickable
                  disabled={disabled || isLoading}
                  variant={
                    isSelected(HashtagAppearing.Half) ? 'filled' : 'outlined'
                  }
                  onClick={() => toggleValue(HashtagAppearing.Half)}
                />
              </ResponsiveTooltip>
              <ResponsiveTooltip
                titleIcon={
                  <OtherProbabilityLikelyIcon width={16} height={16} />
                }
                title={t(
                  'createQuiz.settings.appearing.likely.hint.title',
                  'Probability of Appearing: Likely'
                )}
                content={t(
                  'createQuiz.settings.appearing.likely.hint.content',
                  'Highly likely to appear on the official exam..'
                )}
                tooltipProps={{ followCursor: true }}
              >
                <Chip
                  label={t(
                    'createQuiz.settings.appearing.likely.title',
                    'likely/50'
                  )}
                  icon={<OtherProbabilityLikelyIcon width={16} height={16} />}
                  clickable
                  disabled={disabled || isLoading}
                  variant={
                    isSelected(HashtagAppearing.Likely) ? 'filled' : 'outlined'
                  }
                  onClick={() => toggleValue(HashtagAppearing.Likely)}
                />
              </ResponsiveTooltip>
            </Box>
          );
        }}
      />
      {isFeatureBlocked.selectIncognitoMode && (
        <Box
          onClick={() =>
            openBlocker(PaidFeatureBlockerType.SelectIncognitoMode, 'rhs')
          }
          sx={styles.blockerOverlay}
        />
      )}
    </Box>
  );
}
