import api from './client';

const funcApi = {
  updateFuncHighlights: (params: UpdateFuncHighlight) => {
    return api.patch('/api/func/highlight', params, { baseURL: '' });
  },
  threadRecompletion: (params: ThreadRecompletion) => {
    return api.post('/func/thread/recompletion', params);
  },
  threadUpdateUpgradeMessage: (params: ThreadUpdateUpgradeMessage) => {
    return api.post('/api/func/thread/update-upgrade-message', params, {
      baseURL: '',
    });
  },
  threadEditMessage: (params: ThreadEditMessage) => {
    return api.put('/func/thread/edit', params);
  },
  getQuestionMetadata: (clubSlug?: string) => {
    return api.get<Response<GetQuestionMetadataRes>>(
      `/func/qbank/question-metadata?${clubSlug ? `clubSlug=${clubSlug}` : ''}`
    );
  },
  getQuestionDetail: (shortId: string) => {
    return api.get<Response<GetQuestionDetailRes>>(
      `/func/qbank/question/${shortId}`
    );
  },
  sendDownloadPdf: (params: PostSendDownloadPdfReq) => {
    return api.post<Response<PostSendDownloadPdfRes>>(
      '/func/qbank/pdf/send',
      params
    );
  },
  checkAuth: (state: string) => {
    return api.get(`/func/auth/check?state=${state}`);
  },
};

export default funcApi;
