import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';

import LandscapeBlocker from './components/LandscapeBlocker';
import LoginBlocker from './components/LoginBlocker';
import OnboardingBlocker from './components/OnboardingBlocker';
import ReferralBlocker from './components/ReferralBlocker';

export default function CommonBlocker() {
  const router = useRouter();
  const blocker = router.query.blocker;
  const { isLoading } = useAuth();

  if (blocker === 'referral') return <ReferralBlocker />;

  if (blocker === 'onboarding' || blocker === 'claim-account')
    return <OnboardingBlocker />;

  if (isLoading) return null;

  return (
    <>
      <LoginBlocker />
      <LandscapeBlocker />
    </>
  );
}
