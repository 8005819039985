export const enum SharedNotificationTag {
  DmSharedAvatarGlobal = 'dm.shared.avatar.global',
  DmSharedClubGroupGlobal = 'dm.shared.club.group.global',
  DmSharedClubGlobal = 'dm.shared.club.global',
  DmSharedAgentGlobal = 'dm.shared.agent.global',
  DmSharedGeneralPageGlobal = 'dm.shared.general.page.global',
  DmSharedGeneralPageClub = 'dm.shared.general.page.club',
  DmSharedChallengeClub = 'dm.shared.challenge.club',
  DmSharedPlaylistClub = 'dm.shared.playlist.club',
  DmSharedDynamicPlaylistClub = 'dm.shared.dynamic.playlist.club',
  DmSharedResultClub = 'dm.shared.result.club',
  DmSharedEditorQuestionClub = 'dm.shared.editor.question.club',
  DmSharedQuestionClub = 'dm.shared.question.club',
  DmSharedDiscountCode = 'dm.shared.discount.code.global',
}

export const enum SharedNotificationPathname {
  ClubSummary = '/club/[slug]/summary',
  ClubAnalyticsMyPath = '/club/[slug]/analytics/my-path',
  ClubAnalyticsGoals = '/club/[slug]/analytics/goals',
  ClubAnalyticsWeakness = '/club/[slug]/analytics/weakness/[category]',
  ClubAnalyticsMistakes = '/club/[slug]/analytics/mistakes/[category]',
  ClubChallengeDetails = '/club/[slug]/challenge/[quizId]',
  ClubPlaylistDetails = '/club/[slug]/playlist/[quizId]',
  ClubPlaylistsGroupMark = '/club/[slug]/playlists/[group]/[markId]',
  ClubPlaylistsGroup = '/club/[slug]/playlists/[group]',
  ClubExamResult = '/club/[slug]/exam/[quizId]/[roundNo]/result',
  ClubPracticeResult = '/club/[slug]/practice/[quizId]/[roundNo]/result',
  ClubEditorQuestion = '/club/[slug]/editor/[questionId]',
  ClubReviewUserQuestion = '/club/[slug]/review/[userId]/[questionId]',
  ClubReviewQuestion = '/club/[slug]/review/all/[questionId]',
  ClubPracticeQuestion = '/club/[slug]/practice/[quizId]/[roundNo]',
  ClubExamQuestion = '/club/[slug]/exam/[quizId]/[roundNo]',
  ClubRankings = '/club/[slug]/rankings',
  ClubChallengePlaylists = '/club/[slug]/challenges',
  ClubHistoryPlaylists = '/club/[slug]/playlists/history',
  ClubSavedPlaylists = '/club/[slug]/playlists/saved',
  ClubEmojiPlaylists = '/club/[slug]/playlists/emoji',
  ClubFriendsPlaylists = '/club/[slug]/playlists/friends-playlists',
  ClubHashtagsPlaylists = '/club/[slug]/playlists/hashtags',
  ClubCreatorsPlaylists = '/club/[slug]/playlists/creators',
  ClubCreateQuestions = '/club/[slug]/create-questions',
  ClubReviewQuestions = '/club/[slug]/review-questions',
  ClubReviewAllQuestions = '/club/[slug]/review-questions/all',
  ClubReviewUserQuestions = '/club/[slug]/review-questions/[userId]',
  ProfileAccount = '/profile/account',
  ClubGroups = '/club-group',
  Clubs = '/',
  Agents = '/agents',
  MyAgents = '/agents/my-agents',
  ClubGroupDetails = '/club-group/[groupId]',
  ClubDetails = '/club/[slug]',
  AgentDetails = '/agents/[agentId]',
}
